import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import styled from "styled-components";

import { muiColors } from "../../../themes";
import { LinesLoader } from "../LinesLoader/LinesLoader";

import { Item } from "./Item";

const Root = styled.div`
  overflow-y: hidden;
`;

export type CommandProps = {
  loading?: boolean;
  children?: React.ReactNode;
  query?: string;
  onQueryChange?: (query: string) => void;
  onSelect?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  showItemsOnEmptyQuery?: boolean;
};

const InputContainer = styled.div`
  padding-inline: 16px;
`;

type CommandContextValue = {
  selectedValue: string | undefined;
  setSelectedValue: (value: string) => void;
  select: (value: string) => void;
};
export const CommandContext = React.createContext<CommandContextValue>({
  selectedValue: undefined,
  setSelectedValue: () => {
    return;
  },
  select: () => {
    return;
  },
});

export const VALUE_ATTR = "data-value";

const Command = ({
  children,
  query,
  onQueryChange,
  onSelect,
  onKeyDown,
  placeholder,
  showItemsOnEmptyQuery = false,
}: CommandProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  function queryValueElements(): HTMLElement[] {
    return Array.from(ref.current?.querySelectorAll(`[${VALUE_ATTR}]`) ?? []);
  }

  function findSelectedIndex(elements: HTMLElement[]) {
    return elements.findIndex(
      (v) => v.getAttribute(VALUE_ATTR) === selectedValue
    );
  }

  function getSelectedItem():
    | { index: number; element: HTMLElement }
    | undefined {
    const elements = queryValueElements();
    const index = findSelectedIndex(elements);
    if (index === -1) {
      return undefined;
    }
    return { index, element: elements?.[index] };
  }

  function scrollElementIntoView(element: HTMLElement | undefined) {
    element?.scrollIntoView({ block: "nearest" });
  }

  const [selectedValue, setSelectedValue] = useState<string | undefined>();
  useEffect(() => {
    setSelectedValue((prevValue) => {
      const values = queryValueElements().map((v) =>
        v.getAttribute(VALUE_ATTR)
      );
      if (!values.includes(prevValue)) {
        return queryValueElements()?.[0]?.getAttribute(VALUE_ATTR);
      }
      return prevValue;
    });
  }, [query, children]);

  function updateSelectedByChange(change: 1 | -1) {
    const values = queryValueElements();
    const selected = getSelectedItem();
    if (!selected) {
      return;
    }
    const item =
      values[Math.max(0, Math.min(values.length - 1, selected.index + change))];
    setSelectedValue(item.getAttribute(VALUE_ATTR));
    scrollElementIntoView(item);
  }

  const next = (e: React.KeyboardEvent) => {
    e.preventDefault();
    updateSelectedByChange(1);
  };

  const prev = (e: React.KeyboardEvent) => {
    e.preventDefault();
    updateSelectedByChange(-1);
  };

  const onInputChange = useCallback(
    (value: string) => {
      onQueryChange?.(value);
    },
    [onQueryChange]
  );

  const select = useCallback(
    (value: string) => {
      setSelectedValue(value);
      onSelect?.(value);
    },
    [onSelect]
  );

  const handleKeyDown = (e: React.KeyboardEvent) => {
    onKeyDown?.(e);

    if (!e.defaultPrevented) {
      switch (e.key) {
        case "n":
        case "j": {
          // vim keybind down
          if (e.ctrlKey) {
            next(e);
          }
          break;
        }
        case "ArrowDown": {
          next(e);
          break;
        }
        case "p":
        case "k": {
          // vim keybind up
          if (e.ctrlKey) {
            prev(e);
          }
          break;
        }
        case "ArrowUp": {
          prev(e);
          break;
        }
        case "Enter": {
          e.preventDefault();
          const item = getSelectedItem();
          item && onSelect?.(item.element.getAttribute(VALUE_ATTR));
          break;
        }
      }
    }
  };

  return (
    <Root ref={ref} onKeyDown={handleKeyDown}>
      <InputContainer>
        <TextField
          autoFocus={true}
          value={query}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder={placeholder}
          fullWidth={true}
          inputProps={{
            sx: { paddingTop: "20px", paddingBottom: "20px" },
          }}
          InputProps={{
            tabIndex: -1,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ marginRight: "13px" }}
                disablePointerEvents={true}
              >
                <Search />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </InputContainer>
      {showItemsOnEmptyQuery || query !== "" ? (
        <>
          <Divider sx={{ bgcolor: muiColors.gray[400] }} />
          <CommandContext.Provider
            value={{
              selectedValue,
              setSelectedValue,
              select,
            }}
          >
            {children}
          </CommandContext.Provider>
        </>
      ) : null}
    </Root>
  );
};

type ResultsProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
`;

const LoaderContainer = styled(Center)`
  height: 486px;
`;

const NoResultsContainer = styled(Center)`
  height: 486px;
`;

const ResultsRoot = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-auto-rows: min-content;
  overflow-y: auto;
  height: 486px;
  max-height: 486px;
`;

const isEmptyResultsChild = (child: React.ReactElement) => {
  return (
    typeof child.type !== "string" &&
    "displayName" in child.type &&
    child?.type.displayName &&
    child.type.displayName === "Empty"
  );
};

const isItem = (child: React.ReactNode): child is ReactElement => {
  return React.isValidElement(child) && !isEmptyResultsChild(child);
};

const Results = ({ children, loading }: ResultsProps) => {
  const items = useMemo(
    () => React.Children.toArray(children).filter(isItem),
    [children]
  );
  const empty = useMemo(
    () =>
      React.Children.toArray(children).filter(
        (child) => React.isValidElement(child) && isEmptyResultsChild(child)
      ),
    [children]
  );

  if (loading) {
    return (
      <LoaderContainer>
        <LinesLoader />
      </LoaderContainer>
    );
  }

  if (items.length === 0) {
    return <NoResultsContainer>{empty}</NoResultsContainer>;
  }

  return <ResultsRoot>{items}</ResultsRoot>;
};

type EmptyProps = {
  children?: React.ReactNode;
};
function Empty({ children }: EmptyProps) {
  return <NoResultsContainer>{children}</NoResultsContainer>;
}
Empty.displayName = "Empty";

const pkg = Object.assign(Command, {
  Item,
  Results,
  Empty,
});

export { pkg as Command };
