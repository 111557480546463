import React from "react";

import { LogoIconProps } from "../types";

export const OpenShift: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="OpenShift logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M22 7.52328C21.7761 7.061 21.5172 6.61484 21.2166 6.19409L18.006 7.36251C18.3794 7.74465 18.6931 8.1742 18.9497 8.63355L22 7.52328ZM7.80775 11.0743L4.59619 12.2427C4.63724 12.7578 4.72618 13.2665 4.85079 13.765L7.90158 12.6542C7.80238 12.1382 7.76719 11.6065 7.80775 11.0743Z"
        fill="#C22133"
      />
      <path
        d="M14.9351 6.61343C15.6031 6.9252 16.1817 7.35035 16.6699 7.8488L19.8805 6.68038C18.9911 5.4323 17.7797 4.38947 16.2955 3.69702C11.7054 1.55663 6.22936 3.54945 4.08946 8.13907C3.39652 9.62415 3.13899 11.2016 3.26018 12.7292L6.47126 11.5608C6.52452 10.8649 6.69409 10.1676 7.00538 9.49905C8.39614 6.51716 11.9532 5.22315 14.9351 6.61343Z"
        fill="#DB212E"
      />
      <path
        d="M18.3596 12.4807C18.3083 13.1761 18.1329 13.8734 17.8206 14.5424C16.4304 17.5248 12.8728 18.8188 9.89142 17.4285C9.22243 17.1162 8.63944 16.6945 8.15321 16.1951L4.94897 17.3611C5.83641 18.6091 7.04636 19.6524 8.53144 20.3454C13.1216 22.4853 18.5967 20.4925 20.737 15.9024C21.4305 14.4183 21.686 12.8408 21.5639 11.3147L18.3596 12.4807Z"
        fill="#DB212E"
      />
      <path
        d="M19.1491 8.56079L16.0983 9.67106C16.6652 10.6865 16.933 11.8554 16.845 13.0312L20.0493 11.8657C19.9574 10.7158 19.651 9.59336 19.1491 8.56079ZM5.05079 13.6915L2 14.8027C2.28001 15.9149 2.76624 16.9719 3.43475 17.9126L6.63849 16.7462C5.81605 15.9017 5.26923 14.833 5.05079 13.6915Z"
        fill="#EB2126"
      />
      <path
        d="M21.535 6.67739C21.4339 6.5132 21.3288 6.35144 21.2169 6.19409L18.0063 7.36251C18.1475 7.50716 18.2775 7.6606 18.4016 7.81795L21.535 6.67739ZM7.79556 11.7191C7.78774 11.505 7.79114 11.29 7.80775 11.0745L4.59619 12.2429C4.61281 12.4487 4.63871 12.6529 4.66949 12.8567L7.79556 11.7191Z"
        fill="#AD213B"
      />
      <path
        d="M21.5636 11.3147L18.3594 12.4807C18.3257 12.942 18.2358 13.4043 18.0887 13.8587L21.5763 12.5872C21.6013 12.1611 21.5973 11.7359 21.5636 11.3147ZM4.94922 17.3615C5.196 17.709 5.46917 18.0403 5.76579 18.3531L9.25395 17.081C8.84639 16.826 8.47793 16.5284 8.15296 16.1951L4.94922 17.3615Z"
        fill="#BA2133"
      />
    </svg>
  );
};
