import React from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

/** @deprecated */
export interface ProgressBarProps {
  progressPercentage: number;
  height: string;
  width?: string;
  backgroundColor?: string;
  lineColor?: string;
  isRound?: boolean;
  ariaLabel?: string;
}

const ProgressBar = styled.div.attrs({
  role: "progressbar",
})<{
  progressPercentage: number;
  height: string;
  width?: string;
  backgroundColor?: string;
  lineColor?: string;
  isRound?: boolean;
}>`
  width: ${({ width }) => (width ? width : "100%")};
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => height};
  padding-left: unset !important;
  ${({ isRound }) => isRound && "border-radius: 14px;"}
  &:after {
    content: "";
    height: ${({ height }) => height};
    width: ${({ progressPercentage }) => progressPercentage}%;
    background-color: ${({ lineColor }) => lineColor};
    display: block;
    ${({ isRound }) => isRound && "border-radius: 14px;"}
  }
`;

/**
 * @deprecated Use MUI LinearProgress instead.
 */
export const ProgressBarComponent: React.FC<ProgressBarProps> = (props) => {
  const progressPercentage =
    props.progressPercentage < 0
      ? 0
      : props.progressPercentage > 100
      ? 100
      : props.progressPercentage;
  return (
    <ProgressBar
      {...props}
      progressPercentage={progressPercentage}
      data-testid={"ProgressBar"}
      aria-label={props.ariaLabel}
    />
  );
};

ProgressBarComponent.defaultProps = {
  backgroundColor: theme.progressBar.background,
  lineColor: theme.progressBar.lineColor,
  isRound: false,
};
