import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { capitalize } from "lodash";

import { TabLabel, ViolationBreakdown } from "../types";
import { getAccumulatedViolations } from "../../risksListUtils";

const getTabLabel = (
  label: TabLabel,
  violations: ViolationBreakdown
): string => {
  const severityLabel = label === "all" ? "All Severities" : capitalize(label);
  const severityNumber =
    label === "all" ? getAccumulatedViolations(violations) : violations[label];
  return `${severityLabel} (${severityNumber})`;
};

type RiskDetailsTabsProps = {
  violationTotals: ViolationBreakdown;
  tabValue: TabLabel;
  onTabChange: (event: React.SyntheticEvent, newValue: string) => void;
};

export const RiskDetailsTabs: React.FC<RiskDetailsTabsProps> = ({
  tabValue,
  onTabChange,
  violationTotals,
}) => {
  return (
    <TabContext value={tabValue}>
      <TabList onChange={onTabChange}>
        <Tab label={getTabLabel("all", violationTotals)} value={"all"} />
        <Tab
          label={getTabLabel("critical", violationTotals)}
          value={"critical"}
        />
        <Tab label={getTabLabel("medium", violationTotals)} value={"medium"} />
        <Tab label={getTabLabel("low", violationTotals)} value={"low"} />
      </TabList>
    </TabContext>
  );
};
