import { muiColors, palette } from "@komodorio/design-system";
import { Dot16 } from "@komodorio/design-system/icons";
import WarningAmberOutlined from "@mui/icons-material/WarningAmberOutlined";
import styled from "styled-components";

export const OpenIssueAlertIcon = styled(WarningAmberOutlined)`
  && {
    font-size: 1.25rem;
  }
  color: ${palette.pink[600]};
  &:hover {
    cursor: pointer;
    color: ${palette.pink[700]};
  }
`;

export const HealthyIcon = styled(Dot16)`
  color: ${muiColors.teal[700]};
  &:hover {
    cursor: pointer;
    color: ${muiColors.teal[800]};
  }
`;

export const InactiveIcon = styled(Dot16)`
  color: ${muiColors.gray[300]};
  &:hover {
    cursor: pointer;
    color: ${muiColors.gray[400]};
  }
`;
