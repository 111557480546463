import React from "react";

import { IconProps } from "../types";

export const AlertTriangle16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18492 2.04441C6.97194 0.651862 9.02806 0.651863 9.81508 2.04441L15.7406 12.0126C16.4999 13.3561 15.5011 15 13.9255 15H2.07445C0.498862 15 -0.499937 13.3561 0.25937 12.0126L6.18492 2.04441ZM8 4.88861C7.57098 4.88861 7.22319 5.22707 7.22319 5.64458V9.18041C7.22319 9.59792 7.57098 9.93638 8 9.93638C8.42903 9.93638 8.77682 9.59792 8.77682 9.18041V5.64458C8.77682 5.22707 8.42903 4.88861 8 4.88861ZM8 10.9523C7.57098 10.9523 7.22319 11.2908 7.22319 11.7083C7.22319 12.1258 7.57098 12.4642 8 12.4642C8.42903 12.4642 8.77682 12.1258 8.77682 11.7083C8.77682 11.2908 8.42903 10.9523 8 10.9523Z"
      />
    </svg>
  );
};

AlertTriangle16.defaultProps = {
  fill: "currentColor",
};
