import React from "react";

import { NewViolationsGraph } from "../../../../../assets/NewViolationsGraph";

import { SmallStatsCard } from "./StatsCard/SmallStatsCard";

export const NewViolationsCard: React.FC = () => {
  return (
    <SmallStatsCard
      title={"New Violations"}
      mainStat={30}
      trend={"-5%"}
      epoch={"Last 7 days"}
      graphComponent={<NewViolationsGraph />}
    />
  );
};
