import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { theme } from "../../../theme";
import { Typography } from "../Typography/Typography";
import { Check16 } from "../../../icons";
import { palette } from "../../../palette";

/** @deprecated */
export type OptionVariant = "single" | "multi";
/** @deprecated */
export interface OptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: OptionVariant;
  subLabel?: string;
  selected?: boolean;
}

const Item = styled.button`
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  color: ${theme.menu.item.text};
  text-align: start;

  :hover:enabled {
    background-color: ${theme.menu.item.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.menu.item.pressedBg};
  }
  :disabled {
    color: ${theme.foreground.fgDisabled};
    cursor: not-allowed;
  }
`;

const SubLabel = styled(Typography)<{ disabled?: boolean }>`
  color: ${({ disabled }) =>
    disabled ? palette.gray[200] : palette.gray[500]};
`;

const Checked = styled.div`
  display: flex;
  width: 1rem;
`;

/**
 * @deprecated Use MUI MenuItem instead.
 */
export const Option: React.FC<OptionProps> = ({
  variant,
  selected,
  children,
  ...rest
}) => {
  const checked =
    variant === "multi" ? (
      <input
        type="checkbox"
        readOnly
        checked={selected}
        disabled={rest.disabled}
      />
    ) : selected ? (
      <Check16 fill={theme.foreground.fgSubtle} />
    ) : null;

  return (
    <Item {...rest}>
      <Checked>{checked}</Checked>
      <div>
        <Typography id="option-label" color="unset">
          {children}
        </Typography>
        <SubLabel disabled={rest.disabled} color="unset">
          {rest.subLabel}
        </SubLabel>
      </div>
    </Item>
  );
};

Option.defaultProps = {
  variant: "single",
  disabled: false,
};
