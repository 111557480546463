import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { PlayBold16 } from "@komodorio/design-system/icons";

import {
  AnalyticEvents,
  dispatchEvent,
} from "../../../../shared/hooks/analytics";
import { PodLogsMode } from "../../../../shared/hooks/podLogs/types";
import { useLogsViewerStore } from "../store/logsViewerStore";
import { selectIsLiveTailEnabledByUser } from "../store/logsViewerStoreSelectors";

import { useAutoFreezeLiveLogsScreen } from "./useAutoFreezeLiveLogsScreen";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { ListItemProps, LogsListLine } from "./LogsListLine";
import { LoadMoreButton } from "./MoreLogsBtn";
import {
  CONTAINER_ID,
  getEstimateLogHeight,
  getLogElementHeight,
} from "./logsListUtils";

const ListContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height + "px"};
  overflow: auto;
`;

const FloatingButton = styled(Button)`
  && {
    position: absolute;
    top: 2rem;
    right: 50%;
    transform: translateX(50%);
    border-color: white;
  }
`;

const ListContainerInner = styled.div<{ height: number }>`
  width: 100%;
  position: relative;
  height: ${({ height }) => height + "px"};
`;

export interface LogsListProps extends ListItemProps {
  mode: PodLogsMode;
  hasFailureMessage: boolean;
  containerHeight: number;
  containerWidth: number;
  moreLogsLoading: boolean;
  onLoadMoreClicked?: () => void;
}

const DELAY_BEFORE_NEW_LOGS_AVAILABLE = 1000;

export const LogsList: React.FC<LogsListProps> = ({
  mode,
  hasFailureMessage,
  containerHeight,
  onLoadMoreClicked,
  moreLogsLoading,
  ...props
}) => {
  const parentRef = useRef(null);
  const prevEnableTextWrapping = useRef(props.enableTextWrapping);
  const [newLogsArrived, setNewLogsArrived] = useState(false);

  const rowVirtualizer = useVirtualizer({
    count: props.logs.length,
    getScrollElement: () => parentRef.current ?? null,
    paddingEnd: props.enableTextWrapping ? 100 : 0,
    estimateSize: (index: number) =>
      getEstimateLogHeight(
        props.enableTextWrapping,
        props.logs[index],
        props.containerWidth
      ),
  });

  const virtualElements = rowVirtualizer.getVirtualItems();

  // without this manual resize, the virtualizer will not resize the items, meaning that text-wrapping will not work
  useEffect(() => {
    const textWrapChanged =
      prevEnableTextWrapping.current !== props.enableTextWrapping;

    if (textWrapChanged) {
      prevEnableTextWrapping.current = props.enableTextWrapping;
      rowVirtualizer.measure();
    }
  }, [
    props.containerWidth,
    props.enableTextWrapping,
    props.logs,
    rowVirtualizer,
    virtualElements,
  ]);

  // set the correct height for each log element from the actual DOM element
  useEffect(() => {
    if (!props.enableTextWrapping) {
      return;
    }

    virtualElements.forEach((virtualItem, idx) => {
      const elHeight = getLogElementHeight(idx);
      if (elHeight !== undefined) {
        rowVirtualizer.resizeItem(virtualItem, elHeight);
      }
    });
  }, [props.enableTextWrapping, rowVirtualizer, virtualElements]);

  const isFirstLineDisplayed = virtualElements[0]?.index === 0;
  const isLastLineDisplayed =
    virtualElements[virtualElements.length - 1]?.index ===
    props.logs.length - 1;
  const isLiveTailEnabledByUser = useLogsViewerStore(
    selectIsLiveTailEnabledByUser
  );

  useAutoFreezeLiveLogsScreen({
    isFirstLineDisplayed,
    logsLength: props.logs.length,
  });

  useEffect(() => {
    setTimeout(() => {
      setNewLogsArrived(true);
    }, DELAY_BEFORE_NEW_LOGS_AVAILABLE);
  }, [props.logs.length, setNewLogsArrived]);

  const scrollToTop = () => {
    setNewLogsArrived(false);
    dispatchEvent(AnalyticEvents.Logs.Live.ResourceLogsNewLogsAvailableClicked);
    rowVirtualizer.scrollToIndex(0);
  };

  const shouldShowScrollToTop =
    mode === PodLogsMode.Live &&
    !isFirstLineDisplayed &&
    props.logs.length > 0 &&
    !hasFailureMessage &&
    isLiveTailEnabledByUser &&
    newLogsArrived;

  const shouldShowLoadMoreButton =
    mode === PodLogsMode.ByDemand && onLoadMoreClicked && isLastLineDisplayed;

  return (
    <>
      <ListContainer ref={parentRef} height={containerHeight}>
        <ListContainerInner
          height={rowVirtualizer.getTotalSize()}
          id={CONTAINER_ID}
        >
          {virtualElements.map((virtualItem) => (
            <LogsListLine
              {...props}
              key={virtualItem.key}
              index={virtualItem.index}
              style={{
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            />
          ))}
        </ListContainerInner>
      </ListContainer>
      {shouldShowScrollToTop && (
        <FloatingButton
          variant="contained"
          onClick={scrollToTop}
          startIcon={<PlayBold16 />}
          size="medium"
        >
          New logs available
        </FloatingButton>
      )}
      {shouldShowLoadMoreButton && (
        <LoadMoreButton onClick={onLoadMoreClicked} loading={moreLogsLoading} />
      )}
    </>
  );
};
