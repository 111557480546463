import React, { useCallback, useMemo, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  DropDownTriggerField,
  TabbedSelectPopover,
} from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Plus16 } from "@komodorio/design-system/icons";

import { CREATE_WORKSPACE_ROUTE } from "../../routes/routes";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";

import { SelectedWorkspaceValue } from "./SelectedWorkspaceValue";
import {
  useNavigateToWorkspace,
  useSyncCurrentWorkspaceIdToUrlIfMissing,
  useTabs,
  useWorkspaceOptions,
  useWorkspaces,
} from "./hooks";
import { ALL_CLUSTERS, ALL_CLUSTERS_VALUE } from "./constants";

const Dropdown = styled(DropDownTriggerField)`
  width: unset;
`;

export const WorkspaceSelector: React.FC = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const [selectedTab, setSelectedTab] = useState("clusters");
  const { currentWorkspaceId, workspaces, isLoading } = useWorkspaces();
  const { filteredOptions, clusterOptions, workspacesOptions } =
    useWorkspaceOptions(query, workspaces);
  const tabs = useTabs({
    numWorkspaces: workspacesOptions.length,
    numClusters: clusterOptions.length,
    numClusterGroups: 0,
  });
  const navigateToWorkspace = useNavigateToWorkspace();

  useSyncCurrentWorkspaceIdToUrlIfMissing();

  const selectWorkspace = useCallback(
    (id?: string) => {
      const workspace = workspaces?.find((w) => w.id === id);
      navigateToWorkspace(workspace);
      setAnchorEl(null);
      dispatchEvent(AnalyticEvents.Workspaces.WorkspaceSelected, {
        workspaceName: workspace?.name ?? ALL_CLUSTERS,
        workspaceKind: workspace?.kind,
      });
    },
    [navigateToWorkspace, workspaces]
  );

  const selectedWorkspace = useMemo(() => {
    if (!workspaces) {
      return undefined;
    }
    if (!currentWorkspaceId) {
      return ALL_CLUSTERS_VALUE;
    }
    const foundWorkspace = filteredOptions.find(
      (o) => o.value === currentWorkspaceId
    );

    return foundWorkspace ?? ALL_CLUSTERS_VALUE;
  }, [currentWorkspaceId, filteredOptions, workspaces]);

  return (
    <>
      <div aria-label={AriaLabels.Workspaces.Selector}>
        <Dropdown
          onClick={(e) => setAnchorEl(e.currentTarget)}
          dropDownOpen={isOpen}
          size="large"
        >
          <SelectedWorkspaceValue selectedWorkspace={selectedWorkspace} />
        </Dropdown>
        <TabbedSelectPopover
          query={query}
          onQueryChange={setQuery}
          loading={isLoading}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => setAnchorEl(null)}
          tabs={tabs}
          items={
            selectedTab === "clusters" ? clusterOptions : workspacesOptions
          }
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
          selectedItem={selectedWorkspace?.value ?? ""}
          onSelectItem={selectWorkspace}
          open={isOpen}
        >
          {selectedTab === "workspaces" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <NavLink
                to={CREATE_WORKSPACE_ROUTE}
                state={{
                  origin: window.location.origin,
                }}
              >
                <Button
                  variant="contained"
                  startIcon={<Plus16 />}
                  size="small"
                  sx={{ padding: "5px 8px" }}
                  onClick={() => {
                    navigate(CREATE_WORKSPACE_ROUTE);
                  }}
                >
                  New workspace
                </Button>
              </NavLink>
            </Box>
          ) : null}
        </TabbedSelectPopover>
      </div>
    </>
  );
};
