import React from "react";

import { IconProps } from "../types";

export const UnlimitedClusters24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2636 7.32709L12.113 3.8121C12.0416 3.777 11.9584 3.777 11.887 3.8121L4.73641 7.32709C4.665 7.36219 4.61314 7.42857 4.59551 7.50744L2.82946 15.4056C2.81182 15.4844 2.83033 15.5672 2.87974 15.6304L7.82811 21.9642C7.87753 22.0275 7.95246 22.0643 8.03171 22.0643H15.9683C16.0475 22.0643 16.1225 22.0275 16.1719 21.9642L21.1203 15.6304C21.1697 15.5672 21.1882 15.4844 21.1705 15.4056L19.4045 7.50744C19.3869 7.42857 19.335 7.36219 19.2636 7.32709ZM12.9039 2.13564C12.3327 1.85485 11.6673 1.85485 11.0961 2.13564L3.94548 5.65064C3.37426 5.93143 2.95939 6.46245 2.81831 7.09339L1.05225 14.9915C0.911172 15.6224 1.05924 16.2846 1.45453 16.7906L6.40291 23.1244C6.7982 23.6304 7.39771 23.925 8.03171 23.925H15.9683C16.6023 23.925 17.2018 23.6304 17.5971 23.1244L22.5455 16.7906C22.9408 16.2846 23.0888 15.6224 22.9477 14.9915L21.1817 7.09339C21.0406 6.46245 20.6257 5.93143 20.0545 5.65064L12.9039 2.13564Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.09551 9.79059C6.80997 9.79059 5.87623 10.2913 5.29551 11.0656C4.75113 11.7914 4.59551 12.651 4.59551 13.2906C4.59551 14.0808 4.96816 14.9315 5.53043 15.5741C6.10925 16.2356 6.99078 16.7906 8.09551 16.7906C10.0615 16.7906 11.2262 15.4954 11.883 14.6035C12.165 14.9276 12.5026 15.2851 12.8801 15.6105C13.5453 16.184 14.4841 16.7906 15.5955 16.7906C16.7002 16.7906 17.5818 16.2356 18.1606 15.5741C18.7229 14.9315 19.0955 14.0808 19.0955 13.2906C19.0955 12.651 18.9399 11.7914 18.3955 11.0656C17.8148 10.2913 16.8811 9.79059 15.5955 9.79059C14.4538 9.79059 13.4788 10.3764 12.7852 10.9663C12.4403 11.2595 12.1345 11.5794 11.8771 11.8877C11.1911 10.9539 10.0022 9.79059 8.09551 9.79059ZM6.59551 13.2906C6.59551 12.9301 6.68989 12.5397 6.89551 12.2656C7.06479 12.0399 7.38105 11.7906 8.09551 11.7906C9.18468 11.7906 9.87302 12.4984 10.3796 13.239C9.75402 14.0864 9.23368 14.7906 8.09551 14.7906C7.70024 14.7906 7.33177 14.5956 7.03559 14.2571C6.72286 13.8997 6.59551 13.5004 6.59551 13.2906ZM17.0955 13.2906C17.0955 12.9301 17.0011 12.5397 16.7955 12.2656C16.6262 12.0399 16.31 11.7906 15.5955 11.7906C15.1372 11.7906 14.6122 12.0381 14.0808 12.4899C13.8001 12.7286 13.552 12.9956 13.3514 13.2438C13.5989 13.5315 13.8791 13.8312 14.186 14.0957C14.7291 14.5639 15.2069 14.7906 15.5955 14.7906C15.9908 14.7906 16.3593 14.5956 16.6554 14.2571C16.9682 13.8997 17.0955 13.5004 17.0955 13.2906Z"
    />
  </svg>
);

UnlimitedClusters24.defaultProps = {
  fill: "currentColor",
};
