import React from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";

import { theme } from "../../../theme";

/** @deprecated */
export type DividerVariant = "horizontal" | "vertical";
/** @deprecated */
export interface DividerProps {
  variant?: DividerVariant;
  spacing?: string;
}

const Container = styled.div.attrs({
  "data-testid": "divider",
  role: "separator",
})<DividerProps>`
  ${({ spacing }) =>
    switchProp("variant", {
      horizontal: `border-bottom: 1px solid ${theme.divider}; margin-block: ${spacing};`,
      vertical: `border-right: 1px solid ${theme.divider}; margin-inline: ${spacing};`,
    })}
`;

/**
 * @deprecated Use MUI Divider instead.
 */
export const Divider: React.FC<DividerProps> = (props) => {
  return <Container {...props} />;
};

Divider.defaultProps = {
  variant: "horizontal",
  spacing: "0",
};
