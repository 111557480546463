import React from "react";

import { IconProps } from "../types";

export const ChevronRight16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.21934 4.21934C6.35997 4.07889 6.55059 4 6.74934 4C6.94809 4 7.13871 4.07889 7.27934 4.21934L10.5293 7.46934C10.6698 7.60997 10.7487 7.80059 10.7487 7.99934C10.7487 8.19809 10.6698 8.38871 10.5293 8.52934L7.27934 11.7793C7.13717 11.9118 6.94912 11.9839 6.75482 11.9805C6.56052 11.9771 6.37513 11.8984 6.23772 11.761C6.10031 11.6235 6.02159 11.4382 6.01817 11.2439C6.01474 11.0496 6.08686 10.8615 6.21934 10.7193L8.93934 7.99934L6.21934 5.27934C6.07889 5.13871 6 4.94809 6 4.74934C6 4.55059 6.07889 4.35997 6.21934 4.21934Z"
    />
  </svg>
);

ChevronRight16.defaultProps = {
  fill: "currentColor",
};
