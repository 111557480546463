export enum StepsNumber {
  Welcome = 0,
  Cluster_Name,
  Install_Command,
  Wait_For_Connection,
}

export enum InstallationScriptStepsNumber {
  Script_Installation = 0,
  Check_Connection,
}

export enum InstallScriptTabs {
  Bash_Tab = 0,
  Powershell_Tab,
  Helm_Tab,
}
