import React from "react";
import { useMenu } from "./useMenu";
import { Menu } from "@mui/material";
import Button from "@mui/material/Button";

interface MenuProps {
  id: string;
  items: any;
  LinkComponent: React.ElementType;
  tooltip?: string;
  ariaLabels?: {
    [key: string]: string;
  };
  children?: React.ReactNode;
  buttonComponent?: React.ElementType;
}

export const ContextMenu: React.FC<MenuProps> = ({
  id,
  items,
  tooltip,
  ariaLabels,
  LinkComponent,
  children,
  buttonComponent,
}: MenuProps) => {
  const {
    BUTTON_ID,
    MENU_ID,
    TooltipWrapper,
    anchorEl,
    handleClick,
    handleClose,
    menuItems,
    open,
  } = useMenu({
    id,
    tooltip,
    items,
    LinkComponent,
  });

  const Component = buttonComponent || Button;

  return (
    <>
      <TooltipWrapper>
        <Component
          id={BUTTON_ID}
          aria-controls={open ? MENU_ID : undefined}
          aria-haspopup="true"
          aria-label={ariaLabels?.button}
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {children}
        </Component>
      </TooltipWrapper>
      <Menu
        id={MENU_ID}
        MenuListProps={{
          "aria-labelledby": BUTTON_ID,
        }}
        aria-label={ariaLabels?.menu}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems}
      </Menu>
    </>
  );
};
