import React from "react";
import { Link, useLocation } from "react-router-dom";
import { VerticalTabsMenu } from "@komodorio/design-system/komodor-ui";

import {
  USER_API_KEYS_ROUTE,
  USER_EMAIL_SETTINGS_ROUTE,
  USER_PROFILE_ROUTE,
} from "@/components/routes/routes";

const TABS = [
  {
    title: "Profile",
    route: USER_PROFILE_ROUTE,
  },
  {
    title: "Email Settings",
    route: USER_EMAIL_SETTINGS_ROUTE,
    disabled: true,
  },
  {
    title: "API Keys",
    route: USER_API_KEYS_ROUTE,
  },
];

export const UserSettingsWrapper: React.FC<{
  title: string;
  children: React.ReactNode;
}> = ({ title, children }) => {
  const location = useLocation();
  return (
    <VerticalTabsMenu
      title={title}
      items={TABS}
      currentTab={location.pathname}
      linkComponent={Link}
    >
      {children}
    </VerticalTabsMenu>
  );
};
