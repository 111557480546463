import { useMemo } from "react";

import {
  supportedDynamicCheckTypes,
  supportedStaticCheckTypes,
} from "../policyDrawerConstants";

import { useIsDynamicChecksTab } from "./useIsDynamicChecksTab";

export const useSupportedCheckTypesByTab = () => {
  const isDynamicChecksTab = useIsDynamicChecksTab();

  return useMemo(() => {
    return isDynamicChecksTab
      ? supportedDynamicCheckTypes
      : supportedStaticCheckTypes;
  }, [isDynamicChecksTab]);
};
