import React, { useCallback, useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

import { H3Poppins, TextRegular } from "../../../common/typography";
import { dispatchEvent } from "../../../../shared/hooks/analytics";
import { AnalyticEvents } from "../../../../shared/config/analyticsEvents";
import { DarkenOverlay } from "../../../common/Overlay";
import { shadowCenteredModalStyles } from "../../../common/Modal/modal.styles";
import {
  blackGreyText,
  blueBrand,
  grayBorder,
  redForDelete,
} from "../../../../Colors";
import Button from "../../../common/controls/Button";
import Trash from "../../../../components/monitorsView/assets/trash.svg?react";
import { useUpdateInstallation } from "../../../../shared/hooks/accounts-service/client/installations/useUpdateInstallation";

import { Installation } from "./useInstallationSubscription";

const RemoveButtonContainer = styled.div`
  position: absolute;
  z-index: 10;
  cursor: pointer;
  padding: 0.4rem 0.4rem 0.3rem 0.4rem;
  border: 1px solid #dcdddf;
  border-radius: 4px;
  right: 1rem;
  top: 1rem;
`;

const StyledTrash = styled(Trash)`
  width: 1rem;
  height: 1rem;
  fill: ${blackGreyText};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: [title] 1rem [body] 1fr [buttons];
  grid-row-gap: 2rem;
  height: 15.25rem;
  width: 38rem;
`;

const StyledTitle = styled(H3Poppins)`
  color: ${blackGreyText};
  grid-row: title / body;
  display: grid;
  align-content: center;
`;

const Content = styled.div`
  grid-row: body / buttons;
  align-content: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-row: buttons / last-line;
  gap: 1rem;
`;

const StyledButton = styled(Button)<{ isDelete?: boolean }>`
  height: 2rem;
  border-radius: 0.25rem;
  cursor: pointer;
  ${({ isDelete }) =>
    isDelete
      ? `color: white; background-color: ${redForDelete}`
      : `color: ${blueBrand}; background-color: white; border: 1px solid ${grayBorder};`};
`;

const RemoveIntegrationModal: React.FC<{
  installation: Installation;
  show: boolean;
  handleClose: () => void;
}> = ({ installation, show, handleClose }) => {
  const { mutateAsync: updateIntegration } = useUpdateInstallation();

  const deleteInstallation = useCallback(async () => {
    await updateIntegration({
      id: installation.id,
      installationUpdateRequest: { isDeleted: true },
    });
    dispatchEvent(AnalyticEvents.Integrations.RemoveTile, {
      id: installation.id,
    });
    handleClose();
  }, [handleClose, installation.id, updateIntegration]);

  return (
    <>
      <DarkenOverlay open={show} />
      <Modal
        isOpen={show}
        style={shadowCenteredModalStyles}
        onRequestClose={handleClose}
      >
        <Container>
          <StyledTitle>Remove integration?</StyledTitle>
          <Content>
            <TextRegular>
              Are you sure you want to remove integration{" "}
              <b>{installation.integration}?</b>
              <br />
              All integrated connections will be lost.
            </TextRegular>
          </Content>
          <ButtonsContainer>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButton isDelete={true} onClick={deleteInstallation}>
              Delete
            </StyledButton>
          </ButtonsContainer>
        </Container>
      </Modal>
    </>
  );
};

const RemoveIntegrationButton: React.FC<{ installation: Installation }> = ({
  installation,
}) => {
  const [showDeletedIntegrationModal, setShowDeletedIntegrationModal] =
    useState(false);

  return (
    <>
      <RemoveIntegrationModal
        installation={installation}
        show={showDeletedIntegrationModal}
        handleClose={() => setShowDeletedIntegrationModal(false)}
      />

      <RemoveButtonContainer
        onClick={() => {
          setShowDeletedIntegrationModal(true);
        }}
      >
        <StyledTrash />
      </RemoveButtonContainer>
    </>
  );
};

export default RemoveIntegrationButton;
