import React from "react";
import { TypographyOptions } from "@mui/material/styles/createTypography";

import { palette } from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    body0: React.CSSProperties;
    body3: React.CSSProperties;
    code1: React.CSSProperties;
    code1bold: React.CSSProperties;
    overline2: React.CSSProperties;
    overline0: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body0?: React.CSSProperties;
    body3?: React.CSSProperties;
    code1?: React.CSSProperties;
    code1bold?: React.CSSProperties;
    overline2?: React.CSSProperties;
    overline0?: React.CSSProperties;
  }
}

export const typography: TypographyOptions = {
  // Base style
  htmlFontSize: 16, // this is the 'rem' value
  fontFamily: "Roboto, sans-serif",
  allVariants: {
    color: palette.text.primary,
  },

  // Headings
  // H1-H3 were formerly known as "Headline"
  h1: {
    fontSize: "32px",
    lineHeight: "1.5",
    fontWeight: 400,
  },
  h2: {
    fontSize: "24px",
    lineHeight: "1.5",
    fontWeight: 500,
  },
  h3: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "1.5",
  },
  // H4-H6 were formerly known as "Title"
  h4: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "1.5",
  },
  h5: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "1.5",
  },
  h6: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "1.5",
  },

  // Subtitles
  // Formerly known as "Text XL"
  subtitle1: {
    fontSize: "18px",
    lineHeight: "1.5",
    fontWeight: "400",
  },
  // Formerly known as "Text L"
  subtitle2: {
    fontSize: "16px",
    lineHeight: "1.5",
    fontWeight: "500",
  },

  // Slightly larger body text.
  body0: {
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "1.5",
  },

  // Body text
  // Formerly text-large
  body1: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "1.5",
  },
  // Formerly text-normal
  body2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.5",
  },

  // Formerly text-small
  body3: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "1.5",
  },

  // Component styles
  button: {
    fontSize: "14px",
    lineHeight: "1.43",
    textTransform: "none",
    fontWeight: 500,
  },

  code1: {
    fontFamily: "'Roboto Mono', monospaced",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "1.6",
  },

  code1bold: {
    fontFamily: "'Roboto Mono', monospaced",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "1.6",
  },

  overline2: {
    fontSize: "11px",
    fontWeight: 500,
    letterSpacing: 1,
    lineHeight: "160%",
    textTransform: "uppercase",
  },

  overline: {
    letterSpacing: 1,
    lineHeight: "160%",
    fontSize: "12px",
    fontWeight: 500,
  },

  overline0: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
    letterSpacing: "1px",
    textTransform: "uppercase",
  },
};
