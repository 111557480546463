//JobsView
export const JOBS = "jobs";
export const JOBS_ROUTE = `/${JOBS}`;
export const JOB_ID_PARAM = "jobId";

//ServicesView
export const SERVICES = "services";
export const SERVICES_ROUTE = `/${SERVICES}`;
export const SERVICE_ID_PARAM = "serviceId";

export const EVENTS = "events";
export const EVENTS_ROUTE = `/main/${EVENTS}`;

export const INTEGRATIONS = "integration";
export const INTEGRATIONS_ROUTE = `/main/${INTEGRATIONS}`;

export const MONITORS = "monitors";
export const MONITORS_ROUTE = `/main/${MONITORS}`;

export const CLUSTERS = "clusters";
export const CLUSTERS_ROUTE = `/${CLUSTERS}`;

export const AGENTS = "agents";
export const AGENTS_ROUTE = `/${AGENTS}`;

export const RESOURCE_DEPENDENCIES = "resource-dependencies";

//Inspection
export const INSPECTION = "resources";
export const INSPECTION_ROUTE = `/main/${INSPECTION}`;

//ArgoWorkflowsView
export const ARGO_WORKFLOWS = "argo-workflows";
export const ARGO_WORKFLOW_ID_PARAM = "argoWorkflowId";
export const ARGO_WORKFLOWS_EXTERNAL_LINK = "external-link";

//Cost
export const COST_OPTIMIZATION = "cost-optimization";
export const COST_OPTIMIZATION_ROUTE = `/${COST_OPTIMIZATION}`;

//Investigation mode
export const INVESTIGATION = "investigation";
export const RUN_ID_PARAM = "runId";

// Expert Mode
export const EXPERT_OVERVIEW = "overview";

export const RELIABILITY = "reliability";
export const RELIABILITY_ROUTE = `/${RELIABILITY}`;

export const K8S_ADDONS = "kubernetes-add-ons";
export const K8S_ADDONS_ROUTE = `/${K8S_ADDONS}`;
export const K8S_ADDONS_CERT_MANAGER = "cert-manager";
export const K8S_ADDONS_CERT_MANAGER_ROUTE = `${K8S_ADDONS_ROUTE}/${K8S_ADDONS_CERT_MANAGER}`;

export const WORKFLOWS = "workflows";
export const WORKFLOWS_ROUTE = `/${WORKFLOWS}`;

//Workspaces
export const WORKSPACE = "workspace";
export const EDIT_WORKSPACE = "edit";
export const CREATE_WORKSPACE = "new";
export const WORKSPACE_ID_PARAM = "id";
export const WORKSPACE_ROUTE = `/${WORKSPACE}`;
export const CREATE_WORKSPACE_ROUTE = `/${WORKSPACE}/${CREATE_WORKSPACE}`;

//Settings
export const SETTINGS = "settings";
export const SETTINGS_ROUTE = "/settings/profile";
export const AUDIT_ROUTE = "/audit";
export const USERS_ROUTE = "/settings/users";
export const ROLES_ROUTE = "/settings/roles";
export const POLICIES_ROUTE = "/settings/policies";
export const ACTIONS_ROUTE = "/settings/actions";
export const IP_WHITELIST_ROUTE = "/settings/cidr-whitelist";
export const FEATURES_ROUTE = "/settings/features";
export const USAGE_ROUTE = "/settings/usage";

// User settings
export const USER_SETTINGS = "user";
export const USER_PROFILE_ROUTE = `/${USER_SETTINGS}/profile`;
export const USER_API_KEYS_ROUTE = `/${USER_SETTINGS}/api-keys`;
export const USER_EMAIL_SETTINGS_ROUTE = `/${USER_SETTINGS}/email-settings`;
export const USER_GET_KUBECONFIG_ROUTE = `/${USER_SETTINGS}/kubeconfig`;
export const USER_LOGOUT_ROUTE = "/logout";

export const DEMO = "d";
export const DEMO_ROUTE = `/${DEMO}`;
export const RISK_MANAGEMENT_DEMO = "risk-management";
export const RISK_MANAGEMENT_DEMO_ROUTE = `/${DEMO}/${RISK_MANAGEMENT_DEMO}`;
export const RELIABILITY_DEMO = "reliability";
export const RELIABILITY_DEMO_ROUTE = `/${DEMO}/${RELIABILITY_DEMO}`;
export const DRIFT_ANALYSIS_DEMO = "drift-analysis";
export const DRIFT_ANALYSIS_DEMO_ROUTE = `/${DEMO}/${DRIFT_ANALYSIS_DEMO}`;
