import React from "react";
import styled from "styled-components";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { muiColors } from "@komodorio/design-system";

import { k8sAriaLabels } from "@/components/k8sAddons/k8sAriaLabels";
import { GenericErrorMessage } from "@/shared/components/GenericErrorMessage";

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const StyledErrorIcon = styled(ErrorOutlineIcon)`
  && {
    width: 32px;
    height: 32px;
    fill: ${muiColors.gray[500]};
  }
`;

type NoResultsFoundProps = {
  onClearFilters: () => void;
  isError: boolean;
};

export const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  onClearFilters,
  isError,
}) => {
  const ariaLabel = isError
    ? k8sAriaLabels.errorMessageContainer
    : k8sAriaLabels.noResultsFound;

  const content = isError ? (
    <GenericErrorMessage />
  ) : (
    <>
      <StyledErrorIcon />
      <Typography variant={"body2"} color={"text.secondary"}>
        No results found
      </Typography>
      <Button variant={"contained"} color={"primary"} onClick={onClearFilters}>
        Clear filters
      </Button>
    </>
  );
  return <Container aria-label={ariaLabel}>{content}</Container>;
};
