import React from "react";
import styled, { css } from "styled-components";

import { theme } from "../../../theme";

const Container = styled.div<{ width: string; height: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  background-color: ${theme.message.background};
  box-shadow: 0px 1px 4px rgba(22, 24, 31, 0.1);
  border-radius: 0.25rem;
  padding: 1.2rem;
`;

const paddingStyle = css`
  padding-inline: 2rem;
`;

export const MessageHeader = styled.div`
  ${paddingStyle}
  display: flex;
  justify-content: center;
`;
export const MessageContent = styled.div`
  ${paddingStyle}
  padding-block: 1rem;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const MessageActions = styled.div`
  ${paddingStyle}
  padding-block-start: 1rem;
  display: flex;
  justify-content: center;
`;

export interface MessageProps {
  width?: string;
  height?: string;
  children: React.ReactNode;
  ariaLabel?: string;
}

export const Message: React.FC<MessageProps> = ({
  width,
  height,
  children,
  ariaLabel,
}) => (
  <Container width={width} height={height} role="status" aria-label={ariaLabel}>
    {children}
  </Container>
);

Message.defaultProps = {
  width: "fit-content",
  height: "fit-content",
};
