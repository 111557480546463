import React from "react";

import { IconProps } from "../types";

export const Events24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2C0.447715 2 0 2.44772 0 3V6C0 6.55228 0.447716 7 1 7H23C23.5523 7 24 6.55228 24 6V3C24 2.44772 23.5523 2 23 2H1ZM1.5 3.5V5.5H22.5V3.5H1.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.5C0.447715 9.5 0 9.94772 0 10.5V13.5C0 14.0523 0.447716 14.5 1 14.5H23C23.5523 14.5 24 14.0523 24 13.5V10.5C24 9.94772 23.5523 9.5 23 9.5H1ZM1.5 11V13H22.5V11H1.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18C0 17.4477 0.447715 17 1 17H23C23.5523 17 24 17.4477 24 18V21C24 21.5523 23.5523 22 23 22H1C0.447716 22 0 21.5523 0 21V18ZM1.5 20.5V18.5H22.5V20.5H1.5Z"
      />
    </svg>
  );
};

Events24.defaultProps = {
  fill: "currentColor",
};
