import React from "react";

import { PageContainer } from "../../../riskManagementStyles";

import { ManageChecksFilters } from "./ManageChecksFilters";
import { ManageChecksContent } from "./ManageChecksContent/ManageChecksContent";

export const ManageChecks: React.FC = () => {
  return (
    <PageContainer>
      <ManageChecksFilters />
      <ManageChecksContent />
    </PageContainer>
  );
};
