import { MutableRefObject, useRef, useState } from "react";

export const useIsMenuHiddenByTopContent = (
  menuListRef: MutableRefObject<HTMLUListElement>
) => {
  const [isMenuHiddenByTopContent, setMenuIsHiddenByTopContent] =
    useState(false);
  const orgMenuY = useRef(menuListRef.current?.getBoundingClientRect().y);

  const handleScroll = () => {
    if (!orgMenuY.current) {
      orgMenuY.current = menuListRef.current?.getBoundingClientRect().y;
    }
    setMenuIsHiddenByTopContent(
      menuListRef.current.getBoundingClientRect().y - orgMenuY.current < 0
    );
  };

  return { isMenuHiddenByTopContent, handleScroll };
};
