import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";

import {
  EventsApiApiV1NodesByAffectedResourcesEventsGetRequest,
  NodeEventsResponse,
  apiV1NodesByAffectedResourcesEventsGetUrl,
} from "../../../../../generated/resourcesApi";
import { useResourcesApiClient } from "../apiClient";

export const NODES_EVENTS_BY_AFFECTED_RESOURCES_PATH =
  "/nodes/by-affected-resources/events";

const fetchNodeEventsByAffectedResources = async (
  apiClient: AxiosInstance,
  params: EventsApiApiV1NodesByAffectedResourcesEventsGetRequest
): Promise<NodeEventsResponse> => {
  const { data } = await apiClient.get(
    apiV1NodesByAffectedResourcesEventsGetUrl(
      params,
      apiClient.defaults.baseURL ?? ""
    )
  );
  return data;
};

export const useGetNodeEventsByAffectedResources = (
  params: EventsApiApiV1NodesByAffectedResourcesEventsGetRequest,
  enabled?: boolean
) => {
  const apiClient = useResourcesApiClient();
  return useQuery(
    [NODES_EVENTS_BY_AFFECTED_RESOURCES_PATH, params],
    () => fetchNodeEventsByAffectedResources(apiClient, params),
    {
      enabled,
    }
  );
};
