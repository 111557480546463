/**
 * @deprecated Use `muiColors` instead.
 */
export const palette = {
  gray: {
    "50": "#F4F7FA",
    "100": "#EFF2F5",
    "200": "#DCDDDF",
    "300": "#CAD1DB",
    "400": "#B3B6BC",
    "500": "#9195A1",
    "600": "#707583",
    "700": "#4E515A",
    "800": "#3B3D45",
    "900": "#2C2E35",
  },
  purple: {
    "50": "#E6D9FA",
    "100": "#D2B9FA",
    "200": "#B893F5",
    "300": "#9D69F0",
    "400": "#8545ED",
    "500": "#681AE8",
    "600": "#4E00CC",
    "700": "#4000A6",
    "800": "#320082",
    "900": "#23005C",
  },
  darkBlue: {
    "50": "#EBEFFF",
    "100": "#C2CFFF",
    "200": "#99AFFF",
    "300": "#708FFF",
    "400": "#476FFF",
    "500": "#1347FF",
    "600": "#0031E0",
    "700": "#0023A3",
    "800": "#001B7A",
    "900": "#061431",
  },
  blue: {
    "50": "#E9F7FF",
    "100": "#D6EFFE",
    "200": "#B6E1FF",
    "300": "#82C8FF",
    "400": "#5AB0FF",
    "500": "#007AFF",
    "600": "#0062DF",
    "700": "#0048AC",
    "800": "#003381",
    "900": "#00265E",
  },
  teal: {
    "50": "#DCFAF8",
    "100": "#C1F7F2",
    "200": "#A2F6EE",
    "300": "#7DF2E6",
    "400": "#54EEDF",
    "500": "#37E8D5",
    "600": "#18CCBA",
    "700": "#12BEAC",
    "800": "#0D877B",
    "900": "#0A5C54",
  },
  green: {
    "50": "#D9FFF1",
    "100": "#A4F8D7",
    "200": "#90F4CE",
    "300": "#6AF0BD",
    "400": "#45EDAD",
    "500": "#1BE99A",
    "600": "#14CC86",
    "700": "#1FA470",
    "800": "#0D8255",
    "900": "#095D3D",
  },
  yellow: {
    "50": "#FFFEE8",
    "100": "#FFF3BD",
    "200": "#FCE492",
    "300": "#FADE4B",
    "400": "#F6D427",
    "500": "#F3CB00",
    "600": "#E6BF00",
    "700": "#CCAA00",
    "800": "#997F00",
    "900": "#6F5C01",
  },
  orange: {
    "50": "#FFF7EB",
    "100": "#FFE7C2",
    "200": "#FFCE85",
    "300": "#FFBE5C",
    "400": "#FFAD33",
    "500": "#FD9800",
    "600": "#E08700",
    "700": "#A36200",
    "800": "#663D00",
    "900": "#3D2500",
  },
  pink: {
    "50": "#FFEBF4",
    "100": "#FFD2E7",
    "200": "#FEA8D0",
    "300": "#FD6AAF",
    "400": "#FC4A9D",
    "500": "#FC1683",
    "600": "#F10472",
    "700": "#B50356",
    "800": "#8D0243",
    "900": "#500126",
  },
  red: {
    "50": "#FEE0E5",
    "100": "#FEBDC9",
    "200": "#FE9AAC",
    "300": "#FD6D87",
    "400": "#FD506F",
    "500": "#FC3357",
    "600": "#FC163F",
    "700": "#D80329",
    "800": "#A2021F",
    "900": "#6C0114",
  },
  black: { "0": "#3B3D45" },
  white: { "0": "#FFFFFF" },
};
