import { useMemo } from "react";

import { messageEnrichers } from "@/components/InvestigationMode/messageEnrichment/enrichersStore";
import { AiInvestigationOutput } from "@/components/InvestigationMode/availability/AiInvestigation/types";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export function useShowAiInvestigationSection(
  wfAiInvestigationOutput: AiInvestigationOutput | undefined,
  wfClosedAt: Date | null,
  errorMessages?: string[]
): { showSection: boolean; requestId?: string } {
  const {
    klaudiaAiSavedAvailabilityIssue,
    klaudiaAiAvailabilityIssueTriageDrawer,
  } = useOverridableFlags();
  const aiInvestigationRequestId = wfAiInvestigationOutput?.requestId;
  const enrichedMessage = useMemo(() => {
    if (!errorMessages || errorMessages.length === 0) {
      return undefined;
    }

    return messageEnrichers.enrich(errorMessages[0]);
  }, [errorMessages]);

  if (klaudiaAiSavedAvailabilityIssue && aiInvestigationRequestId) {
    return {
      showSection: true,
      requestId: aiInvestigationRequestId,
    };
  }

  if (wfClosedAt) {
    return { showSection: false };
  }

  if (
    klaudiaAiAvailabilityIssueTriageDrawer &&
    !enrichedMessage?.hasPrecedenceOverAIInvestigation
  ) {
    return { showSection: true };
  }

  return { showSection: false };
}
