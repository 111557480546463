import { Size } from "../types";

export const selectSize: Record<Size, number> = {
  large: 40,
  medium: 32,
  small: 28,
};

export const defaultSelectSize: Size = "medium";

export const popoverTransitionDuration = 250;
