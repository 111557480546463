import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";

const Container = styled(Paper)`
  padding: 16px 24px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const EmailSettingsContent: React.FC = () => {
  const [showAlert, setShowAlert] = useState(true);

  return (
    <Container>
      <TopSection>
        <Typography variant={"h4"}>Komodor Digest</Typography>
        <FlexContainer>
          <Typography variant={"body2"}>
            Subscribe to the Komodor digest
          </Typography>
          <Switch />
        </FlexContainer>
      </TopSection>

      {showAlert && (
        <Alert
          variant={"standard"}
          severity="info"
          color="running"
          onClose={() => setShowAlert(false)}
        >
          NOTE: Email settings are personal! Any selections you make are for
          your user only.
        </Alert>
      )}
    </Container>
  );
};
