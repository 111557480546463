import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useRiskManagementContext } from "../../context/RiskManagementContext";

import { Filter } from "./types";
import { FiltersSection } from "./FiltersSection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ContentContainer = styled.div<{ $sticky: boolean }>`
  position: ${({ $sticky }) => ($sticky ? "fixed" : "relative")};
  top: ${({ $sticky }) => ($sticky ? "25px" : "0")};
  width: inherit;
`;

const StyledDivider = styled(Divider)`
  && {
    margin: 10px 0;
  }
`;

interface FiltersProps {
  filtersData: Filter[];
}

export const Filters: React.FC<FiltersProps> = ({ filtersData }) => {
  const { isTopSectionShown } = useRiskManagementContext();

  const sections = useMemo(() => {
    return filtersData.map((filterData) => {
      return (
        <div key={filterData.title}>
          <FiltersSection filterSectionData={filterData} />
          <StyledDivider />
        </div>
      );
    });
  }, [filtersData]);

  return (
    <Container>
      <ContentContainer $sticky={!isTopSectionShown}>
        <Typography
          variant="h4"
          color={"text.primary"}
          sx={{ lineHeight: 2.5 }}
        >
          Scope filters
        </Typography>
        {sections}
      </ContentContainer>
    </Container>
  );
};
