/* eslint-disable max-lines */
import styled from "styled-components";
import { palette } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import { Typography } from "@komodorio/design-system/deprecated";
import React, { useEffect, useMemo } from "react";
import { orderBy } from "lodash";

import { FlexRow } from "../../../common/ProcessList/details/WorkflowIssueEventDetails/triage/common/styles";
import { MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import { useStateInSearchParams } from "../../../../shared/hooks/state";
import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import { PodOption } from "../../../monitorsView/workflowRunView/checks/availability/detailsComponents/workloadHealthyPodsDetails/WorkloadHealthyPodsDetailsEvents";
import { SnapshotInfoDetail } from "../../../common/Alert/SnapshotInfo";
import AvailabilityUpgradeAgent from "../../../monitorsView/common/availabilityComponents/AvailabilityUpgradeAgent";
import { EnrichedMessageSection } from "../../messageEnrichment/EnrichedMessageSection";
import { useActiveAgent } from "../../../../shared/hooks/useAgents";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import Resource from "../../../ResourceView/resources";
import { Section } from "../common/Section";
import { Container, SummaryDetails } from "../common/styles";
import useResourceWithInterval from "../../../ResourceView/useResource";
import {
  KubernetesNodesResource,
  KubernetesPodsResource,
} from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

import { UnhealthyPodsSnapshotOutput } from "./types";
import PodInformation from "./PodInformation";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;
`;
const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 40rem;
`;
const PodsMenuItem = styled.div`
  row-gap: 1rem;
`;
const PodStatus = styled.div`
  display: flex;
  gap: 0.3rem;
  align-items: center;
`;
const PodStatusRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const PodsContent: React.FC<{
  unhealthyPodsSnapshot: UnhealthyPodsSnapshotOutput;
  onResourceClick?: (resource: Resource) => void;
}> = ({ unhealthyPodsSnapshot, onResourceClick }) => {
  const [selectedPodName, setSelectedPodName] = useStateInSearchParams(
    MONITORS_AVAILABILITY_RUN_POD_PARAM_KEY
  );

  const { format } = useDateFormatter();

  const issue = useInvestigationModeStore(issueSelector);

  const agentId = useActiveAgent(issue?.cluster ?? "");

  const unhealthyPods = useMemo(
    () => unhealthyPodsSnapshot?.pods ?? [],
    [unhealthyPodsSnapshot?.pods]
  );

  useEffect(() => {
    if (!selectedPodName && unhealthyPods.length > 0) {
      setSelectedPodName(unhealthyPods[0]?.name, true);
    }
  }, [selectedPodName, setSelectedPodName, unhealthyPods]);

  const selectedPod = useMemo(
    () =>
      unhealthyPods.find((p) => p.name === selectedPodName) ?? unhealthyPods[0],
    [selectedPodName, unhealthyPods]
  );

  const podsOptions = useMemo<PodOption[]>(() => {
    const phaseOrder = ["Failed", "Succeeded", "Running", "Pending", "Unknown"];
    return orderBy(
      unhealthyPods,
      [
        (p) => p.containers.length,
        (p) => p.podEvents?.length ?? 0,
        (p) => phaseOrder.indexOf(p.phase),
      ],
      ["desc", "desc", "asc"]
    ).map((pod) => ({
      label: pod.name,
      value: pod,
    }));
  }, [unhealthyPods]);

  const {
    resource: nodeResource,
    isFetching: isFetchingNodeResource,
    clearState: clearNodeResource,
  } = useResourceWithInterval({
    agentId: agentId ?? "",
    cluster: issue?.cluster ?? "",
    namespace: "",
    resourceName: selectedPod?.nodeName ?? "",
    resourceType: KubernetesNodesResource.Kind,
  });

  const {
    resource: podResource,
    isFetching: isFetchingPodResource,
    clearState: clearPodResource,
  } = useResourceWithInterval({
    agentId: agentId ?? "",
    cluster: issue?.cluster ?? "",
    namespace: issue?.namespace ?? "",
    resourceName: selectedPodName ?? "",
    resourceType: KubernetesPodsResource.Kind,
  });

  const handleChange = (event: SelectChangeEvent<typeof selectedPodName>) => {
    const {
      target: { value },
    } = event;
    clearNodeResource();
    clearPodResource();
    setSelectedPodName(value);
  };

  const failedContainers = useMemo(
    () =>
      selectedPod?.containers.filter((c) => !c.isHealthy).map((c) => c.name),
    [selectedPod?.containers]
  );

  return (
    <>
      {unhealthyPods.length === 0 ? (
        <SummaryDetails
          aria-label={
            AriaLabels.InvestigationMode.UnhealthyPodsStep.PodsSummaryTitle
          }
        >
          <Typography variant="title" size="large">
            {unhealthyPodsSnapshot.podsFetchFailureMessage
              ? `Problem fetching pods: ${unhealthyPodsSnapshot.podsFetchFailureMessage}`
              : `Found 0 unhealthy pods, but ready replicas are less than desired at the time of this snapshot.\nReady: ${unhealthyPodsSnapshot.readyReplicas}, Desired: ${unhealthyPodsSnapshot.desiredReplicas}.`}
          </Typography>
        </SummaryDetails>
      ) : (
        <>
          <SelectorContainer>
            {unhealthyPods.length > 1 && (
              <>
                <RowContainer>
                  {unhealthyPods.length > 1 && (
                    <Typography variant="title" size="large">
                      Select a Pod
                    </Typography>
                  )}
                </RowContainer>
                <MuiSelect
                  aria-label={
                    AriaLabels.InvestigationMode.UnhealthyPodsStep.PodSelector
                  }
                  MenuProps={{
                    style: {
                      maxHeight: "30rem",
                    },
                  }}
                  size={"medium"}
                  onChange={handleChange}
                  value={selectedPodName}
                  renderValue={(value) => (
                    <MuiTypography
                      variant={"body1"}
                      color={palette.darkBlue[500]}
                    >
                      {value}
                    </MuiTypography>
                  )}
                  maxRows={10}
                >
                  {podsOptions.map((option) => (
                    <MenuItem
                      key={option.value.name}
                      value={option.value.name}
                      aria-label={`${AriaLabels.InvestigationMode.UnhealthyPodsStep.PodSelectorOption}-${option.value.name}`}
                    >
                      <PodsMenuItem>
                        <MuiTypography
                          variant={"body1"}
                          color={palette.black[0]}
                        >
                          {option.value.name}
                        </MuiTypography>
                        <PodStatusRow>
                          <PodStatus>
                            <MuiTypography
                              variant={"overline"}
                              color={palette.gray[600]}
                            >
                              Phase:
                            </MuiTypography>
                            <MuiTypography
                              variant={"body2"}
                              color={palette.black[0]}
                            >
                              {option.value.phase}
                            </MuiTypography>
                          </PodStatus>
                          <PodStatus>
                            <MuiTypography
                              variant={"overline"}
                              color={palette.gray[600]}
                            >
                              Reason:{" "}
                            </MuiTypography>
                            <MuiTypography
                              variant={"body2"}
                              color={palette.black[0]}
                            >
                              {option.value.calculatedReason}
                              {option.value?.calculatedExitCode &&
                                ` - Exit Code: ${option.value.calculatedExitCode}`}
                            </MuiTypography>
                          </PodStatus>
                        </PodStatusRow>
                      </PodsMenuItem>
                    </MenuItem>
                  ))}
                </MuiSelect>
              </>
            )}
            <SnapshotInfoDetail variant={"infoGray"}>
              Snapshot is from{" "}
              <b>{format(new Date(unhealthyPodsSnapshot.startTime))}</b>
            </SnapshotInfoDetail>
            {(!unhealthyPodsSnapshot?.isPodEventAllowed ||
              !unhealthyPodsSnapshot?.isPodLogsAllowed) && (
              <AvailabilityUpgradeAgent />
            )}
          </SelectorContainer>
          <Container>
            <SummaryDetails>
              {!selectedPod?.calculatedMessage && (
                <Section
                  title={"reason"}
                  ariaLabel={
                    AriaLabels.InvestigationMode.UnhealthyPodsStep.Summary
                      .ReasonSection
                  }
                >
                  <MuiTypography variant={"body1"}>
                    {`${selectedPod.calculatedReason}${
                      selectedPod?.calculatedExitCode
                        ? ` - Exit Code: ${selectedPod.calculatedExitCode}`
                        : ""
                    }`}
                  </MuiTypography>
                </Section>
              )}
              {selectedPod?.calculatedMessage && (
                <Section
                  title={"reason"}
                  ariaLabel={
                    AriaLabels.InvestigationMode.UnhealthyPodsStep.Summary
                      .ReasonSection
                  }
                >
                  <EnrichedMessageSection
                    errorMessages={[selectedPod.calculatedMessage]}
                    reasons={[
                      `${selectedPod.calculatedReason}${
                        selectedPod?.calculatedExitCode
                          ? ` - Exit Code: ${selectedPod.calculatedExitCode}`
                          : ""
                      }`,
                    ]}
                    agentId={agentId}
                    cluster={issue?.cluster ?? ""}
                    namespace={issue?.namespace ?? ""}
                    onClick={onResourceClick}
                  />
                </Section>
              )}
              <Section
                title={"where"}
                ariaLabel={
                  AriaLabels.InvestigationMode.UnhealthyPodsStep.Summary
                    .WhereSection
                }
              >
                <FlexRow>
                  <Metadata
                    title={"pod"}
                    value={selectedPod.name}
                    onValueClick={
                      podResource && onResourceClick && !isFetchingPodResource
                        ? () => {
                            onResourceClick?.(podResource);
                          }
                        : undefined
                    }
                  />
                  {selectedPod?.nodeName && (
                    <Metadata
                      title={"node"}
                      value={selectedPod.nodeName}
                      onValueClick={
                        nodeResource &&
                        onResourceClick &&
                        !isFetchingNodeResource
                          ? () => {
                              onResourceClick?.(nodeResource);
                            }
                          : undefined
                      }
                    />
                  )}
                </FlexRow>
              </Section>
              <Section
                title={"status"}
                ariaLabel={
                  AriaLabels.InvestigationMode.UnhealthyPodsStep.Summary.Status
                }
              >
                <FlexRow>
                  <Metadata title={"pod phase"} value={selectedPod.phase} />
                  {!!failedContainers?.length && (
                    <Metadata
                      title={"failed containers"}
                      value={failedContainers.join(", ")}
                    />
                  )}
                </FlexRow>
              </Section>
            </SummaryDetails>
          </Container>
          <PodInformation
            selectedPod={selectedPod}
            startTime={unhealthyPodsSnapshot.startTime}
          />
        </>
      )}
    </>
  );
};

export default PodsContent;
