import React from "react";

import { LogoIconProps } from "../types";

export const Slack: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Slack logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57047 2.5C8.50446 2.50079 7.64173 3.36422 7.64252 4.42818C7.64173 5.49214 8.50525 6.35557 9.57126 6.35636H11.5V4.42896C11.5008 3.36501 10.6373 2.50157 9.57047 2.5C9.57126 2.5 9.57126 2.5 9.57047 2.5V2.5ZM9.57047 7.64286H4.42874C3.36273 7.64364 2.49921 8.50708 2.5 9.57104C2.49843 10.635 3.36195 11.4984 4.42795 11.5H9.57047C10.6365 11.4992 11.5 10.6358 11.4992 9.57182C11.5 8.50708 10.6365 7.64364 9.57047 7.64286Z"
        fill="#36C5F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 9.57104C21.5008 8.50708 20.6372 7.64364 19.5711 7.64286C18.505 7.64364 17.6414 8.50708 17.6422 9.57104V11.5H19.5711C20.6372 11.4992 21.5008 10.6358 21.5 9.57104ZM16.357 9.57104V4.42818C16.3578 3.36501 15.495 2.50157 14.4289 2.5C13.3628 2.50079 12.4992 3.36422 12.5 4.42818V9.57104C12.4984 10.635 13.362 11.4984 14.4281 11.5C15.4942 11.4992 16.3578 10.6358 16.357 9.57104Z"
        fill="#2EB67D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4287 21.5C15.4947 21.4992 16.3583 20.6359 16.3575 19.572C16.3583 18.5081 15.4947 17.6448 14.4287 17.644H12.5V19.572C12.4992 20.6351 13.3627 21.4984 14.4287 21.5ZM14.4287 16.3568H19.5713C20.6373 16.356 21.5008 15.4927 21.5 14.4288C21.5016 13.3649 20.6381 12.5016 19.572 12.5H14.4295C13.3635 12.5008 12.5 13.3641 12.5008 14.428C12.5 15.4927 13.3627 16.356 14.4287 16.3568Z"
        fill="#ECB22E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 14.4282C2.49921 15.4921 3.36281 16.3556 4.42891 16.3564C5.49501 16.3556 6.35861 15.4921 6.35782 14.4282V12.5H4.42891C3.36281 12.5008 2.49921 13.3642 2.5 14.4282ZM7.64297 14.4282V19.571C7.64139 20.635 8.50499 21.4984 9.57109 21.5C10.6372 21.4992 11.5008 20.6358 11.5 19.5718V14.4298C11.5016 13.3658 10.638 12.5024 9.57188 12.5008C8.50499 12.5008 7.64218 13.3642 7.64297 14.4282C7.64297 14.4282 7.64297 14.429 7.64297 14.4282Z"
        fill="#E01E5A"
      />
    </svg>
  );
};
