import React from "react";

import { IconProps } from "../types";

export const Network16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 0C3.89543 0 3 0.895431 3 2V8C3 9.10457 3.89543 10 5 10H7.25V13H6C5.44772 13 5 13.4477 5 14V14.5H0V16H16V14.5H11V14C11 13.4477 10.5523 13 10 13H8.75V10H11C12.1046 10 13 9.10457 13 8V2C13 0.895431 12.1046 0 11 0H5ZM11 1.5H5C4.72386 1.5 4.5 1.72386 4.5 2V8C4.5 8.27614 4.72386 8.5 5 8.5H11C11.2761 8.5 11.5 8.27614 11.5 8V2C11.5 1.72386 11.2761 1.5 11 1.5Z"
      />
    </svg>
  );
};

Network16.defaultProps = {
  fill: "currentColor",
};
