import { useCallback } from "react";

import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../shared/types";

type OnItemSelectParams<T extends MuiSelectionOptionValue> = {
  freeSolo: boolean;
  freeSoloOptions: MuiSelectionOption<T>[];
  options: MuiSelectionOption<T>[];
  selectedOptions: MuiSelectionOption<T>[];
  onSetSelectedOptions: (options: MuiSelectionOption<T>[]) => void;
  setFreeSoloOptions: (options: MuiSelectionOption<T>[]) => void;
};

export const useOnItemSelect = <T extends MuiSelectionOptionValue>({
  options,
  freeSolo,
  freeSoloOptions,
  selectedOptions,
  onSetSelectedOptions,
  setFreeSoloOptions,
}: OnItemSelectParams<T>) => {
  return useCallback(
    (value: T) => {
      const optionFromValue = options.find((option) => option.value === value);
      if (!optionFromValue) {
        if (!freeSolo) return;
        if (!freeSoloOptions.find((option) => option.value === value)) {
          setFreeSoloOptions([
            ...freeSoloOptions,
            { value, label: value.toString() },
          ]);
        }
      }

      const optionExists = selectedOptions.find(
        (option) => option.value === value
      );

      if (optionExists) {
        onSetSelectedOptions(
          selectedOptions.filter((option) => option.value !== value)
        );
      } else {
        onSetSelectedOptions([
          ...selectedOptions,
          optionFromValue ?? { value, label: value.toString() },
        ]);
      }
    },
    [
      freeSolo,
      freeSoloOptions,
      onSetSelectedOptions,
      options,
      selectedOptions,
      setFreeSoloOptions,
    ]
  );
};
