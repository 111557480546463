import React from "react";

import { IconProps } from "../types";

export const CheckBold16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-1 0 16 10"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      role={svgProps.onClick ? "button" : "img"}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48461 6.5836L10.9015 0.217061C11.1932 -0.0723536 11.6631 -0.0723536 11.9548 0.217061L12.7812 1.03699C13.0729 1.3264 13.0729 1.7926 12.7812 2.06587L5.01929 9.78294C4.72759 10.0724 4.2577 10.0724 3.96598 9.78294L0.206572 6.05304C-0.0688574 5.77977 -0.0688574 5.31348 0.206572 5.02416L1.04916 4.20423C1.32459 3.91482 1.79457 3.91482 2.08618 4.20423L4.48441 6.58365L4.48461 6.5836Z"
      />
    </svg>
  );
};

CheckBold16.defaultProps = {
  fill: "currentColor",
};
