import React from "react";

import { IconProps } from "../types";

export const KomoBot24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.86978L3.78384 5.02661H20.2163L22 6.86174V17.3978L20.2163 19.2329H3.78372L2 17.3978V6.86978ZM4.94831 7.03274L4.25128 7.73424V16.5376L4.94831 17.2391H19.0733L19.7446 16.5364V7.7354L19.0733 7.03274H4.94831ZM14.6189 13.4578C15.5458 13.4578 16.2972 12.3725 16.2972 11.0336C16.2972 9.69474 15.5458 8.60938 14.6189 8.60938C13.692 8.60938 12.9406 9.69474 12.9406 11.0336C12.9406 12.3725 13.692 13.4578 14.6189 13.4578ZM11.3098 11.0335C11.3098 12.3723 10.5584 13.4577 9.63152 13.4577C8.70462 13.4577 7.95322 12.3723 7.95322 11.0335C7.95322 9.6946 8.70462 8.60924 9.63152 8.60924C10.5584 8.60924 11.3098 9.6946 11.3098 11.0335Z"
      />
    </svg>
  );
};

KomoBot24.defaultProps = {
  fill: "currentColor",
};
