import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import Arrow from "../../../common/ProcessList/Arrow";
import { WorkflowConfigType, WorkflowConfiguration } from "../../common/types";
import { LightText } from "../../common/fonts";
import { getDefaultRule } from "../../monitorsConfiguration/common/RuleTypeSection";
import { blackGreyText } from "../../../../Colors";
import { TextButton } from "../../common/Button";
import { MONITORS_RULE_PARAM_KEY } from "../../../../shared/config/urlSearchParamsKeys";
import AvailableWorkflows from "../../common/availableWorkflows";

import RuleRow from "./RuleRow";
import { useUpdateActiveWorkflowConfiguration } from "./actions";

const Container = styled.div`
  padding-left: 2rem;
  padding-bottom: 1rem;
`;

const MonitorRow = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const MonitorButton = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background: #f4f7fa;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  div:first-letter {
    text-transform: uppercase;
  }
`;

const SmallLightText = styled(LightText)`
  font-size: 12px;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #dcdddf;
  border-bottom: none;
  border-radius: 4px;
  border-spacing: 0;
  font-size: 12px;
  th {
    padding: 0.5rem 1rem;
    text-align: start;
    font-weight: bold;
    color: #707583;
    white-space: nowrap;
    background-color: #f4f7fa;
  }
  td {
    border-bottom: 1px solid #dcdddf;
    font-weight: 400;
    padding: 0.75rem 1rem;
    width: 20rem;
  }
  td:last-child {
    width: 2rem;
  }
`;

interface MonitorRulesProps {
  clusterName: string;
  type: WorkflowConfigType;
  rules: WorkflowConfiguration[];
  handleAddRule: (
    rule: WorkflowConfiguration | undefined,
    isTypeStatic?: boolean
  ) => void;
  handleEditRule: (ruleId: string) => void;
  handleDeleteRule: (ruleId: string) => void;
}

const MonitorRules: React.FC<MonitorRulesProps> = ({
  clusterName,
  type,
  rules,
  handleAddRule,
  handleEditRule,
  handleDeleteRule,
}) => {
  const [showRules, setShowRules] = useState(false);

  const updateActiveInDB = useUpdateActiveWorkflowConfiguration();
  const { callback: debounedUpdateActive } = useDebouncedCallback(
    (id: string, active: boolean) => {
      updateActiveInDB({ id, active });
    },
    1000
  );

  useEffect(() => {
    const ruleId = new URLSearchParams(location.search).get(
      MONITORS_RULE_PARAM_KEY
    );
    if (rules.some(({ id }) => id === ruleId)) {
      setShowRules(true);
    }
  }, [rules, showRules]);

  const monitorDisplayName = `${
    AvailableWorkflows[type].displayName ?? type
  } monitor`;

  return (
    <Container>
      <MonitorRow>
        <MonitorButton onClick={() => setShowRules((prev) => !prev)}>
          <Arrow
            direction={showRules ? "down" : "right"}
            width="0.75rem"
            color={blackGreyText}
          />
          <div>{monitorDisplayName}</div>
        </MonitorButton>
        <SmallLightText>
          {`${rules.length} Rules (${
            rules.filter((r) => r.active).length
          } Active)`}
        </SmallLightText>
      </MonitorRow>
      {showRules && (
        <>
          <Table>
            <thead>
              <tr>
                <th>Trigger</th>
                <th>Scope</th>
                <th>Sink</th>
                <th>Modified</th>
                <th>Enable</th>
              </tr>
            </thead>
            <tbody>
              {rules.map((rule, index) => (
                <RuleRow
                  rule={rule}
                  key={index}
                  updateActiveInDB={debounedUpdateActive}
                  handleEditRule={handleEditRule}
                  handleDeleteRule={handleDeleteRule}
                />
              ))}
            </tbody>
          </Table>
          <TextButton
            onClick={() =>
              handleAddRule(getDefaultRule(clusterName, type), true)
            }
          >
            + Add rule
          </TextButton>
        </>
      )}
    </Container>
  );
};

export default MonitorRules;
