import React from "react";
import { Kubernetes16 } from "@komodorio/design-system/icons";

import { clustersDemoRoutes } from "../../ClustersDemoView/constants";
import { DEMO } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "Clusters";

export const ClustersDemoLink: React.FC = () => {
  return (
    <AppBarLink
      text={DISPLAY_NAME}
      route={`${DEMO}/${clustersDemoRoutes.clusters}`}
      icon={<Kubernetes16 />}
    />
  );
};
