import React from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { muiTheme } from "./theme";

export const ThemeProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      {/* removing CssBaseline until we can verify it does not break anything */}
      {children}
    </MuiThemeProvider>
  );
};
