"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilterGroupsOperators = exports.Operators = void 0;
var Operators;
(function (Operators) {
    Operators["is"] = "is";
    Operators["isNot"] = "is not";
    Operators["contains"] = "contains";
    Operators["doesNotContains"] = "does not contain";
    Operators["hasKeysAny"] = "hasKeysAny";
})(Operators = exports.Operators || (exports.Operators = {}));
var FilterGroupsOperators;
(function (FilterGroupsOperators) {
    FilterGroupsOperators["or"] = "or";
    FilterGroupsOperators["and"] = "and";
    // When there is only one filter group, there is no logical condition between the different filter groups.
    // Therefore the operator field should be empty
    FilterGroupsOperators["onlyOneFilterGroup"] = "";
})(FilterGroupsOperators = exports.FilterGroupsOperators || (exports.FilterGroupsOperators = {}));
