import React from "react";
import Typography from "@mui/material/Typography";

import { ViolationDetails } from "../types";

type RiskDetailsTableViolationProps = {
  details: ViolationDetails;
};

export const RiskDetailsTableViolation: React.FC<
  RiskDetailsTableViolationProps
> = ({ details: { title, cluster, namespace, serviceName } }) => {
  return (
    <div>
      <Typography variant={"h5"}>{title}</Typography>
      <Typography variant={"body2"} color={"text.secondary"}>
        {`${cluster} > ${namespace} > ${serviceName}`}
      </Typography>
    </div>
  );
};
