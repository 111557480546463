import React from "react";

import { IconProps } from "../types";

export const PauseFilled16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M4 12.6667H6.66667V3.33334H4V12.6667ZM9.33333 3.33334V12.6667H12V3.33334H9.33333Z"
        fillOpacity="0.5"
      />
    </svg>
  );
};

PauseFilled16.defaultProps = {
  fill: "currentColor",
};
