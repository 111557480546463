import React from "react";

import { RiskUrgencyToSeverity } from "../../../../../constants";
import { SeverityChip } from "../../../../shared/SeverityChip";

import { RiskUrgency } from "./types";

type RiskUrgencyChipProps = {
  urgency: RiskUrgency;
};

const riskUrgencyToTitle: Record<RiskUrgency, string> = {
  urgent: "Urgent",
  moderate: "Moderate",
  low: "Low",
};

export const RiskUrgencyChip: React.FC<RiskUrgencyChipProps> = ({
  urgency,
}) => {
  const label = riskUrgencyToTitle[urgency];
  const severity = RiskUrgencyToSeverity[urgency];

  return <SeverityChip label={label} severity={severity} />;
};
