import React from "react";

import clusterViewHeader from "../assets/cluster2/clusterViewHeader.png";
import clusterViewContent from "../assets/cluster2/clusterViewContent.png";
import { RelativeContainer } from "../styles";

import { CriticalIssuesItem } from "./CriticalIssuesItem";
import { Header } from "./Header";

export const ClusterView2: React.FC = () => {
  return (
    <RelativeContainer>
      <Header headerImg={clusterViewHeader} />
      <CriticalIssuesItem />
      <img src={clusterViewContent} alt={"cluster view content"} />
    </RelativeContainer>
  );
};
