import React, { useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import styled from "styled-components";

import { MuiSelectionOption, MuiSelectionOptionValue } from "../shared/types";
import { DISABLED_ITEM_OPACITY } from "../shared/constants";
import { OverflowTextStyle } from "../../../../styles/styles";

const DisabledMenuItem = styled(MenuItem)`
  && {
    cursor: default;
    opacity: ${DISABLED_ITEM_OPACITY};
    width: fit-content;
    &:hover {
      background-color: unset;
    }
  }
`;

const TruncatedTypography = styled(Typography)`
  && {
    ${OverflowTextStyle};
  }
`;

type MenuItemsProps<T extends MuiSelectionOptionValue> = {
  options: MuiSelectionOption<T>[];
  filterString?: string;
  onSelect: (value: T) => void;
  selectedOption?: MuiSelectionOption<T>;
  customOptionElement?: (option: MuiSelectionOption<T>) => React.ReactNode;
};

export const useMenuItems = <T extends MuiSelectionOptionValue>({
  options,
  filterString,
  onSelect,
  selectedOption,
  customOptionElement,
}: MenuItemsProps<T>) => {
  return useMemo(() => {
    const searchTerm = filterString.toLowerCase();

    const filteredOption = options
      .filter((option) => option.label.toLowerCase().includes(searchTerm))
      .sort(
        (a, b) =>
          a.label.toLowerCase().indexOf(searchTerm) -
          b.label.toLowerCase().indexOf(searchTerm)
      );

    return filteredOption.map((option) => {
      const Item = option.disabled ? DisabledMenuItem : MenuItem;
      return (
        <Tooltip
          title={option.disabledTooltipContent}
          placement={"top"}
          key={option.value}
        >
          <Item
            key={option.value}
            value={option.value}
            selected={selectedOption?.value === option.value}
            aria-label={option.label}
            {...(!option.disabled && { onClick: () => onSelect(option.value) })}
          >
            {customOptionElement ? (
              customOptionElement(option)
            ) : (
              <TruncatedTypography variant={"body2"}>
                {option.label}
              </TruncatedTypography>
            )}
          </Item>
        </Tooltip>
      );
    });
  }, [filterString, onSelect, options, selectedOption?.value]);
};
