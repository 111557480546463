import React, { useMemo } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Typography } from "@komodorio/design-system/deprecated";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { muiColors } from "@komodorio/design-system";

import ResponsiveLayout from "../common/ResponsiveLayout";
import { useHasPermissions } from "../../shared/hooks/useUserMetadata/rbac";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import isElectronContext from "../../shared/context/electron/isElectronContext";

import { useAccountFeatures } from "@/components/Settings/General/hooks/useFeatures";

const Container = styled.div`
  color: #3d4048;
`;

const BottomMarginToScrollTheIntercom = styled.div`
  margin-bottom: 5rem;
`;

const Title = styled(Typography).attrs({ variant: "headline", size: "medium" })`
  padding: 1.5rem 0 1rem 0;
`;

type TabType = {
  label: string;
  route: string;
  disabled?: boolean;
};
const useSettingsTabs = (): TabType[] => {
  const { canManageUsers } = useHasPermissions();
  const { showApiKeys, whitelistCidr } = useOverridableFlags();
  const { data: accountFeatures } = useAccountFeatures();
  return useMemo(
    () => [
      { label: "General", route: "/settings/profile" },
      ...(isElectronContext()
        ? []
        : [
            {
              label: "Users",
              route: "/settings/users",
            },
            {
              label: "Roles",
              route: "/settings/roles",
              disabled: !canManageUsers,
            },
            {
              label: "Policies",
              route: "/settings/policies",
              disabled: !canManageUsers,
            },
            {
              label: "Actions",
              route: "/settings/actions",
              disabled: !canManageUsers || !accountFeatures?.rbacClusterSync,
            },
            {
              label: "API Keys",
              route: "/settings/api-keys",
              disabled: !showApiKeys,
            },
            {
              label: "CIDR Whitelist",
              route: "/settings/cidr-whitelist",
              disabled: !canManageUsers || !whitelistCidr,
            },
          ]),
    ],
    [
      accountFeatures?.rbacClusterSync,
      canManageUsers,
      showApiKeys,
      whitelistCidr,
    ]
  );
};

const SettingsViewLayout: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = useSettingsTabs();

  return (
    <Container>
      <ResponsiveLayout>
        <Title>Settings</Title>
        <Tabs value={location.pathname}>
          {tabs.map(({ label, route, disabled }) =>
            !disabled ? (
              <Tab
                key={route}
                label={label}
                value={route}
                onClick={() => navigate(route)}
                sx={{ color: muiColors.gray[800] }}
              />
            ) : null
          )}
        </Tabs>
      </ResponsiveLayout>
      <Divider />
      <br />
      <BottomMarginToScrollTheIntercom>
        <ResponsiveLayout>{children}</ResponsiveLayout>
      </BottomMarginToScrollTheIntercom>
    </Container>
  );
};

export default SettingsViewLayout;
