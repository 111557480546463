import React from "react";

import { IconProps } from "../types";

export const Check16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7803 4.21967C14.0732 4.51256 14.0732 4.98744 13.7803 5.28033L6.98744 12.0732C6.30402 12.7566 5.19598 12.7566 4.51256 12.0732L2.21967 9.78033C1.92678 9.48744 1.92678 9.01256 2.21967 8.71967C2.51256 8.42678 2.98744 8.42678 3.28033 8.71967L5.57322 11.0126C5.67085 11.1102 5.82914 11.1102 5.92678 11.0126L12.7197 4.21967C13.0126 3.92678 13.4874 3.92678 13.7803 4.21967Z"
      />
    </svg>
  );
};

Check16.defaultProps = {
  fill: "currentColor",
};
