import React from "react";
import styled from "styled-components";

import { sectionGap } from "../../../../../constants";

import { ViolationsBreakdown } from "./ViolationsBreakdown/ViolationsBreakdown";
import { NewViolationsCard } from "./NewViolationsCard";
import { UnattendedViolationsCard } from "./UnattendedViolationsCard";
import { RiskScoreCard } from "./RiskScoreCard";

const FlexContainer = styled.div`
  display: flex;
  gap: ${sectionGap};
  margin-bottom: ${sectionGap};
  & > div {
    flex-basis: 50%;
    height: auto;
    &:last-child {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
  height: fit-content;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
  & > div {
    flex-basis: 50%;
    &:nth-child(1) {
      display: flex;
      flex-direction: column;
    }
    & > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

const SmallCardsContainer = styled.div`
  display: flex;
  gap: ${sectionGap};
  & > div {
    flex-basis: 50%;
    height: auto;
  }
`;

export const RisksCards: React.FC = () => {
  return (
    <FlexContainer>
      <CardsContainer>
        <RiskScoreCard />
        <SmallCardsContainer>
          <NewViolationsCard />
          <UnattendedViolationsCard />
        </SmallCardsContainer>
      </CardsContainer>
      <ViolationsBreakdown />
    </FlexContainer>
  );
};
