import React from "react";
import { LicenseInfo } from "@mui/x-license-pro";

const licenseKey =
  "758452caada712a459ed94d9c422892eTz05NzU5MSxFPTE3NTcxNjY5ODgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==";

export const MuiXLicense: React.FC = () => {
  LicenseInfo.setLicenseKey(licenseKey);
  return null;
};
