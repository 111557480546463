import React, { HTMLAttributes, useState } from "react";
import styled from "styled-components";

import {
  InfoCircleOutlined16,
  AlertTriangleOutlined16,
  CheckBold16,
  AlertCircleOutlined16,
  Close16,
} from "../../../icons";
import { IconButton, Typography } from "../../deprecated";
import { palette } from "../../../palette";

const Container = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
  height: 34px;
  gap: 1rem;
  border: 1px solid ${({ color }) => color};
  background-color: ${palette.white[0]};
`;

const IconContainer = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 2px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 1rem;
  margin-left: auto;
`;

const PaddedTypography = styled(Typography)`
  padding-right: 10px;
`;

/** @deprecated */
export type AlertVariant = "success" | "error" | "warning" | "info";
/** @deprecated */
export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  variant: AlertVariant;
  children: React.ReactNode;
  onClose?: () => void;
  autoClose?: boolean;
}

const getThemeByType = (type: AlertProps["variant"]) => {
  const { teal, pink, orange, blue } = palette;
  switch (type) {
    case "success":
      return {
        foreground: teal[700],
        background: teal[50],
        border: teal[400],
        Icon: CheckBold16,
      };
    case "error":
      return {
        foreground: pink[700],
        background: pink[50],
        border: pink[300],
        Icon: AlertTriangleOutlined16,
      };
    case "warning":
      return {
        foreground: orange[700],
        background: orange[50],
        border: orange[200],
        Icon: AlertCircleOutlined16,
      };
    case "info":
      return {
        foreground: blue[600],
        background: blue[50],
        border: blue[300],
        Icon: InfoCircleOutlined16,
      };
  }
};

/**
 * @deprecated Use MUI Alert instead.
 */
export const Alert: React.FC<AlertProps> = ({
  variant,
  children,
  onClose,
  autoClose,
  ...rest
}: AlertProps) => {
  const [open, setOpen] = useState(true);
  const { foreground, background, border, Icon } = getThemeByType(variant);

  const onCloseClick = () => {
    onClose && onClose();
    autoClose && setOpen(false);
  };

  const showCloseIcon = onClose || autoClose;

  if (!open) return null;

  return (
    <Container color={border} role="alert" {...rest}>
      <IconContainer color={background}>
        <Icon fill={foreground} />
      </IconContainer>
      <PaddedTypography
        variant={"text"}
        size={"medium"}
        aria-label={"alert text content"}
      >
        {children}
      </PaddedTypography>
      {showCloseIcon && (
        <StyledIconButton
          noBorder
          onClick={onCloseClick}
          icon={Close16}
          aria-label={"close alert"}
        />
      )}
    </Container>
  );
};
