import React, { useMemo } from "react";
import styled from "styled-components";
import { Folder16 } from "@komodorio/design-system/icons";
import { Divider } from "@komodorio/design-system/deprecated";
import { muiTheme, palette } from "@komodorio/design-system";
import { AccountPlan } from "komodor-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import useUserMetadata from "../../shared/hooks/useUserMetadata/useUserMetadata";
import { offWhite } from "../../Colors";
import { newAppBarWidth } from "../../constants";
import useDefaultClusterForInspection from "../Inspection/utils/useDefaultClusterForInspection";
import { AccountSwitcher } from "../AccountSwitcher/AccountSwitcher";
import { useOverridableFlags } from "../../shared/context/featureFlags/OverridableFlags";
import { isSandbox } from "../../shared/utils/sandbox";
import { EventsLimitsInfo } from "../Freemium/EventLimitsInfo/EventsLimitsInfo";
import isElectronContext from "../../shared/context/electron/isElectronContext";
import { ShareDesktopApp } from "../DesktopApp/ShareDesktopApp/ShareDesktopApp";
import { ZIndex } from "../../constants/zIndex";

import { AppBarStateProvider } from "./internal/appBarStateContext";
import { Logo } from "./internal/Logo";
import { Body } from "./internal/Body";
import { Footer } from "./internal/Footer";
import { MonitorsLink } from "./links/Monitors";
import { IntegrationsLink } from "./links/Integrations";
import { DocumentationLink } from "./internal/Documentation";
import ProfileButton from "./ProfileButton";
import { NodesLink } from "./links/Nodes";
import { CrdsLink } from "./links/CRDs";
import { LinkGroup } from "./linkGroups/LinkGroup";
import { HelmLink } from "./links/Helm";
import { DesktopClusterManagementLink } from "./links/ClustersManagement";
import { AppBarTitle } from "./constants";
import { WhatsNewLink } from "./internal/WhatsNew";
import { DevCommandBar } from "./Search/DevCommandBar";
import { CommandBar } from "./Search/CommandBar";
import { AgentsLink } from "./links/Agents";

import { K8sAddonCertManager } from "@/components/AppBar/links/K8sAddonCertManager";
import { ChatSupportLink } from "@/components/AppBar/internal/ChatSupport";
import { useAppBar } from "@/components/AppBar/useAppBar";
import { ArgoWorkflowsLink } from "@/components/AppBar/links/ArgoWorkflows";

// The Container is essentially a dark "stripe" running alongside the rest of the page.
const Container = styled.nav`
  background-color: ${palette.darkBlue[900]};
  color: ${palette.white[0]};
  z-index: ${ZIndex.AppBar};
  top: 0;
  bottom: 0;
  position: fixed; // This attribute is also inherited by the element's child – Content.
`;

const Content = styled.div`
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  width: ${newAppBarWidth}rem;
  height: 100vh;
  grid-area: nav;

  a {
    text-decoration: none;
    color: ${offWhite};
  }

  span {
    cursor: pointer;
  }
`;

const Header = styled.div`
  grid-area: logo;
  padding: 0.75rem 0.75rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const AppBarDivider = styled(Divider).attrs({ spacing: "0.25rem" })`
  && {
    border-bottom-color: ${palette.gray[800]};
  }
`;

const AccountActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 40px;
`;

const IndentedContainer = styled.div`
  padding-left: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 16px 7px;
`;

export const AppBar: React.FC = () => {
  const { accountPlan } = useUserMetadata();
  const defaultCluster = useDefaultClusterForInspection();

  const {
    showFeatureFlagToggles,
    showAccountSwitcher,
    devCmdShiftK,
    cmdk,
    showK8SAddons,
    appNavigationChanges,
  } = useOverridableFlags();

  const sandboxMode = isSandbox();
  const {
    topSectionMenuItems,
    middleSectionMenuItems,
    nativeResourcesMenuItems,
  } = useAppBar();

  const showEventsLimitsInfo = accountPlan === AccountPlan.free;

  const footerContent = (
    <>
      {showEventsLimitsInfo && (
        <>
          <EventsLimitsInfo />
          <AppBarDivider />
        </>
      )}
      {isElectronContext() && <ShareDesktopApp />}
      {(showFeatureFlagToggles || showAccountSwitcher) && (
        <>
          <AccountActions data-e2e-selector="appBarAccountActions">
            {showAccountSwitcher ? <AccountSwitcher /> : <Box />}
            {devCmdShiftK === true && <DevCommandBar />}
          </AccountActions>
          {!appNavigationChanges && <AppBarDivider />}
        </>
      )}
      {!appNavigationChanges && (
        <>
          <AgentsLink />
          {isElectronContext() && <DesktopClusterManagementLink />}
          {!isElectronContext() && <IntegrationsLink />}
          <MonitorsLink />
          <DocumentationLink />
          <ChatSupportLink />
          <WhatsNewLink />
          {!sandboxMode && <ProfileButton />}
        </>
      )}
    </>
  );

  const topSectionContent = useMemo(
    () =>
      topSectionMenuItems
        .filter(Boolean)
        .map(({ Component, name }, index) => (
          <Component key={`${name}-${index}`} />
        )),
    [topSectionMenuItems]
  );

  const middleSectionContent = useMemo(
    () =>
      middleSectionMenuItems
        .filter(Boolean)
        .map(({ Component, name }, index) => (
          <Component key={`${name}-${index}`} />
        )),
    [middleSectionMenuItems]
  );

  const nativeResourcesContent = useMemo(
    () =>
      nativeResourcesMenuItems.map(({ header, icon, selector, items }) => (
        <LinkGroup
          key={header}
          title={header}
          icon={icon}
          e2eSelector={selector}
        >
          <IndentedContainer>
            {items.map(({ Component, name }, index) => (
              <Component key={`${name}-${index}`} />
            ))}
          </IndentedContainer>
        </LinkGroup>
      )),
    [nativeResourcesMenuItems]
  );

  return (
    <Container>
      <Content data-e2e-selector="appBarHeader">
        <AppBarStateProvider>
          <Header>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            {!!cmdk && <CommandBar />}
          </Header>
          <Body>
            <>
              {topSectionContent}
              {appNavigationChanges && (
                <>
                  <AppBarDivider />
                  {middleSectionContent}
                </>
              )}
              {defaultCluster && (
                <>
                  <AppBarDivider />
                  {appNavigationChanges && (
                    <Typography
                      variant="overline2"
                      color={muiTheme.palette.text.secondary}
                      sx={{ padding: "4px 16px 0 16px" }}
                      display={"block"}
                    >
                      KUBERNETES EXPLORER
                    </Typography>
                  )}
                  <LinkGroup
                    title={
                      appNavigationChanges
                        ? "Native Resources"
                        : AppBarTitle.Resources
                    }
                    icon={<Folder16 />}
                    e2eSelector="appBarInspectionButtons"
                    alwaysExpanded
                  >
                    <NodesLink />
                    {nativeResourcesContent}
                    <CrdsLink />
                    {!appNavigationChanges && <HelmLink />}
                  </LinkGroup>
                </>
              )}
              {showK8SAddons && (
                <LinkGroup
                  title={AppBarTitle.K8sAddons}
                  icon={<Folder16 />}
                  e2eSelector="k8sAddonesExpandableSectionTitle"
                >
                  {!!appNavigationChanges && (
                    <>
                      <HelmLink />
                      <ArgoWorkflowsLink />
                    </>
                  )}
                  <K8sAddonCertManager />
                </LinkGroup>
              )}
            </>
          </Body>
          <Footer>{footerContent}</Footer>
        </AppBarStateProvider>
      </Content>
    </Container>
  );
};
