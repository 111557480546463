import { AxiosResponse } from "axios";

import {
  CertificateFullFromJSON,
  EntityResourceResponse,
} from "../../../../generated/addonsApi";

import { useLiveStateDrawerContext } from "@/components/k8sAddons/LiveStateDrawer/context/useLiveStateDrawerContext";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";

export const useAddonFullLiveState = <T>(
  select: (json: AxiosResponse<EntityResourceResponse, unknown>) => T
) => {
  const { addonType, addonEntity, uid, name, clusterName } =
    useLiveStateDrawerContext();
  return useGetAddonFullLiveState<T>(
    {
      addon: addonType,
      entity: addonEntity,
      uid,
      name,
      getEntityRequest: {
        clusterName,
      },
    },
    select
  );
};

export function selectCertificate(
  json: AxiosResponse<EntityResourceResponse, unknown>
) {
  return {
    type: json?.data?.type,
    // eslint-disable-next-line new-cap
    data: CertificateFullFromJSON(json?.data?.data),
  };
}
