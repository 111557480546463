import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

import { sectionGap } from "../../../../../../../../../../constants";

import { contentStyles } from "./styles";

const Container = styled.div`
  ${contentStyles};
  display: flex;
  flex-direction: column;
  gap: ${sectionGap};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Title = styled.div`
  display: flex;
  gap: 2px;
`;

interface CardProps {
  title: string;
  additionalInfo?: string;
  content: string;
  children: React.ReactNode;
}

export const Card: React.FC<CardProps> = ({
  title,
  additionalInfo,
  content,
  children,
}) => {
  return (
    <Container>
      <Content>
        <Title>
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body2" color={"text.secondary"}>
            {additionalInfo}
          </Typography>
        </Title>
        <Typography variant="h3" color={"error.dark"}>
          {content}
        </Typography>
      </Content>

      {children}
    </Container>
  );
};
