import { Navigate, Route, Routes } from "react-router-dom";

import { DagView } from "@/components/k8sAddons/addons/workflows/DagView";
import { DagsListPage } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsListPage";

export const Workflows: React.FC = () => {
  return (
    <Routes>
      <Route path={"dags"} element={<DagsListPage />} />
      <Route path={"dag/:komodorWorkflowId"} element={<DagView />} />
      <Route path="*" element={<Navigate to={"dags"} replace />} />
    </Routes>
  );
};
