import React from "react";
import Drawer from "@mui/material/Drawer";

import RowDetailsDrawerImage from "../../assets/noisyNeightborDrawer.png";
import { CloseButtonClickArea } from "../../reliabilityDemoStyles";

type RowDetailsDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};
export const RowDetailsDrawer: React.FC<RowDetailsDrawerProps> = ({
  open,
  setOpen,
}) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"}>
      <img
        src={RowDetailsDrawerImage}
        alt={"loading content"}
        width={864}
        height={1871}
      />
      <CloseButtonClickArea onClick={() => setOpen(false)} />
    </Drawer>
  );
};
