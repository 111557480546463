import React from "react";

import { RiskScoreGraph } from "../../../../../assets/RiskScoreGraph";

import { LargeStatsCard } from "./StatsCard/LargeStatsCard";

export const RiskScoreCard: React.FC = () => {
  return (
    <LargeStatsCard
      title={"Risk Score"}
      mainStat={78}
      trend={"-32%"}
      epoch={"Last 7 days"}
      graphComponent={<RiskScoreGraph />}
    />
  );
};
