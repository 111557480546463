import React from "react";

import { IconProps } from "../types";

export const AlertCircleOutlined16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M7.22318 4.64452C7.22318 4.22701 7.57097 3.88855 7.99999 3.88855C8.42901 3.88855 8.7768 4.22701 8.7768 4.64452V9.18035C8.7768 9.59786 8.42901 9.93632 7.99999 9.93632C7.57097 9.93632 7.22318 9.59786 7.22318 9.18035V4.64452Z" />
      <path d="M7.22318 11.7082C7.22318 11.2907 7.57097 10.9522 7.99999 10.9522C8.42901 10.9522 8.7768 11.2907 8.7768 11.7082C8.7768 12.1257 8.42901 12.4642 7.99999 12.4642C7.57097 12.4642 7.22318 12.1257 7.22318 11.7082Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8ZM8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z"
      />
    </svg>
  );
};

AlertCircleOutlined16.defaultProps = {
  fill: "currentColor",
};
