import React, { CSSProperties } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { CustomHistogramTooltip } from "./CustomHistogramTooltip";
import { HistogramProps } from "./types";
import {
  defaultBarColors,
  defaultTimeFrameToDateFormat,
  defaultTotalBars,
} from "./defaultConfig";
import { getYAxisMargin, useHistogramData } from "./histogramHooks";
import { EmptyHistogram } from "./EmptyHistogram";

const labelStyle: CSSProperties = {
  fontSize: "12px",
  fontFamily: "Roboto",
};
export const TimeWindowHistogram: React.FC<HistogramProps> = ({
  data,
  timeWindow,
  timeFrameToDateFormat = defaultTimeFrameToDateFormat,
  totalBars = defaultTotalBars,
  barColors = defaultBarColors,
  aggregatedIdsLabel = "Others total",
  barChartProps,
}) => {
  const { dataOutput, idsDictionary, highestVal } = useHistogramData({
    data,
    barColors,
    timeWindow,
    timeFrameToDateFormat,
    totalBars,
    aggregatedIdsLabel,
  });

  if (!data.length) return <EmptyHistogram barChartProps={barChartProps} />;

  const barsContent = Array.from({
    length: Object.keys(barColors).length,
  }).map((_, idx) => {
    const dataKey = Object.keys(idsDictionary)[idx];
    return (
      <Bar
        dataKey={dataKey}
        fill={barColors[idx]}
        stackId={"stackId"}
        key={idx}
      />
    );
  });

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BarChart
        {...barChartProps}
        margin={{
          top: 0,
          right: 0,
          left: getYAxisMargin(highestVal),
          bottom: 0,
        }}
        data={dataOutput}
      >
        <CartesianGrid vertical={false} />
        {barsContent}
        <XAxis dataKey="name" style={labelStyle} />
        <YAxis
          type={"number"}
          domain={["dataMin", "dataMax"]}
          style={labelStyle}
          allowDecimals={false}
        />
        <Tooltip content={<CustomHistogramTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
};
