"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IdentityProvider = void 0;
var IdentityProvider;
(function (IdentityProvider) {
    IdentityProvider["USERNAMEPASSWORD"] = "username-password";
    IdentityProvider["GSUITE"] = "gsuite";
    IdentityProvider["GITHUB"] = "github";
    IdentityProvider["MICROSOFT"] = "microsoft";
    IdentityProvider["OKTA"] = "okta";
    IdentityProvider["ACTIVE_DIRECTORY"] = "active-directory";
    IdentityProvider["LDAP_ACTIVE_DIRECTORY"] = "ldap-active-directory";
    IdentityProvider["SAMLP"] = "samlp";
})(IdentityProvider = exports.IdentityProvider || (exports.IdentityProvider = {}));
