import React from "react";
import styled from "styled-components";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { Details } from "./Details/Details";
import { Actions } from "./Actions/Actions";

const Container = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px;
`;

const CloseButton = styled.div`
  width: 24px;
`;

export const Header: React.FC<{ closeCb: () => void }> = ({ closeCb }) => {
  return (
    <Container>
      <CloseButton>
        <IconButton onClick={closeCb}>
          <Close />
        </IconButton>
      </CloseButton>
      <Details />
      <Actions />
    </Container>
  );
};
