import React from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { palette } from "../../../../../themes/palette";
import { gray } from "../../../../../themes/colors";
import { selectAriaLabels } from "../ariaLabels";
import { selectSize } from "../../../../../constants";
import { Size } from "../../../../../types";

const getHeightBySize = (size: Size) => selectSize[size];

const Container = styled.div<{
  size: Size;
  autoheight?: boolean;
}>`
  width: 100%;
  height: ${({ autoheight, size }) =>
    autoheight ? "auto" : `${getHeightBySize(size)}px`};
  min-height: ${({ autoheight, size }) =>
    autoheight ? `${getHeightBySize(size)}px` : "unset"};
  border: 1px solid ${gray[300]};
  border-radius: 4px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${gray[600]};
  }
`;

const ContentContainer = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 4px;
  width: 100%;
  max-width: calc(100% - 44px);
`;

const ArrowContainer = styled.div<{ flip: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > svg {
    color: ${palette.action.active};
    transition: transform 0.2s ease-in-out;
    transform: ${({ flip }) => (flip ? "rotate(180deg)" : "rotate(0deg)")};
  }

  margin-right: 12px;
`;

type DropDownTriggerFieldProps = {
  children: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  dropDownOpen: boolean;
  enableLineBreaks?: boolean;
  className?: string;
  size: Size;
};

export const DropDownTriggerField: React.FC<DropDownTriggerFieldProps> = ({
  children,
  onClick,
  dropDownOpen,
  className,
  enableLineBreaks,
  size,
}) => {
  return (
    <Container
      onClick={onClick}
      className={className}
      autoheight={enableLineBreaks}
      size={size}
      aria-label={selectAriaLabels.dropDownList}
    >
      <ContentContainer>{children}</ContentContainer>
      <ArrowContainer flip={dropDownOpen}>
        <ArrowDropDownIcon />
      </ArrowContainer>
    </Container>
  );
};

DropDownTriggerField.displayName = "DropDownTriggerField";
