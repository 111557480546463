import React from "react";

import { IconProps } from "../types";

export const Workflows24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 15H20V13H14V15ZM8 19H20V17H8V19ZM8 11H16V9H14H8V11ZM4 5V7H10V5H4Z"
    />
  </svg>
);

Workflows24.defaultProps = {
  fill: "currentColor",
};
