import React, { MouseEvent } from "react";
import Switch from "@mui/material/Switch";

import { useRiskManagementContext } from "../../../../../context/RiskManagementContext";
import { ReliabilityCheckData } from "../../../../../riskManagementTypes";

type ActiveCheckSwitchProps = {
  data: ReliabilityCheckData;
};

export const ActiveCheckSwitch: React.FC<ActiveCheckSwitchProps> = ({
  data,
}) => {
  const { updateReliabilityCheck } = useRiskManagementContext();

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    updateReliabilityCheck({
      ...data,
      active: (e.target as HTMLInputElement).checked,
    });
  };

  return <Switch checked={data.active} onClick={onClick} />;
};
