import React from "react";

import { IconProps } from "../types";

export const EventManual24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 6C17 7.90036 15.9398 9.5531 14.3787 10.399V7.5C14.3787 6.11929 13.2594 5 11.8787 5C10.4979 5 9.37866 6.11929 9.37866 7.5V10.2586C7.95133 9.37813 7 7.80016 7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6ZM11.8787 6C11.0503 6 10.3787 6.67157 10.3787 7.5V16.8787L7.56066 14.0607C6.97488 13.4749 6.02514 13.4749 5.43934 14.0607C4.85355 14.6465 4.85355 15.5962 5.43934 16.182L9.81307 21.5557C10.7573 22.5 11.2914 23 12.3787 23H17.3787C19.0355 23 20.3787 21.6569 20.3787 20V18C20.3787 15.2386 18.1401 13 15.3787 13H13.3787V7.5C13.3787 6.67157 12.7071 6 11.8787 6Z"
      />
    </svg>
  );
};

EventManual24.defaultProps = {
  fill: "currentColor",
};
