import React, { useEffect } from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { useDeletedPodsProperties } from "../../../Inspection/historicalFetch/useResourceListFromDatabase";
import { Timeframe } from "../../../../shared/types/TimeWindow";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { useActiveAgent } from "@/shared/hooks/useAgents";
import PodsContentAtm from "@/components/ResourceView/tabs/PodsTab/PodsData";
import { Panel } from "@/components/Inspection/styles";

export const WorkflowPodsTab: React.FC<{ resource: WorkflowRun }> = ({
  resource,
}) => {
  const {
    showDeletedPodsParam,
    setShowDeletedPodsParam,
    setDeletedPodsTimeWindow,
  } = useDeletedPodsProperties();

  useEffect(() => {
    if (showDeletedPodsParam === null) {
      setShowDeletedPodsParam(true, true);
      setDeletedPodsTimeWindow(
        { timeframe: Timeframe.Last48Hours },
        { replace: true }
      );
    }
  }, [setDeletedPodsTimeWindow, setShowDeletedPodsParam, showDeletedPodsParam]);

  const agentId = useActiveAgent(resource.cluster);

  if (!agentId) {
    return (
      <Panel>
        <LinesLoader />
      </Panel>
    );
  }

  return <PodsContentAtm resource={resource} agentId={agentId} />;
};
