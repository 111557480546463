import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";

import { TabLabel, ViolationBreakdown } from "../types";
import { getAccumulatedViolations } from "../../risksListUtils";

const Container = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 40px;
  padding: 20px 20px 20px 0;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ArrowsContainer = styled.div`
  display: flex;
  gap: 30px;
`;

type FakeTableFooterProps = {
  violationTotals: ViolationBreakdown;
  tabValue: TabLabel;
};

export const FakeTableFooter: React.FC<FakeTableFooterProps> = ({
  tabValue,
  violationTotals,
}) => {
  const total =
    tabValue === "all"
      ? getAccumulatedViolations(violationTotals)
      : violationTotals[tabValue];

  return (
    <Container>
      <FlexContainer>
        <Typography variant={"body3"} color={"text.secondary"}>
          Rows per page:
        </Typography>
        <Typography variant={"body3"} color={"text.primary"}>
          5
        </Typography>
        <ArrowDropDown color={"action"} />
      </FlexContainer>
      <FlexContainer>
        <Typography variant={"body3"} color={"text.primary"}>
          {`1-5 of ${total}`}
        </Typography>
      </FlexContainer>
      <ArrowsContainer>
        <ArrowBackIos color={"action"} sx={{ width: "16px" }} />
        <ArrowForwardIos color={"action"} sx={{ width: "16px" }} />
      </ArrowsContainer>
    </Container>
  );
};
