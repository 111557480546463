import React, { createContext, useContext } from "react";

import Resource from "./resources";

const resourceContext = createContext<Resource>({} as Resource);

export const ResourceProvider: React.FC<{
  resource: Resource;
  children?: React.ReactNode;
}> = ({ resource, children }) => {
  return (
    <resourceContext.Provider value={resource}>
      {children}
    </resourceContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useResourceView = (): Resource => useContext(resourceContext);
