import React from "react";
import Drawer from "@mui/material/Drawer";

import noisyNeighborDrawer from "../assets/noisyNeighborDrawer.png";
import { CloseButtonArea } from "../styles";

type NoisyNeighborDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const NoisyNeighborDrawer: React.FC<NoisyNeighborDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img
        src={noisyNeighborDrawer}
        alt={"noisy neighbor drawer"}
        width={864}
      />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};
