import { muiColors } from "@komodorio/design-system";
import { Area } from "recharts";
import { useMemo } from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import { sortBy } from "lodash";

import {
  MonitorType,
  OpenIssuesResponseDataByCluster,
} from "../../../../../generated/monitorsApi";
import { CountGraph } from "../../../../Metrics/graphs/CountGraph";
import { sharedStraightLineProps } from "../../../../Metrics/constants";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { DateTick } from "../../../../common/EventsChart/ticks";
import { TooltipItemIcon } from "../../../../Metrics/graphEntity/TooltipItemIcon";
import { useGetIssuesOverTime } from "../../../../../shared/hooks/monitors-api/client/issues/useGetIssuesOverTime";
import { _30_DAYS_MS } from "../CostSection/constants";
import { useStoreIsFetchingForReport } from "../../useReportClusterOverviewLoadingTime";

import { BOX_HEIGHT } from "./constants";

import { CustomGraphDataPoint } from "@/components/Metrics/types";
import { unixTimeToMilliseconds } from "@/shared/utils/timeUtils";
import {
  ROUND_TO_NEAREST_SECONDS,
  roundToNearestNSeconds,
} from "@/components/Metrics/utils";

const { CountArea, ItemIcon } =
  AriaLabels.ClusterOverview.UnhealthyServicesSection.OpenIssuesCountGraph;

const OTHERS_CLUSTER_NAME = "others";

const STACK_ID = "stack-id";
const NUM_OF_TICKS = 4;

const othersStackColor = {
  stroke: muiColors.gray[500],
  fill: muiColors.gray[100],
} as const;

const areaStackColors = [
  {
    stroke: muiColors.blue[400],
    fill: muiColors.blue[100],
  },
  {
    stroke: muiColors.orange[600],
    fill: muiColors.orange[100],
  },
  {
    stroke: muiColors.deepPurple[300],
    fill: muiColors.deepPurple[100],
  },
  {
    stroke: muiColors.red[700],
    fill: muiColors.pink[100],
  },
  {
    stroke: muiColors.indigo[500],
    fill: muiColors.blue[100],
  },
] as const;
const singleLineColor = muiColors.indigo[400];

const Container = styled.div`
  height: ${BOX_HEIGHT};
  padding: 16px 16px 16px 4px;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const transformData = (data: OpenIssuesResponseDataByCluster[]) => {
  const transformed: CustomGraphDataPoint[] = [];
  const timestampMap: { [timestamp: string]: CustomGraphDataPoint } = {};

  data.forEach((cluster) => {
    cluster.datapoints.forEach((datapoint) => {
      const { timestamp, count } = datapoint;
      const time = unixTimeToMilliseconds(
        roundToNearestNSeconds(Date.parse(timestamp), ROUND_TO_NEAREST_SECONDS)
      );
      if (!timestampMap[timestamp]) {
        timestampMap[timestamp] = { time };
        transformed.push(timestampMap[timestamp]);
      }
      timestampMap[timestamp][cluster.clusterName] = count;
    });
  });

  return transformed;
};

const toEpoch = new Date(Date.now());
const fromEpoch = new Date(toEpoch.getTime() - _30_DAYS_MS);

interface OpenIssuesCountGraphProps {
  clusters: string[];
  isSingleCluster: boolean;
  shouldShowLoader?: boolean;
}
export const OpenIssuesCountGraph: React.FC<OpenIssuesCountGraphProps> = ({
  clusters,
  isSingleCluster,
  shouldShowLoader = false,
}) => {
  const { data, isLoading } = useGetIssuesOverTime(
    {
      clusterNames: clusters,
      types: [MonitorType.Availability],
      fromEpoch: fromEpoch.getTime().toString(),
      toEpoch: toEpoch.getTime().toString(),
    },
    { staleTime: 0 }
  );
  useStoreIsFetchingForReport("isFetchingOpenIssuesCountGraph", isLoading);

  const includesOthers = (data?.data ?? []).find(
    (cdp) => cdp.clusterName === OTHERS_CLUSTER_NAME
  );

  const stackColors = useMemo(
    () =>
      includesOthers ? [othersStackColor, ...areaStackColors] : areaStackColors,
    [includesOthers]
  );
  const colorsLen = stackColors.length;

  const clustersDataPoints = sortBy(
    data?.data ?? [],
    ({ datapoints, clusterName }) => {
      // Give priority to "others" and then sort by the last datapoint count
      return clusterName === OTHERS_CLUSTER_NAME
        ? -Infinity
        : -datapoints[datapoints.length - 1].count;
    }
  );

  const transformedData = useMemo(
    () => transformData(clustersDataPoints),
    [clustersDataPoints]
  );

  const tooltipItems = useMemo(
    () =>
      clustersDataPoints
        .map(({ clusterName }, i) => ({
          dataKey: clusterName,
          title: isSingleCluster ? "Issues" : clusterName,
          tooltipItemKey: "issues",
          icon: (
            <TooltipItemIcon
              color={
                isSingleCluster
                  ? singleLineColor
                  : stackColors[i % colorsLen].stroke
              }
              ariaLabel={ItemIcon}
            />
          ),
        }))
        .reverse(),
    [clustersDataPoints, colorsLen, isSingleCluster, stackColors]
  );

  if (isLoading || shouldShowLoader) {
    return (
      <Container>
        <Center>
          <LinesLoader />
        </Center>
      </Container>
    );
  }

  if (transformedData.length === 0) {
    return (
      <Container>
        <Center>No Data</Center>
      </Container>
    );
  }

  return (
    <Container>
      <CountGraph
        data={transformedData}
        timelineTicksNum={NUM_OF_TICKS}
        label={"count"}
        tickComponent={(props) => <DateTick lastTickXOffset={-15} {...props} />}
        tooltipItems={tooltipItems}
        tooltipDateOnly
        disableZoom
        areaChart
      >
        {clustersDataPoints.map(({ clusterName }, i) => (
          <Area
            key={clusterName}
            aria-label={CountArea}
            stroke={
              isSingleCluster
                ? singleLineColor
                : stackColors[i % colorsLen].stroke
            }
            fill={isSingleCluster ? "none" : stackColors[i % colorsLen].fill}
            dataKey={clusterName}
            stackId={STACK_ID}
            {...sharedStraightLineProps}
          />
        ))}
      </CountGraph>
    </Container>
  );
};
