import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { StyledPaper } from "../styles";

const FlexContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(FlexContainer)`
  display: flex;
`;

const StatsContainer = styled(FlexContainer)`
  align-items: flex-end;
  gap: 6px;
  flex-grow: 1;
  & > svg {
    flex-grow: 1;
  }
`;

const HugeTypographyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const HugeTypography = styled(Typography)`
  && {
    font-size: 48px;
    font-weight: 700;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrendContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  top: -10%;
`;

type LargeStatsCardProps = {
  title: string;
  mainStat: number;
  trend: string;
  epoch: string;
  graphComponent: React.ReactNode;
};

export const LargeStatsCard: React.FC<LargeStatsCardProps> = ({
  title,
  mainStat,
  trend,
  epoch,
  graphComponent,
}) => {
  return (
    <StyledPaper>
      <Typography variant={"h4"}>{title}</Typography>
      <ContentContainer>
        <TextContainer>
          <HugeTypographyContainer>
            <HugeTypography variant={"h1"} color={"error.dark"}>
              {mainStat}
            </HugeTypography>
          </HugeTypographyContainer>
          <TrendContainer>
            <Typography variant={"body3"} color={"text.secondary"}>
              {trend}
            </Typography>
            <Typography variant={"body3"} color={"text.secondary"}>
              {epoch}
            </Typography>
          </TrendContainer>
        </TextContainer>
        <StatsContainer>{graphComponent}</StatsContainer>
      </ContentContainer>
    </StyledPaper>
  );
};
