import React from "react";

import { IconProps } from "../types";

export const Copy24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 9.5H4C3.72386 9.5 3.5 9.72386 3.5 10V20C3.5 20.2761 3.72386 20.5 4 20.5H14C14.2761 20.5 14.5 20.2761 14.5 20V10C14.5 9.72386 14.2761 9.5 14 9.5ZM4 8C2.89543 8 2 8.89543 2 10V20C2 21.1046 2.89543 22 4 22H14C15.1046 22 16 21.1046 16 20V10C16 8.89543 15.1046 8 14 8H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5H20C20.2761 3.5 20.5 3.72386 20.5 4V14C20.5 14.2761 20.2761 14.5 20 14.5H18V16H20C21.1046 16 22 15.1046 22 14V4C22 2.89543 21.1046 2 20 2H10C8.89543 2 8 2.89543 8 4V6H9.5V4C9.5 3.72386 9.72386 3.5 10 3.5Z"
      />
    </svg>
  );
};

Copy24.defaultProps = {
  fill: "currentColor",
};
