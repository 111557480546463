import React from "react";

import { IconProps } from "../types";

export const AlertCircle16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.22321 4.64464C7.22321 4.22713 7.571 3.88867 8.00002 3.88867C8.42904 3.88867 8.77683 4.22713 8.77683 4.64464V9.18047C8.77683 9.59798 8.42904 9.93644 8.00002 9.93644C7.571 9.93644 7.22321 9.59798 7.22321 9.18047V4.64464ZM7.22321 11.7083C7.22321 11.2908 7.571 10.9524 8.00002 10.9524C8.42904 10.9524 8.77683 11.2908 8.77683 11.7083C8.77683 12.1258 8.42904 12.4643 8.00002 12.4643C7.571 12.4643 7.22321 12.1258 7.22321 11.7083Z"
      />
    </svg>
  );
};

AlertCircle16.defaultProps = {
  fill: "currentColor",
};
