import React from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { DataGridPro, GridColDef, GridRowParams } from "@mui/x-data-grid-pro";

import { ViolationDetails } from "../types";
import { useRiskManagementContext } from "../../../../../../context/RiskManagementContext";

const StyledDataGrid = styled(DataGridPro)`
  && {
    .MuiDataGrid-columnHeaders {
      background-color: ${muiColors.gray[50]};
      border-bottom: 1px solid ${muiColors.gray[200]};
      border-top: 1px solid ${muiColors.gray[200]};
    }

    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
  }
` as typeof DataGridPro;

type RiskDetailsTableProps = {
  rows: ViolationDetails[];
  columns: GridColDef<ViolationDetails>[];
};

export const RiskDetailsTable: React.FC<RiskDetailsTableProps> = ({
  rows,
  columns,
}) => {
  const { setIsRiskDrawerOpen } = useRiskManagementContext();
  const handleRowClick = (params: GridRowParams) => {
    setIsRiskDrawerOpen(!!params.id);
  };

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      checkboxSelection
      columnHeaderHeight={40}
      rowHeight={72}
      rowSpacingType={"border"}
      onRowClick={handleRowClick}
    />
  );
};
