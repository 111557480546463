import pluralize from "pluralize";

import { now } from "@/components/k8sAddons/constants/time";
import { ONE_DAY_IN_MS } from "@/shared/constants/time";

export const getTimeElapsedStr = (targetDateString: string) => {
  const timeElapsedInMs = new Date(targetDateString).getTime() - now;
  const daysElapsed = Math.floor(timeElapsedInMs / ONE_DAY_IN_MS);
  let outputStr = "";

  if (daysElapsed === 0) {
    outputStr = `Today`;
  } else if (daysElapsed > 0) {
    outputStr = `${daysElapsed}d`;
  } else {
    const daysElapsedAbs = Math.abs(daysElapsed);
    outputStr = `${daysElapsedAbs} ${pluralize("day", daysElapsedAbs)} ago`;
  }

  return { outputStr, daysElapsed, timeElapsedInMs };
};
