import React from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

import NodePressureDrawerImage from "../../assets/nodePressureDrawer.png";
import { CloseButtonClickArea } from "../../reliabilityDemoStyles";

const SelectableRow = styled.div`
  position: absolute;
  cursor: pointer;
  width: 746px;
  height: 59px;
  top: 382px;
  left: 25px;
`;

type NodePressureDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onRowClick: () => void;
};
export const NodePressureDrawer: React.FC<NodePressureDrawerProps> = ({
  open,
  setOpen,
  onRowClick,
}) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)} anchor={"right"}>
      <img
        src={NodePressureDrawerImage}
        alt={"loading content"}
        width={796}
        height={901}
      />
      <SelectableRow onClick={onRowClick} />
      <CloseButtonClickArea onClick={() => setOpen(false)} />
    </Drawer>
  );
};
