import { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { severitiesList } from "../policyDrawerConstants";

import { CheckType } from "@/generated/reliabilityApi";
import { SeverityValues } from "@/components/reliability/components/pages/policies/PolicyDrawer/policyDrawerTypes";
import { useValidateNumericFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useValidateNumericFields";
import { useValidateDeprecatedApiFields } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useValidateDeprecatedApiFields";
import { getConfigurationFieldNamesByCheckTypeAsList } from "@/components/reliability/components/pages/policies/PolicyDrawer/utils/getConfigurationFieldNamesByCheckType";

export const useValidateConfigurationControlsInput = (checkType: CheckType) => {
  const { formState, getValues } = useFormContext();
  const validateNumericFields = useValidateNumericFields();
  const validateDeprecatedApiFields = useValidateDeprecatedApiFields();

  const getCurrentValues = useCallback(() => {
    return severitiesList.reduce<SeverityValues>((acc, severity) => {
      const configurationFieldNames =
        getConfigurationFieldNamesByCheckTypeAsList(checkType, severity);

      acc[`${severity}Values`] = getValues(configurationFieldNames);

      return acc;
    }, {});
  }, [checkType, getValues]);

  const values = getCurrentValues();

  return useEffect(() => {
    if (!values) return;

    const isReverseSeverityOrder = checkType === CheckType.KubernetesEndOfLife;
    const currentValues = getCurrentValues();

    if (checkType === CheckType.DeprecatedApis) {
      return validateDeprecatedApiFields(currentValues);
    }

    const configurationFieldNames =
      getConfigurationFieldNamesByCheckTypeAsList(checkType);

    configurationFieldNames.forEach((configurationName, idx) => {
      return validateNumericFields({
        configurationName,
        isReverseSeverityOrder,
        highNumber: parseFloat(currentValues.highValues?.[idx] as string),
        mediumNumber: parseFloat(currentValues.mediumValues?.[idx] as string),
        lowNumber: parseFloat(currentValues.lowValues?.[idx] as string),
      });
    });
  }, [
    values,
    checkType,
    formState.errors,
    getCurrentValues,
    getValues,
    validateNumericFields,
    validateDeprecatedApiFields,
  ]);
};
