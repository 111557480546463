import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";

import { InputContainer, InputContainerProps } from "../Input/InputContainer";

/** @deprecated */
export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> &
  Omit<InputContainerProps, "onChange">;

const StyledTextArea = styled.textarea`
  /* disable textarea default style*/
  border: none;
  padding: unset;
  background-color: unset;
  color: unset;
  font-family: unset;
  font-style: unset;
  font-weight: unset;
  font-size: unset;
  line-height: unset;
  resize: none;

  width: 100%;

  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

/**
 * @deprecated Use MUI TextField instead.
 */
export const TextArea: React.FC<TextAreaProps> = ({ onChange, ...rest }) => {
  return (
    <InputContainer {...rest}>
      <StyledTextArea onChange={onChange} {...rest} />
    </InputContainer>
  );
};

TextArea.defaultProps = {
  rows: 3,
};
