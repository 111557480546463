import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import ThumbDownAlt from "@mui/icons-material/ThumbDownAlt";
import ThumbUpAlt from "@mui/icons-material/ThumbUpAlt";

import { sectionGap } from "../../../../../../../../../../constants";

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: ${sectionGap};
`;

export const Rate: React.FC = () => (
  <Container>
    <Typography variant="body3" color="text.secondary">
      Did you find this useful?
    </Typography>
    <ThumbUpAlt color="action" />
    <ThumbDownAlt color="action" />
  </Container>
);
