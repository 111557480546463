import React from "react";

import { IconProps } from "../types";

export const ChevronUp16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.21934 10.5293C4.07889 10.3887 4 10.1981 4 9.99934C4 9.80059 4.07889 9.60997 4.21934 9.46934L7.46934 6.21934C7.60997 6.07889 7.80059 6 7.99934 6C8.19809 6 8.38871 6.07889 8.52934 6.21934L11.7793 9.46934C11.9118 9.61152 11.9839 9.79956 11.9805 9.99386C11.9771 10.1882 11.8984 10.3735 11.761 10.511C11.6235 10.6484 11.4382 10.7271 11.2439 10.7305C11.0496 10.7339 10.8615 10.6618 10.7193 10.5293L7.99934 7.80934L5.27934 10.5293C5.13871 10.6698 4.94809 10.7487 4.74934 10.7487C4.55059 10.7487 4.35997 10.6698 4.21934 10.5293Z"
    />
  </svg>
);

ChevronUp16.defaultProps = {
  fill: "currentColor",
};
