import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ifProp, switchProp } from "styled-tools";

import { theme } from "../../../theme";

// Headline Styles

const headlineStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
`;
const headlineSmallStyle = css`
  ${headlineStyle};
  font-size: 20px;
  line-height: 28px;
`;
const headlineMediumStyle = css`
  ${headlineStyle};
  font-size: 24px;
  line-height: 36px;
`;
const headlineLargeStyle = css`
  ${headlineStyle};
  font-size: 32px;
  line-height: 48px;
`;

// Title Styles

const titleStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
`;
const titleSmallStyle = css`
  ${titleStyle};
  font-size: 12px;
  line-height: 16px;
`;
const titleMediumStyle = css`
  ${titleStyle};
  font-size: 14px;
  line-height: 20px;
`;
const titleLargeStyle = css`
  ${titleStyle};
  font-size: 16px;
  line-height: 24px;
`;

// Text Styles

const textStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
`;
const textSmallStyle = css`
  ${textStyle};
  font-size: 12px;
  line-height: 16px;
`;
const textMediumStyle = css`
  ${textStyle};
  font-size: 14px;
  line-height: 20px;
`;
const textLargeStyle = css`
  ${textStyle};
  font-size: 16px;
  line-height: 24px;
`;

const uppercaseStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

/** @deprecated */
export type TypographyVariant = "headline" | "title" | "text" | "uppercase";
/** @deprecated */
export type TypographySize = "small" | "medium" | "large";
/** @deprecated */
export interface TypographyProps extends HTMLAttributes<HTMLDivElement> {
  variant?: TypographyVariant;
  size?: TypographySize;
  color?: string;
  underline?: boolean;
  bold?: boolean;
  strikeThrough?: boolean;
  mono?: boolean;
}

const Container = styled.div<TypographyProps>`
  color: ${({ color }) => color};
  ${switchProp("variant", {
    headline: switchProp("size", {
      small: headlineSmallStyle,
      medium: headlineMediumStyle,
      large: headlineLargeStyle,
    }),
    title: switchProp("size", {
      small: titleSmallStyle,
      medium: titleMediumStyle,
      large: titleLargeStyle,
    }),
    text: switchProp("size", {
      small: textSmallStyle,
      medium: textMediumStyle,
      large: textLargeStyle,
    }),
    uppercase: uppercaseStyle,
  })}
  ${ifProp("underline", "text-decoration-line: underline;")}
  ${ifProp("bold", "font-weight: 700;")}
  ${ifProp("strikeThrough", "text-decoration-line: line-through;")}
  ${ifProp("mono", "font-family: 'Roboto Mono';")}
`;

/**
 * @deprecated Use MUI Typography instead.
 */
export const Typography: React.FC<TypographyProps> = (props) => {
  return <Container {...props} role="definition" />;
};

Typography.defaultProps = {
  variant: "text",
  size: "small",
  color: theme.text,
  underline: false,
  bold: false,
  strikeThrough: false,
  mono: false,
};
