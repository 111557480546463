import React from "react";

import { IconProps } from "../types";

export const LightningBolt16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M7.44444 1L3 8H6.33333L3 15L13 6H9.66667L13 1H7.44444Z" />
    </svg>
  );
};

LightningBolt16.defaultProps = {
  fill: "currentColor",
};
