import React, { useState } from "react";
import styled from "styled-components";

import { ClickableArea } from "../styles";
import costItemClose from "../assets/cluster1/costItemClose.png";
import costItemOpen from "../assets/cluster1/costItemOpen.png";

import { ApplyRecommendationsModal } from "./ApplyRecommendationsModal";
import { Item } from "./Item";

const SelectableArea = styled(ClickableArea)`
  width: 7.6%;
  height: 6.25%;
  top: 34.9%;
  left: 85.1%;
  border-radius: 4px;
`;

export const CostItem: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Item closedImg={costItemClose} openedImg={costItemOpen}>
      <SelectableArea onClick={() => setOpenModal(true)} />
      <ApplyRecommendationsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </Item>
  );
};
