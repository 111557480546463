import React from "react";

import { IconProps } from "../types";

export const Pencil16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2501 1C11.7862 1 11.3412 1.1842 11.0131 1.51111L2.40308 10.1211C2.19269 10.3318 2.03955 10.5928 1.95807 10.8792L1.02911 14.1292C0.992187 14.2578 0.990356 14.3939 1.0238 14.5236C1.05054 14.6271 1.099 14.7234 1.16565 14.8062C1.18243 14.827 1.20038 14.847 1.21936 14.8661C1.31396 14.9607 1.43213 15.0283 1.56171 15.062C1.63415 15.0808 1.70868 15.0885 1.78284 15.0853C1.84125 15.0828 1.89941 15.0734 1.95611 15.0571L5.20709 14.1271C5.35278 14.0858 5.4917 14.0256 5.62073 13.9489C5.74499 13.8748 5.86005 13.7852 5.96307 13.6821L14.5731 5.07214C14.901 4.74402 15.0852 4.29907 15.0852 3.83508C15.0852 3.37122 14.901 2.92627 14.5731 2.59814L13.4871 1.51208C13.1589 1.1842 12.714 1 12.2501 1ZM12.3459 2.51782C12.3763 2.5304 12.4039 2.54883 12.4271 2.57214L13.5131 3.65808C13.5364 3.6814 13.5549 3.70898 13.5674 3.73926C13.5801 3.76965 13.5865 3.80225 13.5865 3.83508C13.5865 3.86804 13.5801 3.90051 13.5674 3.93091C13.5549 3.9613 13.5364 3.98889 13.5131 4.01208L4.90308 12.6211C4.88818 12.6361 4.87152 12.649 4.85351 12.6598C4.83527 12.6707 4.81567 12.6792 4.7951 12.6852L2.8421 13.2432L3.40008 11.2902C3.41186 11.2493 3.4339 11.2122 3.46411 11.1821L12.0731 2.57214C12.0963 2.54883 12.1239 2.5304 12.1543 2.51782C12.1846 2.50513 12.2172 2.49866 12.2501 2.49866C12.283 2.49866 12.3156 2.50513 12.3459 2.51782Z"
      />
    </svg>
  );
};

Pencil16.defaultProps = {
  fill: "currentColor",
};
