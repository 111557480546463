import { useMemo, useState } from "react";

import { LineGroup } from "./groupEventsByLine";

const PAGE_SIZE = 10;

export const useSwimlanesPagination = (
  eventsByLine: LineGroup[],
  paginateSwimlanes: boolean
) => {
  const linesCount = useMemo(() => eventsByLine.length, [eventsByLine.length]);
  const [page, setPage] = useState(1);
  const totalPages = useMemo(
    () => Math.max(Math.ceil(linesCount / PAGE_SIZE), 1),
    [linesCount]
  );
  const pageOffset = useMemo(
    () => (linesCount === 0 ? 0 : ((page - 1) * PAGE_SIZE) % linesCount),
    [page, linesCount]
  );
  const eventsByLinePerPage = useMemo(() => {
    let eventsByLinePerPage = eventsByLine;
    if (paginateSwimlanes) {
      eventsByLinePerPage = eventsByLine.slice(
        pageOffset,
        pageOffset + PAGE_SIZE
      );
    }
    return eventsByLinePerPage.reverse();
  }, [eventsByLine, pageOffset, paginateSwimlanes]);

  return {
    eventsByLinePerPage,
    page,
    setPage,
    totalPages,
  };
};
