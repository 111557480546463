import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";

interface TabsProps {
  tabValue: string;
  onTabChange?: (event: React.SyntheticEvent, newValue: string) => void;
}

export const Tabs: React.FC<TabsProps> = ({ tabValue, onTabChange }) => {
  return (
    <TabContext value={tabValue}>
      <TabList onChange={onTabChange}>
        <Tab label="Overview" value="Overview" />
        <Tab label="History" value="History" />
      </TabList>
    </TabContext>
  );
};
