import React from "react";

import { IconProps } from "../types";

export const Code16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00841 12.1556C5.94701 12.5652 6.22932 12.947 6.63896 13.0084C7.0486 13.0698 7.43044 12.7875 7.49184 12.3779L9.32002 3.86133C9.38142 3.45169 9.09911 3.06984 8.68948 3.00845C8.27984 2.94705 7.89799 3.22936 7.83659 3.639L6.00841 12.1556ZM4.78068 10.78C4.64006 10.9204 4.44943 10.9993 4.25068 10.9993C4.05193 10.9993 3.86131 10.9204 3.72068 10.78L1.47068 8.52997C1.33023 8.38934 1.25134 8.19872 1.25134 7.99997C1.25134 7.80122 1.33023 7.61059 1.47068 7.46997L3.72068 5.21997C3.86286 5.08749 4.0509 5.01537 4.2452 5.01879C4.43951 5.02222 4.62489 5.10093 4.7623 5.23835C4.89972 5.37576 4.97843 5.56114 4.98186 5.75545C4.98529 5.94975 4.91316 6.13779 4.78068 6.27997L3.06068 7.99997L4.78068 9.71997C4.92113 9.86059 5.00002 10.0512 5.00002 10.25C5.00002 10.4487 4.92113 10.6393 4.78068 10.78ZM11.2194 5.23804C11.36 5.09759 11.5506 5.0187 11.7494 5.0187C11.9481 5.0187 12.1387 5.09759 12.2794 5.23804L14.5294 7.48804C14.6698 7.62866 14.7487 7.81929 14.7487 8.01804C14.7487 8.21679 14.6698 8.40741 14.5294 8.54804L12.2794 10.798C12.1372 10.9305 11.9491 11.0026 11.7548 10.9992C11.5605 10.9958 11.3752 10.9171 11.2377 10.7797C11.1003 10.6422 11.0216 10.4569 11.0182 10.2626C11.0148 10.0683 11.0869 9.88021 11.2194 9.73804L12.9394 8.01804L11.2194 6.29804C11.0789 6.15741 11 5.96679 11 5.76804C11 5.56929 11.0789 5.37866 11.2194 5.23804Z"
      />
    </svg>
  );
};

Code16.defaultProps = {
  fill: "currentColor",
};
