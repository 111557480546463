import React, { AnchorHTMLAttributes } from "react";
import styled from "styled-components";

import { theme } from "../../../theme";
import { Typography } from "../Typography/Typography";

const StyledAnchor = styled.a<LinkProps>`
  color: ${({ color }) => color};
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

/** @deprecated */
export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: string;
}

/**
 * @deprecated Use MUI Link instead.
 */
export const Link: React.FC<LinkProps> = ({ children, ...rest }) => {
  const content =
    typeof children === "string" ? (
      <Typography color="inherit">{children}</Typography>
    ) : (
      children
    );

  return (
    <StyledAnchor {...rest} role="link">
      {content}
    </StyledAnchor>
  );
};

Link.defaultProps = {
  color: theme.foreground.fgDarkBlue,
};
