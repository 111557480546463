import React from "react";

import { IconProps } from "../types";

export const Wrench16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 1.96058L5.66431 1.982C5.18635 2.10149 4.76082 2.37455 4.45306 2.75926L3.96218 3.37286C3.50116 3.94913 3.25 4.66514 3.25 5.40312V5.66666C3.25 6.36987 3.47808 7.0541 3.9 7.61666L5.9 10.2833C6.12719 10.5862 6.25 10.9547 6.25 11.3333V15C6.25 15.4142 5.91421 15.75 5.5 15.75C5.08579 15.75 4.75 15.4142 4.75 15V11.3333C4.75 11.2792 4.73246 11.2266 4.7 11.1833L2.7 8.51667C2.08334 7.69446 1.75 6.69442 1.75 5.66666V5.40312C1.75 4.32453 2.11708 3.27806 2.79087 2.43582L3.28176 1.82222C3.7947 1.18104 4.50391 0.725938 5.30051 0.526789L5.69683 0.427708C6.48576 0.230475 7.25 0.827173 7.25 1.64039V5.08578C7.25 5.15209 7.27634 5.21568 7.32322 5.26256L7.82322 5.76256C7.92085 5.86019 8.07915 5.86019 8.17678 5.76256L8.67678 5.26256C8.72366 5.21568 8.75 5.15209 8.75 5.08578V1.64039C8.75 0.827172 9.51424 0.230475 10.3032 0.427708L10.6995 0.52679C11.4961 0.725938 12.2053 1.18104 12.7182 1.82222L13.2091 2.43582C13.8829 3.27806 14.25 4.32453 14.25 5.40312V5.66666C14.25 6.69442 13.9167 7.69446 13.3 8.51667L11.3 11.1833C11.2675 11.2266 11.25 11.2792 11.25 11.3333V15C11.25 15.4142 10.9142 15.75 10.5 15.75C10.0858 15.75 9.75 15.4142 9.75 15V11.3333C9.75 10.9547 9.87281 10.5862 10.1 10.2833L12.1 7.61666C12.5219 7.0541 12.75 6.36987 12.75 5.66666V5.40312C12.75 4.66514 12.4988 3.94913 12.0378 3.37286L11.5469 2.75926C11.2392 2.37455 10.8136 2.10149 10.3357 1.982L10.25 1.96058V5.08578C10.25 5.54991 10.0656 5.99503 9.73744 6.32322L9.23744 6.82322C8.55402 7.50664 7.44598 7.50664 6.76256 6.82322L6.26256 6.32322C5.93437 5.99503 5.75 5.54991 5.75 5.08578V1.96058Z"
    />
  </svg>
);

Wrench16.defaultProps = {
  fill: "currentColor",
};
