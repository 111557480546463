import React from "react";

import { IconProps } from "../types";

export const Access24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path d="M17 17.9251C17.4167 17.9251 17.7708 17.7792 18.0625 17.4876C18.3542 17.1959 18.5 16.8417 18.5 16.4251C18.5 16.0084 18.3542 15.6542 18.0625 15.3626C17.7708 15.0709 17.4167 14.9251 17 14.9251C16.5833 14.9251 16.2292 15.0709 15.9375 15.3626C15.6458 15.6542 15.5 16.0084 15.5 16.4251C15.5 16.8417 15.6458 17.1959 15.9375 17.4876C16.2292 17.7792 16.5833 17.9251 17 17.9251ZM17 20.9251C17.5167 20.9251 17.9917 20.8042 18.425 20.5626C18.8583 20.3209 19.2083 20.0001 19.475 19.6001C19.1083 19.3834 18.7167 19.2167 18.3 19.1001C17.8833 18.9834 17.45 18.9251 17 18.9251C16.55 18.9251 16.1167 18.9834 15.7 19.1001C15.2833 19.2167 14.8917 19.3834 14.525 19.6001C14.7917 20.0001 15.1417 20.3209 15.575 20.5626C16.0083 20.8042 16.4833 20.9251 17 20.9251ZM17 22.9251C15.6167 22.9251 14.4375 22.4376 13.4625 21.4626C12.4875 20.4876 12 19.3084 12 17.9251C12 16.5417 12.4875 15.3626 13.4625 14.3876C14.4375 13.4126 15.6167 12.9251 17 12.9251C18.3833 12.9251 19.5625 13.4126 20.5375 14.3876C21.5125 15.3626 22 16.5417 22 17.9251C22 19.3084 21.5125 20.4876 20.5375 21.4626C19.5625 22.4376 18.3833 22.9251 17 22.9251ZM12 22.8501H11.7625C11.6875 22.8501 11.6083 22.8334 11.525 22.8001C9.34167 22.1334 7.54167 20.7834 6.125 18.7501C4.70833 16.7167 4 14.4751 4 12.0251V7.30007C4 6.88341 4.12083 6.50841 4.3625 6.17507C4.60417 5.84174 4.91667 5.60007 5.3 5.45007L11.3 3.20007C11.5333 3.11674 11.7667 3.07507 12 3.07507C12.2333 3.07507 12.4667 3.11674 12.7 3.20007L18.7 5.45007C19.0833 5.60007 19.3958 5.84174 19.6375 6.17507C19.8792 6.50841 20 6.88341 20 7.30007V11.6001C19.6833 11.4667 19.3583 11.3459 19.025 11.2376C18.6917 11.1292 18.35 11.0501 18 11.0001V7.32507L12 5.07507L6 7.32507V12.0251C6 12.8084 6.10417 13.5917 6.3125 14.3751C6.52083 15.1584 6.8125 15.9042 7.1875 16.6126C7.5625 17.3209 8.01667 17.9751 8.55 18.5751C9.08333 19.1751 9.675 19.6751 10.325 20.0751C10.5083 20.6084 10.7417 21.1084 11.025 21.5751C11.3083 22.0417 11.6417 22.4667 12.025 22.8501H12Z" />
  </svg>
);

Access24.defaultProps = {
  fill: "currentColor",
};
