import { DrawersStackStore } from "./drawersStackStore";
import {
  DrawerState,
  DrawerStateBase,
  DrawerStatePush,
  DrawerStateSet,
  DrawerStateType,
} from "./types";

// getters
export const drawersStackSelector = (
  state: DrawersStackStore
): DrawerStateType[] => state.drawersStack;

export const drawerAtSelector =
  <T extends DrawerState>(index: number | undefined) =>
  (state: DrawersStackStore) =>
    (index === undefined ? undefined : state.drawersStack.at(index)) as
      | (DrawerStateBase & T)
      | undefined;

// setters
export const closeAllDrawersSelector = (
  state: DrawersStackStore
): ((replaceUrl?: boolean) => void) => state.closeAllDrawers;

export const pushDrawerSelector = (
  state: DrawersStackStore
): ((drawersState: DrawerStatePush, replaceUrl?: boolean) => void) =>
  state.pushDrawer;

export const setDrawerAtSelector =
  (index: number | undefined) =>
  (state: DrawersStackStore) =>
  (drawerUrlStates: DrawerStateSet, replaceUrl?: boolean): void =>
    state.setDrawerAt(index, drawerUrlStates, replaceUrl);

export const popDrawerSelector = (
  state: DrawersStackStore
): ((replaceUrl?: boolean) => void) => state.popDrawer;
