import React from "react";

import { IconProps } from "../types";

export const MagnifyingGlass24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.65998 14.4383C4.5121 12.2904 4.5121 8.80799 6.65998 6.66011C8.80787 4.51222 12.2903 4.51222 14.4382 6.66011C16.4062 8.6281 16.571 11.7164 14.9326 13.8721L18.7311 17.6705C19.0239 17.9634 19.0239 18.4383 18.7311 18.7312C18.4382 19.0241 17.9633 19.0241 17.6704 18.7312L13.872 14.9328C11.7163 16.5711 8.62798 16.4063 6.65998 14.4383ZM13.3775 13.3776C11.8154 14.9397 9.28274 14.9397 7.72064 13.3776C6.15855 11.8155 6.15855 9.28286 7.72064 7.72077C9.28274 6.15867 11.8154 6.15867 13.3775 7.72077C14.9396 9.28286 14.9396 11.8155 13.3775 13.3776Z"
      />
    </svg>
  );
};

MagnifyingGlass24.defaultProps = {
  fill: "currentColor",
};
