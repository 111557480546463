import styled from "styled-components";
import React from "react";
import { palette, theme } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";

import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import { PodEvent } from "../../../monitorsView/workflowRunView/CommonTypes";
import { StyledFontTableMin } from "../styles";

const BorderedWrapper = styled.div<{ isHeightRestricted: boolean }>`
  border: 1px solid #c9cbcf;
  border-radius: 4px;
  margin: 1rem 0 0;
  overflow-y: auto;
  min-height: 8rem;
  ${({ isHeightRestricted }) => isHeightRestricted && `max-height: 17.5rem;`}
`;

const EventTd = styled.td<{ isWarning?: boolean }>`
  ${({ isWarning }) =>
    `border-left: 3px solid ${
      isWarning ? palette.pink[400] : palette.gray[300]
    };`}
`;

const EventTr = styled.tr`
  background-color: ${theme.foreground.fgWhite};
`;

const IgnoredWarningEvents: Record<string, boolean> = {
  DNSConfigForming: true,
};

const isWarning = (eventType: string, reason: string) => {
  return eventType === "Warning" && !IgnoredWarningEvents?.[reason];
};

const PodEventsTable: React.FC<{
  podEvents: PodEvent[];
  isHeightRestricted?: boolean;
}> = ({ podEvents, isHeightRestricted = true }) => {
  const { format } = useDateFormatter();

  return (
    <BorderedWrapper isHeightRestricted={isHeightRestricted}>
      <StyledFontTableMin aria-label="pod-event-table">
        <thead>
          <tr>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Reason
              </Typography>
            </th>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Last Timestamp
              </Typography>
            </th>
            <th>
              <Typography bold color={theme.foreground.fgSubtle}>
                Message
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {podEvents.map((podEvent, i) => {
            return (
              <EventTr key={i}>
                <EventTd isWarning={isWarning(podEvent.type, podEvent.reason)}>
                  <Typography
                    color={
                      isWarning(podEvent.type, podEvent.reason)
                        ? palette.pink[700]
                        : theme.foreground.fgPrimary
                    }
                  >
                    {podEvent.reason}
                  </Typography>
                </EventTd>
                <td>
                  <Typography>
                    {podEvent.lastTimestamp
                      ? format(new Date(podEvent.lastTimestamp))
                      : podEvent.firstTimestamp
                      ? format(new Date(podEvent.firstTimestamp))
                      : ""}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color={
                      isWarning(podEvent.type, podEvent.reason)
                        ? palette.pink[700]
                        : theme.foreground.fgPrimary
                    }
                  >
                    {podEvent.message}
                  </Typography>
                </td>
              </EventTr>
            );
          })}
        </tbody>
      </StyledFontTableMin>
    </BorderedWrapper>
  );
};
export default PodEventsTable;
