import React, { useEffect, useMemo, useState } from "react";
import { camelCase } from "lodash";

import { sortTableModel } from "../../Inspection/utils/getSortedColumns";
import { LinesLoader } from "../loaders/Line";
import { NoItemsContainer } from "../../Inspection/styles";
import { KubernetesPodsResource } from "../../Inspection/inspectionConfiguration/SupportedResourcesTypes";

import { Container, StyledTable, TableFooter } from "./TableStyles";
import ResourceListTableBody from "./ResourceListTableBody";
import { PageSize } from "./PageSize";
import { Pagination } from "./Pagination";
import ResourceListTableHead, {
  InspectionTableHeader,
} from "./ResourceListTableHead";
import { SelectedRowsProvider } from "./SelectedRowsProvider";
import { ResourceListTableProps } from "./ResourceListTableProps";
import { useInitialInspectionHeaders } from "./hooks/useInitialInspectionHeaders";
import ResourceListTableSecondaryHeader from "./ResourceListTableSecondaryHeader";
import {
  useGetRawHeaders,
  useTableSortOrder,
  useToggleAllRowsSelection,
} from "./hooks/resourcesListTableHooks";
import { RelatedResourcesPanel } from "./styles";
import RelatedResourceListError from "./RelatedResourceListError";

const DEFAULT_COLUMN_BY_WHICH_TO_SORT = "name";

const ResourceListTable: React.FC<ResourceListTableProps> = ({
  rows,
  overridingColumns,
  currentService,
  resourceType,
  cluster,
  refreshResults,
  overrideRowClick,
  initialSortOrder,
  rowSelection,
  pageLabelAccessory,
  isPaginating,
  hidePageLabel = false,
  configurablePageSize = true,
  defaultPageSize = 10,
  includeActionsColumn = true,
  loadingProps,
  showLiveDataButton = false,
  errorMessage,
}) => {
  const headersOrder = useMemo(
    () =>
      Object.keys(rows?.[0] ?? []).filter(
        (key) => !["id", "deletedAt"].includes(key)
      ),
    [rows]
  );
  const [tableHeaders, setTableHeaders] = useState<InspectionTableHeader[]>([]);

  const properties = useMemo(
    () => ({
      columns: {
        data: overridingColumns ?? [],
      },
      overrideRowClick,
    }),
    [overridingColumns, overrideRowClick]
  );

  const rawHeaders = useGetRawHeaders({
    resourceType,
    overridingColumns,
    headersOrder: headersOrder,
  });

  const initialHeaders = useInitialInspectionHeaders(rawHeaders, resourceType);

  useEffect(() => {
    setTableHeaders(
      rowSelection
        ? [{ name: "Checkbox", displayName: "" }, ...initialHeaders]
        : initialHeaders
    );
  }, [initialHeaders, rowSelection]);

  const [page, setPage] = useState(0);
  const sortOrder = useTableSortOrder({ initialSortOrder, resourceType });

  const [sortColumn, setSortColumn] = useState("");

  useEffect(() => {
    setSortColumn(
      initialSortOrder?.column ??
        resourceType.DefaultSort?.ColumnName ??
        DEFAULT_COLUMN_BY_WHICH_TO_SORT
    );
  }, [initialSortOrder?.column, resourceType.DefaultSort?.ColumnName]);

  const [sortDirection, setSortDirection] = useState(sortOrder);

  useEffect(() => {
    setSortDirection(sortOrder);
  }, [sortOrder]);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const totalPages = useMemo(
    () => Math.max(Math.ceil(rows.length / pageSize), 1),
    [rows.length, pageSize]
  );

  const rowsOffset = useMemo(
    () => (rows.length === 0 ? 0 : (page * pageSize) % rows.length),
    [page, pageSize, rows.length]
  );

  const rowsOnPage = useMemo(() => {
    let columnByWhichToSort = camelCase(sortColumn);

    if (
      !tableHeaders.some(
        (header) => camelCase(header.name) === columnByWhichToSort
      )
    ) {
      columnByWhichToSort = camelCase(DEFAULT_COLUMN_BY_WHICH_TO_SORT);
    }

    const sortedRows = [...rows].sort(
      sortTableModel(sortDirection, columnByWhichToSort, resourceType)
    );

    return sortedRows.slice(rowsOffset, rowsOffset + pageSize);
  }, [
    pageSize,
    resourceType,
    rowsOffset,
    sortColumn,
    sortDirection,
    rows,
    tableHeaders,
  ]);

  const allRowsSelected = useMemo(
    () =>
      rowsOnPage.every(
        ({ uid }) =>
          rowSelection?.selectedRowNames.includes(uid) ||
          rowSelection?.unselectableRowNames?.includes(uid)
      ),
    [
      rowSelection?.selectedRowNames,
      rowSelection?.unselectableRowNames,
      rowsOnPage,
    ]
  );

  const toggleAllRowsSelection = useToggleAllRowsSelection({
    rowSelection,
    allRowsSelected,
    rowsOnPage,
  });

  const activeTableHeaders = useMemo(
    () => tableHeaders.filter((th) => !th.hide).map((th) => camelCase(th.name)),
    [tableHeaders]
  );

  // Go back to first page whenever rows are changed (i.e. filtered) or sorted.
  useEffect(() => {
    if (totalPages < page + 1) {
      setPage(0);
    }
  }, [rows, sortColumn, sortDirection, totalPages, page]);
  if (errorMessage) {
    return <RelatedResourceListError />;
  }
  if (loadingProps?.isLoading) {
    return <>{loadingProps.loaderElement ?? <LinesLoader marginTop="20%" />}</>;
  }

  const showDeletedPods = resourceType.Kind === KubernetesPodsResource.Kind;

  const resourceListTable = (
    <>
      <ResourceListTableSecondaryHeader
        clusterName={cluster}
        hidePageLabel={hidePageLabel}
        isPaginating={isPaginating}
        rowsOffset={rowsOffset}
        pageSize={pageSize}
        listSize={rows.length}
        accessory={pageLabelAccessory}
        showLiveDataButton={showLiveDataButton}
        showDeletedPodsToggle={showDeletedPods}
      />
      {rows.length === 0 && !loadingProps?.isLoading ? (
        <RelatedResourcesPanel>
          <NoItemsContainer>There are no items to show</NoItemsContainer>
        </RelatedResourcesPanel>
      ) : (
        <Container>
          <StyledTable>
            <ResourceListTableHead
              headers={tableHeaders}
              sortDirection={sortDirection}
              setSortDirection={setSortDirection}
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
              setTableHeaders={setTableHeaders}
              kubernetesResource={resourceType}
              includeColumnSelector={includeActionsColumn}
              allRowsSelection={
                rowSelection?.includeAllRowsSelection
                  ? {
                      allRowsSelected,
                      onAllRowsSelection: toggleAllRowsSelection,
                    }
                  : undefined
              }
            />
            <ResourceListTableBody
              tableRows={rowsOnPage}
              currentService={currentService}
              headers={activeTableHeaders}
              resourceType={resourceType}
              clusterName={cluster}
              properties={properties}
              refreshResults={refreshResults}
              includeActionsColumn={includeActionsColumn}
            />
          </StyledTable>
          <TableFooter>
            {configurablePageSize && <PageSize onChange={setPageSize} />}
            <Pagination page={page} setPage={setPage} totalPages={totalPages} />
          </TableFooter>
        </Container>
      )}
    </>
  );

  return rowSelection ? (
    <SelectedRowsProvider rowSelectionController={rowSelection}>
      {resourceListTable}
    </SelectedRowsProvider>
  ) : (
    resourceListTable
  );
};

export default ResourceListTable;
