import React from "react";

import { IconProps } from "../types";

export const ArrowRight24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3662 4.96967C11.0733 5.26256 11.0733 5.73744 11.3662 6.03033L16.5858 11.25H5.39648C4.98227 11.25 4.64648 11.5858 4.64648 12C4.64648 12.4142 4.98227 12.75 5.39648 12.75H16.5858L11.3662 17.9697C11.0733 18.2626 11.0733 18.7374 11.3662 19.0303C11.659 19.3232 12.1339 19.3232 12.4268 19.0303L19.1036 12.3536C19.2989 12.1583 19.2989 11.8417 19.1036 11.6464L12.4268 4.96967C12.1339 4.67678 11.659 4.67678 11.3662 4.96967Z"
      />
    </svg>
  );
};

ArrowRight24.defaultProps = {
  fill: "currentColor",
};
