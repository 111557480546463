import React from "react";

import { IconProps } from "../types";

export const Duplicate16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6.5H3C2.72386 6.5 2.5 6.72386 2.5 7V13C2.5 13.2761 2.72386 13.5 3 13.5H9C9.27614 13.5 9.5 13.2761 9.5 13V7C9.5 6.72386 9.27614 6.5 9 6.5ZM3 5C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H9C10.1046 15 11 14.1046 11 13V7C11 5.89543 10.1046 5 9 5H3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2.5H13C13.2761 2.5 13.5 2.72386 13.5 3V9C13.5 9.27614 13.2761 9.5 13 9.5H12V11H13C14.1046 11 15 10.1046 15 9V3C15 1.89543 14.1046 1 13 1H7C5.89543 1 5 1.89543 5 3V4H6.5V3C6.5 2.72386 6.72386 2.5 7 2.5Z"
      />
      <path d="M6.75 7.75C6.75 7.33579 6.41421 7 6 7C5.58579 7 5.25 7.33579 5.25 7.75V9.25H3.75C3.33579 9.25 3 9.58579 3 10C3 10.4142 3.33579 10.75 3.75 10.75H5.25V12.25C5.25 12.6642 5.58579 13 6 13C6.41421 13 6.75 12.6642 6.75 12.25V10.75H8.25C8.66421 10.75 9 10.4142 9 10C9 9.58579 8.66421 9.25 8.25 9.25H6.75V7.75Z" />
    </svg>
  );
};

Duplicate16.defaultProps = {
  fill: "currentColor",
};
