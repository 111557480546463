/* eslint-disable max-lines */
import { Record, Number } from "runtypes";
import _ from "lodash";

import { MetricsTaskResult } from "./metricsTaskResultParser";

enum MetricsRatioColumns {
  CpuFromRequestColumn = "cpuFromRequest",
  CpuFromLimitColumn = "cpuFromLimit",
  MemoryFromRequestColumn = "memoryFromRequest",
  MemoryFromLimitColumn = "memoryFromLimit",
}

enum MetricsUsageColumns {
  CpuUsageColumn = "cpuUsage",
  MemoryUsageColumn = "memoryUsage",
  DiskUsageColumn = "diskUsage",
}

enum MetricsUtilizationColumn {
  CpuUtilizationColumn = "cpuUtil",
  MemoryUtilizationColumn = "memoryUtil",
  DiskUtilizationColumn = "diskUtil",
}

export const MetricsColumns = {
  ...MetricsRatioColumns,
  ...MetricsUsageColumns,
  ...MetricsUtilizationColumn,
};

export const isMetricsColumn = (columnName: string): boolean => {
  return Object.values(MetricsColumns)
    .map((mc) => mc.toString().toLowerCase())
    .includes(columnName.toLowerCase());
};

export const isMetricsRatioColumn = (columnName: string): boolean => {
  return Object.values(MetricsRatioColumns)
    .map((mc) => mc.toString().toLowerCase())
    .includes(columnName.toLowerCase());
};

export const isMetricsUsageColumns = (columnName: string): boolean => {
  return Object.values(MetricsUsageColumns)
    .map((mc) => mc.toString().toLowerCase())
    .includes(columnName.toLowerCase());
};

abstract class UsageMetrics {
  abstract convertRawMetricToUnits(rawMetric: number): number;
  abstract readonly metricUsageName: string;
  abstract readonly metricAllocationName: string;
  abstract readonly usageUnit: string;
  abstract readonly usageColumnName: string;
  abstract readonly utilizationColumnName: string;

  buildUsageColumn(usage: number | null): { [_: string]: string } {
    const usageString =
      usage !== null ? usage.toString() + this.usageUnit : "N/A";
    return { [this.usageColumnName]: usageString };
  }

  private extractMetricFromPoints(
    metricResult: MetricsTaskResult,
    resourceName: string,
    metricColumnName: string
  ): number | null {
    const points = metricResult.results[metricColumnName]?.[resourceName];
    return points?.[0]?.value ?? null;
  }

  extractMetricUnits(
    metricResult: MetricsTaskResult,
    resourceName: string,
    metricName: string
  ): number | null {
    const metricUnits = this.extractMetricFromPoints(
      metricResult,
      resourceName,
      metricName
    );
    return metricUnits !== null
      ? this.convertRawMetricToUnits(metricUnits)
      : null;
  }
}

class CpuUsage extends UsageMetrics {
  metricUsageName = "cpu";
  metricAllocationName = "cpuAllocation";
  usageColumnName = MetricsColumns.CpuUsageColumn.toString();
  utilizationColumnName = MetricsColumns.CpuUtilizationColumn.toString();
  usageUnit = "m";
  convertRawMetricToUnits(rawMetric: number): number {
    rawMetric *= 1000;
    return Math.ceil(rawMetric);
  }
}
class MemoryUsage extends UsageMetrics {
  metricUsageName = "memory";
  metricAllocationName = "memoryAllocation";
  usageColumnName = MetricsColumns.MemoryUsageColumn.toString();
  utilizationColumnName = MetricsColumns.MemoryUtilizationColumn.toString();
  usageUnit = "Mi";
  convertRawMetricToUnits(rawMetric: number): number {
    rawMetric /= Math.pow(1024, 2);
    return Math.floor(rawMetric);
  }
}

class DiskUsage extends UsageMetrics {
  metricUsageName = "disk";
  metricAllocationName = "diskSize";
  usageColumnName = MetricsColumns.DiskUsageColumn.toString();
  utilizationColumnName = MetricsColumns.DiskUtilizationColumn.toString();
  usageUnit = "Gi";
  convertRawMetricToUnits(rawMetric: number): number {
    rawMetric /= Math.pow(1024, 3);
    return Math.floor(rawMetric);
  }
}

const Resources = Record({
  cpu: Number,
  memory: Number,
});

interface MetricsRatio {
  description: string;
  usage: number | null;
  upperBound: number | null;
  units: string;
}

export const ratioToString = (ratio: MetricsRatio): string => {
  return `${ratio.description};${ratio.usage};${ratio.upperBound};${ratio.units}`;
};

export const parseRatioStringToRatio = (ratioString: string): MetricsRatio => {
  const [description, usageString, upperBoundString, units] =
    ratioString.split(";");
  const usage = usageString === "null" ? null : parseFloat(usageString);
  const upperBound =
    upperBoundString === "null" ? null : parseFloat(upperBoundString);
  return {
    description: description,
    usage: usage,
    upperBound: upperBound,
    units: units,
  };
};

export function buildMetricsForNode(
  nodeName: string,
  metricResult: MetricsTaskResult | null
): { [_: string]: string } {
  if (!metricResult || metricResult.errorParsingResults) return {};
  const cpu = new CpuUsage();
  const memory = new MemoryUsage();
  const disk = new DiskUsage();

  const cpuUsage = cpu.extractMetricUnits(
    metricResult,
    nodeName,
    cpu.metricUsageName
  );
  const memoryUsage = memory.extractMetricUnits(
    metricResult,
    nodeName,
    memory.metricUsageName
  );
  const diskUsage = disk.extractMetricUnits(
    metricResult,
    nodeName,
    disk.metricUsageName
  );

  const cpuUsageColumn = cpu.buildUsageColumn(cpuUsage);
  const memoryUsageColumn = memory.buildUsageColumn(memoryUsage);
  const diskUsageColumn = disk.buildUsageColumn(diskUsage);

  const cpuUtilizationColumn = {
    [cpu.utilizationColumnName]: ratioToString({
      description: "CPU Usage/Allocation",
      usage: cpuUsage,
      upperBound: cpu.extractMetricUnits(
        metricResult,
        nodeName,
        cpu.metricAllocationName
      ),
      units: cpu.usageUnit,
    }),
  };
  const memoryUtilizationColumn = {
    [memory.utilizationColumnName]: ratioToString({
      description: "Memory Usage/Allocation",
      usage: memoryUsage,
      upperBound: memory.extractMetricUnits(
        metricResult,
        nodeName,
        memory.metricAllocationName
      ),
      units: memory.usageUnit,
    }),
  };
  const diskUtilizationColumn = {
    [disk.utilizationColumnName]: ratioToString({
      description: "Disk Usage/Capacity",
      usage: diskUsage,
      upperBound: disk.extractMetricUnits(
        metricResult,
        nodeName,
        disk.metricAllocationName
      ),
      units: disk.usageUnit,
    }),
  };

  return _.merge(
    cpuUsageColumn,
    memoryUsageColumn,
    diskUsageColumn,
    cpuUtilizationColumn,
    memoryUtilizationColumn,
    diskUtilizationColumn
  );
}

export function buildMetricsForPod(
  podName: string,
  metricResult: MetricsTaskResult | null,
  requests: unknown,
  limits: unknown
): { [_: string]: string } {
  const { cpu: cpuRequest, memory: memoryRequest } = parseResources(requests);
  const { cpu: cpuLimit, memory: memoryLimit } = parseResources(limits);

  return buildMetricsForPodWithRequestAndLimits(
    podName,
    metricResult,
    cpuLimit,
    memoryLimit,
    cpuRequest,
    memoryRequest
  );
}

export function buildMetricsForPodWithRequestAndLimits(
  podName: string,
  metricResult: MetricsTaskResult | null,
  cpuLimit: number,
  memoryLimit: number,
  cpuRequest: number,
  memoryRequest: number
): { [_: string]: string } {
  if (!metricResult || metricResult.errorParsingResults) return {};

  const cpu = new CpuUsage();
  const memory = new MemoryUsage();

  const cpuUsage = cpu.extractMetricUnits(
    metricResult,
    podName,
    cpu.metricUsageName
  );
  const memoryUsage = memory.extractMetricUnits(
    metricResult,
    podName,
    memory.metricUsageName
  );

  const cpuUsageColumn = cpu.buildUsageColumn(cpuUsage);
  const memoryUsageColumn = memory.buildUsageColumn(memoryUsage);

  const requestsLimitsColumns = {
    [MetricsColumns.CpuFromRequestColumn.toString()]: ratioToString({
      description: "CPU Usage/Request",
      usage: cpuUsage,
      upperBound: cpuRequest,
      units: "mCPUs",
    }),
    [MetricsColumns.CpuFromLimitColumn.toString()]: ratioToString({
      description: "CPU Usage/Limit",
      usage: cpuUsage,
      upperBound: cpuLimit,
      units: "CPUs",
    }),

    [MetricsColumns.MemoryFromRequestColumn.toString()]: ratioToString({
      description: "Memory Usage/Request",
      usage: memoryUsage,
      upperBound: memory.convertRawMetricToUnits(memoryRequest),
      units: memory.usageUnit,
    }),
    [MetricsColumns.MemoryFromLimitColumn.toString()]: ratioToString({
      description: "Memory Usage/Limit",
      usage: memoryUsage,
      upperBound: memory.convertRawMetricToUnits(memoryLimit),
      units: memory.usageUnit,
    }),
  };
  return _.merge(requestsLimitsColumns, cpuUsageColumn, memoryUsageColumn);
}

function parseResources(resources: unknown): { cpu: number; memory: number } {
  if (!Resources.guard(resources)) {
    return { cpu: 0, memory: 0 };
  }
  return { cpu: resources.cpu, memory: resources.memory };
}
