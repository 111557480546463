import React from "react";
import { Tooltip as ReactTooltip, ITooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import styled, { css } from "styled-components";

import { theme } from "../../../theme";

/** @deprecated */
export interface TooltipContanierProps {
  maxWidth: string;
}

const textSmallStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

const commonStyle = css`
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  word-break: break-word;
  z-index: 20;
`;

const TooltipContanier = styled.span<TooltipContanierProps>`
  .default-style {
    ${commonStyle}
    ${textSmallStyle}
    background-color: ${theme.background.bgDark};
    color: ${theme.foreground.fgWhite};
    opacity: 0.9;
    max-width: ${({ maxWidth }) => maxWidth};
  }
  .light-style {
    ${commonStyle}
    ${textSmallStyle}
    background-color: ${theme.background.bgWhite};
    color: ${theme.foreground.fgPrimary};
    box-shadow: 0px 2px 8px 1px rgba(22, 24, 31, 0.15);
    opacity: 1;
    max-width: ${({ maxWidth }) => maxWidth};
  }
`;

/** @deprecated */
export type TooltipVariant = "default" | "light";
/** @deprecated */
export interface TooltipProps
  extends Omit<ITooltip, "id" | "variant" | "maxWidth"> {
  id: string;
  variant?: TooltipVariant;
  maxWidth?: string;
}

/**
 * @deprecated Use MUI Tooltip instead.
 */
export const Tooltip: React.FC<TooltipProps> = ({
  variant,
  maxWidth,
  ...props
}) => {
  return (
    <TooltipContanier maxWidth={maxWidth}>
      <ReactTooltip className={`${variant}-style`} {...props} />
    </TooltipContanier>
  );
};

Tooltip.defaultProps = {
  variant: "default",
  maxWidth: "20rem",
  delayShow: 100,
  delayHide: 100,
  clickable: true,
  noArrow: true,
  offset: 5,
};
