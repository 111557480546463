import React from "react";

import { IconProps } from "../types";

export const Drain16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 5C12.9125 5 13.2469 5.34922 13.2469 5.78V12.3369L14.725 10.7046C15.0167 10.4 15.4896 10.4 15.7812 10.7046C16.0729 11.0092 16.0729 11.503 15.7812 11.8077L13.0281 14.7715C12.7364 15.0762 12.2636 15.0762 11.9719 14.7715L9.21876 11.8077C8.92708 11.503 8.92708 11.0092 9.21876 10.7046C9.51043 10.4 9.98333 10.4 10.275 10.7046L11.7531 12.3369V5.78C11.7531 5.34922 12.0875 5 12.5 5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 1C3.91249 1 4.24688 1.34922 4.24688 1.78V8.33691L5.72499 6.70457C6.01667 6.39996 6.48957 6.39996 6.78124 6.70457C7.07292 7.00918 7.07292 7.50304 6.78124 7.80765L4.02812 10.7715C3.73645 11.0762 3.26355 11.0762 2.97188 10.7715L0.218756 7.80765C-0.0729188 7.50304 -0.0729188 7.00918 0.218756 6.70457C0.510432 6.39996 0.98333 6.39996 1.27501 6.70457L2.75312 8.33691V1.78C2.75312 1.34922 3.08751 1 3.5 1Z"
      />
    </svg>
  );
};

Drain16.defaultProps = {
  fill: "currentColor",
};
