/* eslint-disable max-lines */
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Switch from "@mui/material/Switch";
import ViewTimelineOutlined from "@mui/icons-material/ViewTimelineOutlined";
import ListAltOutlined from "@mui/icons-material/ListAltOutlined";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { addMilliseconds } from "date-fns";

import {
  useStateInSearchParams,
  useStringifiedStateInSearchParams,
} from "../../../../../shared/hooks/state";
import { TimelineChart } from "../../../../common/EventsChart/TimelineChart";
import { ProcessList } from "../../../../common/ProcessList";
import {
  SetTimeWindow,
  Timeframe,
} from "../../../../../shared/types/TimeWindow";
import {
  ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE,
  EVENTS_TAB_PREFIX,
  EVENT_PARAM_KEY,
  RESOURCE_PREFIX,
  TIMELINE_ZOOM_CHANGED_PARAM_KEY,
} from "../../../../../shared/config/urlSearchParamsKeys";
import { EmptyState } from "../../../../Inspection/Helm/tabs/components/EmptyData";
import { groupWorkflowIssuesEvents } from "../../../../common/EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { LinesLoader } from "../../../../common/loaders/Line";
import { groupGroupedPodEvents } from "../../../../common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { ZIndex } from "../../../../../constants/zIndex";
import { useDDRumViewAndTimingListener } from "../../../../../shared/hooks/datadog-rum/datadogRumHooks";
import { dataDogViewNames } from "../../../../../shared/constants/datadog";
import useChartCallbacks from "../content/useChartCallbacks";
import useTimeWindowFromURL from "../content/useTimeWindowFromURL";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { WorkflowPod } from "@/generated/addonsApi";
import { PodGroups } from "@/generated/resourcesApi";
import { MonitorData } from "@/generated/monitorsApi";
import { buildKomodorUid } from "@/shared/hooks/resources-api/resourcesAPIUtils";
import { useGetWorkflowPodsEvents } from "@/components/ResourceView/tabs/EventsTab/WorkflowEventsTab/useGetWorkflowPodsEvents";

const MIN_DATE = new Date(0);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: max-content auto max-content max-content;
  gap: 16px;
  align-items: center;
`;

const StyledToggleButton = styled(ToggleButton)`
  && {
    display: flex;
    column-gap: 0.5rem;
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;

const ScrollableContainer = styled.div`
  overflow-y: auto;
  height: calc(100vh - 29rem);
`;

const Panel = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? "block" : "none")};
`;

enum ViewToggle {
  Timeline = "timeline",
  List = "list",
}

export const WorkflowEventsTab: React.FC<{ resource: WorkflowRun }> = ({
  resource,
}) => {
  const [highlightedEvents, setHighlightedEvents] = useState<string[]>([]);
  const [showOnlyPodsWithIssues, setShowOnlyPodsWithIssues] = useState(false);
  const [eventId, setEventId] = useStateInSearchParams(
    RESOURCE_PREFIX + EVENTS_TAB_PREFIX + EVENT_PARAM_KEY
  );

  const [intentId, setIntentId] = useState<string | null>();

  const [viewToggle, setViewToggle] =
    useStringifiedStateInSearchParams<ViewToggle>(
      ARGO_WORKFLOWS_EVENTS_TAB_VIEW_TOGGLE
    );

  useEffect(() => {
    if (!viewToggle) {
      setViewToggle(ViewToggle.Timeline, true);
    }
  }, [setViewToggle, viewToggle]);

  const workflowTimeWindow = useMemo(
    () => ({
      timeframe: Timeframe.Custom,
      start: resource.startedAt ?? MIN_DATE,
      end: resource.finishedAt
        ? addMilliseconds(resource.finishedAt, 1)
        : new Date(),
    }),
    [resource]
  );

  const { data, isFetching } = useGetWorkflowPodsEvents({
    workflowRun: resource,
    workflowTimeWindow,
    showOnlyPodsWithIssues,
  });

  const podsData = data?.data.data as WorkflowPod[];
  const podsPhases = useMemo(
    () =>
      podsData?.map((pod): PodGroups => {
        const komodorUid = buildKomodorUid({
          clusterName: resource.cluster,
          namespace: resource.namespace,
          kind: "Pod",
          resourceName: pod.name,
        });
        return { komodorUid, groups: pod.phases };
      }),
    [podsData, resource.cluster, resource.namespace]
  );
  const podsIssues = useMemo(
    () =>
      podsData
        ?.map((pod) => pod.issues as MonitorData[])
        .flat()
        .filter((issue) => issue),
    [podsData]
  );

  const issuesEventGroups = groupWorkflowIssuesEvents(podsIssues, "");

  const podPhasesGroups = groupGroupedPodEvents(podsPhases, "");

  const eventGroups = useMemo(() => {
    return [...issuesEventGroups, ...podPhasesGroups];
  }, [issuesEventGroups, podPhasesGroups]);

  const { onChartMouseEnter, onChartMouseLeave, onChartClick } =
    useChartCallbacks(setHighlightedEvents, setEventId);

  const [zoomChanged, setZoomChanged] =
    useStringifiedStateInSearchParams<boolean>(TIMELINE_ZOOM_CHANGED_PARAM_KEY);
  const [timeWindow, setTimeWindow] = useTimeWindowFromURL(workflowTimeWindow);
  useEffect(() => {
    if (!zoomChanged) {
      setTimeWindow(workflowTimeWindow, { replace: true });
    }
  }, [setTimeWindow, workflowTimeWindow, zoomChanged]);
  const onZoomChange: SetTimeWindow = (tw, options) => {
    setTimeWindow(tw, options);
    setZoomChanged(true, true);
  };
  const onResetZoom = () => {
    setTimeWindow(workflowTimeWindow);
    setZoomChanged(false, true);
  };

  const isEmpty = eventGroups.length === 0;

  useDDRumViewAndTimingListener({
    isResourceFetching: isFetching,
    viewName: dataDogViewNames.workflowDagViewEventsTab,
    addTimingParams: {
      enable: !isFetching,
    },
  });

  return (
    <Container>
      <Header>
        <Typography variant="h4">
          Events {eventGroups ? `(${eventGroups.length})` : null}
        </Typography>
        <div>
          {zoomChanged && <Button onClick={onResetZoom}>Reset zoom</Button>}
        </div>
        <SwitchContainer>
          <Switch
            checked={showOnlyPodsWithIssues}
            onChange={() => {
              setShowOnlyPodsWithIssues((prevState) => !prevState);
            }}
            aria-label={AriaLabels.ArgoWorkflows.ShowOnlyPodsWithErrorsToggle}
          />
          <Typography variant="body2">Show only pods with failures</Typography>
        </SwitchContainer>
        <ToggleButtonGroup
          exclusive
          onChange={(_e, value) => setViewToggle(value)}
          value={viewToggle}
        >
          <StyledToggleButton value={ViewToggle.Timeline}>
            <ViewTimelineOutlined sx={{ width: 16, height: 16 }} />
            Timeline
          </StyledToggleButton>
          <StyledToggleButton value={ViewToggle.List}>
            <ListAltOutlined sx={{ width: 16, height: 16 }} />
            List
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Header>
      {isFetching && isEmpty ? (
        <LinesLoader />
      ) : isEmpty ? (
        <EmptyState text="No events" />
      ) : (
        <>
          <Panel show={viewToggle === ViewToggle.Timeline}>
            <TimelineChart
              data-e2e-selector="events-timeline-chart"
              onMouseEnter={onChartMouseEnter}
              onMouseLeave={onChartMouseLeave}
              onClick={onChartClick}
              eventGroups={[]}
              serviceIds={[]}
              timeWindow={timeWindow}
              setTimeWindow={onZoomChange}
              xAxisPosition={"top"}
              zIndex={ZIndex.AboveTimelineChart}
            />
            <ScrollableContainer>
              <TimelineChart
                onMouseEnter={onChartMouseEnter}
                onMouseLeave={onChartMouseLeave}
                onClick={onChartClick}
                eventGroups={eventGroups}
                serviceIds={[]}
                timeWindow={timeWindow}
                setTimeWindow={onZoomChange}
                highlightedId={intentId ?? undefined}
                paginateSwimlanes
                hideXAxis
              />
            </ScrollableContainer>
          </Panel>
          <Panel show={viewToggle === ViewToggle.List}>
            <ProcessList
              highlightedEvents={highlightedEvents}
              showServiceColumn={false}
              eventGroups={eventGroups ?? []}
              showMoreEventsPanel={true}
              eventId={eventId}
              setEventId={setEventId}
              setIntentId={setIntentId}
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
            />
          </Panel>
        </>
      )}
    </Container>
  );
};
