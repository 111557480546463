import React from "react";

import { IconProps } from "../types";

export const Dot16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <circle cx="8" cy="8" r="5" />
    </svg>
  );
};

Dot16.defaultProps = {
  fill: "currentColor",
};
