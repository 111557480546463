import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { indigo } from "../../../themes/colors";

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > div {
    height: 16px;
    border-radius: 4px;
  }

  & > div:nth-child(1) {
    background-color: ${indigo[600]};
  }
  & > div:nth-child(2) {
    background-color: ${indigo[400]};
  }
  & > div:nth-child(3) {
    background-color: ${indigo[200]};
  }
`;

const WIDTH = 72;
const MAGNITUDE = 10;
const TIME_FACTOR = 300;
const TIME_OFFSET = 400;
const PART_FRACTION = 4;
const MARGIN = 8;

export const LinesLoader = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const [widths, setWidths] = useState({ left: 0, middle: 0, right: 0 });

  const requestRef = React.useRef<number>();
  const startTime = React.useRef<number>(Date.now());

  const animate = useCallback(() => {
    const now = Date.now();
    const deltaTime = now - startTime.current;
    const part = WIDTH / PART_FRACTION;

    const left = part + Math.sin(deltaTime / TIME_FACTOR) * MAGNITUDE;
    const right =
      part + Math.sin(deltaTime / TIME_FACTOR + TIME_OFFSET) * MAGNITUDE;
    const middle = WIDTH - left - right - MARGIN * 2;
    setWidths({ left, middle, right });

    requestRef.current = requestAnimationFrame(animate);
  }, []);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [animate]);

  return (
    <LoaderContainer {...props} aria-label="Loading...">
      <div style={{ width: widths.left }}></div>
      <div style={{ width: widths.middle }}></div>
      <div style={{ width: widths.right }}></div>
    </LoaderContainer>
  );
};
