import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { description, title } from "./texts";
import { Info } from "./components/Info/Info";
import { Explanation } from "./components/Explanation/Explanation";
import { Rate } from "./components/Rate/Rate";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TabData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const OverviewTab: React.FC = () => {
  return (
    <Container>
      <TabData>
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ fontWeight: 400 }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.primary">
          {description}
        </Typography>
        <Info />
      </TabData>
      <Explanation />
      <Rate />
    </Container>
  );
};
