import React from "react";

import { AggregationsList } from "@/components/k8sAddons/components/AggregationsList";
import { Addon, Entity } from "@/generated/addonsApi";
import { Filters } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/Filters";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { aggregationFiltersList } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";
import { CertificatePageTable } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/CertificatePageTable";
import { PageContainer } from "@/components/k8sAddons/styles";
import { useAddOnsRefetchInterval } from "@/components/k8sAddons/hooks/useAddOnsRefetchInterval";

export const CertificatesPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.CertManager}
      addonEntity={Entity.Certificate}
      refetchInterval={useAddOnsRefetchInterval()}
    >
      <PageContainer>
        <Filters />
        <AggregationsList listItemsData={aggregationFiltersList} />
        <CertificatePageTable />
      </PageContainer>
    </AddonContextProvider>
  );
};
