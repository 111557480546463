import React from "react";

import { UnattendedViolationsGraph } from "../../../../../assets/UnattendedViolationsGraph";

import { SmallStatsCard } from "./StatsCard/SmallStatsCard";

export const UnattendedViolationsCard: React.FC = () => {
  return (
    <SmallStatsCard
      title={"Unattended violations"}
      mainStat={92}
      trend={"+12%"}
      epoch={"Last 7 days"}
      graphComponent={<UnattendedViolationsGraph />}
    />
  );
};
