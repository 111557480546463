import React from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { ReliabilityCheckData } from "../../../../../riskManagementTypes";

import { useReliabilityChecksColumns } from "./useReliabilityChecksColumns";

const StyledDataGrid = styled(DataGridPro)`
  &&&& {
    .MuiDataGrid-main,
    .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor {
      background-color: white;
      border-top: none;
    }
    .MuiDataGrid-columnHeaderTitle {
      color: ${muiColors.gray[500]};
    }
    .MuiDataGrid-columnHeaders {
      background-color: ${muiColors.gray[50]};
    }

    .MuiDataGrid-withBorderColor {
      border-color: ${muiColors.gray[200]};
      border-bottom-color: ${muiColors.gray[200]};
    }
    .MuiDataGrid-footerContainer {
      display: none;
    }
  }
` as typeof DataGridPro;

type ManageChecksTableProps = {
  rows: ReliabilityCheckData[];
};

export const ManageChecksTable: React.FC<ManageChecksTableProps> = ({
  rows,
}) => {
  const columns = useReliabilityChecksColumns();

  return (
    <StyledDataGrid
      rows={rows}
      columns={columns}
      columnHeaderHeight={40}
      rowHeight={72}
      rowSpacingType={"border"}
      autoHeight
    />
  );
};
