import styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { muiColors } from "@komodorio/design-system";
import MuiTypography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import LinearProgress from "@mui/material/LinearProgress";
import { createTheme, ThemeProvider } from "@mui/material";
import { Typography } from "@komodorio/design-system/deprecated";
import {
  GridEventListener,
  useGridApiRef,
  DataGridPro,
  GridRowParams,
  GridSortModel,
} from "@mui/x-data-grid-pro";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodPhaseGroup from "../../../EventGroup/groupedPodEvent/PodPhaseGroup";
import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import { useGetPodEvents } from "../../../../../shared/hooks/resources-api/client/events/useGetPodEvents";
import { useGetNativePodEvents } from "../../../../../shared/hooks/resources-api/client/events/useGetNativePodEvents";
import { buildKomodorUid } from "../../../../../shared/hooks/resources-api/resourcesAPIUtils";

import { Section } from "./styles";
import { StatusConditions } from "./StatusConditions";
import { ContainerStatusWrapper } from "./ContainerStatus";
import {
  useEventsTableColumns,
  useEventsTableRows,
  useFetchedEvents,
} from "./eventsSectionHooks";

const SectionHeader = styled.div`
  display: flex;
  padding: 1.5rem 0.3rem;
  justify-content: space-between;
  align-items: center;
`;

const SectionTitle = styled(Typography).attrs({
  variant: "uppercase",
  color: muiColors.gray[500],
})`
  font-size: 12px;
`;

const SwitchContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  row-gap: 1rem;
`;

const PAGINATION_SIZE_OPTIONS = [15, 20, 25];
const DEFAULT_PAGE_SIZE = 15;

const customTheme = createTheme({
  typography: {
    fontFamily: "body2, sans-serif",
    fontSize: 12,
  },
});

export const EventsSection: React.FC<{
  eventGroup: PodPhaseGroup;
  setIsFetchingEvents?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ eventGroup, setIsFetchingEvents }) => {
  const [showPodEvents, setShowPodEvents] = useState<boolean>(false);

  const eventsParams = useMemo(
    () => ({
      komodorUids: [
        buildKomodorUid({
          clusterName: eventGroup.clusterName,
          namespace: eventGroup.namespace,
          resourceName: eventGroup.podName,
          kind: "Pod",
        }),
      ],
      fromEpoch: eventGroup.startTime.getTime().toString(),
      toEpoch: eventGroup.isCompleted
        ? eventGroup.endTime.getTime().toString()
        : Date.now().toString(),
    }),
    [eventGroup]
  );

  const { data: nativePodEvents, isLoading: isLoadingNativePodEvents } =
    useGetNativePodEvents(eventsParams, true);
  const { data: podEvents, isLoading: isLoadingPodEvents } = useGetPodEvents(
    eventsParams,
    true
  );

  const fetchedEvents = useFetchedEvents(
    nativePodEvents,
    podEvents,
    eventGroup
  );

  const columns = useEventsTableColumns(eventGroup, fetchedEvents);
  const rows = useEventsTableRows(eventGroup, fetchedEvents);

  const filteredRows = rows.filter((row) => {
    if (showPodEvents) {
      return true;
    }
    return row.kind !== "PodEvent";
  });

  useEffect(() => {
    if (filteredRows.length === 0) {
      setShowPodEvents(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "last occurrence",
      sort: "desc",
    },
  ]);

  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const getDetailPanelContent = (rowParams: GridRowParams) => {
    if (rowParams.row.kind !== "PodEvent") {
      return null;
    }
    return (
      <TooltipContainer>
        <StatusConditions
          statusConditions={rowParams.row.statusConditions}
          title={"pod conditions"}
        />
        <ContainerStatusWrapper
          containerStatuses={rowParams.row.containerStatuses}
        />
      </TooltipContainer>
    );
  };

  const apiRef = useGridApiRef();

  const onRowClick = useCallback<GridEventListener<"rowClick">>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef]
  );

  const isFetching = isLoadingNativePodEvents || isLoadingPodEvents;

  useEffect(() => {
    setIsFetchingEvents?.(isFetching);
  }, [isFetching, setIsFetchingEvents]);

  return (
    <Section>
      <SectionHeader>
        <SectionTitle>Events during phase</SectionTitle>
        <SwitchContainer>
          <Switch
            aria-label={AriaLabels.PodPhases.StatusConditionsSwitch}
            checked={showPodEvents}
            onChange={() => setShowPodEvents(!showPodEvents)}
            color="primary"
          />
          <MuiTypography variant={"body2"}>Show status changes</MuiTypography>
        </SwitchContainer>
      </SectionHeader>
      <ThemeProvider theme={customTheme}>
        <DataGridPro
          apiRef={apiRef}
          loading={isFetching}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          aria-label={AriaLabels.PodPhases.EventsTable}
          experimentalFeatures={{ columnGrouping: true }}
          rows={filteredRows}
          columns={columns}
          hideFooter={filteredRows.length < DEFAULT_PAGE_SIZE}
          getRowClassName={(params) => {
            if (params?.row?.kind === "PodEvent") {
              return "pointer-cursor-class";
            }
            return "";
          }}
          sx={{
            "& .MuiDataGrid-withBorderColor": {
              borderColor: muiColors.gray[100],
            },
            "& .MuiDataGrid-cell": {
              padding: "15.5px 10px",
            },
            "& .pointer-cursor-class:hover": {
              cursor: "pointer",
            },
            "& .MuiTablePagination-root": {
              marginRight: "4rem",
            },
          }}
          pageSizeOptions={PAGINATION_SIZE_OPTIONS}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: DEFAULT_PAGE_SIZE,
              },
            },
          }}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          getRowHeight={() => "auto"}
          autoHeight
          pagination
          disableVirtualization
          getDetailPanelContent={getDetailPanelContent}
          onRowClick={onRowClick}
        />
      </ThemeProvider>
    </Section>
  );
};
