import React, { useState } from "react";
import styled from "styled-components";

import { ClickableArea } from "../styles";
import reliabilityItemClose from "../assets/cluster1/reliabilityItemClose.png";
import reliabilityItemOpen from "../assets/cluster1/reliabilityItemOpen.png";

import { NoisyNeighborDrawer } from "./NoisyNeighborDrawer";
import { EndOfLifeDrawer } from "./EndOfLifeDrawer";
import { Item } from "./Item";

const SelectableArea = styled(ClickableArea)<{ top: string }>`
  width: 72.3%;
  height: 12.8%;
  top: ${({ top }) => top};
  left: 26.3%;
`;

export const ReliabilityItem: React.FC = () => {
  const [openEOLDrawer, setOpenEOLDrawer] = useState(false);
  const [openNNDrawer, setOpenNNDrawer] = useState(false);

  return (
    <Item closedImg={reliabilityItemClose} openedImg={reliabilityItemOpen}>
      <SelectableArea top={"32.25%"} onClick={() => setOpenEOLDrawer(true)} />
      <SelectableArea top={"45.2%"} onClick={() => setOpenNNDrawer(true)} />
      <EndOfLifeDrawer
        open={openEOLDrawer}
        onClose={() => setOpenEOLDrawer(false)}
      />
      <NoisyNeighborDrawer
        open={openNNDrawer}
        onClose={() => setOpenNNDrawer(false)}
      />
    </Item>
  );
};
