import { ImpactGroupIdentifier } from "../../../generated/reliabilityApi";

export const impactGroupIdentifierToText: Record<
  ImpactGroupIdentifier,
  { title: string; explanation: string }
> = {
  NodePressure: {
    title: "Node Pressure",
    explanation:
      "Violations that could generate unexpected load on Nodes, causing them to suffer from increased stress on the nodes themselves, subsequently impacting the performance of workloads hosted on those nodes.",
  },
  DegradedService: {
    title: "Degraded Service",
    explanation:
      "Degraded services usually happen when the desired number of replicas are not ready. This could lead to clients experiencing poor application performance and slow response time.",
  },
  EOLDeprecationOutdated: {
    title: "Cluster Upgrades",
    explanation:
      "Deprecation of APIs means that they are no longer recommended for use, and will eventually be removed in future Kubernetes versions.",
  },
  NodeTerminations: {
    title: "Node Terminations",
    explanation:
      "Low resource demand or underutilized nodes, will remove nodes to save resources.",
  },
  FlakyServices: {
    title: "Flaky Services",
    explanation: "Flaky services are unpredictable and may work inconsistency",
  },
  BestPractices: {
    title: "Best Practices",
    explanation: "TODO: Add explanation", //check about this - https://app.clickup.com/t/86bzgpp1u
  },
  CertificateManagement: {
    title: "Certificate Management",
    //TODO: Consider changing this co-pilot generated text
    explanation:
      "Certificates are used to secure communication between services. When they expire, services may become unavailable.",
  },
};
