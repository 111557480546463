import React from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

import { selectAriaLabels } from "../../ariaLabels";
import { gray } from "../../../../../../themes/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${gray[300]};
  padding: 8px 12px 4px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  && {
    margin-right: 8px;
  }
`;

const StyledInputBase = styled(InputBase)`
  && {
    width: 100%;
    font-size: 14px;
  }
`;

type SearchBarProps = {
  onSearch: (searchText: string) => void;
  searchValue: string;
  className?: string;
  searchPlaceholder?: string;
  onFocus?: () => void;
};

export const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  searchValue,
  className,
  searchPlaceholder,
  onFocus,
}) => {
  return (
    <Container className={className} onFocus={onFocus}>
      <StyledSearchIcon sx={{ color: gray[500] }} />
      <StyledInputBase
        placeholder={searchPlaceholder}
        inputProps={{ "aria-label": selectAriaLabels.search }}
        onChange={(e) => onSearch(e.target.value)}
        value={searchValue}
        autoFocus
      />
    </Container>
  );
};
