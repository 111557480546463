import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { DRAWER_STACK_STATE_URL_PARAM } from "../../config/urlSearchParamsKeys";

import { DrawerStatePush, DrawerStateSet, DrawerStateType } from "./types";

export type DrawersStackState = {
  drawersStack: DrawerStateType[];
};

export type DrawersStackActions = {
  pushDrawer: (drawerState: DrawerStatePush, replaceUrl?: boolean) => void;
  popDrawer: (replaceUrl?: boolean) => void;
  setDrawerAt: (
    index: number | undefined,
    drawerState: DrawerStateSet,
    replaceUrl?: boolean
  ) => void;
  closeAllDrawers: (replaceUrl?: boolean) => void;
};

export type DrawersStackStore = DrawersStackState & DrawersStackActions;

export const initialState: DrawersStackState = {
  drawersStack: [],
};

export const getURLSearchParams = (
  key: string,
  value: DrawersStackState
): URLSearchParams => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const stringifiedValue = JSON.stringify(value.drawersStack);
  searchParams.set(key, stringifiedValue);
  return searchParams;
};

const setStateInUrl = (
  key: string,
  value: DrawersStackState,
  replace = false
) => {
  const { pathname } = window.location;
  const searchParams = getURLSearchParams(key, value);
  if (replace) {
    window.history.replaceState(null, "", `${pathname}?${searchParams}`);
  } else {
    window.history.pushState(null, "", `${pathname}?${searchParams}`);
  }
};

export const useDrawersStackStore = create(
  immer<DrawersStackStore>((set, get) => ({
    ...initialState,
    pushDrawer: (drawerState: DrawerStatePush, replaceUrl?: boolean) => {
      set((state) => {
        state.drawersStack.push({
          index: state.drawersStack.length,
          ...drawerState,
        });
        setStateInUrl(DRAWER_STACK_STATE_URL_PARAM, state, replaceUrl);
      });
    },
    popDrawer: (replaceUrl?: boolean) => {
      set((state) => {
        state.drawersStack.pop();
        setStateInUrl(DRAWER_STACK_STATE_URL_PARAM, state, replaceUrl);
      });
    },
    setDrawerAt: (
      index: number | undefined,
      drawerUrlStates: DrawerStateSet,
      replaceUrl?: boolean
    ) => {
      if (index === undefined) {
        return;
      }
      set((state) => {
        const currentDrawerUrlStates = state.drawersStack[index].urlStates;
        state.drawersStack[index].urlStates = {
          ...currentDrawerUrlStates,
          ...drawerUrlStates,
        };
        setStateInUrl(DRAWER_STACK_STATE_URL_PARAM, state, replaceUrl);
      });
    },
    closeAllDrawers: (replaceUrl?: boolean) => {
      set((state) => {
        state.drawersStack = [];
        setStateInUrl(DRAWER_STACK_STATE_URL_PARAM, state, replaceUrl);
      });
    },
  }))
);

export const syncDrawersStackStore = (): void => {
  const urlParams = new URLSearchParams(window.location.search);
  const stateFromURL = urlParams.get(DRAWER_STACK_STATE_URL_PARAM);
  if (stateFromURL) {
    try {
      const parsedState = JSON.parse(stateFromURL);
      useDrawersStackStore.setState({ drawersStack: parsedState });
    } catch (e) {
      resetDrawersStackStore(true);
    }
  } else {
    resetDrawersStackStore(true);
  }
};

export const resetDrawersStackStore = (replaceUrl?: boolean): void => {
  const state = {
    ...initialState,
  };
  useDrawersStackStore.setState(state);
  setStateInUrl(DRAWER_STACK_STATE_URL_PARAM, state, replaceUrl);
};
