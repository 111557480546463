import React from "react";

import { IconProps } from "../types";

export const MagnifyingGlass16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66023 10.4383C0.512346 8.2904 0.512346 4.80799 2.66023 2.66011C4.80811 0.512223 8.29052 0.512224 10.4384 2.66011C12.4064 4.6281 12.5712 7.71644 10.9329 9.87209L14.7313 13.6705C15.0242 13.9634 15.0242 14.4383 14.7313 14.7312C14.4384 15.0241 13.9635 15.0241 13.6706 14.7312L9.87222 10.9328C7.71656 12.5711 4.62822 12.4063 2.66023 10.4383ZM9.37774 9.37762C7.81565 10.9397 5.28299 10.9397 3.72089 9.37762C2.15879 7.81552 2.15879 5.28286 3.72089 3.72077C5.28299 2.15867 7.81565 2.15867 9.37774 3.72077C10.9398 5.28286 10.9398 7.81552 9.37774 9.37762Z"
      />
    </svg>
  );
};

MagnifyingGlass16.defaultProps = {
  fill: "currentColor",
};
