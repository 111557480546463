import React from "react";
import Typography from "@mui/material/Typography";

import { TypographyProps } from "@/components/k8sAddons/types";
import { getTimeElapsedStr } from "@/components/k8sAddons/components/table/TimeElapsedCell/timeElapsedCellUtils";

type DateCellProps = {
  targetDateString: string;
  getTypographyScheme: (timeElapsedInMs: number) => TypographyProps;
};

export const TimeElapsedCell: React.FC<DateCellProps> = ({
  targetDateString,
  getTypographyScheme,
}) => {
  const { outputStr, daysElapsed, timeElapsedInMs } =
    getTimeElapsedStr(targetDateString);
  const textProps = getTypographyScheme(timeElapsedInMs);
  const fontWeight = daysElapsed < 0 ? 600 : 400;

  return (
    <Typography variant={"body2"} {...textProps} fontWeight={fontWeight}>
      {outputStr}
    </Typography>
  );
};
