import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { switchProp } from "styled-tools";

import { Close16 } from "../../../icons";
import { theme } from "../../../theme";

/** @deprecated */
export type TagSize = "small" | "medium";
/** @deprecated */
export type TagVariant = "square" | "round";

/** @deprecated */
export interface TagProps extends HTMLAttributes<HTMLDivElement> {
  size?: TagSize;
  color?: string;
  onDelete?: () => void;
  variant?: TagVariant;
}

const Container = styled.div<TagProps>`
  background-color: ${({ color }) => color};
  ${switchProp("size", {
    small: "padding: 2px 4px;",
    medium: "padding: 4px 8px;",
  })}
  display:flex;
  column-gap: 0.3rem;
  align-items: center;
  ${switchProp("variant", {
    square: "border-radius: 0.25rem;",
    round: "border-radius:1rem;",
  })}
  width: fit-content;
`;

/**
 * @deprecated Use MUI Chip instead.
 */
export const Tag: React.FC<TagProps> = ({ children, onDelete, ...rest }) => {
  return (
    <Container {...rest} role="status">
      {children}
      {onDelete && (
        <Close16
          fill={theme.foreground.fgSubtle}
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        />
      )}
    </Container>
  );
};

Tag.defaultProps = {
  variant: "square",
  size: "medium",
  color: theme.background.bgBlueLight,
};
