import { useMemo, useState } from "react";

import { useSetIsPaginating } from "../useSetIsPaginating";

import { GenericFilter, GetEntityListRequest } from "@/generated/addonsApi";
import { useAddonContext } from "@/components/k8sAddons/context/useAddonContext";
import { useFilterListFromUrlAsGenericFilters } from "@/components/k8sAddons/hooks/filters/useFilterListFromUrlAsGenericFilters";
import { useTablePaginationInUrl } from "@/components/k8sAddons/hooks/table/useTablePaginationInUrl";
import { useRefetchIntervalFromContext } from "@/components/k8sAddons/hooks/useRefetchIntervalFromContext";
import { useGetLiveStateResetFilters } from "@/components/k8sAddons/hooks/filters/useGetLiveStateResetFilters";
import { useResetCurrentPageOnFiltersChange } from "@/components/k8sAddons/hooks/table/useResetCurrentPageOnFiltersChange";
import { useMostRecentUpdateRequestParam } from "@/components/k8sAddons/hooks/filters/useMostRecentUpdateRequestParam";
import { useGetAddonLiveStateList } from "@/shared/hooks/k8s-add-ons/useGetAddonLiveStateList";

type FetchLiveStateListParams = {
  additionalRequestFilters?: GenericFilter[] | undefined;
};

export const useFetchLiveStateList = ({
  additionalRequestFilters,
}: FetchLiveStateListParams) => {
  const [isPaginating, setIsPaginating] = useState(true);

  const { addonType, addonEntity } = useAddonContext();
  const filtersListFromUrl = useFilterListFromUrlAsGenericFilters();
  const { sortModel, paginationModel, setPaginationModel } =
    useTablePaginationInUrl();
  const refetchInterval = useRefetchIntervalFromContext(isPaginating);
  const liveStateResetFilters = useGetLiveStateResetFilters();

  const filtersToUse = useMemo<GenericFilter[]>(
    () => [...filtersListFromUrl, ...(additionalRequestFilters ?? [])],

    [filtersListFromUrl, additionalRequestFilters]
  );

  const { pageToUse } = useResetCurrentPageOnFiltersChange({
    filtersList: filtersToUse,
    setPaginationModel,
    currentPage: paginationModel.page,
  });

  const { updateMostRecentDataFrom, getMostRecentDataFromToUse } =
    useMostRecentUpdateRequestParam(liveStateResetFilters ?? []);

  const requestParams = useMemo<GetEntityListRequest>(() => {
    return {
      mostRecentDataFrom: getMostRecentDataFromToUse(),
      filter: filtersToUse,
      page: pageToUse,
      pageSize: paginationModel.pageSize,
      sort: sortModel[0].field,
      order: sortModel[0].sort === "asc" ? "asc" : "desc",
    };
  }, [
    filtersToUse,
    getMostRecentDataFromToUse,
    pageToUse,
    paginationModel.pageSize,
    sortModel,
  ]);

  const queryResult = useGetAddonLiveStateList(
    {
      addon: addonType,
      entity: addonEntity,
      getEntityListRequest: requestParams,
    },
    {
      keepPreviousData: true,
      refetchInterval,
      enabled: !!liveStateResetFilters?.length,
    }
  );

  useSetIsPaginating({
    currentIsPaginating: isPaginating,
    fetchedIsPaginating: queryResult.data?.data.isPaginating,
    setIsPaginating,
  });

  updateMostRecentDataFrom(queryResult.data?.data.mostRecentUpdate);

  return queryResult;
};
