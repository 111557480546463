import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import {
  CertificateStatusConditions,
  ConditionStatus,
} from "@/generated/addonsApi";
import { AddonStatusChip } from "@/components/k8sAddons/components/AddonStatusChip";
import { SeverityColor } from "@/components/k8sAddons/types";
import { certificatesPageAriaLabels } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatesPageAriaLabels";
import { conditionPropertyToTitle } from "@/components/k8sAddons/addons/CertManager/CertificatesPage/certificatePageConstants";

const HitArea = styled.div`
  width: fit-content;
  cursor: default;
`;

const PopoverContent = styled.div`
  padding: 8px;
  min-width: 150px;
  max-width: 380px;
  & > div:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const StatusLine = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
`;

const {
  status: {
    statusConditions: statusConditionsAriaLabel,
    statusConditionsPopover: statusConditionsPopoverAriaLabel,
  },
} = certificatesPageAriaLabels.drawer;

const certificateStatusToSeverityColor: Record<ConditionStatus, SeverityColor> =
  {
    True: "success",
    False: "error",
    Unknown: "info",
  };

const errorColorKeys: (keyof CertificateStatusConditions)[] = [
  "reason",
  "message",
];

type StatusWithPopoverProps = {
  conditions: CertificateStatusConditions[] | undefined;
};

export const StatusWithPopover: React.FC<StatusWithPopoverProps> = ({
  conditions,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const { type = "", status = "Unknown", reason } = conditions?.[0] ?? {};

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const generateLine = useCallback(
    (key: keyof CertificateStatusConditions) => {
      const color =
        reason === "Expired" && errorColorKeys.includes(key)
          ? "error.dark"
          : "text.primary";
      return (
        <StatusLine
          aria-label={`status line for ${conditionPropertyToTitle[key]}`}
        >
          <Typography variant={"h5"} color={"text.secondary"}>
            {conditionPropertyToTitle[key]}
          </Typography>
          <Typography variant={"body2"} color={color}>
            {conditions?.[0][key]}
          </Typography>
        </StatusLine>
      );
    },
    [conditions, reason]
  );

  const popOverContent = useMemo(() => {
    return (
      <>
        {generateLine("type")}
        {generateLine("status")}
        {generateLine("lastTransitionTime")}
        {generateLine("reason")}
        {generateLine("message")}
      </>
    );
  }, [generateLine]);

  if (!conditions || conditions.length === 0) {
    return null;
  }

  return (
    <div>
      <HitArea
        onMouseLeave={() => {
          handleClose();
        }}
        onMouseEnter={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        aria-label={statusConditionsAriaLabel}
      >
        <AddonStatusChip
          label={type}
          color={certificateStatusToSeverityColor[status]}
        />
      </HitArea>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableRestoreFocus
        sx={{
          pointerEvents: "none",
        }}
        anchorOrigin={{
          vertical: -20,
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <PopoverContent aria-label={statusConditionsPopoverAriaLabel}>
          {popOverContent}
        </PopoverContent>
      </Popover>
    </div>
  );
};
