import React from "react";

import Resource, { ResourceTab, ResourceTabName } from ".";

import { WorkflowDag as WorkflowDagType } from "@/generated/addonsApi";
import WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { WorkflowEngineType } from "@/components/k8sAddons/addons/workflows/types";
import { WorkflowDagContent } from "@/components/k8sAddons/addons/workflows/DagView/WorkflowDagContent";
import { WorkflowDagHeader } from "@/components/k8sAddons/addons/workflows/DagView/WorkflowDagHeader";

export default class WorkflowDag implements Resource {
  readonly agentId = "";
  readonly id;
  readonly cluster;
  readonly namespace;
  readonly kind;
  readonly name;
  readonly engine;
  readonly labels = {};
  readonly annotations = {};
  readonly drawerTabs: ResourceTab[] = [
    { label: ResourceTabName.Events },
    { label: ResourceTabName.Pods },
  ];
  readonly defaultTab;
  readonly actions = [];

  readonly lastRun;

  constructor(wfDag: WorkflowDagType) {
    this.id = wfDag.komodorWorkflowId ?? "";
    this.cluster = wfDag.cluster ?? "";
    this.namespace = wfDag.namespace ?? "";
    this.name = wfDag.dagId ?? "";
    this.kind = wfDag.type ?? "";
    this.engine = wfDag.workflowEngine as WorkflowEngineType;
    this.lastRun = new WorkflowRun(wfDag.lastRun);
    this.defaultTab = 0;
  }

  renderDrawerContent(selectedTab: number): JSX.Element {
    return <WorkflowDagContent workflowDag={this} selectedTab={selectedTab} />;
  }

  renderDrawerHeader(): JSX.Element {
    return <WorkflowDagHeader resource={this} />;
  }
}
