import { compact, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  ACCOUNT_PARAM_KEY,
  RESOURCE_PREFIX,
} from "../config/urlSearchParamsKeys";
import { LocalStorageItem } from "../utils/localStorageSettings";
import { useDrawerStackIndex } from "../context/drawersStack/useDrawerStackIndex";
import { useDrawersStackStore } from "../store/drawersStackStore/drawersStackStore";
import {
  drawerAtSelector,
  setDrawerAtSelector,
} from "../store/drawersStackStore/drawersStackSelectors";
import { useIsOldDrawerStateMechanism } from "../context/drawersStack/useIsOldDrawerStateMechanism";
import { useResourceView } from "../../components/ResourceView/ResourceProvider";
import {
  WORKSPACE_ID_URL_PARAM,
  useAppViewsStore,
} from "../store/appViewsStore/appViewsStore";
import { selectedAppViewIdSelector } from "../store/appViewsStore/appViewStoreSelectors";

import useUserMetadata from "./useUserMetadata/useUserMetadata";

export type StateInSearchParamsType = [
  string | null,
  (value: string | null, replace?: boolean) => void
];
export const useStateInSearchParams = (
  key: string,
  fromUrlLocation?: string
): StateInSearchParamsType => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = new URLSearchParams(location.search).get(key);

  const setState = useCallback(
    (value: string | null, replace = false) => {
      const searchParams = new URLSearchParams(window.location.search);
      if (value === null) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, value);
      }
      const newLocation = {
        pathname: fromUrlLocation ?? window.location.pathname,
        search: searchParams.toString(),
      };
      if (replace) {
        navigate(newLocation, { replace: true });
      } else {
        navigate(newLocation);
      }
    },
    [navigate, key, fromUrlLocation]
  );
  return [state, setState];
};

export type StateInMultipleSearchParamsType = {
  searchParams: { [key: string]: string[] };
  setSearchParams: (
    newState: { [key: string]: string[] | null },
    replace?: boolean
  ) => void;
  clearSearchParams: () => void;
};

export const useStateInMultipleSearchParams = (
  keys: string[]
): StateInMultipleSearchParamsType => {
  const location = useLocation();
  const navigate = useNavigate();

  // A buffer-state is created, which is updated only when one of the search parameters in `keys` is updated.
  const [state, setSpecifiedKeyValues] = useState<{
    [key: string]: string[];
  }>({});

  useEffect(() => {
    // `currentKeyValues` is an object constructed only from the search parameters that are included in `keys`.
    const currentKeyValues = Object.fromEntries(
      Array.from(new URLSearchParams(location.search).entries())
        .filter(([key]) => keys.includes(key))
        .map(([key, value]) => [key, compact(value.split(","))])
    );

    if (!isEqual(state, currentKeyValues)) {
      setSpecifiedKeyValues(currentKeyValues);
    }
  }, [keys, location.search, state]);

  const setState = useCallback(
    (newState: { [key: string]: string[] | null }, replace = false) => {
      const searchParams = new URLSearchParams(window.location.search);

      Object.entries(newState).forEach(([key, values]) => {
        if (values === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, values.join(","));
        }
      });

      const newLocation = {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      };
      navigate(newLocation, { replace });
    },
    [navigate]
  );

  const clearSearchParams = useCallback(() => {
    setState(keys.reduce((params, p) => ({ ...params, [p]: null }), {}));
  }, [keys, setState]);

  return {
    searchParams: state,
    setSearchParams: setState,
    clearSearchParams,
  };
};

export function useStringifiedStateInSearchParams<T>(
  paramKey: string
): [T | null, (newValue: T | null, replace?: boolean) => void] {
  const [state, setState] = useStateInSearchParams(paramKey);
  const parsedState = useMemo<T | null>(() => {
    try {
      return JSON.parse(state || "null");
    } catch {
      return null;
    }
  }, [state]);
  const setStringifiedState = useCallback(
    (newValue: T | null, replace?: boolean) => {
      setState(newValue === null ? null : JSON.stringify(newValue), replace);
    },
    [setState]
  );
  return [parsedState, setStringifiedState];
}

export const useStateInLocalStorage = (
  item: LocalStorageItem,
  defaultValue: string
): [string, (value: string) => void] => {
  const [state, setState] = useState(item.get());

  const LSState = useMemo(() => state || defaultValue, [defaultValue, state]);

  const setLSState = useCallback(
    (value: string) => {
      item.set(value);
      setState(value);
      //dispatch event so all other instances using this localStorageItem would get updated
      window.dispatchEvent(new Event("storage"));
    },
    [item]
  );

  useEffect(() => {
    if (!state) {
      setLSState(defaultValue);
    }
  }, [defaultValue, setLSState, state]);

  const onStorageEvent = useCallback(
    (event: StorageEvent | CustomEvent) => {
      const storageEvent = event as StorageEvent;
      if (storageEvent?.key && storageEvent.key !== item.key) {
        return;
      }
      setState(item.get());
    },
    [item]
  );

  useEffect(() => {
    window.addEventListener("storage", onStorageEvent);
    return () => window.removeEventListener("storage", onStorageEvent);
  }, [onStorageEvent]);

  return [LSState, setLSState];
};

export const useQueryStringInLocalStorage = (
  item: LocalStorageItem,
  toSessionStorage?: string[]
): void => {
  const [prevPathname, setPrevPathname] = useState("");
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const currentWorkspaceId = useAppViewsStore(selectedAppViewIdSelector);
  const { accountId } = useUserMetadata();

  const [localSearch, sessionSearch] = useMemo(() => {
    const localSearchParams = new URLSearchParams(search);
    localSearchParams.delete(ACCOUNT_PARAM_KEY);
    localSearchParams.delete(WORKSPACE_ID_URL_PARAM);
    const sessionSearchParams = new URLSearchParams();
    toSessionStorage?.forEach((paramKey) => {
      const paramVal = localSearchParams.get(paramKey);
      if (paramVal !== null) {
        localSearchParams.delete(paramKey);
        sessionSearchParams.set(paramKey, paramVal);
      }
    });
    return [localSearchParams.toString(), sessionSearchParams.toString()];
  }, [search, toSessionStorage]);

  useEffect(() => {
    if (localSearch || sessionSearch) {
      if (localSearch) item.set(localSearch);
      if (sessionSearch) sessionStorage.setItem(item.key, sessionSearch);
    } else if (prevPathname === pathname) {
      item.remove();
      sessionStorage.removeItem(item.key);
    } else {
      const localStoredSearch = item.get();
      const sessionStoredSearch = sessionStorage.getItem(item.key);
      const newSearch =
        localStoredSearch && sessionStoredSearch
          ? `${localStoredSearch}&${sessionStoredSearch}`
          : localStoredSearch
          ? localStoredSearch
          : sessionStoredSearch;
      if (newSearch) {
        const searchParams = new URLSearchParams(newSearch);
        searchParams.set(ACCOUNT_PARAM_KEY, accountId);
        searchParams.set(WORKSPACE_ID_URL_PARAM, currentWorkspaceId ?? "null");
        navigate(
          {
            search: searchParams.toString(),
          },
          { replace: true }
        );
      }
    }
    setPrevPathname(pathname);
  }, [
    navigate,
    item,
    pathname,
    prevPathname,
    localSearch,
    sessionSearch,
    currentWorkspaceId,
    accountId,
  ]);
};

export function useDrawerUrlState<T>(
  paramKey: string
): [T | null, (newValue: T | null, replace?: boolean) => void] {
  const drawerIndex = useDrawerStackIndex();
  const drawerState = useDrawersStackStore(drawerAtSelector(drawerIndex));
  const setDrawerState = useDrawersStackStore(setDrawerAtSelector(drawerIndex));
  const isOldDrawerStateMechanism = useIsOldDrawerStateMechanism();
  const resource = useResourceView();
  const uniqueParamKey = isOldDrawerStateMechanism
    ? `${RESOURCE_PREFIX}${resource.kind}-${paramKey}`
    : paramKey;
  const [searchParamsState, setSearchParamsState] =
    useStringifiedStateInSearchParams<T>(uniqueParamKey);

  if (drawerIndex !== undefined && drawerState) {
    return [
      (drawerState.urlStates?.[paramKey] ?? null) as T | null,
      (newValue: T | null, replace?: boolean) =>
        setDrawerState(
          {
            [paramKey]: newValue,
          },
          replace
        ),
    ];
  } else {
    return [searchParamsState, setSearchParamsState];
  }
}
