import React, { useCallback, useMemo } from "react";
import sortBy from "lodash/sortBy";
import { IntegrationType } from "komodor-types";

import useInstallationSubscription, {
  Installation,
} from "@/components/integrations/management/installed/useInstallationSubscription";
import { convertZonedTimeToUtc } from "@/shared/utils/dateUtils";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";
import definitions, {
  Integration,
} from "@/components/integrations/management/definitions";

/**
 * For some reason, we want to keep only one Slack installation, although we can have multiple installations of the same type.
 * @param installations
 */
const keepOneSlackInstallation = (installations: Installation[]) => {
  const slackInstallations = installations.filter(
    (i) => i.integration === IntegrationType.SLACK
  );

  const installationsWithoutSlack = installations.filter(
    (i) => i.integration !== IntegrationType.SLACK
  );

  if (slackInstallations.length) {
    installationsWithoutSlack.push(slackInstallations[0]);
  }

  return installationsWithoutSlack;
};

export const getAvailableIntegrations = (
  featureFlags: Record<string, unknown>
) => {
  return Object.entries(definitions).filter(([_, integration]) => {
    const hasRequiredFeatureFlag = integration.featureFlag
      ? !!featureFlags[integration.featureFlag]
      : true;
    return !integration.hideAvailable && hasRequiredFeatureFlag;
  });
};

export const useInstallations = () => {
  const { data: installations, fetching } = useInstallationSubscription();
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const featureFlags = useOverridableFlags();

  const filterIntegrations = useCallback(
    (searchTerm: string) => {
      setSearchTerm(searchTerm);
    },
    [setSearchTerm]
  );

  const availableIntegrations = useMemo(() => {
    const integrations = getAvailableIntegrations(featureFlags);

    if (searchTerm) {
      return integrations.filter(([, integration]) =>
        integration.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return integrations;
  }, [searchTerm, featureFlags]);

  const sortedInstallations = useMemo(() => {
    if (!installations) {
      return [];
    }

    let filteredInstallations = installations.filter((i) => i.showInWeb);

    if (searchTerm) {
      filteredInstallations = filteredInstallations.filter((i) =>
        i.integration.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    filteredInstallations = keepOneSlackInstallation(filteredInstallations);

    return sortBy(
      filteredInstallations,
      (i: Installation) => -new Date(i.createdAt).getTime()
    );
  }, [installations, searchTerm]);

  const installedIntegrations = useMemo(() => {
    return new Set(sortedInstallations.map((i) => i.integration.toString()));
  }, [sortedInstallations]);

  const getInstallationByType = useCallback(
    (type: string) => {
      const installation = sortedInstallations.find(
        (i) => i.integration === type
      );

      if (!installation) {
        return undefined;
      }

      return {
        installedAt: convertZonedTimeToUtc(new Date(installation.createdAt)),
        installedBy: installation.user?.displayName ?? "N/A",
        documentationLink: definitions[type as IntegrationType].docsLink,
      };
    },
    [sortedInstallations]
  );

  const isFetching = useMemo(
    () => fetching || (!sortedInstallations?.length && !searchTerm),
    [fetching, sortedInstallations, searchTerm]
  );

  const sortByInstallationStatus = (
    a: [integrationType: string, integration: Integration],
    b: [integrationType: string, integration: Integration]
  ) => {
    const [intType1] = a;
    const [intType2] = b;

    const isInstalled1 = installedIntegrations.has(intType1);
    const isInstalled2 = installedIntegrations.has(intType2);

    return isInstalled1 && isInstalled2 ? 0 : isInstalled1 ? -1 : 1;
  };

  return {
    getInstallationByType,
    isFetching,
    searchTerm,
    filterIntegrations,
    availableIntegrations,
    installedIntegrations,
    sortByInstallationStatus,
  };
};
