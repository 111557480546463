import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";

import { theme } from "../../../theme";
import { useResizeObserver } from "../../../hooks";
import { Typography } from "@mui/material";

const ExpandButton = styled(Typography)`
  color: ${theme.foreground.fgDarkBlue};
  width: max-content;
  margin-block-start: 0.25rem;
  cursor: pointer;
  :hover {
    text-decoration-line: underline;
  }
`;

const Container = styled.div`
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;

type CollapsableContainerProps = {
  children: React.ReactNode;
  labels?: {
    showMore: string;
    showLess: string;
  };
  containerHeight?: number;
  ariaLabel?: string;
};

export const CollapsableContainer: React.FC<CollapsableContainerProps> = ({
  children,
  labels = {
    showMore: "Show more",
    showLess: "Show less",
  },
  containerHeight = 50,
  ariaLabel,
}) => {
  const [isShowingAll, setIsShowingAll] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const childContainerRef = useRef<HTMLDivElement>(null);
  const maxHeight = isShowingAll
    ? `${childContainerRef.current?.clientHeight ?? 1000}px`
    : containerHeight;
  const buttonLabel = isShowingAll ? labels.showLess : labels.showMore;

  const onResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      setShouldShowButton(
        isShowingAll ||
          (childContainerRef?.current?.clientHeight ?? 0) >
            (entries[0]?.contentRect.height ?? 0)
      );
    },
    [isShowingAll]
  );

  const { callback: debouncedOnResize } = useDebouncedCallback(onResize, 100);
  useResizeObserver(containerRef.current, debouncedOnResize);

  useEffect(() => {
    const hasOverflow =
      (childContainerRef?.current?.clientHeight ?? 0) >
      (containerRef.current?.clientHeight ?? 0);
    setShouldShowButton(hasOverflow);
  }, []);

  const toggleIsShowingAll = () => {
    setIsShowingAll(!isShowingAll);
  };

  return (
    <div aria-label={ariaLabel} aria-expanded={isShowingAll}>
      <Container ref={containerRef} style={{ maxHeight }}>
        {<div ref={childContainerRef}>{children}</div>}
      </Container>
      {shouldShowButton && (
        <ExpandButton onClick={toggleIsShowingAll}>{buttonLabel}</ExpandButton>
      )}
    </div>
  );
};
