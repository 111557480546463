import React from "react";

import { IconProps } from "../types";

export const Team16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.5C11.3284 5.5 12 4.82843 12 4C12 3.17157 11.3284 2.5 10.5 2.5C9.67159 2.5 9.00002 3.17157 9.00002 4C9.00002 4.82843 9.67159 5.5 10.5 5.5ZM10.5 7C12.1569 7 13.5 5.65685 13.5 4C13.5 2.34315 12.1569 1 10.5 1C8.84316 1 7.50002 2.34315 7.50002 4C7.50002 5.65685 8.84316 7 10.5 7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50002 6.5C5.0523 6.5 5.50002 6.05228 5.50002 5.5C5.50002 4.94772 5.0523 4.5 4.50002 4.5C3.94773 4.5 3.50002 4.94772 3.50002 5.5C3.50002 6.05228 3.94773 6.5 4.50002 6.5ZM4.50002 8C5.88073 8 7.00002 6.88071 7.00002 5.5C7.00002 4.11929 5.88073 3 4.50002 3C3.11931 3 2.00002 4.11929 2.00002 5.5C2.00002 6.88071 3.11931 8 4.50002 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75156 12.4269C6.76748 12.4385 6.78894 12.4513 6.81661 12.4633C6.86805 12.4855 6.93087 12.5 7.00002 12.5H14C14.0692 12.5 14.132 12.4855 14.1834 12.4633C14.2111 12.4513 14.2326 12.4385 14.2485 12.4269C13.6572 10.6473 12.1451 9.5 10.5 9.5C8.85489 9.5 7.34286 10.6473 6.75156 12.4269ZM5.30312 12.0307C6.05016 9.68428 8.09479 8 10.5 8C12.9052 8 14.9499 9.68428 15.6969 12.0307C16.032 13.0832 15.1046 14 14 14H7.00002C5.89545 14 4.96803 13.0832 5.30312 12.0307Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58779 14V12.5H1.69887C1.65795 12.5 1.61899 12.4945 1.58366 12.4852C2.07899 11.5778 3.00302 10.9422 4.08018 10.8461L4.96491 9.37151C4.76867 9.34635 4.56862 9.33337 4.36554 9.33337C2.48033 9.33337 0.85621 10.4512 0.119874 12.0603C-0.339763 13.0647 0.594299 14 1.69887 14H3.58779Z"
      />
    </svg>
  );
};

Team16.defaultProps = {
  fill: "currentColor",
};
