import React from "react";

import { IconProps } from "../../types";
import { theme } from "../../..";

export const STS: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 18.035 17.5"
      {...svgProps}
    >
      <path
        d="M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.01489 0 0 1.01489 15.945 -3.873)"
      />
      <path
        d="M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z"
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "Sans",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          direction: "ltr",
          baselineShift: "baseline",
          textAnchor: "start",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          marker: "none",
        }}
        transform="matrix(1.01489 0 0 1.01489 15.945 -3.873)"
      />
      <path
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#fff",
          strokeWidth: 0.52914584,
          strokeLinecap: "square",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "1.58743761,1.58743761",
          strokeDashoffset: 3.66698074,
          strokeOpacity: 1,
        }}
        d="M8.053 5.13h6.525v4.582H8.053z"
        transform="translate(-.724 -.082)"
      />
      <path
        d="M6.514 6.54h6.525v4.584H6.514z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "#fff",
          strokeWidth: 0.52914584,
          strokeLinecap: "square",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "1.58743761,1.58743761",
          strokeDashoffset: 3.87863898,
          strokeOpacity: 1,
        }}
        transform="translate(-.724 -.082)"
      />
      <path
        d="M4.976 7.952H11.5v4.583H4.976z"
        style={{
          fill: "#fff",
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.26458332,
          strokeLinecap: "square",
          strokeMiterlimit: 10,
        }}
        transform="translate(-.724 -.082)"
      />
      <path
        d="M4.976 7.952H11.5v4.583H4.976z"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: "#fff",
          strokeWidth: 0.5291667,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeOpacity: 1,
        }}
        transform="translate(-.724 -.082)"
      />
      <path
        d="M5.509 9.226c0-.43 1.222-.777 2.729-.777 1.507 0 2.73.348 2.73.777 0 .43-1.223.778-2.73.778s-2.73-.349-2.73-.778z"
        style={{
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.26458332,
          strokeLinecap: "square",
          strokeMiterlimit: 10,
        }}
        transform="translate(-.724 -.082)"
      />
      <path
        d="M10.967 9.226c0 .43-1.222.778-2.73.778-1.506 0-2.728-.349-2.728-.778 0-.43 1.222-.777 2.729-.777 1.507 0 2.73.348 2.73.777v2.103c0 .43-1.223.778-2.73.778s-2.73-.348-2.73-.778V9.226"
        style={{
          fill: "#000",
          fillOpacity: 0,
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.26458332,
          strokeLinecap: "square",
          strokeMiterlimit: 10,
        }}
        fill="none"
        transform="translate(-.724 -.082)"
      />
      <path
        d="M10.967 9.226c0 .43-1.222.778-2.73.778-1.506 0-2.728-.349-2.728-.778 0-.43 1.222-.777 2.729-.777 1.507 0 2.73.348 2.73.777v2.103c0 .43-1.223.778-2.73.778s-2.73-.348-2.73-.778V9.226"
        style={{
          fill: "none",
          fillRule: "evenodd",
          stroke: svgProps.fill,
          strokeWidth: 0.5291667,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeOpacity: 1,
        }}
        transform="translate(-.724 -.082)"
      />
    </svg>
  );
};

STS.defaultProps = {
  fill: theme.kubernetesIcon,
};
