import React from "react";

export const JiraIcon: React.FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5069 7.27469L8.57459 1.25008L7.99964 0.666626L1.49278 7.27469C1.28007 7.4913 1.28007 7.84195 1.49278 8.05812L5.57263 12.2013L7.99964 14.6666L14.5069 8.05812C14.7198 7.84195 14.7198 7.4913 14.5069 7.27469ZM7.99964 9.73592L5.96197 7.6667L7.99964 5.59674L10.0378 7.6667L7.99964 9.73592Z"
      fill="#2684FF"
    />
  </svg>
);
