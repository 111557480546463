import React from "react";

import { IconProps } from "../types";

export const Placeholder16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44288 2.89178C8.16372 2.75389 7.83628 2.75389 7.55712 2.89178L4.12389 4.58766C3.85247 4.72173 3.6564 4.97153 3.59064 5.26703L2.71977 9.1807C2.65502 9.47168 2.72346 9.77637 2.90643 10.0117L5.31824 13.1138C5.50769 13.3575 5.79905 13.5 6.10771 13.5H9.8923C10.201 13.5 10.4923 13.3575 10.6818 13.1138L13.0936 10.0117C13.2765 9.77637 13.345 9.47168 13.2802 9.1807L12.4094 5.26703C12.3436 4.97153 12.1475 4.72173 11.8761 4.58766L8.44288 2.89178ZM13.7176 4.24021C13.6518 3.94471 13.4557 3.69491 13.1843 3.56084L8.44288 1.21876C8.16372 1.08087 7.83628 1.08087 7.55712 1.21876L2.81569 3.56084C2.54427 3.69491 2.3482 3.94471 2.28244 4.24021L1.10223 9.54403C1.03748 9.83501 1.10592 10.1397 1.28889 10.375L4.58444 14.6138C4.77389 14.8575 5.06525 15 5.37391 15H10.6261C10.9347 15 11.2261 14.8575 11.4156 14.6138L14.7111 10.375C14.8941 10.1397 14.9625 9.83501 14.8978 9.54403L13.7176 4.24021Z"
      />
    </svg>
  );
};

Placeholder16.defaultProps = {
  fill: "currentColor",
};
