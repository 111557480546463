import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import page1Image from "../assets/page1.png";
import { routes } from "../reliabilityDemoConstants";
import { RELIABILITY_DEMO_ROUTE } from "../../routes/routes";
import { RelativeContainer } from "../reliabilityDemoStyles";

const ClickableArea = styled.div`
  position: absolute;
  cursor: pointer;
  width: 96%;
  height: 13%;
  top: 40%;
  left: 2%;
`;

export const OverviewPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <img src={page1Image} alt={"loading page"} />
      <ClickableArea
        onClick={() =>
          navigate(`${RELIABILITY_DEMO_ROUTE}/${routes.detailsPage}`)
        }
      />
    </RelativeContainer>
  );
};
