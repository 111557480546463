import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";

import { theme } from "../../../theme";
import { IconProps } from "../../../icons/types";
import { Typography } from "..";

const defaultItemStyle = css`
  color: ${theme.menu.item.text};
  :hover:enabled {
    background-color: ${theme.menu.item.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.menu.item.pressedBg};
  }
`;

const dangerItemStyle = css`
  color: ${theme.menu.item.danger.text};
  :hover:enabled {
    background-color: ${theme.menu.item.danger.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.menu.item.danger.pressedBg};
  }
`;

const Item = styled.button<ActionItemProps>`
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  ${switchProp("variant", {
    default: defaultItemStyle,
    danger: dangerItemStyle,
  })}
  :disabled {
    color: ${theme.menu.item.textDisabled};
    cursor: not-allowed;
  }
`;

/** @deprecated */
export type ActionItemVaraint = "default" | "danger";
/** @deprecated */
export interface ActionItemProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.FC<IconProps>;
  variant?: ActionItemVaraint;
}

/**
 * @deprecated Use MUI ListItem instead.
 */
export const ActionItem: React.FC<ActionItemProps> = ({
  icon: Icon,
  children,
  ...rest
}) => {
  const { variant, disabled } = rest;
  const iconColor =
    !disabled && variant === "default" ? theme.foreground.fgSubtle : undefined;
  return (
    <Item {...rest}>
      {Icon && <Icon fill={iconColor} />}
      <Typography color="unset">{children}</Typography>
    </Item>
  );
};

ActionItem.defaultProps = {
  variant: "default",
  disabled: false,
};
