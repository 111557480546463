import { useCallback, useMemo } from "react";

import { buildKomodorUidFromResource } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import { ResourceListResponse } from "../../../Inspection/InspectionViews/common";
import {
  useDeletedPodsProperties,
  useResourceListOfDeletedPods,
} from "../../../Inspection/historicalFetch/useResourceListFromDatabase";
import Resource from "../../resources";
import { KubernetesNodesResource } from "../../../Inspection/inspectionConfiguration/supportedResourcesTypes/KubernetesNodesResource";

export const useAppendDeletedPodsByOwnerResource = (
  resource: Resource,
  resourceList: ResourceListResponse,
  refresh: () => void,
  disableDeletedPods?: boolean
): [ResourceListResponse, () => void] => {
  const { deletedPodsTimeWindow, showDeletedPodsParam } =
    useDeletedPodsProperties();

  const params = useMemo(() => {
    if (resource.kind === KubernetesNodesResource.Kind)
      return { clusterName: resource.cluster, nodeNames: [resource.name] };
    const ownerKomodorUid = buildKomodorUidFromResource(resource);
    if (ownerKomodorUid) return { ownerKomodorUid };
    return null;
  }, [resource]);
  const {
    data: deletedPodsData,
    fetching: isDeletedPodsFetching,
    emptyResult: deletedPodsEmptyResult,
    refresh: refreshDeletedPods,
    errorMessage: deletedPodsErrorMessage,
  } = useResourceListOfDeletedPods(
    deletedPodsTimeWindow,
    params,
    !showDeletedPodsParam || disableDeletedPods
  );

  const refreshWithDeletedPods = useCallback(() => {
    refresh();
    if (showDeletedPodsParam) {
      refreshDeletedPods();
    }
  }, [refresh, refreshDeletedPods, showDeletedPodsParam]);

  return useMemo(() => {
    return [
      {
        ...resourceList,
        rows: deletedPodsData.concat(resourceList.rows),
        fetching: resourceList.fetching || isDeletedPodsFetching,
        emptyResult: resourceList.emptyResult && deletedPodsEmptyResult,
        errorMessage: resourceList.errorMessage || deletedPodsErrorMessage,
      },
      refreshWithDeletedPods,
    ];
  }, [
    deletedPodsData,
    deletedPodsEmptyResult,
    deletedPodsErrorMessage,
    isDeletedPodsFetching,
    refreshWithDeletedPods,
    resourceList,
  ]);
};
