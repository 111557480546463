import { useEffect, useRef } from "react";

export const useResizeObserver = (
  element: HTMLElement,
  callback: (entries: ResizeObserverEntry[]) => void
) => {
  const observer = useRef(null);

  useEffect(() => {
    if (!element) {
      return;
    }
    if (observer && observer.current) {
      observer.current.unobserve(element);
    }

    observer.current = new ResizeObserver((entries) => {
      callback(entries);
    });

    observer.current.observe(element);
    return () => {
      observer.current?.disconnect();
    };
  }, [callback, element]);
};
