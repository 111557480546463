import React, { useState } from "react";
import styled from "styled-components";

import { SelectableClickableArea } from "../../reliabilityDemoStyles";

import { RowDetailsDrawer } from "./RowDetailsDrawer";
import { NodePressureDrawer } from "./NodePressureDrawer";

const NodePressureClickableArea = styled(SelectableClickableArea)<{
  selected: boolean;
}>`
  width: 29.3%;
  height: 13.4%;
  top: 47%;
  left: 2.6%;
`;

export const NodePressureDrawerContainer: React.FC = () => {
  const [nodePressureIsOpen, setNodePressureIsOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  return (
    <>
      <NodePressureClickableArea
        onClick={() => setNodePressureIsOpen(true)}
        selected={nodePressureIsOpen}
      />
      <NodePressureDrawer
        open={nodePressureIsOpen}
        setOpen={setNodePressureIsOpen}
        onRowClick={() => setDetailsDrawerOpen(true)}
      />
      <RowDetailsDrawer
        open={detailsDrawerOpen}
        setOpen={setDetailsDrawerOpen}
      />
    </>
  );
};
