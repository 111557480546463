import React from "react";

import { LogoIconProps } from "../types";

export const Oracle: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Oracle logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M8.26875 18.7502H16.1842C17.9593 18.7042 19.6463 17.9666 20.8855 16.6948C22.1248 15.423 22.8183 13.7174 22.8183 11.9417C22.8183 10.1659 22.1248 8.4604 20.8855 7.18858C19.6463 5.91675 17.9593 5.17921 16.1842 5.13315H8.26875C7.35978 5.10957 6.45528 5.26823 5.6086 5.59979C4.76193 5.93134 3.99021 6.42908 3.33897 7.06365C2.68772 7.69821 2.17014 8.45675 1.81673 9.29455C1.46332 10.1323 1.28125 11.0324 1.28125 11.9417C1.28125 12.851 1.46332 13.751 1.81673 14.5888C2.17014 15.4266 2.68772 16.1852 3.33897 16.8197C3.99021 17.4543 4.76193 17.952 5.6086 18.2836C6.45528 18.6152 7.35978 18.7738 8.26875 18.7502ZM16.0054 16.3532H8.44292C7.29874 16.3162 6.21377 15.8358 5.41745 15.0134C4.62113 14.1909 4.17589 13.091 4.17589 11.9463C4.17589 10.8015 4.62113 9.70161 5.41745 8.87919C6.21377 8.05677 7.29874 7.57631 8.44292 7.5394H16.0054C16.5961 7.52035 17.1846 7.62024 17.7359 7.83313C18.2872 8.04602 18.7901 8.36757 19.2146 8.77867C19.6392 9.18977 19.9768 9.68202 20.2074 10.2262C20.4379 10.7703 20.5567 11.3553 20.5567 11.9463C20.5567 12.5373 20.4379 13.1222 20.2074 13.6664C19.9768 14.2105 19.6392 14.7028 19.2146 15.1139C18.7901 15.525 18.2872 15.8465 17.7359 16.0594C17.1846 16.2723 16.5961 16.3722 16.0054 16.3532Z"
        fill="#E43122"
      />
    </svg>
  );
};
