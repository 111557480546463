export const filtersData = [
  { title: "Cluster", items: ["prod", "staging", "dev-2"] },
  {
    title: "Namespace",
    items: [
      "default",
      "kube-system",
      "defualt-prod",
      "ops-mgmt",
      "flux-system",
    ],
  },
  {
    title: "Application",
    items: ["front", "transactions", "team-core", "test"],
  },
];
