import React from "react";

import { IconProps } from "../types";

export const History24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="25"
    viewBox="0 0 24 25"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path d="M12 21.925C9.9 21.925 8.04167 21.2875 6.425 20.0125C4.80833 18.7375 3.75833 17.1084 3.275 15.125C3.20833 14.875 3.25833 14.6459 3.425 14.4375C3.59167 14.2292 3.81667 14.1084 4.1 14.075C4.36667 14.0417 4.60833 14.0917 4.825 14.225C5.04167 14.3584 5.19167 14.5584 5.275 14.825C5.675 16.325 6.5 17.55 7.75 18.5C9 19.45 10.4167 19.925 12 19.925C13.95 19.925 15.6042 19.2459 16.9625 17.8875C18.3208 16.5292 19 14.875 19 12.925C19 10.975 18.3208 9.32088 16.9625 7.96255C15.6042 6.60422 13.95 5.92505 12 5.92505C10.85 5.92505 9.775 6.19172 8.775 6.72505C7.775 7.25838 6.93333 7.99172 6.25 8.92505H8C8.28333 8.92505 8.52083 9.02088 8.7125 9.21255C8.90417 9.40422 9 9.64172 9 9.92505C9 10.2084 8.90417 10.4459 8.7125 10.6375C8.52083 10.8292 8.28333 10.925 8 10.925H4C3.71667 10.925 3.47917 10.8292 3.2875 10.6375C3.09583 10.4459 3 10.2084 3 9.92505V5.92505C3 5.64172 3.09583 5.40422 3.2875 5.21255C3.47917 5.02088 3.71667 4.92505 4 4.92505C4.28333 4.92505 4.52083 5.02088 4.7125 5.21255C4.90417 5.40422 5 5.64172 5 5.92505V7.27505C5.85 6.20838 6.8875 5.38338 8.1125 4.80005C9.3375 4.21672 10.6333 3.92505 12 3.92505C13.25 3.92505 14.4208 4.16255 15.5125 4.63755C16.6042 5.11255 17.5542 5.75422 18.3625 6.56255C19.1708 7.37088 19.8125 8.32088 20.2875 9.41255C20.7625 10.5042 21 11.675 21 12.925C21 14.175 20.7625 15.3459 20.2875 16.4375C19.8125 17.5292 19.1708 18.4792 18.3625 19.2875C17.5542 20.0959 16.6042 20.7375 15.5125 21.2125C14.4208 21.6875 13.25 21.925 12 21.925ZM13 12.525L15.5 15.025C15.6833 15.2084 15.775 15.4417 15.775 15.725C15.775 16.0084 15.6833 16.2417 15.5 16.425C15.3167 16.6084 15.0833 16.7 14.8 16.7C14.5167 16.7 14.2833 16.6084 14.1 16.425L11.3 13.625C11.2 13.525 11.125 13.4125 11.075 13.2875C11.025 13.1625 11 13.0334 11 12.9V8.92505C11 8.64172 11.0958 8.40422 11.2875 8.21255C11.4792 8.02088 11.7167 7.92505 12 7.92505C12.2833 7.92505 12.5208 8.02088 12.7125 8.21255C12.9042 8.40422 13 8.64172 13 8.92505V12.525Z" />
  </svg>
);

History24.defaultProps = {
  fill: "currentColor",
};
