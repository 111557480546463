import * as React from "react";

import { IconProps } from "../types";

export const AccessControl16: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width={16}
    height={16}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 4.236v3.208c0 3.617 2.727 5.704 4.5 6.455 1.773-.751 4.5-2.838 4.5-6.455V4.236L8 2.153 3.5 4.236ZM8 .5 2 3.278v4.166c0 4.89 4 7.408 6 8.056 2-.648 6-3.167 6-8.056V3.278L8 .5Z"
    />
  </svg>
);

AccessControl16.defaultProps = {
  fill: "currentColor",
};
