import React, { useCallback, useMemo } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import {
  useDeletedPodsProperties,
  useResourceListOfDeletedPods,
} from "../historicalFetch/useResourceListFromDatabase";
import {
  KubernetesPodsResource,
  KubernetesResource,
} from "../inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { useInspectionData } from "../utils/useGetInspectionData";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import InspectionViewComponent, {
  InspectionComponentProps,
  ResourceListResponse,
} from "./common";

export interface NamespacesResult {
  namespaces?: string[];
  errorMessage: string;
}
const InspectionViewAtm: React.FC<InspectionComponentProps> = ({
  kubernetesResource,
  cluster,
  onClusterChange,
  agentInfo,
  agentId,
}) => {
  const { namespacesResult, resourceList, refresh, isLiveDataSupported } =
    useInspectionData({
      agentId,
      agentInfo,
      cluster,
      kubernetesResource,
    });

  const { showDeletedPodsParam } = useDeletedPodsProperties();

  const shouldShowDeletedPods = useMemo(
    () =>
      showDeletedPodsParam &&
      kubernetesResource.NameInK8S === KubernetesPodsResource.NameInK8S,
    [kubernetesResource.NameInK8S, showDeletedPodsParam]
  );

  return shouldShowDeletedPods ? (
    <InspectionViewAtmWithDeletedPods
      kubernetesResource={kubernetesResource}
      cluster={cluster}
      onClusterChange={onClusterChange}
      namespacesResult={namespacesResult}
      resourceList={resourceList}
      refresh={refresh}
      shouldUseLiveData={isLiveDataSupported}
    />
  ) : (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceList}
      kubernetesResource={kubernetesResource}
      refresh={refresh}
      namespaces={namespacesResult}
      shouldUseLiveData={isLiveDataSupported}
    />
  );
};
type InspectionWithDeletedPodsComponentProps = {
  kubernetesResource: KubernetesResource;
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  namespacesResult: NamespacesResult;
  resourceList: ResourceListResponse;
  refresh: () => void;
  shouldUseLiveData: boolean;
};

const InspectionViewAtmWithDeletedPods: React.FC<
  InspectionWithDeletedPodsComponentProps
> = ({
  kubernetesResource,
  cluster,
  onClusterChange,
  namespacesResult,
  resourceList,
  refresh,
  shouldUseLiveData,
}) => {
  const { deletedPodsTimeWindow } = useDeletedPodsProperties();

  const params = useMemo(
    () => (cluster ? { clusterName: cluster } : null),
    [cluster]
  );
  const {
    data: deletedPodsData,
    fetching: isDeletedPodsFetching,
    emptyResult: deletedPodsEmptyResult,
    refresh: refreshDeletedPods,
    errorMessage: deletedPodsErrorMessage,
  } = useResourceListOfDeletedPods(deletedPodsTimeWindow, params);

  const resourceListWithDeletedPods: ResourceListResponse = useMemo(() => {
    return {
      ...resourceList,
      rows: deletedPodsData.concat(resourceList.rows),
      fetching: resourceList.fetching || isDeletedPodsFetching,
      emptyResult: resourceList.emptyResult && deletedPodsEmptyResult,
      errorMessage: resourceList.errorMessage || deletedPodsErrorMessage,
    };
  }, [
    deletedPodsData,
    deletedPodsEmptyResult,
    deletedPodsErrorMessage,
    isDeletedPodsFetching,
    resourceList,
  ]);
  const refreshWithDeletedPods = useCallback(() => {
    refresh();
    refreshDeletedPods();
  }, [refresh, refreshDeletedPods]);

  return (
    <InspectionViewComponent
      cluster={cluster}
      onClusterChange={onClusterChange}
      resourceList={resourceListWithDeletedPods}
      kubernetesResource={{ ...kubernetesResource, isDeletedPods: true }}
      refresh={refreshWithDeletedPods}
      namespaces={namespacesResult}
      shouldUseLiveData={shouldUseLiveData}
    />
  );
};

export default InspectionViewAtm;
