import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { muiColors } from "@komodorio/design-system";
import WarningIcon from "@mui/icons-material/Warning";

import { Insight } from "../types";
import { sectionGap } from "../../../../../../constants";

const Container = styled.div`
  display: flex;
  gap: ${sectionGap};
  margin-top: 26px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const StyledPaper = styled(Paper)<{ $severe?: boolean }>`
  && {
    box-shadow: none;
    border: 1px solid;
    border-color: ${({ $severe }) =>
      $severe ? muiColors.pink[300] : muiColors.gray[200]};
    padding: 10px;
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  && {
    fill: ${muiColors.pink[700]};
  }
`;

type RiskDetailsCardProps = {
  insights: Insight[];
};

export const RiskDetailsCardInsights: React.FC<RiskDetailsCardProps> = ({
  insights,
}) => {
  const insightContent = useMemo(() => {
    return insights.map(({ title, value, isSevere }) => (
      <StyledPaper $severe={isSevere} key={title}>
        <FlexContainer>
          {isSevere && <StyledWarningIcon />}
          <Typography variant={"body1"} color={"text.primary"}>
            {value}
          </Typography>
        </FlexContainer>
        <Typography variant={"body3"} color={"text.secondary"}>
          {title}
        </Typography>
      </StyledPaper>
    ));
  }, [insights]);

  return <Container>{insightContent}</Container>;
};
