import React from "react";

import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";
import { EmailSettingsContextProvider } from "@/components/Settings/email/context/EmailSettingsContext";
import { EmailSettingsContent } from "@/components/Settings/email/EmailSettingsContent";

export const EmailSettings: React.FC = () => {
  return (
    <SettingsViewLayoutWrapper title={"Email Settings"}>
      <EmailSettingsContextProvider>
        <EmailSettingsContent />
      </EmailSettingsContextProvider>
    </SettingsViewLayoutWrapper>
  );
};
