import React from "react";
import Stack from "@mui/material/Stack";
import {
  AdvancedMultiSelect,
  TextInput,
} from "@komodorio/design-system/komodor-ui";

import { UserSettingsWrapper } from "@/pages/user-settings/UserSettingsWrapper";
import isElectronContext from "@/shared/context/electron/isElectronContext";
import TimezoneSelect from "@/components/Settings/General/TimezoneSelect";
import { useProfilePage } from "@/pages/user-settings/useProfilePage";
import { ValidationsProvider } from "@/shared/context/ValidationsProvider";

const WIDTH = "360px";

const NameInput: React.FC = () => {
  const { handleNameChange, name, errors } = useProfilePage();

  return (
    <TextInput
      size="medium"
      label="Name"
      value={name}
      onChange={handleNameChange}
      error={!!errors["name"]}
      helperText={errors["name"]}
      fullWidth
    />
  );
};

export const ProfilePage: React.FC = () => {
  const { userRoles, user } = useProfilePage();

  return (
    <UserSettingsWrapper title="Profile">
      <Stack direction="column" spacing={2} sx={{ width: WIDTH }}>
        <ValidationsProvider>
          <NameInput />
        </ValidationsProvider>
        {!isElectronContext() && (
          <TextInput
            size="medium"
            label="Email"
            disabled
            defaultValue={user?.email}
            fullWidth
          />
        )}
        <TimezoneSelect
          width={WIDTH}
          selectPopoverProps={{
            height: "200px",
            width: WIDTH,
          }}
        />
        <AdvancedMultiSelect
          size="medium"
          width={WIDTH}
          label="Roles"
          value={userRoles}
          disabled
          options={[]}
        />
      </Stack>
    </UserSettingsWrapper>
  );
};
