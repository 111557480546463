import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import applyRecommendationsModalHeader from "../assets/applyRecommendationsModal/applyRecommendationsModalHeader.png";
import applyRecommendationsModalContent from "../assets/applyRecommendationsModal/applyRecommendationsModalContent.png";
import applyRecommendationsModalFooter from "../assets/applyRecommendationsModal/applyRecommendationsModalFooter.png";

type ApplyRecommendationsModalProps = {
  open: boolean;
  onClose: () => void;
};
export const ApplyRecommendationsModal: React.FC<
  ApplyRecommendationsModalProps
> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle sx={{ padding: 0 }}>
        <img
          src={applyRecommendationsModalHeader}
          alt={"apply recommendations modal header"}
          width={1080}
        />
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <img
          src={applyRecommendationsModalContent}
          alt={"apply recommendations modal content"}
          width={1080}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <img
          src={applyRecommendationsModalFooter}
          alt={"apply recommendations modal footer"}
          width={1080}
        />
      </DialogActions>
    </Dialog>
  );
};
