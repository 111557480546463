import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import type WorkflowDag from "@/components/ResourceView/resources/workflowDag";
import { Header } from "@/components/ResourceView/common/Header/Header";
import { WorkflowRunSwitcher } from "@/components/k8sAddons/addons/workflows/DagView/WorkflowRunSwitcher";
import { WorkflowEngineIcon } from "@/components/k8sAddons/addons/workflows/WorkflowEngineIcon";

export const WorkflowDagHeader: React.FC<{
  resource: WorkflowDag;
}> = ({ resource }) => {
  return (
    <>
      <Header
        resource={resource}
        labels={[
          {
            name: "engine",
            value: (
              <Box display="flex" gap="8px">
                <WorkflowEngineIcon engineType={resource.engine} />
                <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
                  {resource.engine}
                </Typography>
              </Box>
            ),
          },
          { name: "type", value: resource.kind },
          { name: "cluster", value: resource.cluster },
          { name: "namespace", value: resource.namespace },
        ]}
      />
      <WorkflowRunSwitcher resource={resource} />
    </>
  );
};
