import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Cog24 } from "@komodorio/design-system/icons";

import { PageRoutes } from "../../riskManagementTypes";
import { PagePadding } from "../../riskManagementStyles";
import { useIntersectionObserver } from "../../../../shared/hooks/useIntersectionObserver";
import { useRiskManagementContext } from "../../context/RiskManagementContext";
import { ExportButton } from "../shared/ExportButton";

import { TopSectionButton } from "./TopSectionButton";

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding-top: 20px;
`;

const ContentContainer = styled.div`
  ${PagePadding};
  height: 100%;
`;

const ButtonsContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
`;

const StyledDivider = styled(Divider)`
  && {
    margin-top: -1px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    margin-right: -10px;
  }
`;

export const TopSection: React.FC = () => {
  const { setIsTopSectionShown } = useRiskManagementContext();
  const location = useLocation();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);

  const isRouteSelected = (route: PageRoutes) =>
    location.pathname.includes(route);

  const changeRoute = (route: PageRoutes) => {
    if (isRouteSelected(route)) return;
    navigate(route);
  };

  const onShow = useCallback(() => {
    setIsTopSectionShown(true);
  }, [setIsTopSectionShown]);

  const onHide = useCallback(() => {
    setIsTopSectionShown(false);
  }, [setIsTopSectionShown]);

  useIntersectionObserver({
    ref: divRef,
    onShow,
    onHide,
  });

  return (
    <Container ref={divRef}>
      <ContentContainer>
        <FlexContainer>
          <Typography variant={"h2"}>Reliability</Typography>
          <ExportButton />
        </FlexContainer>

        <ButtonsContainer>
          <TopSectionButton
            label={"Risks"}
            isSelected={isRouteSelected("risks")}
            onClick={() => changeRoute("risks")}
          />

          <TopSectionButton
            label={"Checks"}
            onClick={() => changeRoute("checks")}
            isSelected={isRouteSelected("checks")}
            icon={<Cog24 />}
          />
        </ButtonsContainer>
      </ContentContainer>
      <StyledDivider />
    </Container>
  );
};
