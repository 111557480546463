import React from "react";

import { IconProps } from "../types";

export const Gauge16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 10C1.5 6.41015 4.41015 3.5 8 3.5C11.5899 3.5 14.5 6.41015 14.5 10C14.5 10.8533 14.3363 11.6648 14.0401 12.4073C14.04 12.4075 14.0366 12.4224 13.9978 12.4458C13.9531 12.4728 13.8753 12.5 13.7748 12.5H2.22523C2.1247 12.5 2.04687 12.4728 2.00219 12.4458C1.9644 12.423 1.96019 12.4082 1.95993 12.4073C1.66366 11.6648 1.5 10.8533 1.5 10ZM16 10C16 11.0469 15.7989 12.0468 15.4333 12.9632C15.1687 13.6263 14.4887 14 13.7748 14H2.22523C1.5113 14 0.831307 13.6263 0.566734 12.9632C0.201084 12.0468 0 11.0469 0 10C0 5.58172 3.58172 2 8 2C12.4183 2 16 5.58172 16 10ZM11.1707 6.33533C11.356 5.96485 11.2058 5.51434 10.8353 5.3291C10.4648 5.14386 10.0143 5.29403 9.8291 5.66451L7.3291 10.6645C7.14386 11.035 7.29403 11.4855 7.66451 11.6707C8.03499 11.856 8.4855 11.7058 8.67074 11.3353L11.1707 6.33533Z"
    />
  </svg>
);

Gauge16.defaultProps = {
  fill: "currentColor",
};
