import React from "react";

import { IconProps } from "../types";

export const Loader16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M14.1393 9.17114C13.9171 10.3361 13.3679 11.4139 12.5561 12.2784C11.7442 13.143 10.7031 13.7587 9.55431 14.0536C8.40556 14.3486 7.1966 14.3106 6.06864 13.9441C4.94068 13.5776 3.94028 12.8977 3.18429 11.9839C2.4283 11.0701 1.94793 9.95999 1.79928 8.78333C1.65064 7.60667 1.83985 6.41201 2.34483 5.33888C2.84981 4.26575 3.6497 3.35845 4.65108 2.72295C5.65246 2.08745 6.81399 1.75 8 1.75"
        fill="none"
        stroke={svgProps.fill}
        strokeWidth="1.5"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 8 8"
          to="360 8 8"
          dur="3s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};

Loader16.defaultProps = {
  fill: "currentColor",
};
