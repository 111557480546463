import React from "react";

import { IconProps } from "../../types";
import { theme } from "../../..";

export const CronJob: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 18.035 17.5"
      {...svgProps}
    >
      <path
        d="M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z"
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "Sans",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          direction: "ltr",
          baselineShift: "baseline",
          textAnchor: "start",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          marker: "none",
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M11.673 3.96v2.143h2.202V3.96zm0 3.084v.546c.258-.06.526-.097.803-.097.497 0 .97.106 1.4.295v-.744zm-6.284.033V9.22h2.203V7.077zm3.142 0V9.22h.928c.31-.52.75-.955 1.275-1.258v-.885zm-3.163 3.117v2.143h2.203v-2.143zm3.152 0v2.143h.707c-.17-.411-.265-.86-.265-1.33 0-.28.037-.551.1-.813z"
        style={{
          opacity: 1,
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.70899999,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "1.418,1.418",
          strokeDashoffset: 23.0452919,
          strokeOpacity: 1,
        }}
        transform="translate(-1.57 -.4)"
      />
      <path
        d="M12.608 7.94a3.21 3.21 0 0 0-3.2 3.2c0 1.76 1.44 3.2 3.2 3.2 1.76 0 3.2-1.44 3.2-3.2 0-1.76-1.44-3.2-3.2-3.2zm1.344 4.543-1.664-1.024V9.54h.48v1.664l1.44.864z"
        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 0.31998053 }}
        transform="translate(-1.57 -.4)"
      />
    </svg>
  );
};

CronJob.defaultProps = {
  fill: theme.kubernetesIcon,
};
