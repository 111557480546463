import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { ClickableArea } from "../styles";
import policiesItemClose from "../assets/cluster1/policiesItemClose.png";
import policiesItemOpen from "../assets/cluster1/policiesItemOpen.png";
import { clustersDemoRoutes } from "../constants";
import { DEMO } from "../../routes/routes";

import { SinglePointOfFailureDrawer } from "./SinglePointOfFailureDrawer";
import { Item } from "./Item";

const SelectableArea = styled(ClickableArea)`
  width: 72.3%;
  height: 14.7%;
  top: 51%;
  left: 26.3%;
`;

const ManagePoliciesButton = styled(ClickableArea)`
  width: 9.5%;
  height: 7%;
  top: 84.1%;
  left: 75.6%;
  border-radius: 4px;
`;

export const PoliciesItem: React.FC = () => {
  const [openSPOFDrawer, setOpenSPOFDrawer] = useState(false);
  const navigate = useNavigate();

  return (
    <Item closedImg={policiesItemClose} openedImg={policiesItemOpen}>
      <ManagePoliciesButton
        onClick={() => navigate(`/${DEMO}/${clustersDemoRoutes.policies}`)}
      />
      <SelectableArea onClick={() => setOpenSPOFDrawer(true)} />
      <SinglePointOfFailureDrawer
        open={openSPOFDrawer}
        onClose={() => setOpenSPOFDrawer(false)}
      />
    </Item>
  );
};
