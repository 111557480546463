import React from "react";

import { LogoIconProps } from "../types";

export const Rancher: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="OpenShift logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M23.9218 8.84703L23.6675 7.23828C23.5851 6.72078 23.3916 6.30078 23.2412 6.30078C23.0872 6.30078 22.9654 6.72828 22.9654 7.25328V7.67328C22.9654 8.19453 22.557 8.62578 22.0555 8.62578H21.6543C21.6257 8.62578 21.597 8.62578 21.5684 8.62953V9.78453C21.597 9.78453 21.6257 9.78828 21.6543 9.78828H23.166C23.6639 9.78453 24.0042 9.36453 23.9218 8.84703Z"
        fill="#2453FF"
      />
      <path
        d="M20.3002 7.48578H17.85C17.8285 7.48578 17.8106 7.48578 17.7891 7.48953H15.2745C15.2458 7.48953 15.2172 7.49328 15.1885 7.49703V7.25328C15.1885 6.73203 15.0632 6.30078 14.9127 6.30078C14.7587 6.30078 14.5688 6.72453 14.4864 7.23828L14.2321 8.84703C14.1497 9.36453 14.49 9.78453 14.9915 9.78453H16.5032C16.6572 9.78453 16.804 9.75828 16.933 9.71328C16.8864 9.98328 16.6608 10.1858 16.3885 10.1858H14.2715C13.9276 10.1858 13.6697 9.86328 13.7235 9.51078L13.9384 8.16453C13.9957 7.81203 13.7342 7.48953 13.3903 7.48953H2.84823C2.62256 7.48953 2.42912 7.62828 2.34315 7.83078L0.358675 10.992C0.326436 11.0445 0.330019 11.1158 0.369422 11.1645L0.756287 11.6408C0.802854 11.7008 0.888824 11.7083 0.946138 11.6595L2.29659 10.5458V17.3108C2.29659 17.6333 2.54375 17.892 2.85181 17.892H5.84285C6.15091 17.892 6.39808 17.6333 6.39808 17.3108V14.9595C6.39808 14.6408 6.64524 14.3783 6.9533 14.3783H14.422C14.73 14.3783 14.9772 14.637 14.9772 14.9595V17.3108C14.9772 17.6333 15.2243 17.892 15.5324 17.892H18.5235C18.8279 17.892 19.0787 17.6333 19.0787 17.3108V14.7795H17.4882C16.9903 14.7795 16.5784 14.352 16.5784 13.827V12.1995C16.5784 11.8883 16.7217 11.6145 16.9438 11.442V13.3845C16.9438 13.9058 17.3521 14.337 17.8536 14.337H20.3037C20.8017 14.337 21.2136 13.9095 21.2136 13.3845V8.43828C21.21 7.91703 20.8017 7.48578 20.3002 7.48578Z"
        fill="#2453FF"
      />
    </svg>
  );
};
