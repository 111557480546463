import React from "react";

import { IconProps } from "../types";

export const List16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C2.26522 4 2.51957 3.89464 2.70711 3.70711C2.89464 3.51957 3 3.26522 3 3C3 2.73478 2.89464 2.48043 2.70711 2.29289C2.51957 2.10536 2.26522 2 2 2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3C1 3.26522 1.10536 3.51957 1.29289 3.70711C1.48043 3.89464 1.73478 4 2 4ZM6.75 2.5C6.55109 2.5 6.36032 2.57902 6.21967 2.71967C6.07902 2.86032 6 3.05109 6 3.25C6 3.44891 6.07902 3.63968 6.21967 3.78033C6.36032 3.92098 6.55109 4 6.75 4H14.25C14.4489 4 14.6397 3.92098 14.7803 3.78033C14.921 3.63968 15 3.44891 15 3.25C15 3.05109 14.921 2.86032 14.7803 2.71967C14.6397 2.57902 14.4489 2.5 14.25 2.5H6.75ZM6.75 7.5C6.55109 7.5 6.36032 7.57902 6.21967 7.71967C6.07902 7.86032 6 8.05109 6 8.25C6 8.44891 6.07902 8.63968 6.21967 8.78033C6.36032 8.92098 6.55109 9 6.75 9H14.25C14.4489 9 14.6397 8.92098 14.7803 8.78033C14.921 8.63968 15 8.44891 15 8.25C15 8.05109 14.921 7.86032 14.7803 7.71967C14.6397 7.57902 14.4489 7.5 14.25 7.5H6.75ZM6.75 12.5C6.55109 12.5 6.36032 12.579 6.21967 12.7197C6.07902 12.8603 6 13.0511 6 13.25C6 13.4489 6.07902 13.6397 6.21967 13.7803C6.36032 13.921 6.55109 14 6.75 14H14.25C14.4489 14 14.6397 13.921 14.7803 13.7803C14.921 13.6397 15 13.4489 15 13.25C15 13.0511 14.921 12.8603 14.7803 12.7197C14.6397 12.579 14.4489 12.5 14.25 12.5H6.75ZM3 8C3 8.26522 2.89464 8.51957 2.70711 8.70711C2.51957 8.89464 2.26522 9 2 9C1.73478 9 1.48043 8.89464 1.29289 8.70711C1.10536 8.51957 1 8.26522 1 8C1 7.73478 1.10536 7.48043 1.29289 7.29289C1.48043 7.10536 1.73478 7 2 7C2.26522 7 2.51957 7.10536 2.70711 7.29289C2.89464 7.48043 3 7.73478 3 8ZM2 14C2.26522 14 2.51957 13.8946 2.70711 13.7071C2.89464 13.5196 3 13.2652 3 13C3 12.7348 2.89464 12.4804 2.70711 12.2929C2.51957 12.1054 2.26522 12 2 12C1.73478 12 1.48043 12.1054 1.29289 12.2929C1.10536 12.4804 1 12.7348 1 13C1 13.2652 1.10536 13.5196 1.29289 13.7071C1.48043 13.8946 1.73478 14 2 14Z"
      />
    </svg>
  );
};

List16.defaultProps = {
  fill: "currentColor",
};
