import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

import { ViolationsBreakdownLegend } from "./ViolationsBreakdownLegend";

const Container = styled.div``;

const ChartContainer = styled.div`
  position: relative;
  width: 100px;
  margin-bottom: 10px;
  & path {
    height: 100%;
  }
  & :last-child {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledTypography = styled(Typography)`
  && {
    margin: 20px 0;
  }
`;

type ViolationsBreakdownGraphProps = {
  graphComponent: React.ReactNode;
  title: string;
  data: Dictionary<number>;
  colorChart: Dictionary<string | undefined>;
};

export const ViolationsBreakdownGraph: React.FC<
  ViolationsBreakdownGraphProps
> = ({ graphComponent, colorChart, data, title }) => {
  const total = useMemo(
    () => Object.values(data).reduce((a, b) => a + b, 0),
    [data]
  );
  return (
    <Container>
      <StyledTypography variant={"h5"} color={"text.secondary"}>
        {title}
      </StyledTypography>
      <ChartContainer>
        {graphComponent}
        <Typography variant={"h3"}>{total}</Typography>
      </ChartContainer>
      <ViolationsBreakdownLegend data={data} colorChart={colorChart} />
    </Container>
  );
};
