import { SingleSelect } from "@komodorio/design-system/komodor-ui";
import Typography from "@mui/material/Typography";
import React from "react";
import styled from "styled-components";

const selectValue = {
  label: "2023-07-12 04:00:00",
  value: "2023-07-12 04:00:00",
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Time: React.FC = () => {
  return (
    <Container>
      <Typography variant="h5" color={"text.primary"}>
        Incident time
      </Typography>
      <SingleSelect
        options={[selectValue]}
        defaultValue={selectValue}
        width="256px"
      />
      <Typography variant="body2" color={"text.secondary"}>
        (12 total incidents in the last 24 hours)
      </Typography>
    </Container>
  );
};
