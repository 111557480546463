import React from "react";
import Drawer from "@mui/material/Drawer";

import apiRemovedDrawer from "../assets/apiRemovedDrawer.png";
import { CloseButtonArea } from "../styles";

type ApiRemovedDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const ApiRemovedDrawer: React.FC<ApiRemovedDrawerProps> = ({
  open,
  onClose,
}) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={apiRemovedDrawer} alt={"api removed drawer"} width={864} />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};
