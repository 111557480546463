import React from "react";

import { IconProps } from "../../types";
import { theme } from "../../..";

export const Node: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 18.035 17.5"
      {...svgProps}
    >
      <path
        d="M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z"
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "Sans",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          direction: "ltr",
          baselineShift: "baseline",
          textAnchor: "start",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          marker: "none",
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M9.992 5.01C9.854 5.006 5.997 6.902 5.947 7c-.122.233-1 4.281-.948 4.368.03.051.663.852 1.405 1.78l1.35 1.689h2.22l2.223.002 1.413-1.766 1.413-1.765-.494-2.169c-.273-1.193-.516-2.189-.54-2.213-.07-.066-3.922-1.913-3.997-1.916Zm.196.948.91.264-.91.263-.909-.263zm-.909.364.852.254-.004 1.183-.847-.47zm1.818 0v.968l-.847.469-.004-1.183zM8.9 7.937l.909.264-.91.263-.908-.263Zm2.208 0 .91.264-.91.263-.908-.263zm-3.117.365.851.252-.004 1.184-.847-.47zm1.818 0v.967l-.847.469-.004-1.184zm.39 0 .852.252-.004 1.184-.847-.47zm1.818 0v.967l-.846.469-.004-1.184zm-3.45 1.515c.271.01.062.282.37.428.327.155.395-.236.6.063.206.299-.183.223-.154.585.03.362.402.225.246.553-.156.327-.285-.048-.584.158-.299.206.006.46-.356.49-.361.028-.101-.271-.429-.427-.328-.156-.395.235-.601-.064-.206-.299.184-.223.155-.584-.03-.362-.402-.225-.246-.553.156-.328.285.048.583-.158.3-.206-.006-.46.356-.49a.56.56 0 0 1 .06-.001zm2.337.362c.483.014.014.468.456.665.441.198.467-.454.799-.102.332.351-.32.34-.147.792.172.452.651.01.638.493-.014.483-.468.014-.665.456-.198.441.454.467.102.799-.351.332-.34-.32-.792-.148-.451.173-.009.652-.492.638-.484-.013-.015-.467-.456-.664-.442-.198-.468.454-.8.102-.332-.351.32-.34.148-.792-.173-.451-.652-.009-.638-.492.013-.484.467-.015.664-.457.198-.441-.454-.467-.102-.799.351-.332.34.32.792.147.452-.172.01-.651.493-.638zm-2.315.254a.532.532 0 1 0 0 1.063.532.532 0 0 0 0-1.063zm2.264.48a1.062 1.062 0 1 0 0 2.125 1.062 1.062 0 0 0 0-2.124z"
        style={{
          opacity: 1,
          fill: "#fff",
          fillOpacity: 1,
          stroke: "#eee",
          strokeWidth: 0,
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeDashoffset: 11.23642349,
          strokeOpacity: 1,
        }}
        transform="translate(-.993 -1.174)"
      />
    </svg>
  );
};

Node.defaultProps = {
  fill: theme.kubernetesIcon,
};
