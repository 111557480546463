import React from "react";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";
import pluralize from "pluralize";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { AdvancedMultiSelect } from "@komodorio/design-system/komodor-ui";

import { TypedWorkspace } from "../../../../shared/hooks/workspaces-api/types";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import { ClustersGroupWorkspace } from "../../../../generated/workspacesApi";

import { WorkspaceActionsButton } from "@/components/workspaces/WorkspaceActionsButton/WorkspaceActionsButton";
import { lightMuiTooltipStyle } from "@/shared/styles/tooltip";
import { fromOptions, toOptions } from "@/shared/utils/selectInputOptions";

const { Description, ClustersSelector } =
  AriaLabels.ClustersGroupOverview.Header;

const Container = styled.div`
  display: flex;
  background-color: ${muiColors.common.white};
  padding: 16px 24px;
  justify-content: space-between;
`;

export const ClustersGroupOverviewHeader: React.FC<{
  workspace: TypedWorkspace;
  selectedClusters: string[] | null;
  setSelectedClusters: (clusters: string[]) => void;
}> = ({ workspace, selectedClusters, setSelectedClusters }) => {
  const { authorizedClusters, clusters = [] } =
    workspace.value as ClustersGroupWorkspace;

  const [firstClusters, restClusters] = [
    clusters.slice(0, 5),
    clusters.slice(5),
  ];
  const tooltipContent = (
    <Box display="flex" flexDirection="column">
      {firstClusters.map((clusterName) => (
        <Typography variant="body3">{clusterName}</Typography>
      ))}
      {restClusters.length > 0 && (
        <Typography variant="body3">+{restClusters.length}</Typography>
      )}
    </Box>
  );

  return (
    <Container>
      <Box display="flex" flexDirection="column" gap="8px">
        <Box display="flex" gap="8px" alignItems="baseline">
          <Typography variant="h3">{workspace.name}</Typography>
          <Tooltip
            componentsProps={lightMuiTooltipStyle}
            title={tooltipContent}
            placement="bottom"
          >
            <Typography variant="h4" color="text.secondary">
              ({authorizedClusters} {pluralize("Cluster", authorizedClusters)})
            </Typography>
          </Tooltip>
        </Box>
        {workspace.description && (
          <Typography variant="body3" aria-label={Description}>
            {workspace.description}
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center" columnGap="8px">
        <Typography variant="body2">Clusters</Typography>
        <AdvancedMultiSelect
          options={toOptions(clusters)}
          value={selectedClusters ? toOptions(selectedClusters) : undefined}
          placeholder={`All (${clusters.length})`}
          onChange={(selectedOptions) =>
            setSelectedClusters(fromOptions(selectedOptions))
          }
          width="240px"
          selectPopoverProps={{
            height: "300px",
          }}
          ariaLabel={ClustersSelector}
        />
        <WorkspaceActionsButton kind="cluster group" />
      </Box>
    </Container>
  );
};
