// eslint-disable-next-line import/no-unresolved
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

export type Dictionary<T> = {
  [key: string]: T;
};

export enum Timeframe {
  Last30Minutes,
  LastHour,
  Last4Hours,
  Last24Hours,
  Last48Hours,
  Last7Days,
  Custom,
}

export interface TimeWindow {
  start: Date;
  end: Date;
  timeframe: Timeframe;
}

export type HistogramProps = {
  timeWindow: TimeWindow;
  data: HistogramData[];
  totalBars?: number;
  barColors?: string[];
  timeFrameToDateFormat?: Record<Timeframe, string>;
  barChartProps?: CategoricalChartProps;
  aggregatedIdsLabel?: string;
};

export type DefinedHistogramProps = Pick<
  HistogramProps,
  "timeWindow" | "data"
> & {
  totalBars: number;
  barColors: string[];
  timeFrameToDateFormat: Record<Timeframe, string>;
  aggregatedIdsLabel: string;
};

export type HistogramData = {
  startTime: Date;
  endTime: Date;
  groupId: string;
};

export type HistogramDataOutput = {
  name: string;
} & Dictionary<string>;
