import Box from "@mui/material/Box";
import React from "react";
import { muiColors } from "@komodorio/design-system";
import styled from "styled-components";

import { Feedback } from "../pod/Feedback";
import { RelatedEvidence } from "../pod/RelatedEvidence";
import { SuggestedRemediation } from "../pod/SuggestedRemediation";
import { WhatHappened } from "../pod/WhatHappened";
import { RichSessionData } from "../common/useEnrichedSession";
import { Loader } from "../pod/Loader";
import { Error } from "../pod/Error";

export type InvestigationContentProps = {
  className?: string;
  richSession: RichSessionData | null;
  isLoading: boolean;
  isError?: boolean;
  onRetryCallback: () => void;
  showBorder?: boolean;
};

const InvestigationContainer = styled.div<{ showBorder: boolean }>`
  padding: 16px;
  background-color: ${muiColors.common.white};
  gap: 10px;
  border: ${({ showBorder }) =>
    showBorder ? `1px solid ${muiColors.gray[200]}` : "none"};
  display: flex;
  flex-direction: column;
`;

export const InvestigationContent: React.FC<InvestigationContentProps> = ({
  className,
  richSession,
  isLoading,
  onRetryCallback,
  isError,
  showBorder = true,
}) => {
  if (richSession?.isComplete && richSession?.isNoProblemFound) {
    return null;
  }
  return (
    <InvestigationContainer className={className} showBorder={showBorder}>
      {isError ? (
        <Error
          text={
            richSession?.isStuck
              ? "Investigation Incomplete"
              : "Analysis Failed"
          }
          onRetryCallback={onRetryCallback}
        />
      ) : isLoading ? (
        <Box height="200px" width="100%" display="flex">
          <Loader session={richSession} />
        </Box>
      ) : (
        <>
          {richSession && (
            <>
              <WhatHappened session={richSession} />
              <RelatedEvidence session={richSession} />
              <SuggestedRemediation session={richSession} />
              <Feedback />
            </>
          )}
        </>
      )}
    </InvestigationContainer>
  );
};
