import React, { AriaRole } from "react";
import styled from "styled-components";

const Div = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => !visible && "none"};
`;

/** @deprecated */
export interface TabPanelProps {
  eager?: boolean;
  value: number;
  index: number;
  children?: React.ReactNode;
}

interface TabPanelPropsWithRole extends TabPanelProps {
  role: AriaRole;
}

const EagerPanel: React.FC<TabPanelPropsWithRole> = ({
  value,
  index,
  children,
}) => {
  return <Div visible={index === value}>{children}</Div>;
};

const NonEagerPanel: React.FC<TabPanelPropsWithRole> = ({
  value,
  index,
  children,
}) => {
  if (index === value) return <>{children}</>;
  return null;
};

/**
 * @deprecated Use MUI TabPanel instead.
 */
export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const PanelComponent = props.eager ? EagerPanel : NonEagerPanel;
  return <PanelComponent {...props} role="tabpanel" />;
};
