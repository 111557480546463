import { useMemo } from "react";
import { format } from "date-fns";

import {
  DefinedHistogramProps,
  Dictionary,
  HistogramDataOutput,
} from "./types";

type AggregatedIdsCountParams = Pick<
  DefinedHistogramProps,
  "data" | "barColors" | "aggregatedIdsLabel"
>;
export const useAggregatedIdsCount = ({
  aggregatedIdsLabel,
  data,
  barColors,
}: AggregatedIdsCountParams): Dictionary<number> => {
  return useMemo(() => {
    // create an aggregated count per each groupId
    const groupIdCount = data.reduce<Dictionary<number>>((acc, curr) => {
      if (!acc[curr.groupId]) {
        acc[curr.groupId] = 1;
      } else {
        ++acc[curr.groupId];
      }
      return acc;
    }, {});

    // sort by DESC
    const sortedIds = Object.entries(groupIdCount).sort(
      ([, a], [, b]) => b - a
    );

    return sortedIds.reduce<Dictionary<number>>((acc, [key, value]) => {
      if (!acc[key]) {
        // for entries > barColors.length, aggregate remaining ids under aggregatedIdsLabel key
        if (Object.keys(acc).length < barColors.length - 1) {
          acc[key] = value;
        } else {
          if (!acc[aggregatedIdsLabel]) {
            acc[aggregatedIdsLabel] = value;
          } else {
            acc[aggregatedIdsLabel] += value;
          }
        }
      } else {
        acc[key] += value;
      }
      return acc;
    }, {});
  }, [aggregatedIdsLabel, barColors.length, data]);
};

type DateRange = {
  start: Date;
  end: Date;
};
const doDateRangesOverlap = (dateA: DateRange, dateB: DateRange) => {
  const { start: startA, end: endA } = dateA;
  const { start: startB, end: endB } = dateB;
  if (startA < startB && startB < endA) return true;
  if (startA < endB && endB < endA) return true;
  return startB < startA && endA < endB;
};

export const useHistogramData = ({
  timeFrameToDateFormat,
  data,
  barColors,
  totalBars,
  timeWindow,
  aggregatedIdsLabel,
}: DefinedHistogramProps): {
  dataOutput: HistogramDataOutput[];
  idsDictionary: Dictionary<number>;
  highestVal: number;
} => {
  const idsDictionary = useAggregatedIdsCount({
    data,
    barColors,
    aggregatedIdsLabel,
  });

  return useMemo(() => {
    const { start, end, timeframe } = timeWindow;
    const dateFormat = timeFrameToDateFormat[timeframe];
    const totalTime = end.getTime() - start.getTime();
    const timeSizePerSegment = totalTime / totalBars;
    let highestVal = 0;

    const dataOutput = Array.from({
      length: totalBars,
    }).map<HistogramDataOutput>((_, idx) => {
      const timeSegmentStart = new Date(
        start.getTime() + timeSizePerSegment * (idx + 1)
      );
      const timeSegmentEnd = new Date(
        start.getTime() + timeSizePerSegment * (idx + 2) - 1
      );

      let totalCount = 0;

      const relevantEvents = data
        .filter(({ startTime, endTime }) =>
          doDateRangesOverlap(
            { start: startTime, end: endTime },
            { start: timeSegmentStart, end: timeSegmentEnd }
          )
        )
        .reduce<Dictionary<number>>((acc, { groupId }) => {
          // assign either a groupId or aggregatedIdsLabel
          const groupIdToUse = idsDictionary[groupId]
            ? groupId
            : aggregatedIdsLabel;
          if (!acc[groupIdToUse]) {
            acc[groupIdToUse] = 1;
          } else {
            acc[groupIdToUse] += 1;
          }
          ++totalCount;
          return acc;
        }, {});

      if (totalCount > highestVal) {
        highestVal = totalCount;
      }
      return {
        name: format(timeSegmentStart, dateFormat),
        ...relevantEvents,
      };
    });

    return {
      dataOutput,
      idsDictionary,
      highestVal,
    };
  }, [
    timeWindow,
    timeFrameToDateFormat,
    totalBars,
    idsDictionary,
    data,
    aggregatedIdsLabel,
  ]);
};

/* correct yAxis left margin according to current font-size */
export const getYAxisMargin = (val: number): number => {
  const maxChars = 8;
  const fontSize = 6.2;
  const currentLen = val.toString().length;
  return -(maxChars - currentLen) * fontSize;
};
