import React from "react";

import { IconProps } from "../types";

export const Pod16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.60608 0.352828C7.85763 0.245021 8.14237 0.245021 8.39392 0.352828L15.1439 3.24568C15.5116 3.40326 15.75 3.7648 15.75 4.16483V11.3455C15.75 11.7242 15.536 12.0705 15.1972 12.2399L8.44721 15.6149C8.16569 15.7557 7.83431 15.7557 7.55279 15.6149L0.802786 12.2399C0.464002 12.0705 0.25 11.7242 0.25 11.3455V4.16483C0.25 3.7648 0.488397 3.40326 0.856081 3.24568L7.60608 0.352828ZM8 1.81596L2.90394 3.99998L8 6.18401L13.0961 3.99998L8 1.81596ZM14.25 5.13739L8.75 7.49453V13.7865L14.25 11.0365V5.13739ZM7.25 13.7865V7.49453L1.75 5.13738V11.0365L7.25 13.7865Z"
      />
    </svg>
  );
};

Pod16.defaultProps = {
  fill: "currentColor",
};
