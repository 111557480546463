import React, { useState } from "react";
import styled from "styled-components";

import { ClickableArea } from "../styles";

const ItemContainer = styled.div`
  position: relative;
  margin: 0.5rem 1.5rem;
  > img {
    width: 100%;
  }
`;

const OpenArea = styled(ClickableArea)`
  width: 99.4%;
  height: 87%;
  top: 2%;
  left: 0.3%;
  border-radius: 4px;
`;

const CloseArea = styled(ClickableArea)`
  width: 99.4%;
  height: 17%;
  top: 0.5%;
  left: 0.3%;
`;

interface ItemProps {
  closedImg: string;
  openedImg: string;
  noHover?: boolean;
  children?: React.ReactNode;
}
export const Item: React.FC<ItemProps> = ({
  closedImg,
  openedImg,
  noHover,
  children,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <ItemContainer>
      {open ? (
        <>
          <img src={openedImg} alt={"item open"} />
          <CloseArea onClick={() => setOpen(false)} noHover />
          {children}
        </>
      ) : (
        <>
          <img src={closedImg} alt={"item close"} />
          <OpenArea onClick={() => setOpen(true)} noHover={noHover} />
        </>
      )}
    </ItemContainer>
  );
};
