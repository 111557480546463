import React from "react";

import { IconProps } from "../types";

export const ArrowDown16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.27143 8.66791C3.56432 8.37502 4.03919 8.37502 4.33209 8.66791L7.55176 11.8876L7.55176 2.69824C7.55176 2.28403 7.88755 1.94824 8.30176 1.94824C8.71597 1.94824 9.05176 2.28403 9.05176 2.69824L9.05176 11.8876L12.2714 8.66791C12.5643 8.37502 13.0392 8.37502 13.3321 8.66791C13.625 8.96081 13.625 9.43568 13.3321 9.72857L8.65531 14.4053C8.46005 14.6006 8.14347 14.6006 7.9482 14.4053L3.27143 9.72857C2.97853 9.43568 2.97853 8.96081 3.27143 8.66791Z"
      />
    </svg>
  );
};

ArrowDown16.defaultProps = {
  fill: "currentColor",
};
