import { ONE_DAY_IN_MS } from "../../constants/time";

export const getCookieValue = (cookieName: string): string | undefined => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts.length !== 2) return undefined;
  return parts.pop()?.split(";").shift();
};

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expirationMinutes: number
): void => {
  const d = new Date();
  d.setTime(d.getTime() + expirationMinutes * ONE_DAY_IN_MS);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
};
