import React from "react";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const PaddedStack = styled(Stack)`
  padding: 4px 0;
`;

export enum Direction {
  left = "row",
  right = "row-reverse",
}

export interface LabeledSwitchProps {
  label: string;
  onCheck?: (value: boolean) => void;
  checked?: boolean;
  labelPosition?: "left" | "right";
  size?: "medium" | "small";
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default";
}

export const LabeledSwitch: React.FC<LabeledSwitchProps> = ({
  label,
  labelPosition = "right",
  checked = false,
  size = "medium",
  color = "primary",
  onCheck,
}) => {
  const onChange = React.useCallback(() => {
    if (onCheck) {
      onCheck(!checked);
    }
  }, [checked]);

  const direction = Direction[labelPosition];
  return (
    <PaddedStack
      gap={1}
      direction={direction}
      justifyContent="start"
      alignItems="center"
      role="group"
    >
      <Typography variant="body1">{label}</Typography>
      <Switch color={color} size={size} checked={checked} onChange={onChange} />
    </PaddedStack>
  );
};
