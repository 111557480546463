import React, { useMemo } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import { FlexColumns } from "../../common/FlexContainers";
import { H2Poppins } from "../../common/typography";
import {
  BackgroundContainer,
  CenteredContainer,
  PageBottom,
} from "../Common/common";
import { useLogout } from "../../Logout/logout";
import backArrow from "../Common/back-arrow.svg";

import { blackGreyText } from "@/Colors";
import { useAccountsService } from "@/shared/hooks/accounts-service/useAccountsService";
import { useAccountChooseHandler } from "@/components/AccountSwitcher/hooks/useAccountChooseHandler";

const SizedCenteredContainer = styled(CenteredContainer)`
  width: 500px;
  height: 420px;
`;

const ContentContainer = styled(FlexColumns)`
  width: 100%;
  height: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 96px;
`;

const BackButton = styled.button`
  width: 0.913rem;
  height: 1rem;
  background-image: url(${backArrow});
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const Title = styled(H2Poppins)`
  width: 17rem;
  text-align: center;
  color: ${blackGreyText};
`;

const AccountLabelButton = styled(Button).attrs({
  variant: "outlined",
  size: "large",
})`
  width: 200px;
  margin: 8px 0 !important;
`;

export const ChooseAccountLoginScene: React.FC = () => {
  const logout = useLogout();
  const { data: unsortedAccounts } = useAccountsService();
  const onChooseAccount = useAccountChooseHandler();

  const sortedAccounts = useMemo(() => {
    return unsortedAccounts?.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }, [unsortedAccounts]);

  return (
    <BackgroundContainer>
      <SizedCenteredContainer>
        <HeaderContainer>
          <BackButton onClick={logout} />
          <Title data-e2e-selector="signup-wizard-teamname">Your teams</Title>
        </HeaderContainer>
        <ContentContainer>
          {unsortedAccounts === undefined && <CircularProgress />}
          {sortedAccounts?.map((account) => (
            <AccountLabelButton
              key={account.id}
              onClick={() =>
                onChooseAccount({
                  value: account.id,
                  label: account.name,
                })
              }
            >
              {account.name}
            </AccountLabelButton>
          ))}
        </ContentContainer>
        <PageBottom></PageBottom>
      </SizedCenteredContainer>
    </BackgroundContainer>
  );
};
