import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { backgroundColor, contentStyles } from "../../styles";
import { MetadataItem } from "../../shared/MetadataItem";

const Container = styled.div`
  display: flex;
  background-color: ${backgroundColor};
  width: 100%;
  gap: 32px;
  ${contentStyles};
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const Metadata: React.FC = () => {
  return (
    <Container>
      <MetadataItem label="CLUSTER:">
        <Typography variant="body2" color="text.primary">
          production
        </Typography>
      </MetadataItem>
      <MetadataItem label="NAMESPACE:">
        <Typography variant="body2" color="text.primary">
          default
        </Typography>
      </MetadataItem>
      <MetadataItem label="SERVICE:">
        <Typography variant="body2" color="primary">
          reservation-mgmt
        </Typography>
      </MetadataItem>
    </Container>
  );
};
