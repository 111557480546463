import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { backgroundColor } from "../../../../../styles";
import { MetadataItem } from "../../../../../shared/MetadataItem";
import sparklineGraphUrl from "../../../../../../../../../../assets/drawer/sparklinegraph.svg";
import tableUrl from "../../../../../../../../../../assets/drawer/table.svg";
import { sectionGap } from "../../../../../../../../../../constants";

import { Card } from "./Card";
import { workloadsData } from "./utils";
import { Time } from "./Time";
import { NodeData } from "./NodeData";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${backgroundColor};
  gap: 24px;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

const ImpactedWorkloadsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${sectionGap};
  row-gap: 10px;
`;

const ImpactedWorkloads: React.FC = () => {
  const content = useMemo(
    () =>
      workloadsData.map((item) => (
        <MetadataItem variant="body3" label={item.label} key={item.label}>
          <Typography variant="body2" color={"text.primary"}>
            {item.value}
          </Typography>
        </MetadataItem>
      )),
    []
  );

  return <ImpactedWorkloadsContainer>{content}</ImpactedWorkloadsContainer>;
};

export const Info: React.FC = () => {
  return (
    <Container>
      <CardsContainer>
        <Card
          title="Availability issues caused by service"
          additionalInfo="(last 24h)"
          content="76"
          children={<img src={sparklineGraphUrl} />}
        />
        <Card
          title={"Impacted workloads"}
          content="379"
          children={<ImpactedWorkloads />}
        />
      </CardsContainer>
      <Time />
      <NodeData />
      <img src={tableUrl} />
    </Container>
  );
};
