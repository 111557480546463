import React, { createContext, PropsWithChildren } from "react";

import { Addon, Entity } from "@/generated/addonsApi";

export type AddonContextState = {
  addonType: Addon;
  addonEntity: Entity;
  refetchInterval?: number;
};

const initialState: AddonContextState = {
  addonType: Addon.CertManager,
  addonEntity: Entity.Controller,
  refetchInterval: 0,
};

export const AddonContext = createContext<AddonContextState>(initialState);

type AddonContextProps = PropsWithChildren<AddonContextState>;

export const AddonContextProvider: React.FC<AddonContextProps> = ({
  addonEntity,
  addonType,
  refetchInterval,
  children,
}) => {
  const contextState: AddonContextState = {
    addonType,
    addonEntity,
    refetchInterval,
  };

  return (
    <AddonContext.Provider value={contextState}>
      {children}
    </AddonContext.Provider>
  );
};
