import React from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVert from "@mui/icons-material/MoreVert";

import { firstSeenDate } from "../../../texts";
import { ActionsSelect } from "../../../../../../../shared/ActionsSelect";
import { sectionGap } from "../../../../../../../../constants";

const Container = styled.div`
  display: flex;
  ${sectionGap};
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Actions: React.FC = () => {
  return (
    <Container>
      <Status>
        <ActionsSelect />
        <Typography variant="body2" color={"text.primary"}>
          {firstSeenDate}
        </Typography>
      </Status>
      <div>
        <IconButton color="inherit" size="small">
          <MoreVert />
        </IconButton>
      </div>
    </Container>
  );
};
