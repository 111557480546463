import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { ifProp, switchProp } from "styled-tools";

import { IconProps } from "../../../icons/types";
import { theme } from "../../../theme";
import { Typography } from "../Typography/Typography";

/** @deprecated */
export type InputContainerSize = "small" | "medium";
/** @deprecated */
export interface InputContainerProps extends HTMLAttributes<HTMLDivElement> {
  size?: InputContainerSize;
  width?: string;
  icon?: React.FC<IconProps>;
  label?: string;
  trailingElement?: React.ReactNode;
  noBorder?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
}

const Layout = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: ${({ width }) => width};
  min-width: 4rem;
`;

const Container = styled.div<InputContainerProps>`
  background-color: ${theme.background.bgWhite};
  padding-inline: 7px;
  ${switchProp("size", {
    small: "padding-block: 3px;",
    medium: "padding-block: 5px;",
  })}

  ${({ noBorder, errorMessage }) =>
    noBorder
      ? "border: none;"
      : `border: 1px solid  ${
          errorMessage !== undefined
            ? theme.border.borderPink
            : theme.border.borderGray
        };`}
  border-radius: 0.25rem;

  display: grid;
  grid-template-areas: "prefix input postfix";
  grid-template-columns: max-content auto max-content;
  align-items: center;
  box-sizing: content-box;
  min-height: 20px;
  ${ifProp("readOnly", "cursor: pointer;")}
  ${ifProp(
    "disabled",
    `background-color: ${theme.background.bgGray};
    cursor: not-allowed;
    `
  )}
  :focus-within {
    border-color: ${theme.border.borderDarkBlue};
  }
`;

const Prefix = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-area: prefix;
  margin-inline-end: 0.3rem;
  color: ${ifProp(
    "disabled",
    theme.foreground.fgDisabled,
    theme.foreground.fgSubtle
  )};
`;

const Postfix = styled.div<{ disabled?: boolean }>`
  display: grid;
  grid-area: postfix;
  margin-inline-start: 0.3rem;
  color: ${ifProp(
    "disabled",
    theme.foreground.fgDisabled,
    theme.foreground.fgSubtle
  )};
`;

const Content = styled(Typography)`
  grid-area: input;
`;

const BreakedLabel = styled.label`
  word-break: break-word;
`;

/**
 * @deprecated Use MUI TextField instead.
 */
export const InputContainer: React.FC<InputContainerProps> = ({
  label,
  size,
  noBorder,
  onClick,
  errorMessage,
  icon: Icon,
  trailingElement,
  children,
  ...rest
}) => {
  const { disabled, readOnly, width = "100%" } = rest;

  return (
    <Layout width={width}>
      {label && (
        <label>
          <Typography size={size}>{label}</Typography>
        </label>
      )}
      <Container
        size={size}
        disabled={disabled}
        noBorder={noBorder}
        errorMessage={errorMessage}
        readOnly={readOnly}
        onClick={!disabled ? onClick : undefined}
      >
        {Icon && (
          <Prefix disabled={disabled}>
            <Icon />
          </Prefix>
        )}
        <Content
          size={size}
          color={disabled ? theme.foreground.fgSubtle : undefined}
        >
          {children}
        </Content>
        {trailingElement && (
          <Postfix disabled={disabled}>{trailingElement}</Postfix>
        )}
      </Container>
      {errorMessage && (
        <BreakedLabel>
          <Typography color={theme.foreground.fgPink}>
            {errorMessage}
          </Typography>
        </BreakedLabel>
      )}
    </Layout>
  );
};

InputContainer.defaultProps = {
  size: "small",
  width: "100%",
  disabled: false,
  readOnly: false,
  noBorder: false,
};
