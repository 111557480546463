/* eslint-disable max-lines */
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropDownTriggerField } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Plus16 } from "@komodorio/design-system/icons";
import { datadogRum } from "@datadog/browser-rum";

import { CREATE_WORKSPACE_ROUTE } from "../../routes/routes";
import { AriaLabels } from "../../../shared/config/ariaLabels";
import { dispatchEvent, AnalyticEvents } from "../../../shared/hooks/analytics";
import { AddEditWorkspaceModal } from "../AddEditWorkspaceModal/AddEditWorkspaceModal";
import { WorkspaceDeletionDialog } from "../WorkspaceActionsButton/WorkspaceDeletionDialog";

import { SelectedWorkspaceValue } from "./SelectedWorkspaceValue";
import {
  useNavigateToWorkspace,
  useSyncCurrentWorkspaceIdToUrlIfMissing,
  useTabs,
  useWorkspaceOptions,
  useWorkspaces,
} from "./hooks";
import { ALL_CLUSTERS, ALL_CLUSTERS_VALUE, WorkspaceOption } from "./constants";
import { WorkspaceTabbedSelectPopover } from "./WorkspaceTabbedSelectPopover";

import KubernetesInstallationModal from "@/components/integrations/installation/kubernetes/KubernetesInstallationModal";
import { WorkspaceKind } from "@/generated/workspacesApi";
import { useDeleteWorkspace } from "@/shared/hooks/workspaces-api/workspaces/useDeleteWorkspace";
import { TypedWorkspace } from "@/shared/hooks/workspaces-api/types";
import { getEditAppViewRoute } from "@/components/appView/sections/AppView/utils/getRoutes";

const Dropdown = styled(DropDownTriggerField)`
  width: unset;
`;

export type TabType =
  | typeof ALL_CLUSTERS
  | "clusters"
  | "workspaces"
  | "clusters_group";

export const WorkspaceSelectorV2: React.FC = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const { currentWorkspaceId, workspaces, isLoading, currentWorkspace } =
    useWorkspaces();
  const [selectedTab, setSelectedTab] = useState<TabType>(
    workspaceKindToTabType(currentWorkspace?.kind)
  );
  const selectedValidTab = useMemo(() => {
    if (selectedTab === ALL_CLUSTERS) {
      return "clusters";
    }
    return selectedTab;
  }, [selectedTab]);
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [editedWorkspace, setEditedWorkspace] = useState<
    TypedWorkspace | undefined
  >(undefined);
  const close = useCallback(() => {
    setAnchorEl(null);
    setQuery("");
    setSelectedTab(workspaceKindToTabType(currentWorkspace?.kind));
  }, [currentWorkspace?.kind]);
  const {
    filteredOptions,
    clusterOptions,
    workspacesOptions,
    clusterGroupsOptions,
  } = useWorkspaceOptions(query, workspaces);
  const tabs = useTabs({
    numWorkspaces: workspacesOptions.length,
    numClusters: clusterOptions.length,
    numClusterGroups: clusterGroupsOptions.length,
  });
  const navigateToWorkspace = useNavigateToWorkspace();

  useSyncCurrentWorkspaceIdToUrlIfMissing();

  const selectWorkspace = useCallback(
    (id?: string) => {
      const workspace = workspaces?.find((w) => w.id === id);
      navigateToWorkspace(workspace);
      close();
      dispatchEvent(AnalyticEvents.Workspaces.WorkspaceSelected, {
        workspaceName: workspace?.name ?? ALL_CLUSTERS,
        workspaceKind: workspace?.kind,
      });
    },
    [close, navigateToWorkspace, workspaces]
  );

  const selectedWorkspace = useMemo(() => {
    if (!workspaces) {
      return undefined;
    }
    if (!currentWorkspaceId) {
      return ALL_CLUSTERS_VALUE;
    }
    const foundWorkspace = filteredOptions.find(
      (o) => o.value === currentWorkspaceId
    );

    return foundWorkspace ?? ALL_CLUSTERS_VALUE;
  }, [currentWorkspaceId, filteredOptions, workspaces]);
  const [isInstallationModalOpen, setIsInstallationModalOpen] = useState(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);
  const deleteWorkspaceMutation = useDeleteWorkspace();
  const onDeleteWorkspace = useCallback(
    (ok: boolean) => {
      if (ok && editedWorkspace) {
        deleteWorkspaceMutation.mutate(
          { id: editedWorkspace.id },
          {
            onSettled: () => {
              setIsDeletionDialogOpen(false);
              setEditedWorkspace(undefined);
            },
            onSuccess: () => {
              if (currentWorkspaceId === editedWorkspace.id) {
                navigateToWorkspace(undefined);
              }
            },
          }
        );
      } else {
        setIsDeletionDialogOpen(false);
      }
    },
    [
      currentWorkspaceId,
      deleteWorkspaceMutation,
      editedWorkspace,
      navigateToWorkspace,
    ]
  );

  const onEdit = useCallback(
    (option: WorkspaceOption) => {
      if (
        option.workspace &&
        option.workspace.kind !== WorkspaceKind.ClustersGroup
      ) {
        navigate(getEditAppViewRoute(option.workspace.id));
        return;
      }
      setIsAddEditModalOpen(true);
      setEditedWorkspace(option.workspace);
      datadogRum.addAction(
        AnalyticEvents.Workspaces.WorkspaceSwitcherEditWorkspaceClicked,
        { name: option.workspace?.name, kind: option.workspace?.kind }
      );
    },
    [navigate]
  );

  const onDelete = useCallback((option: WorkspaceOption) => {
    setIsDeletionDialogOpen(true);
    setEditedWorkspace(option.workspace);
  }, []);

  return (
    <div aria-label={AriaLabels.Workspaces.Selector}>
      {isDeletionDialogOpen && editedWorkspace ? (
        <WorkspaceDeletionDialog
          workspace={editedWorkspace}
          onClose={onDeleteWorkspace}
        />
      ) : null}
      {isAddEditModalOpen ? (
        <AddEditWorkspaceModal
          existingWorkspace={editedWorkspace}
          onClose={(newWorkspace) => {
            setIsAddEditModalOpen(false);
            if (newWorkspace) {
              navigateToWorkspace(newWorkspace);
            }
            setEditedWorkspace(undefined);
          }}
        />
      ) : null}
      <KubernetesInstallationModal
        closeModalCallback={() => setIsInstallationModalOpen(false)}
        isOpen={isInstallationModalOpen}
        showWelcomeStep={false}
      />
      <Dropdown
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          datadogRum.addAction(
            AnalyticEvents.Workspaces.WorkspaceSwitcherClicked
          );
        }}
        dropDownOpen={isOpen}
        size="large"
      >
        <SelectedWorkspaceValue selectedWorkspace={selectedWorkspace} />
      </Dropdown>
      <WorkspaceTabbedSelectPopover
        query={query}
        onQueryChange={setQuery}
        loading={isLoading}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={close}
        tabs={tabs}
        items={
          selectedValidTab === "clusters"
            ? clusterOptions
            : selectedValidTab === "clusters_group"
            ? clusterGroupsOptions
            : workspacesOptions
        }
        selectedTab={selectedTab}
        onSelectTab={(value) => {
          value !== ALL_CLUSTERS_VALUE.value
            ? setSelectedTab(value)
            : selectWorkspace(value);
        }}
        selectedItem={selectedWorkspace?.value ?? ""}
        onSelectItem={selectWorkspace}
        onEdit={onEdit}
        onDelete={onDelete}
        open={isOpen}
      >
        {selectedValidTab === "workspaces" ? (
          <ActionFooter
            label="Add workspace"
            onClick={() => {
              navigate(CREATE_WORKSPACE_ROUTE);
            }}
          />
        ) : null}
        {selectedValidTab === "clusters" ? (
          <ActionFooter
            label="Connect new cluster"
            onClick={() => {
              setAnchorEl(null);
              setIsInstallationModalOpen(true);
            }}
          />
        ) : null}
        {selectedValidTab === "clusters_group" ? (
          <ActionFooter
            label="Add cluster group"
            onClick={() => {
              setAnchorEl(null);
              setIsAddEditModalOpen(true);
              datadogRum.addAction(
                AnalyticEvents.Workspaces
                  .WorkspaceSwitcherAddClusterGroupClicked
              );
            }}
          />
        ) : null}
      </WorkspaceTabbedSelectPopover>
    </div>
  );
};

type ActionFooterProps = {
  label: string;
  onClick: () => void;
};

function ActionFooter({ label, onClick }: ActionFooterProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
      }}
    >
      <Button
        variant="contained"
        startIcon={<Plus16 />}
        size="small"
        sx={{ padding: "5px 8px" }}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  );
}

function workspaceKindToTabType(kind: WorkspaceKind | undefined): TabType {
  switch (kind) {
    case undefined:
    case WorkspaceKind.Cluster:
      return "clusters";
    case WorkspaceKind.ClustersGroup:
      return "clusters_group";
    case WorkspaceKind.ServiceId:
    case WorkspaceKind.Namespace:
    case WorkspaceKind.Label:
      return "workspaces";
  }
}
