import React from "react";

import { IconProps } from "../types";

export const Eye16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M7.99999 10C9.10456 10 9.99999 9.10457 9.99999 8C9.99999 6.89543 9.10456 6 7.99999 6C6.89543 6 5.99999 6.89543 5.99999 8C5.99999 9.10457 6.89543 10 7.99999 10Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5585 7.43948C15.8326 7.76861 15.8326 8.23138 15.5585 8.56052C14.4714 9.86617 11.4979 13 7.99999 13C4.50211 13 1.52854 9.86617 0.441463 8.56052C0.167421 8.23139 0.167421 7.76862 0.441462 7.43948C1.52854 6.13384 4.5021 3 7.99999 3C11.4979 3 14.4714 6.13383 15.5585 7.43948ZM11.8076 5.94796C12.7504 6.6259 13.5311 7.40747 14.061 8C13.5311 8.59253 12.7504 9.3741 11.8076 10.052C10.6184 10.9072 9.30722 11.5 7.99999 11.5C6.69277 11.5 5.38162 10.9072 4.19239 10.052C3.24959 9.3741 2.46892 8.59253 1.939 8C2.46892 7.40747 3.24959 6.6259 4.19239 5.94796C5.38162 5.09282 6.69277 4.5 7.99999 4.5C9.30722 4.5 10.6184 5.09282 11.8076 5.94796Z"
      />
    </svg>
  );
};

Eye16.defaultProps = {
  fill: "currentColor",
};
