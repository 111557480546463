import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import policiesView from "../assets/policiesView.png";
import { ClickableArea, RelativeContainer } from "../styles";

const BackButtonArean = styled(ClickableArea)`
  width: 3.2%;
  height: 2.1%;
  top: 1%;
  left: 1%;
  border-radius: 4px;
`;

export const PoliciesView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <img src={policiesView} alt={"policies page"} />
      <BackButtonArean onClick={() => navigate(-1)} />
    </RelativeContainer>
  );
};
