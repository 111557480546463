import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Title = styled(Typography).attrs({
  variant: "subtitle2",
  color: "text.primary",
})``;

const Description = styled(Typography).attrs({
  variant: "body2",
  color: "text.primary",
})``;

interface InformationProps {
  title: string;
  description: string;
  children?: React.ReactNode;
}

export const Information: React.FC<InformationProps> = ({
  title,
  description,
  children,
}) => (
  <ExplanationContainer>
    <Title>{title}</Title>
    <Description>{description}</Description>
    {children}
  </ExplanationContainer>
);
