import { OptionType } from "@komodorio/design-system/deprecated";
import { add, formatDuration, intervalToDuration, parseISO } from "date-fns";

import { User } from "../../../generated/auth";

import { RoleWithUserExpiration } from "./types";

import {
  formatDistanceLocale,
  timeTokens,
} from "@/components/Settings/Users/const";

export const validateUserRoles = (roles: OptionType<string>[] | undefined) =>
  !roles?.length ? "Please select at least one role" : undefined;

export const validateUserTempRoles = (role: OptionType<string> | undefined) =>
  !role?.value
    ? "Please select role or remove temporary role section"
    : undefined;

export const getFilteredUserRoles = (u: User, temporaryRoles: boolean) => {
  return u.rbacRoles
    ?.map((rbacRole) => {
      const r = u.rbacUserRoles?.find(
        (rbacUserRole) => rbacUserRole.roleId === rbacRole.id
      );
      if (r) {
        return { ...rbacRole, expiration: r.expiration };
      }
      return null;
    })
    .filter((role): role is RoleWithUserExpiration => role !== null)
    .filter((role) =>
      temporaryRoles ? role.expiration !== null : role.expiration === null
    );
};

const getShortLocale = () => ({
  formatDistance: (token: timeTokens, count: string) =>
    formatDistanceLocale[token]?.replace("{{count}}", count),
});
export const getTimeLeft = (targetDate: string) => {
  const duration = intervalToDuration({
    start: parseISO(targetDate),
    end: new Date(),
  });
  return formatDuration(duration, {
    format: ["years", "months", "days", "hours", "minutes"],
    locale: getShortLocale(),
  });
};

export const getNextDay = () => {
  return add(new Date(), { days: 1 });
};
