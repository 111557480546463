import React from "react";

import { IconProps } from "../types";

export const Close16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.72007 3.71997C3.8607 3.57952 4.05132 3.50063 4.25007 3.50063C4.44882 3.50063 4.63945 3.57952 4.78007 3.71997L8.00007 6.93997L11.2201 3.71997C11.2887 3.64628 11.3715 3.58718 11.4635 3.54619C11.5555 3.5052 11.6548 3.48316 11.7556 3.48138C11.8563 3.4796 11.9563 3.49813 12.0497 3.53585C12.1431 3.57357 12.2279 3.62971 12.2991 3.70093C12.3703 3.77215 12.4265 3.85698 12.4642 3.95037C12.5019 4.04376 12.5204 4.14379 12.5187 4.24449C12.5169 4.3452 12.4948 4.44451 12.4539 4.53651C12.4129 4.62851 12.3538 4.71131 12.2801 4.77997L9.06007 7.99997L12.2801 11.22C12.3538 11.2886 12.4129 11.3714 12.4539 11.4634C12.4948 11.5554 12.5169 11.6547 12.5187 11.7554C12.5204 11.8562 12.5019 11.9562 12.4642 12.0496C12.4265 12.143 12.3703 12.2278 12.2991 12.299C12.2279 12.3702 12.1431 12.4264 12.0497 12.4641C11.9563 12.5018 11.8563 12.5203 11.7556 12.5186C11.6548 12.5168 11.5555 12.4947 11.4635 12.4538C11.3715 12.4128 11.2887 12.3537 11.2201 12.28L8.00007 9.05997L4.78007 12.28C4.6379 12.4124 4.44985 12.4846 4.25555 12.4811C4.06125 12.4777 3.87586 12.399 3.73845 12.2616C3.60104 12.1242 3.52233 11.9388 3.5189 11.7445C3.51547 11.5502 3.58759 11.3621 3.72007 11.22L6.94007 7.99997L3.72007 4.77997C3.57962 4.63935 3.50073 4.44872 3.50073 4.24997C3.50073 4.05122 3.57962 3.8606 3.72007 3.71997Z"
      />
    </svg>
  );
};

Close16.defaultProps = {
  fill: "currentColor",
};
