import { useMemo } from "react";

const SWIMLANE_HEIGHT = 4.375;
const X_AXIS_HEIGHT = 3;
const PAGINATION_BAR_HEIGHT = 3;

export const useDynamicChartHeight = (
  numOfSwimlanes: number,
  paginateSwimlanes: boolean,
  hideXAxis: boolean
) => {
  return useMemo(() => {
    let height = numOfSwimlanes * SWIMLANE_HEIGHT;
    if (paginateSwimlanes && numOfSwimlanes > 0) {
      height += PAGINATION_BAR_HEIGHT;
    }
    if (!hideXAxis) {
      height += X_AXIS_HEIGHT;
    }
    return height + "rem";
  }, [hideXAxis, numOfSwimlanes, paginateSwimlanes]);
};
