import React from "react";

import { LogoIconProps } from "../types";

export const Azure: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Azure logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M15.5718 17.3907C15.5499 17.3957 13.6398 17.7594 11.3272 18.1989L7.12255 18.9981L14.0613 18.999L21 19L20.9716 18.9465C20.9559 18.9171 19.2728 15.7783 17.2313 11.9714C15.0507 7.90522 13.5165 5.05543 13.5122 5.06337C13.4996 5.08714 11.2451 11.7665 11.2451 11.7803C11.2451 11.7876 12.2276 13.0508 13.4284 14.5876L15.6117 17.3816L15.5718 17.3907Z"
        fill="#0089D6"
      />
      <path
        d="M5.28614 13.0772C4.02876 15.4216 3 17.343 3 17.3469C3 17.3508 3.92977 17.3528 5.06616 17.3513L7.13232 17.3485L9.97369 10.7921C11.5365 7.18602 12.8383 4.18182 12.8667 4.11605C12.8951 4.05027 12.9143 3.99808 12.9095 4.00005C12.9047 4.00203 11.7018 5.08612 10.2365 6.40914L7.57228 8.81463L5.28614 13.0772Z"
        fill="#0089D6"
      />
    </svg>
  );
};
