import React from "react";

import { IconProps } from "../types";

export const Storage16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.8C15 2.2536 11.866 1 8 1C4.13401 1 1 2.2536 1 3.8V12.2C1 13.7464 4.13401 15 8 15C11.866 15 15 13.7464 15 12.2V3.8ZM13.5 3.88591C13.4843 3.86614 13.4589 3.83748 13.4192 3.79995C13.2487 3.63867 12.9214 3.42433 12.3927 3.21282C11.3434 2.79312 9.79102 2.5 8 2.5C6.20898 2.5 4.65659 2.79312 3.60734 3.21282C3.07855 3.42433 2.75129 3.63867 2.58076 3.79995C2.54107 3.83748 2.51567 3.86614 2.5 3.88591V4.11409C2.51567 4.13386 2.54107 4.16252 2.58076 4.20005C2.75129 4.36133 3.07855 4.57567 3.60734 4.78718C4.65659 5.20688 6.20898 5.5 8 5.5C9.79102 5.5 11.3434 5.20688 12.3927 4.78718C12.9214 4.57567 13.2487 4.36133 13.4192 4.20005C13.4589 4.16252 13.4843 4.13386 13.5 4.11409V3.88591ZM13.5 5.93224C12.2182 6.58253 10.231 7 8 7C5.76904 7 3.78183 6.58253 2.5 5.93224V8.11409C2.51567 8.13386 2.54107 8.16252 2.58076 8.20005C2.75129 8.36133 3.07855 8.57567 3.60734 8.78718C4.65659 9.20688 6.20898 9.5 8 9.5C9.79102 9.5 11.3434 9.20688 12.3927 8.78718C12.9214 8.57567 13.2487 8.36133 13.4192 8.20005C13.4589 8.16252 13.4843 8.13386 13.5 8.11409V5.93224ZM2.5 12.1141V9.93224C3.78183 10.5825 5.76904 11 8 11C10.231 11 12.2182 10.5825 13.5 9.93224V12.1141C13.4843 12.1339 13.4589 12.1625 13.4192 12.2001C13.2487 12.3613 12.9214 12.5757 12.3927 12.7872C11.3434 13.2069 9.79102 13.5 8 13.5C6.20898 13.5 4.65659 13.2069 3.60734 12.7872C3.07855 12.5757 2.75129 12.3613 2.58076 12.2001C2.54107 12.1625 2.51567 12.1339 2.5 12.1141ZM13.519 3.91243C13.5216 3.91671 13.5226 3.91886 13.5224 3.91886L13.5216 3.91775C13.521 3.91671 13.5201 3.91493 13.519 3.91243Z"
      />
    </svg>
  );
};

Storage16.defaultProps = {
  fill: "currentColor",
};
