import React from "react";

import { IconProps } from "../types";

export const ChevronUp24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.78 15.28C18.9205 15.1393 18.9994 14.9487 18.9994 14.75C18.9994 14.5512 18.9205 14.3606 18.78 14.22L12.53 7.96995C12.3894 7.8295 12.1988 7.75061 12 7.75061C11.8013 7.75061 11.6107 7.8295 11.47 7.96995L5.22003 14.22C5.14634 14.2886 5.08724 14.3714 5.04625 14.4634C5.00526 14.5554 4.98322 14.6547 4.98144 14.7554C4.97966 14.8561 4.99819 14.9562 5.03591 15.0495C5.07363 15.1429 5.12977 15.2278 5.20099 15.299C5.27221 15.3702 5.35705 15.4264 5.45043 15.4641C5.54382 15.5018 5.64385 15.5203 5.74455 15.5185C5.84526 15.5168 5.94457 15.4947 6.03657 15.4537C6.12857 15.4127 6.21137 15.3536 6.28003 15.28L12 9.55995L17.72 15.28C17.8607 15.4204 18.0513 15.4993 18.25 15.4993C18.4488 15.4993 18.6394 15.4204 18.78 15.28Z"
      />
    </svg>
  );
};

ChevronUp24.defaultProps = {
  fill: "currentColor",
};
