import React from "react";

import { IconProps } from "../types";

export const Workloads16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M1.5 5V13C1.5 13.2761 1.72386 13.5 2 13.5H9.29076C9.52556 14.08 9.89247 14.5925 10.3542 15H2C0.895431 15 0 14.1046 0 13V4C0 2.89543 0.895431 2 2 2H14C15.1046 2 16 2.89543 16 4V9.35418C15.5925 8.89247 15.08 8.52556 14.5 8.29076V5H1.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2308 11.6939V12.3061L15.9124 12.7959C15.9743 12.7959 15.9743 12.8571 15.9124 12.9184L15.2927 13.9592C15.2307 14.0204 15.1688 14.0204 15.1068 14.0204L14.3632 13.7755C14.2703 13.8367 14.1928 13.8827 14.1154 13.9286C14.0379 13.9745 13.9605 14.0204 13.8675 14.0816L13.7436 14.8776C13.7436 14.9388 13.6816 15 13.6197 15H12.3803C12.3184 15 12.2564 14.9388 12.1945 14.8776L12.0705 14.0816C11.8846 14.0204 11.6987 13.898 11.5748 13.7755L10.8312 14.0816C10.7693 14.0816 10.7073 14.0816 10.6453 14.0204L10.0257 12.9796C9.9637 12.9184 10.0257 12.8571 10.0876 12.7959L10.7692 12.3061V11.6939L10.0876 11.2041C10.0257 11.1429 10.0257 11.0816 10.0257 11.0204L10.6453 9.97959C10.7073 9.91837 10.7693 9.91837 10.8312 9.91837L11.5748 10.2245C11.6677 10.1633 11.7452 10.1173 11.8227 10.0714C11.9001 10.0255 11.9776 9.97959 12.0705 9.91837L12.1945 9.12245C12.1945 9.06122 12.2564 9 12.3803 9H13.6197C13.6816 9 13.7436 9.06122 13.7436 9.12245L13.8675 9.91837C14.0534 9.97959 14.2393 10.102 14.4252 10.2245L15.1688 9.91837C15.2308 9.91837 15.3547 9.91837 15.3547 9.97959L15.9743 11.0204C16.0363 11.0816 15.9743 11.1429 15.9124 11.2041L15.2308 11.6939ZM12.0086 11.9388C12.0086 12.4286 12.4423 12.8571 12.938 12.8571C13.4338 12.8571 13.8675 12.4286 13.8675 11.9388C13.8675 11.449 13.4338 11.0204 12.938 11.0204C12.4423 11.0204 12.0086 11.449 12.0086 11.9388Z"
      />
    </svg>
  );
};

Workloads16.defaultProps = {
  fill: "currentColor",
};
