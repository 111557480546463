import React from "react";

import useDateFormatter from "../../../../shared/hooks/useDateFormatter";
import {
  ONE_DAY_IN_MS,
  ONE_MINUTE_IN_MS,
} from "../../../../shared/constants/time";

import { TickProps, calculateOffset } from "./tickUtils";
import { TextGroup } from "./TextGroup";
import { TSpan } from "./TSpan";

export type TickComponent = React.FC<TickProps>;

const LAST_TICK_OFFSET_IN_MINUTES = 5;

const useTickPropsData = ({
  x,
  y,
  payload,
  index,
  visibleTicksCount,
  orientation,
  lastTickXOffset,
}: TickProps) => {
  const { formatToParts, format } = useDateFormatter();
  if (!x || !y || !payload || !Number.isFinite(payload.value)) {
    return null;
  }
  const eventDate = new Date(payload.value);
  const parts = new Map(
    formatToParts(eventDate).map(({ type, value }) => [type, value])
  );
  const xOffset = calculateOffset(index, visibleTicksCount, lastTickXOffset);
  let yOffset = 0;
  if (orientation === "top") {
    yOffset = -25;
  }
  return {
    formattedDate: format(eventDate),
    xOffset,
    yOffset,
    parts,
  };
};

const getFormattedDateFromParts = (parts: Map<string, string>) => {
  return `${parts.get("hour")}:${parts.get("minute")} ${parts.get(
    "dayPeriod"
  )}`;
};

export const HourTick: TickComponent = (props) => {
  const {
    parts,
    xOffset = 0,
    yOffset = 0,
    formattedDate,
  } = useTickPropsData(props) ?? {};
  if (!parts || !props.x || !props.y || !formattedDate) {
    return null;
  }

  const formattedText = getLastTickText(props);
  if (formattedText) {
    return (
      <SimpleTextTick
        text={formattedText}
        x={props.x + xOffset}
        y={props.y + yOffset}
        title={formattedDate}
      />
    );
  }

  return (
    <TextGroup
      x={props.x + xOffset}
      y={props.y + yOffset}
      fullText={formattedDate}
    >
      <TSpan dy={16} text={getFormattedDateFromParts(parts)} />
    </TextGroup>
  );
};

export const DateHourTick: TickComponent = (props) => {
  const {
    parts,
    xOffset = 0,
    yOffset = 0,
    formattedDate,
  } = useTickPropsData(props) ?? {};
  if (!parts || !props.x || !props.y || !formattedDate) {
    return null;
  }

  const formattedText = getLastTickText(props);
  if (formattedText) {
    return (
      <SimpleTextTick
        text={formattedText}
        x={props.x + xOffset}
        y={props.y + yOffset}
        title={formattedDate}
      />
    );
  }

  return (
    <TextGroup
      x={props.x + xOffset}
      y={props.y + yOffset}
      fullText={formattedDate}
    >
      <TSpan dy={12} text={`${parts.get("month")} ${parts.get("day")}`} />
      <TSpan dy={12} text={getFormattedDateFromParts(parts)} />
    </TextGroup>
  );
};

export const DateTick: TickComponent = (props) => {
  const {
    parts,
    xOffset = 0,
    yOffset = 0,
    formattedDate,
  } = useTickPropsData(props) ?? {};
  if (!parts || !props.x || !props.y || !formattedDate) {
    return null;
  }

  return (
    <TextGroup
      x={props.x + xOffset}
      y={props.y + yOffset}
      fullText={formattedDate}
    >
      <TSpan dy={12} text={`${parts.get("month")} ${parts.get("day")}`} />
    </TextGroup>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const getTickComponent = (
  startTime: Date,
  endTime: Date
): TickComponent => {
  const timeRange = endTime.getTime() - startTime.getTime();
  return timeRange <= ONE_DAY_IN_MS ? HourTick : DateHourTick;
};

const SimpleTextTick: React.FC<{
  x: number;
  y: number;
  text: string;
  title: string;
}> = ({ x, y, text, title }) => {
  return (
    <TextGroup x={x} y={y} fullText={title}>
      <tspan
        x={0}
        dy={12}
        textAnchor="right"
        style={{ fontWeight: 700, userSelect: "none" }}
      >
        {text}
      </tspan>
    </TextGroup>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const lastTickFormatter = (value: string | number | Date): string => {
  const isTickNumber = typeof value === "number";
  const isTickAlmostNow =
    isTickNumber &&
    Math.abs(value - Date.now()) <
      LAST_TICK_OFFSET_IN_MINUTES * ONE_MINUTE_IN_MS;
  if (isTickAlmostNow) {
    return "now";
  }
  return "";
};

const getLastTickText = (props: TickProps) => {
  const isLastTick = props.index === (props.visibleTicksCount ?? 0) - 1;
  if (
    props.tickFormatter &&
    props.payload?.value &&
    isLastTick &&
    props.index
  ) {
    return props.tickFormatter(props.payload?.value, props.index);
  }
  return null;
};
