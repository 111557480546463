import React from "react";
import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
// eslint-disable-next-line import/no-unresolved
import { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";

type EmptyHistogramProps = {
  barChartProps?: CategoricalChartProps;
};

const fakeDataOutput = Array.from({ length: 40 }).map((data, idx) => ({
  name: idx.toString(),
  "empty bar": Math.max(Math.round(Math.random() * 5), 1),
}));
export const EmptyHistogram: React.FC<EmptyHistogramProps> = ({
  barChartProps,
}: EmptyHistogramProps) => {
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <BarChart
        {...barChartProps}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        data={fakeDataOutput}
      >
        <Bar
          dataKey={"empty bar"}
          fill={"#C9CBCF"}
          barSize={10}
          fillOpacity={0.2}
        />
        <XAxis dataKey="name" tick={false} />
      </BarChart>
    </ResponsiveContainer>
  );
};
