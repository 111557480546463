import React from "react";
import styled from "styled-components";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";

const selectOptions: MuiSelectionOption<string>[] = [
  { label: "Open", value: "Open" },
  { label: "Confirmed", value: "Confirmed" },
  { label: "Dismiss", value: "Dismiss" },
  { label: "Resolved", value: "Resolved" },
];
const listColor = muiColors.gray[50];

const SelectContainer = styled.div`
  & .dropDownTriggerField {
    height: auto;
    width: 110px;
    background-color: ${listColor};
    border-color: ${muiColors.gray[200]};
    pointer-events: none;
  }
  & .menuList {
    background-color: ${listColor};
  }
  & svg {
    color: ${muiColors.gray[800]};
  }
`;

export const ActionsSelect: React.FC = () => {
  return (
    <SelectContainer>
      <SingleSelect
        options={selectOptions}
        defaultValue={selectOptions[0]}
        classNames={{
          dropDownTriggerField: "dropDownTriggerField",
          menuList: "menuList",
        }}
      />
    </SelectContainer>
  );
};
