import React from "react";

import { IconProps } from "../types";

export const ClockFilled16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8.5 3.75C8.5 3.55109 8.42098 3.36032 8.28033 3.21967C8.13968 3.07902 7.94891 3 7.75 3C7.55109 3 7.36032 3.07902 7.21967 3.21967C7.07902 3.36032 7 3.55109 7 3.75V8.25C7.00003 8.39978 7.04492 8.54612 7.12886 8.67017C7.21281 8.79421 7.33197 8.89028 7.471 8.946L10.971 10.946C11.1541 11.0129 11.3561 11.0058 11.5341 10.9263C11.7121 10.8468 11.8522 10.7011 11.9246 10.5201C11.997 10.3391 11.9961 10.137 11.9221 9.95669C11.8481 9.77634 11.7067 9.63191 11.528 9.554L8.5 7.742V3.75Z"
      />
    </svg>
  );
};

ClockFilled16.defaultProps = {
  fill: "currentColor",
};
