import React from "react";

import { IconProps } from "../types";

export const Nodes16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M3 3.5C3 2.94772 3.44772 2.5 4 2.5C4.55228 2.5 5 2.94772 5 3.5C5 4.05228 4.55228 4.5 4 4.5C3.44772 4.5 3 4.05228 3 3.5Z" />
      <path d="M6 3.5C6 2.94772 6.44772 2.5 7 2.5C7.55228 2.5 8 2.94772 8 3.5C8 4.05228 7.55228 4.5 7 4.5C6.44772 4.5 6 4.05228 6 3.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1V6C16 6.55228 15.5523 7 15 7H1C0.447716 7 0 6.55228 0 6V1ZM1.5 5.5V1.5H14.5V5.5H1.5Z"
      />
      <path d="M4 11.5C3.44772 11.5 3 11.9477 3 12.5C3 13.0523 3.44772 13.5 4 13.5C4.55228 13.5 5 13.0523 5 12.5C5 11.9477 4.55228 11.5 4 11.5Z" />
      <path d="M7 11.5C6.44772 11.5 6 11.9477 6 12.5C6 13.0523 6.44772 13.5 7 13.5C7.55228 13.5 8 13.0523 8 12.5C8 11.9477 7.55228 11.5 7 11.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 9.44772 0.447715 9 1 9H15C15.5523 9 16 9.44772 16 10V15C16 15.5523 15.5523 16 15 16H1C0.447716 16 0 15.5523 0 15V10ZM1.5 14.5V10.5H14.5V14.5H1.5Z"
      />
    </svg>
  );
};

Nodes16.defaultProps = {
  fill: "currentColor",
};
