import React, { useCallback, useEffect, useState } from "react";
import MenuList from "@mui/material/MenuList";

import { selectAriaLabels } from "components/komodor-ui/Select/shared/ariaLabels";
import { SearchBar } from "components/komodor-ui/Select/shared/components/SearchBar/SearchBar";
import { SelectPopover } from "components/komodor-ui/Select/shared/components/SelectPopOver";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "components/komodor-ui/Select/shared/types";
import { placeholderOptionsValue } from "components/komodor-ui/Select/SingleSelect/singleSelectConstants";
import { SingleSelectPopoverProps } from "components/komodor-ui/Select/SingleSelect/singleSelectTypes";
import { useMenuItems } from "components/komodor-ui/Select/SingleSelectPopover/SingleSelectHooks";

export const SingleSelectPopover = <T extends MuiSelectionOptionValue>({
  anchorEl,
  options,
  value,
  defaultValue,
  onChange,
  onClose,
  texts,
  classNames,
  enableSearch = false,
  customOptionElement,
  menuListTrailingElement,
  selectPopoverProps,
}: SingleSelectPopoverProps<T>) => {
  const [localValue, setLocalValue] = useState(defaultValue || value);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const onItemSelect = useCallback(
    (value: T) => {
      let selectedOption: MuiSelectionOption<T> | undefined = undefined;
      if (value !== placeholderOptionsValue) {
        selectedOption = options.find((option) => option.value === value);
      }
      setLocalValue(selectedOption);
      onChange?.(selectedOption);
      onClose?.();
    },
    [onChange, options]
  );

  const dropdownOptions = useMenuItems({
    options,
    filterString: searchTerm,
    selectedOption: localValue,
    onSelect: onItemSelect,
    customOptionElement,
  });

  const onSearchTermChange = (searchString: string) =>
    setSearchTerm(searchString);

  return (
    <SelectPopover
      anchorEl={anchorEl}
      onClose={onClose}
      {...selectPopoverProps}
    >
      <div>
        {enableSearch && (
          <SearchBar
            searchValue={searchTerm}
            onSearch={onSearchTermChange}
            className={classNames?.searchBar}
            searchPlaceholder={texts?.searchPlaceholder}
          />
        )}
        <MenuList
          className={classNames?.menuList}
          aria-label={selectAriaLabels.menuList}
        >
          {dropdownOptions}
          {menuListTrailingElement}
        </MenuList>
      </div>
    </SelectPopover>
  );
};
