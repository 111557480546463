import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";

import { LoadingApp } from "@/LoadingApp";

export const OverridableFlagsContext = createContext({
  flags: {},
  setFlag: noop,
});

export const OverridableFlags: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const ldFlags = useFlags();
  const [overrides, setOverrides] = useState<Record<string, unknown>>({});
  const flags = useMemo(() => {
    return { ...ldFlags, ...overrides };
  }, [ldFlags, overrides]);
  const client = useLDClient();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    client?.waitUntilReady().then(() => {
      setReady(true);
    });
  }, [client]);

  const value = useMemo(
    () => ({
      flags,
      setFlag(flag: string, value: unknown) {
        setOverrides((prev) => ({ ...prev, [flag]: value }));
      },
    }),
    [flags]
  );

  return (
    <OverridableFlagsContext.Provider value={value}>
      {!ready ? <LoadingApp /> : children}
    </OverridableFlagsContext.Provider>
  );
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useOverridableFlags = (): Record<string, unknown> =>
  useContext(OverridableFlagsContext).flags;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useSetFlag = (): ((flag: string, value: unknown) => void) =>
  useContext(OverridableFlagsContext).setFlag;
