import React from "react";

import { IconProps } from "../types";

export const CheckCircleOutlined16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8ZM11.78 6.28C11.9125 6.13783 11.9846 5.94978 11.9812 5.75548C11.9777 5.56118 11.899 5.37579 11.7616 5.23838C11.6242 5.10097 11.4388 5.02225 11.2445 5.01883C11.0502 5.0154 10.8622 5.08752 10.72 5.22L6.75 9.19L5.28 7.72C5.13783 7.58752 4.94978 7.5154 4.75548 7.51883C4.56118 7.52225 4.37579 7.60097 4.23838 7.73838C4.10097 7.87579 4.02225 8.06118 4.01883 8.25548C4.0154 8.44978 4.08752 8.63783 4.22 8.78L6.22 10.78C6.36062 10.9205 6.55125 10.9993 6.75 10.9993C6.94875 10.9993 7.13938 10.9205 7.28 10.78L11.78 6.28Z"
      />
    </svg>
  );
};

CheckCircleOutlined16.defaultProps = {
  fill: "currentColor",
};
