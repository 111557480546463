import React from "react";

import { IconProps } from "../types";

export const Refresh24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M3.38 8C4.14302 6.35687 5.35986 4.96612 6.88711 3.99166C8.41436 3.01719 10.1884 2.49964 12 2.5C14.1165 2.49987 16.1724 3.20635 17.8417 4.5074C19.5111 5.80845 20.6982 7.62959 21.215 9.682C21.2633 9.87508 21.3863 10.0411 21.5569 10.1435C21.7276 10.2459 21.9319 10.2763 22.125 10.228C22.3181 10.1797 22.4841 10.0567 22.5865 9.88608C22.6889 9.71542 22.7193 9.51108 22.671 9.318C21.473 4.539 17.15 1 12 1C10.0781 0.99922 8.18964 1.50221 6.52277 2.45881C4.8559 3.41542 3.46888 4.79224 2.5 6.452V4.75C2.5 4.55109 2.42098 4.36032 2.28033 4.21967C2.13968 4.07902 1.94891 4 1.75 4C1.55109 4 1.36032 4.07902 1.21967 4.21967C1.07902 4.36032 1 4.55109 1 4.75V8.5C1 8.76522 1.10536 9.01957 1.29289 9.20711C1.48043 9.39464 1.73478 9.5 2 9.5H5.75C5.94891 9.5 6.13968 9.42098 6.28033 9.28033C6.42098 9.13968 6.5 8.94891 6.5 8.75C6.5 8.55109 6.42098 8.36032 6.28033 8.21967C6.13968 8.07902 5.94891 8 5.75 8H3.38ZM2.785 14.318C2.7344 14.1276 2.61091 13.9647 2.44122 13.8646C2.27153 13.7645 2.06926 13.7352 1.87814 13.783C1.68701 13.8309 1.52237 13.952 1.41981 14.1202C1.31724 14.2884 1.28498 14.4902 1.33 14.682C2.527 19.461 6.85 23 12 23C16.052 23 19.592 20.809 21.5 17.549V19.25C21.5 19.4489 21.579 19.6397 21.7197 19.7803C21.8603 19.921 22.0511 20 22.25 20C22.4489 20 22.6397 19.921 22.7803 19.7803C22.921 19.6397 23 19.4489 23 19.25V15.5C23 15.2348 22.8946 14.9804 22.7071 14.7929C22.5196 14.6054 22.2652 14.5 22 14.5H18.25C18.0511 14.5 17.8603 14.579 17.7197 14.7197C17.579 14.8603 17.5 15.0511 17.5 15.25C17.5 15.4489 17.579 15.6397 17.7197 15.7803C17.8603 15.921 18.0511 16 18.25 16H20.62C19.857 17.6431 18.6401 19.0339 17.1129 20.0083C15.5856 20.9828 13.8116 21.5004 12 21.5C7.554 21.5 3.819 18.445 2.785 14.318Z" />
    </svg>
  );
};

Refresh24.defaultProps = {
  fill: "currentColor",
};
