import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { useRiskManagementContext } from "../../../../../context/RiskManagementContext";
import { ReliabilityCheckCategory } from "../../../../../riskManagementTypes";

const Container = styled.div``;

type ActiveChecksStatusProps = {
  category: ReliabilityCheckCategory;
};

export const ActiveChecksStatus: React.FC<ActiveChecksStatusProps> = ({
  category,
}) => {
  const { reliabilityChecksData } = useRiskManagementContext();

  const activeChecks = useMemo(() => {
    return reliabilityChecksData[category].reduce((acc, { active }) => {
      active && acc++;
      return acc;
    }, 0);
  }, [category, reliabilityChecksData]);

  return (
    <Container>
      <Typography
        variant={"body3"}
        color={"text.secondary"}
      >{`${activeChecks} out of ${reliabilityChecksData[category].length} checks are active`}</Typography>{" "}
    </Container>
  );
};
