import React, { createContext, useContext, useState } from "react";
import { cloneDeep } from "lodash";

import {
  ReliabilityCheckByCategory,
  ReliabilityCheckData,
} from "../riskManagementTypes";

type RiskManagementContextState = {
  isRiskDrawerOpen: boolean;
  setIsRiskDrawerOpen: (val: boolean) => void;
  reliabilityChecksData: ReliabilityCheckByCategory;
  setReliabilityChecksData: (data: ReliabilityCheckByCategory) => void;
  updateReliabilityCheck: (data: ReliabilityCheckData) => void;
  isTopSectionShown: boolean;
  setIsTopSectionShown: (val: boolean) => void;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialState: RiskManagementContextState = {
  isRiskDrawerOpen: false,
  setIsRiskDrawerOpen: () => undefined,
  reliabilityChecksData: {
    "Infrastructure issues": [],
    Standardization: [],
    "Resource optimization": [],
  },
  setReliabilityChecksData: () => undefined,
  updateReliabilityCheck: () => undefined,
  isTopSectionShown: true,
  setIsTopSectionShown: () => undefined,
};

const {
  isRiskDrawerOpen: initialRiskDrawerOpen,
  reliabilityChecksData: initialChecksData,
  isTopSectionShown: initialIsTopSectionShown,
} = initialState;

const RiskManagementContext =
  createContext<RiskManagementContextState>(initialState);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useRiskManagementContext = (): RiskManagementContextState =>
  useContext(RiskManagementContext);

export const RiskManagementContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isRiskDrawerOpen, setIsRiskDrawerOpen] = useState<boolean>(
    initialRiskDrawerOpen
  );
  const [reliabilityChecksData, setReliabilityChecksData] =
    useState<ReliabilityCheckByCategory>(initialChecksData);

  const [isTopSectionShown, setIsTopSectionShown] = useState<boolean>(
    initialIsTopSectionShown
  );

  const updateReliabilityCheck = (data: ReliabilityCheckData) => {
    setReliabilityChecksData((prevData) => {
      const entryIndex = prevData[data.category].findIndex(
        (entry) => entry.id === data.id
      );
      if (entryIndex === -1) return prevData;

      const clone = cloneDeep(prevData);
      clone[data.category][entryIndex] = data;

      return clone;
    });
  };

  const contextState: RiskManagementContextState = {
    isRiskDrawerOpen,
    setIsRiskDrawerOpen,
    reliabilityChecksData,
    setReliabilityChecksData,
    updateReliabilityCheck,
    isTopSectionShown,
    setIsTopSectionShown,
  };

  return (
    <RiskManagementContext.Provider value={contextState}>
      <div>{children}</div>
    </RiskManagementContext.Provider>
  );
};
