import React from "react";
import { ReferenceLine } from "recharts";
import styled from "styled-components";
import { Close16 } from "@komodorio/design-system/icons";

import { grayReferenceLine } from "../../../../Colors";

import { LineGroup } from "./groupEventsByLine";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const ServiceLabel = styled.div`
  background-color: #dce3ef;
  width: max-content;
  padding: 0.25em 0.5em;
  margin-inline-start: 0.3rem;
  font-size: 12px;
  font-weight: 500;
  border-radius: 0.25em 0.25em 0.25em 0;
  position: relative;
  user-select: none;
  white-space: nowrap;
  display: flex;
  align-content: center;
`;

interface ReferenceLineLabelProps {
  viewBox: {
    x: number;
    y: number;
  };
}

const LABEL_OFFSET = 40;
const renderLabel =
  (
    prefix: string | null,
    name: string,
    hoveredLabel?: string,
    setHoveredLabel?: (label: string | undefined) => void,
    removeSwimlane?: (label: string) => void
  ) =>
  ({ viewBox }: ReferenceLineLabelProps) => {
    const dy = LABEL_OFFSET;
    return (
      <foreignObject x={viewBox.x} y={viewBox.y - dy} overflow="visible">
        <div
          style={{ display: "flex", width: "max-content" }}
          onMouseEnter={() => {
            setHoveredLabel?.(name);
          }}
          onMouseLeave={() => {
            setHoveredLabel?.(undefined);
          }}
        >
          <ServiceLabel>{prefix ? prefix + name : name}</ServiceLabel>

          {/* 
            The button will render for all the swimlanes, but can only be made visible for those that had been added
            via the related resource selector. 
            The reason for this is that the button dictates the size of each label,
            and since we want similar size for all labels, we choose to render the button for all labels and keep them
            invisible.
          */}
          <SwimlaneRemovalButton
            showButton={hoveredLabel === name}
            onClick={() => {
              removeSwimlane?.(name);
            }}
          />
        </div>
      </foreignObject>
    );
  };

const createReferenceLines = (
  lineGroups: LineGroup[],
  hoveredLabel?: string,
  setHoveredLabel?: (label: string | undefined) => void,
  removeSwimlane?: (label: string) => void
): JSX.Element[] => {
  return lineGroups.map((g, index) => {
    return (
      <ReferenceLine
        key={g.label}
        stroke={grayReferenceLine}
        pointerEvents="none"
        y={index + 1}
        label={renderLabel(
          g.prefix,
          g.label,
          hoveredLabel,
          setHoveredLabel,
          removeSwimlane
        )}
      />
    );
  });
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const SwimlaneRemovalButton: React.FC<{
  onClick: () => void;
  showButton?: boolean;
}> = ({ onClick, showButton = false }) => (
  <ServiceLabel
    style={{
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
      borderRadius: "0.25em",
      visibility: showButton ? undefined : "hidden",
    }}
  >
    <Close16 onClick={onClick} />
  </ServiceLabel>
);

export default createReferenceLines;
