import React from "react";

import { IconProps } from "../types";

export const Logout16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M8.99999 2.5L2.99999 2.5C2.72385 2.5 2.49999 2.72386 2.49999 3L2.49999 13C2.49999 13.2761 2.72385 13.5 2.99999 13.5L8.99999 13.5C9.27613 13.5 9.49999 13.2761 9.49999 13L9.49999 12L11 12L11 13C11 14.1046 10.1046 15 8.99999 15L2.99999 15C1.89542 15 0.999988 14.1046 0.999988 13L0.999988 3C0.999989 1.89543 1.89542 1 2.99999 1L8.99999 1C10.1046 1 11 1.89543 11 3L11 4L9.49999 4L9.49999 3C9.49999 2.72386 9.27613 2.5 8.99999 2.5Z" />
      <path d="M12.0007 11.0179C11.8019 11.0179 11.6113 10.939 11.4707 10.7986C11.3302 10.658 11.2513 10.4673 11.2513 10.2686C11.2513 10.0698 11.3302 9.87922 11.4707 9.73859L12.4407 8.76859L6.00066 8.76859C5.80175 8.76859 5.61098 8.68957 5.47033 8.54892C5.32968 8.40827 5.25066 8.2175 5.25066 8.01859C5.25066 7.81968 5.32968 7.62891 5.47033 7.48826C5.61098 7.34761 5.80175 7.26859 6.00066 7.26859L12.4407 7.26859L11.4707 6.29859C11.397 6.22993 11.3379 6.14713 11.2969 6.05513C11.2559 5.96313 11.2338 5.86382 11.2321 5.76311C11.2303 5.66241 11.2488 5.56238 11.2865 5.46899C11.3243 5.3756 11.3804 5.29077 11.4516 5.21955C11.5228 5.14833 11.6077 5.09219 11.7011 5.05447C11.7945 5.01675 11.8945 4.99822 11.9952 5C12.0959 5.00178 12.1952 5.02382 12.2872 5.06481C12.3792 5.1058 12.462 5.1649 12.5307 5.23859L14.7807 7.48859C14.9211 7.62922 15 7.81984 15 8.01859C15 8.21734 14.9211 8.40797 14.7807 8.54859L12.5307 10.7986C12.39 10.939 12.1994 11.0179 12.0007 11.0179Z" />
    </svg>
  );
};

Logout16.defaultProps = {
  fill: "currentColor",
};
