"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KubernetesEventBody = void 0;
const runtypes_1 = require("runtypes");
exports.KubernetesEventBody = (0, runtypes_1.Record)({
    action: runtypes_1.String,
    agentId: (0, runtypes_1.Optional)(runtypes_1.String),
    eventId: runtypes_1.String,
    clusterName: (0, runtypes_1.Optional)(runtypes_1.String),
    kind: runtypes_1.String,
    apiVersion: (0, runtypes_1.Optional)(runtypes_1.String),
    timestamp: runtypes_1.String,
    data: runtypes_1.Unknown,
});
