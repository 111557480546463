import React from "react";

import { IconProps } from "../types";

export const ArrowSouthEast16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.04028 12.1025C5.04028 11.6883 5.37606 11.3525 5.79028 11.3525L10.3436 11.3525L3.84573 4.85467C3.55284 4.56177 3.55284 4.0869 3.84573 3.79401C4.13863 3.50111 4.6135 3.50111 4.90639 3.79401L11.4042 10.2919L11.4042 5.73855C11.4042 5.32434 11.74 4.98855 12.1542 4.98855C12.5685 4.98855 12.9042 5.32434 12.9042 5.73855L12.9042 12.3525C12.9042 12.6287 12.6804 12.8525 12.4042 12.8525L5.79028 12.8525C5.37606 12.8525 5.04028 12.5167 5.04028 12.1025Z"
      />
    </svg>
  );
};

ArrowSouthEast16.defaultProps = {
  fill: "currentColor",
};
