import { ReliabilityCheckCategory } from "../../../../riskManagementTypes";
import { reliabilityCheckCategoryToLabel } from "../../../../constants";

import { Severity, ViolationBreakdown } from "./RisksList/types";
import { riskDataList } from "./RisksList/staticData";

export const getAccumulatedViolations = (violations: ViolationBreakdown) =>
  Object.values(violations).reduce((acc, curr) => acc + curr, 0);

export const getTotalViolations = () =>
  riskDataList.reduce((acc, riskData) => {
    const violations = getAccumulatedViolations(riskData.violations.totals);
    return acc + violations;
  }, 0);

export const getViolationsBreakdown = (): Record<Severity, number> =>
  riskDataList.reduce(
    (acc, riskData) => {
      const { critical, medium, low } = riskData.violations.totals;
      return {
        critical: acc.critical + critical,
        medium: acc.medium + medium,
        low: acc.low + low,
      };
    },
    { critical: 0, medium: 0, low: 0 }
  );

export const getViolationsByTypeBreakdown = (): Record<string, number> => {
  const totalViolations = getTotalViolations();
  const infrastructureViolations = Math.floor(totalViolations / 8);
  const standardizationViolations = Math.floor(totalViolations / 2);
  const optimizationViolations =
    totalViolations - infrastructureViolations - standardizationViolations;

  const getLabel = (violationType: ReliabilityCheckCategory) =>
    reliabilityCheckCategoryToLabel[violationType];

  return {
    [getLabel("Infrastructure issues")]: infrastructureViolations,
    [getLabel("Standardization")]: standardizationViolations,
    [getLabel("Resource optimization")]: optimizationViolations,
  };
};
