import React from "react";

import { IconProps } from "../types";

export const Reload16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M15 8C15 11.866 11.866 15 8 15C4.38697 15 1.41327 12.2627 1.03958 8.74882C0.995779 8.33693 1.33579 8 1.75 8C2.16421 8 2.49463 8.33765 2.55045 8.74808C2.91541 11.4318 5.21617 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 4.96243 11.0376 2.5 8 2.5C6.48091 2.5 5.10784 3.11394 4.11091 4.11091L3.05025 3.05025C4.317 1.7835 6.067 1 8 1C11.866 1 15 4.13401 15 8Z" />
      <path d="M5.14645 5.14646L2.35355 2.35356C2.03857 2.03858 1.5 2.26167 1.5 2.70712V5.50001C1.5 5.77615 1.72386 6.00001 2 6.00001H4.79289C5.23835 6.00001 5.46143 5.46144 5.14645 5.14646Z" />
    </svg>
  );
};

Reload16.defaultProps = {
  fill: "currentColor",
};
