import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";

import endOfLifeDrawer from "../assets/endOfLifeDrawer.png";
import { ClickableArea, CloseButtonArea } from "../styles";

import { ApiRemovedDrawer } from "./ApiRemovedDrawer";

const SelectableArea = styled(ClickableArea)`
  width: 815px;
  height: 39px;
  top: 429px;
  left: 25px;
`;

type EndOfLifeDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const EndOfLifeDrawer: React.FC<EndOfLifeDrawerProps> = ({
  open,
  onClose,
}) => {
  const [openAPIDrawer, setOpenAPIDrawer] = useState(false);

  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img src={endOfLifeDrawer} alt={"end of life drawer"} width={864} />
      <SelectableArea onClick={() => setOpenAPIDrawer(true)} />
      <CloseButtonArea onClick={onClose} />
      <ApiRemovedDrawer
        open={openAPIDrawer}
        onClose={() => setOpenAPIDrawer(false)}
      />
    </Drawer>
  );
};
