import React from "react";

import { IconProps } from "../types";

export const InfoCircle16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1ZM6.71967 6.71967C6.57902 6.86032 6.5 7.05109 6.5 7.25C6.5 7.44891 6.57902 7.63968 6.71967 7.78033C6.86032 7.92098 7.05109 8 7.25 8H7.5V11H7.25C7.05109 11 6.86032 11.079 6.71967 11.2197C6.57902 11.3603 6.5 11.5511 6.5 11.75C6.5 11.9489 6.57902 12.1397 6.71967 12.2803C6.86032 12.421 7.05109 12.5 7.25 12.5H9.25C9.44891 12.5 9.63968 12.421 9.78033 12.2803C9.92098 12.1397 10 11.9489 10 11.75C10 11.5511 9.92098 11.3603 9.78033 11.2197C9.63968 11.079 9.44891 11 9.25 11H9V7.25C9 7.05109 8.92098 6.86032 8.78033 6.71967C8.63968 6.57902 8.44891 6.5 8.25 6.5H7.25C7.05109 6.5 6.86032 6.57902 6.71967 6.71967ZM8.70711 5.20711C8.51957 5.39464 8.26522 5.5 8 5.5C7.73478 5.5 7.48043 5.39464 7.29289 5.20711C7.10536 5.01957 7 4.76522 7 4.5C7 4.23478 7.10536 3.98043 7.29289 3.79289C7.48043 3.60536 7.73478 3.5 8 3.5C8.26522 3.5 8.51957 3.60536 8.70711 3.79289C8.89464 3.98043 9 4.23478 9 4.5C9 4.76522 8.89464 5.01957 8.70711 5.20711Z"
      />
    </svg>
  );
};

InfoCircle16.defaultProps = {
  fill: "currentColor",
};
