import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useEffect, useMemo } from "react";

import { EntityObj, EntityListResponse } from "@/generated/addonsApi";
import { useIsTableFetchingNewData } from "@/components/k8sAddons/hooks/table/useIsTableFetchingNewData";
import { formatNumber } from "@/shared/utils/formatNumber";
import { notifyDDError } from "@/shared/hooks/exceptionManagement";
import { DATA_FETCHING_ERROR_MESSAGE } from "@/components/k8sAddons/constants/table";
import { useTablePaginationInUrl } from "@/components/k8sAddons/hooks/table/useTablePaginationInUrl";
import { generateEmptyRows } from "@/shared/utils/tableUtils";

type CommonTablePropsParams = {
  tableTitle: string;
  response: UseQueryResult<AxiosResponse<EntityListResponse>>;
  loadingStateColumns: GridColDef[];
  dataStateColumns: GridColDef[];
};

export const useCommonTableProps = ({
  tableTitle,
  response,
  loadingStateColumns,
  dataStateColumns,
}: CommonTablePropsParams) => {
  const { data, isFetching, isLoading, error } = response;
  const { isFetchingData } = useIsTableFetchingNewData(isFetching);

  const hasData = !isLoading && !!data?.data?.data?.length;
  const rowsToUse = useDataOrEmptyRows(response);
  const columnsToUse =
    isFetchingData || !hasData ? loadingStateColumns : dataStateColumns;

  const rowCount = data?.data.meta?.totalResults ?? 0;
  const title = !isLoading
    ? `${tableTitle} (${formatNumber(rowCount)})`
    : `${tableTitle}`;

  useEffect(() => {
    if (error) {
      notifyDDError(error as Error, {
        message: DATA_FETCHING_ERROR_MESSAGE,
      });
    }
  }, [error]);

  return {
    isFetchingData,
    rowsToUse,
    columnsToUse,
    rowCount,
    title,
    error,
  };
};

const useDataOrEmptyRows = (
  queryResult: UseQueryResult<AxiosResponse<EntityListResponse>>
) => {
  const { paginationModel } = useTablePaginationInUrl();
  const { data, isLoading, error } = queryResult;

  return useMemo(() => {
    const hasTableData = !!data?.data.data;
    if (!hasTableData && error) return [];
    return isLoading || !hasTableData
      ? generateEmptyRows<EntityObj>(paginationModel.pageSize)
      : data?.data.data;
  }, [data?.data.data, error, isLoading, paginationModel.pageSize]);
};
