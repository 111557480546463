import React from "react";

import { IconProps } from "../types";

export const LightningBoltCircleOutlined24: React.FC<IconProps> = (
  svgProps
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role={svgProps.onClick ? "button" : "img"}
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <rect
        x="0.392857"
        y="0.392857"
        width="23.2143"
        height="23.2143"
        rx="11.6071"
        fill={svgProps.bgColor}
      />
      <path d="M11.5 7L7.5 13H10.5L7.5 19L16.5 11.2857H13.5L16.5 7H11.5Z" />
      <rect
        x="0.392857"
        y="0.392857"
        width="23.2143"
        height="23.2143"
        rx="11.6071"
        stroke={svgProps.fill}
        strokeWidth="0.785714"
        fill="none"
      />
    </svg>
  );
};

LightningBoltCircleOutlined24.defaultProps = {
  fill: "currentColor",
  bgColor: "transparent",
};
