import { SVGProps } from "react";

export type TickProps = Partial<{
  x: number;
  y: number;
  payload: { value: number };
  index: number;
  visibleTicksCount: number;
  orientation: "top" | "bottom" | "left" | "right";
  tickFormatter?: (value: string | number | Date, index: number) => string;
  lastTickXOffset?: number;
}>;

export const tickTextProps: SVGProps<SVGTextElement> = {
  fill: "#666",
  fontSize: "11",
  fontWeight: "bold",
};

export const calculateOffset = (
  index: number | undefined,
  visibleTicksCount: number | undefined,
  lastTickXOffset?: number
) => {
  if (index === undefined || visibleTicksCount === undefined) return 0;
  const isLastTick = index === (visibleTicksCount ?? 0) - 1;
  return index === 0 ? 15 : isLastTick ? lastTickXOffset ?? -25 : 0; //this for making the text fully visible and not cutoff
};
