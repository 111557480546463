"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KomodorAccessToken = void 0;
const runtypes_1 = require("runtypes");
exports.KomodorAccessToken = (0, runtypes_1.Record)({
    "https://hasura.io/jwt/claims": (0, runtypes_1.Record)({
        // Auth
        "x-hasura-default-role": runtypes_1.String,
        "x-hasura-allowed-roles": (0, runtypes_1.Array)(runtypes_1.String),
        "x-hasura-komodor-role": runtypes_1.String,
        "x-hasura-user-id": runtypes_1.String,
        "x-hasura-accepted-tos": runtypes_1.String,
        "x-hasura-is-komodor-admin": runtypes_1.String,
        // Authz
        "x-hasura-allowed-actions": runtypes_1.String,
        // Accounts
        "x-hasura-allowed-accounts": runtypes_1.String,
        "x-hasura-original-account-id": runtypes_1.String,
        "x-hasura-account-id": runtypes_1.String,
        "x-hasura-account-name": runtypes_1.String,
        "x-hasura-account-disabled": runtypes_1.String,
        "x-hasura-account-trial-end-date": runtypes_1.String,
        "x-hasura-account-origin": runtypes_1.String,
        "x-hasura-account-plan": runtypes_1.String,
        "x-hasura-account-salesforce-id": runtypes_1.String,
        "x-hasura-account-acquisition-channel": runtypes_1.String,
        "x-hasura-account-purchase-date": runtypes_1.String,
        "x-hasura-account-registration-date": runtypes_1.String,
        "x-hasura-account-rbac-enabled": runtypes_1.String,
    }),
});
