import React from "react";

import { AddonFilterHandler } from "@/components/k8sAddons/components/AddonFilterHandler/AddonFilterHandler";
import { PageFiltersContainer } from "@/components/k8sAddons/styles";
import { useSearchFieldAsGenericFilter } from "@/components/k8sAddons/hooks/filters/useSearchFieldAsGenericFilter";
import { SEARCH_TABLE_PLACEHOLDER } from "@/components/k8sAddons/addons/workflows/DagsListPage/constants";
import { columnsConfig } from "@/components/k8sAddons/addons/workflows/hooks/dagsTableHooks";

const sharedFilterProps = {
  selectProps: {
    width: "200px",
    selectPopoverProps: { maxHeight: "300px" },
  },
};

export const Filters: React.FC = () => {
  const { searchFieldComponent, searchFilter } = useSearchFieldAsGenericFilter({
    filterName: columnsConfig.name.field,
    searchFieldProps: {
      placeholder: SEARCH_TABLE_PLACEHOLDER,
      label: "Search",
    },
  });

  return (
    <PageFiltersContainer>
      {searchFieldComponent}
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.cluster.field}
        label={"Cluster"}
        searchTermFilter={searchFilter}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.status.field}
        label={"Latest Run Status"}
        searchTermFilter={searchFilter}
      />
      <AddonFilterHandler
        {...sharedFilterProps}
        filterCategory={columnsConfig.workflowEngine.field}
        label={"Workflow Engine"}
        searchTermFilter={searchFilter}
      />
    </PageFiltersContainer>
  );
};
