import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { routes } from "./reliabilityDemoConstants";
import { OverviewPage } from "./components/OverviewPage";
import { DetailsPage } from "./components/DetailsPage";
import { ManageChecksPage } from "./components/ManageChecksPage";

export const ReliabilityDemoView: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path={routes.overviewPage} element={<OverviewPage />} />
        <Route path={routes.detailsPage} element={<DetailsPage />} />
        <Route path={routes.manageChecksPage} element={<ManageChecksPage />} />
        <Route path="*" element={<Navigate to={routes.overviewPage} />} />
      </Routes>
    </div>
  );
};
