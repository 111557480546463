import { User, useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";

import isElectronContext, {
  isDesktopAuth,
} from "../../context/electron/isElectronContext";
import getDesktopUserCredentials from "../../context/electron/getDesktopUserCredentials";

const getElectronUserLoginInfo = async (): Promise<User | undefined> => {
  const electronUserData = await getDesktopUserCredentials();
  if (!(isElectronContext() && electronUserData)) {
    return undefined;
  }
  return electronUserData;
};

const UserLoginInfoContext = createContext<User | undefined>(undefined);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useUserLoginInfo = (): User | undefined => {
  return useContext(UserLoginInfoContext);
};

export const UserLoginInfoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [userLoginInfo, setUserLoginInfo] = useState<User | undefined>(
    undefined
  );
  const { user } = useAuth0();

  useEffect(() => {
    const updateUserLoginInfo = async () => {
      const isDesktopAuthAllowed = isElectronContext() && isDesktopAuth();
      setUserLoginInfo(
        isDesktopAuthAllowed ? await getElectronUserLoginInfo() : user
      );
    };
    updateUserLoginInfo();
  }, [user]);

  return (
    <UserLoginInfoContext.Provider value={userLoginInfo}>
      {children}
    </UserLoginInfoContext.Provider>
  );
};
