import React from "react";
import { TooltipProps } from "recharts";
import styled from "styled-components";

import { palette } from "../../../palette";
import { Typography } from "../../deprecated";

import { TooltipRow } from "./TooltipRow";

const Container = styled.div`
  border: 1px solid ${palette.gray[200]};
  border-radius: 4px;
  background: white;
  padding: 10px;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
`;

export const CustomHistogramTooltip: React.FC<TooltipProps<number, string>> = ({
  active,
  payload,
  label,
}): JSX.Element | null => {
  if (active && payload && payload.length) {
    const totalCount = payload.reduce<number>((acc, curr) => {
      return acc + (curr.value || 0);
    }, 0);
    return (
      <Container>
        <TextContainer>
          <Typography size={"medium"}>{label}</Typography>
          <Typography size={"medium"} bold={true}>
            {`${totalCount} issues`}
          </Typography>
        </TextContainer>
        {payload.map((p) => (
          <TooltipRow payload={p} key={p.dataKey} />
        ))}
      </Container>
    );
  }

  return null;
};
