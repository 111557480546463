import { useMemo } from "react";

import { useListKubernetesAgentsQuery } from "../../generated/graphql";

import useQueryPolling from "./useQueryPolling";

const useIsAgentConnected = (): boolean | null => {
  const [data] = useQueryPolling(useListKubernetesAgentsQuery(), 15 * 1000);
  return useMemo(() => {
    if (!data) return null;
    return data.activeAgents.length !== 0 || data.inactiveAgents.length !== 0;
  }, [data]);
};

export default useIsAgentConnected;
