import React from "react";

import { PageContainer } from "../../../riskManagementStyles";

import { RiskFilters } from "./RiskFilters";
import { RisksContent } from "./RiskContent/RisksContent";

export const Risks: React.FC = () => {
  return (
    <PageContainer>
      <RiskFilters />
      <RisksContent />
    </PageContainer>
  );
};
