import React from "react";
import { DefaultTooltipContentProps } from "recharts";
import styled from "styled-components";

import { Typography } from "../../deprecated";

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 6px;
  margin-bottom: 10px;
`;

const ColoredSquare = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  margin: 4px 0;
`;

const StyledTypography = styled(Typography)`
  max-width: 180px;
  overflow-wrap: break-word;
  word-break: break-all;
`;

type TooltipRowProps = {
  payload: DefaultTooltipContentProps<number, string>["payload"][number];
};

export const TooltipRow: React.FC<TooltipRowProps> = ({
  payload,
}): JSX.Element | null => {
  return (
    <FlexContainer>
      <ColoredSquare color={payload.color} />
      <StyledTypography
        size={"small"}
      >{`${payload.value}: ${payload.name}`}</StyledTypography>
    </FlexContainer>
  );
};
