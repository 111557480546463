import React, { ChangeEventHandler, HTMLAttributes } from "react";
import styled from "styled-components";

import { MagnifyingGlass16 } from "../../../icons";
import { Divider, Input } from "..";

/** @deprecated */
export interface MenuListProps extends HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  onSearch?: ChangeEventHandler<HTMLInputElement>;
}

const List = styled.div<MenuListProps>`
  box-sizing: border-box;
  padding: 0.5rem;
  width: ${({ width }) => width};
  min-width: 4rem;
`;

/**
 * @deprecated Use MUI List instead.
 */
export const MenuList: React.FC<MenuListProps> = ({
  onSearch,
  children,
  ...rest
}) => {
  return (
    <List {...rest} role="menu">
      {onSearch ? (
        <>
          <Input
            width={`calc(${rest.width} - 1rem)`}
            placeholder="Search"
            icon={MagnifyingGlass16}
            onChange={onSearch}
            noBorder
          />
          <Divider spacing="0.25rem" />
        </>
      ) : null}
      {children}
    </List>
  );
};

MenuList.defaultProps = {
  width: "fit-content",
};
