import { ReliabilityCheckData } from "../../../riskManagementTypes";

export const filtersData = [
  { title: "Check scope", items: ["Workload", "Cluster", "Node"] },
];

export const checksData: ReliabilityCheckData[] = [
  {
    id: "1",
    title: "Image tag is not specified",
    subtitle: "Image tag is not specified or ‘latest’ on at least 1 container",
    active: true,
    category: "Standardization",
  },
  {
    id: "2",
    title: "Image Pull policy",
    subtitle: "Image Pull policy in not ‘always’ (on at least one container)",
    active: true,
    category: "Standardization",
  },
  {
    id: "4",
    title: "Evictions (Priority class affect)",
    subtitle: "> 300 Pod evictions (last 7d))",
    active: true,
    category: "Standardization",
  },
  {
    id: "6",
    title: "Low replica count",
    subtitle: "Desired replicas <= threshold",
    active: false,
    category: "Standardization",
  },
  {
    id: "7",
    title: "Deprecated APIs",
    subtitle: "Using a deprecated API",
    active: true,
    category: "Standardization",
  },
  {
    id: "8",
    title: "Deprecated Kubernetes version",
    subtitle: "Cluster Kubernetes version is nearing End-of-life",
    active: true,
    category: "Standardization",
  },
  {
    id: "9",
    title: "Outdated helm charts",
    active: true,
    category: "Standardization",
  },
  {
    id: "10",
    title: "Outdated images",
    subtitle:
      "Monitors container images across deployments, flagging those that are outdated",
    active: true,
    category: "Standardization",
  },
  {
    id: "12",
    title: "Restarting containers",
    subtitle: "container restarts > 800 (Last 7 days)",
    active: true,
    category: "Standardization",
  },
  {
    id: "13",
    title: "Degradation after deploy",
    subtitle:
      "Monitors system changes continuously, pinpointing deployments that result in performance degradation",
    active: true,
    category: "Standardization",
  },
  {
    id: "14",
    title: "Readiness probes",
    subtitle: "Readiness probe is not configured or partially configured",
    active: true,
    category: "Standardization",
  },
  {
    id: "15",
    title: "Liveness probes",
    subtitle: "Liveness probe is not configured or partially configured",
    active: true,
    category: "Standardization",
  },
  /////
  {
    id: "20",
    title: "Zone distribution",
    subtitle: "30% of all replicas are running in the same Availability zone",
    active: true,
    category: "Infrastructure issues",
  },
  {
    id: "21",
    title: "Node distribution",
    subtitle: "40% of all replicas are running in the same Node",
    active: true,
    category: "Infrastructure issues",
  },
  //////
  {
    id: "3",
    title: "HPA maximum reached",
    subtitle: "HPA reached it's maximum capacity for 20% of time (last 7 days)",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "5",
    title: "Unscheduable time",
    subtitle:
      "Total Pending (unschedulable) time for Pods on the scope (last 7d)",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "11",
    title: "CPU Throttling / OOMKilled (Low limits vs usage)",
    subtitle:
      "CPU usage reaches limits for 30% of time (per container, last 7days)",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "16",
    title: "Requests not/partially configured",
    subtitle: "Requests are not set for at least one container",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "17",
    title: "Limits not/partially configured",
    subtitle: "Limits are not set for at least one container",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "18",
    title: "Under provisioned workloads",
    subtitle: "P99 usage is higher than requests in 200%",
    active: true,
    category: "Resource optimization",
  },
  {
    id: "19",
    title: "High requests / limits ratio",
    subtitle: "Limits > requests by 50%",
    active: true,
    category: "Resource optimization",
  },
];
