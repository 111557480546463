import React from "react";

import { IconProps } from "../types";

export const Lock16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 4V6H5.5V4C5.5 2.61929 6.61929 1.5 8 1.5C9.38071 1.5 10.5 2.61929 10.5 4ZM3 6H4V4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4V6H13C13.5523 6 14 6.44772 14 7V13C14 14.6569 12.6569 16 11 16H5C3.34315 16 2 14.6569 2 13V7C2 6.44771 2.44772 6 3 6ZM6.66666 9.33333C6.66666 8.59695 7.26361 8 7.99999 8C8.73637 8 9.33332 8.59695 9.33332 9.33333C9.33332 9.82686 9.06519 10.2578 8.66665 10.4883V13.3334C8.66665 13.7015 8.36817 14 7.99998 14C7.63179 14 7.33331 13.7015 7.33331 13.3334V10.4883C6.93478 10.2577 6.66666 9.82685 6.66666 9.33333Z"
      />
    </svg>
  );
};

Lock16.defaultProps = {
  fill: "currentColor",
};
