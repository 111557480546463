import React from "react";
import styled from "styled-components";
import {
  Popover as ReactPopover,
  PopoverProps as ReactPopoverProps,
} from "react-tiny-popover";

import { useEscape } from "../../../hooks";
import { theme } from "../../../theme";

const Container = styled.div<{ maxHeight: string; maxWidth: string }>`
  background-color: ${theme.background.bgWhite};
  box-shadow: 0px 2px 8px 1px rgba(22, 24, 31, 0.15);
  border-radius: 4px;
  overflow: overlay;
  ${({ maxHeight, maxWidth }) =>
    `max-height: ${maxHeight};
     max-width: ${maxWidth};
    `}
`;

const Wrapper = styled.div`
  width: max-content;
`;

/** @deprecated */
export interface PopoverProps extends Omit<ReactPopoverProps, "children"> {
  maxHeight?: string;
  maxWidth?: string;
  content: JSX.Element;
  handleClose: () => void;
  children: React.ReactNode;
}

/**
 * @deprecated Use MUI Popover instead.
 */
export const Popover: React.FC<PopoverProps> = ({
  content,
  children,
  maxHeight = "max-content",
  maxWidth = "max-content",
  handleClose,
  ...rest
}) => {
  useEscape(handleClose);

  return (
    <ReactPopover
      onClickOutside={handleClose}
      content={
        <Container maxHeight={maxHeight} maxWidth={maxWidth}>
          {content}
        </Container>
      }
      {...rest}
    >
      <Wrapper>{children}</Wrapper>
    </ReactPopover>
  );
};

Popover.defaultProps = {
  positions: ["bottom", "top"],
  align: "end",
  padding: 5,
  reposition: true,
};
