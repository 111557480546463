import React from "react";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import styled from "styled-components";

import { RISK_MANAGEMENT_DEMO_ROUTE } from "../../routes/routes";

import { AppBarLink } from "./AppBarLink";

const DISPLAY_NAME = "Reliability";

const StyledErrorIcon = styled(ErrorOutline)`
  && {
    width: 20px;
    height: 20px;
    margin-left: -2px;
  }
`;

export const RiskManagementDemo: React.FC = () => (
  <AppBarLink
    text={DISPLAY_NAME}
    route={RISK_MANAGEMENT_DEMO_ROUTE}
    icon={<StyledErrorIcon />}
  />
);
