import { muiColors } from "@komodorio/design-system";

import { Severity } from "../../RisksList/types";
import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

export const severityToLegendColorScheme: Record<Severity, string | undefined> =
  {
    critical: muiColors.pink[800],
    medium: muiColors.orange[600],
    low: muiColors.yellow[500],
  };

export const violationTypeToColorScheme: Record<
  keyof typeof staticViolationsTypeBreakdown,
  string | undefined
> = {
  Infrastructure: "#9C27B0",
  Standardization: muiColors.blue[500],
  Optimization: muiColors.teal[700],
};

export const staticViolationsTypeBreakdown: Dictionary<number> = {
  Infrastructure: 60,
  Standardization: 248,
  Optimization: 191,
};
