import { css } from "styled-components";

import { sectionGap } from "../../../../../constants";

export const backgroundColor = "#f3f5fb";

export const contentStyles = css`
  padding-left: 72px;
  padding-right: 72px;
`;

export const gap = css`
  gap: ${sectionGap};
`;
