import React from "react";

import { LogoIconProps } from "../types";

export const PagerDuty: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="PagerDuty logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8527 17.1429C21.4804 17.1429 22.8 15.827 22.8 14.2041V3H20.5895V6.9497C20.354 6.88943 20.1071 6.85714 19.8527 6.85714H15.9843C14.3565 6.85714 13.0369 8.17299 13.0369 9.79592V14.2041C13.0369 15.827 14.3565 17.1429 15.9843 17.1429H19.8527ZM15.9843 9.06122H20.5895V14.2041C20.5895 14.6098 20.2596 14.9388 19.8527 14.9388H15.9843C15.5773 14.9388 15.2474 14.6098 15.2474 14.2041V9.79592C15.2474 9.39019 15.5773 9.06122 15.9843 9.06122Z"
        fill="#00C74F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74742 6.85714C3.11965 6.85714 1.80005 8.17299 1.80005 9.79592V21H4.01058V17.0503C4.24606 17.1106 4.49296 17.1429 4.74742 17.1429H8.61584C10.2436 17.1429 11.5632 15.827 11.5632 14.2041V9.79592C11.5632 8.17299 10.2436 6.85714 8.61584 6.85714H4.74742ZM8.61584 14.9388H4.01058V9.79592C4.01058 9.39019 4.3405 9.06122 4.74742 9.06122H8.61584C9.02276 9.06122 9.35268 9.39019 9.35268 9.79592V14.2041C9.35268 14.6098 9.02276 14.9388 8.61584 14.9388Z"
        fill="#00C74F"
      />
    </svg>
  );
};
