import { NavigateOptions } from "react-router-dom";

export enum Timeframe {
  Last30Minutes,
  LastHour,
  Last4Hours,
  Last24Hours,
  Last48Hours,
  Last7Days,
  Last30Days,
  Custom,
}

export interface TimeWindow {
  start: Date;
  end: Date;
  timeframe: Timeframe;
}

export type SetTimeWindow = (
  tw: Partial<TimeWindow>,
  options?: NavigateOptions
) => void;

export interface TimeWindowProps {
  timeWindow: TimeWindow;
  setTimeWindow: SetTimeWindow;
}

export function createTimeWindowKey(tw: { start: Date; end: Date }): string {
  return `${tw.start.toISOString()}..${tw.end.toISOString()}`;
}
