import React from "react";

import { IconProps } from "../types";

export const ArrowUp16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3321 7.8321C13.0392 8.12499 12.5643 8.12499 12.2714 7.8321L9.05176 4.61243L9.05176 13.8018C9.05176 14.216 8.71597 14.5518 8.30176 14.5518C7.88754 14.5518 7.55176 14.216 7.55176 13.8018L7.55176 4.61243L4.33209 7.8321C4.03919 8.12499 3.56432 8.12499 3.27143 7.8321C2.97853 7.5392 2.97853 7.06433 3.27143 6.77144L7.9482 2.09466C8.14347 1.8994 8.46005 1.8994 8.65531 2.09466L13.3321 6.77144C13.625 7.06433 13.625 7.5392 13.3321 7.8321Z"
      />
    </svg>
  );
};

ArrowUp16.defaultProps = {
  fill: "currentColor",
};
