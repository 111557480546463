import Typography from "@mui/material/Typography";

import { formatNumber } from "../../../../../../../../../shared/utils/formatNumber";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";
import {
  TextContainer,
  StyledOrderedList,
} from "../../../violationDrawerStyles";

const {
  containerRestarts: { description: descriptionAriaLabel },
} = reliabilityArialLabels.violationDrawer;

export const getWhatDescription = (
  containersNum: number,
  totalRestarts: number
) => (
  <TextContainer aria-label={descriptionAriaLabel}>
    <b>{containersNum} containers</b> had a total of{" "}
    <b>{formatNumber(totalRestarts)} restarts</b> in the last 7 days
  </TextContainer>
);

export const getWhyDescription = () => (
  <>
    <Typography variant={"body2"}>
      Container restarts can occur for various reasons, and understanding the
      reasons behind these restarts is crucial for maintaining a healthy and
      reliable application.
      <br />
    </Typography>
    <div>
      <Typography variant={"body2"}>
        Here are some common reasons why containers might be restarted in a
        Kubernetes environment:
      </Typography>
      <StyledOrderedList>
        <li>
          <Typography variant={"body2"}>
            <b>Application bugs:</b>
            <br />
            If there are bugs or issues in the application code, it may crash
            and cause the container to restart.
          </Typography>
        </li>
        <li>
          <Typography variant={"body2"}>
            <b>Out of Memory (OOM) kill:</b>
            <br />
            The Linux kernel can kill processes, including containers, if the
            system runs out of memory. This can lead to container restarts.
          </Typography>
        </li>
        <li>
          <Typography variant={"body2"}>
            <b>Liveness probes:</b>
            <br />
            Containers often have liveness probes configured to check the health
            of the application. If a container fails the liveness probe,
            Kubernetes will restart it to attempt recovery.
          </Typography>
        </li>
      </StyledOrderedList>
    </div>
  </>
);

export const howDescription = (
  <>
    <StyledOrderedList>
      <li>
        <Typography variant={"body2"}>
          Increase allocated resources
          <br />
        </Typography>
      </li>
      <li>
        <Typography variant={"body2"}>
          Debug your application
          <br />
        </Typography>
      </li>
    </StyledOrderedList>
  </>
);
