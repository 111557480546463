import React, { useEffect, useState } from "react";
import styled from "styled-components";

const transitionTime = 500;

const Container = styled.div<{ open: boolean }>`
  overflow: hidden;
  transition: max-height ${transitionTime}ms ease-in-out;
  max-height: ${({ open }) => (open ? "1000px" : "0px")};
`;

type ExpandableContentContainerProps = {
  isExpanded: boolean;
  children: React.ReactNode;
};
export const ExpandableContentContainer: React.FC<
  ExpandableContentContainerProps
> = ({ isExpanded, children }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (isExpanded) {
      setShowContent(true);
      return;
    }

    const timeoutId = setTimeout(() => {
      setShowContent(false);
    }, transitionTime);
    return () => clearTimeout(timeoutId);
  }, [isExpanded]);
  return <Container open={isExpanded}>{showContent && children}</Container>;
};
