import React, { useMemo } from "react";

import { riskDataList } from "./staticData";
import { RiskDetailsCard } from "./RiskDetailsCard/RiskDetailsCard";

export const RisksList: React.FC = () => {
  const riskDetails = useMemo(() => {
    return riskDataList.map((riskData) => {
      return <RiskDetailsCard riskData={riskData} key={riskData.id} />;
    });
  }, []);

  return <div>{riskDetails}</div>;
};
