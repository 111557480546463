import React from "react";

import { IconProps } from "../types";

export const Download16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.47 10.78C7.61063 10.9205 7.80125 10.9993 8 10.9993C8.19875 10.9993 8.38937 10.9205 8.53 10.78L12.28 7.03C12.4125 6.88783 12.4846 6.69978 12.4812 6.50548C12.4777 6.31118 12.399 6.12579 12.2616 5.98838C12.1242 5.85097 11.9388 5.77225 11.7445 5.76883C11.5502 5.7654 11.3622 5.83752 11.22 5.97L8.75 8.44V1.75C8.75 1.55109 8.67098 1.36032 8.53033 1.21967C8.38968 1.07902 8.19891 1 8 1C7.80109 1 7.61032 1.07902 7.46967 1.21967C7.32902 1.36032 7.25 1.55109 7.25 1.75V8.44L4.78 5.97C4.63783 5.83752 4.44978 5.7654 4.25548 5.76883C4.06118 5.77225 3.87579 5.85097 3.73838 5.98838C3.60097 6.12579 3.52225 6.31118 3.51883 6.50548C3.5154 6.69978 3.58752 6.88783 3.72 7.03L7.47 10.78ZM3.75 13C3.55109 13 3.36032 13.079 3.21967 13.2197C3.07902 13.3603 3 13.5511 3 13.75C3 13.9489 3.07902 14.1397 3.21967 14.2803C3.36032 14.421 3.55109 14.5 3.75 14.5H12.25C12.4489 14.5 12.6397 14.421 12.7803 14.2803C12.921 14.1397 13 13.9489 13 13.75C13 13.5511 12.921 13.3603 12.7803 13.2197C12.6397 13.079 12.4489 13 12.25 13H3.75Z"
      />
    </svg>
  );
};

Download16.defaultProps = {
  fill: "currentColor",
};
