import React from "react";

import { IconProps } from "../types";

export const Boxes16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path d="M16 0H12V4H16V0Z" />
    <path d="M16 6H12V10H16V6Z" />
    <path d="M12 12H16V16H12V12Z" />
    <path d="M9.99998 6H5.99995V10H9.99998V6Z" />
    <path d="M5.99995 12H9.99998V16H5.99995V12Z" />
    <path d="M4.00003 6H0V10H4.00003V6Z" />
    <path d="M0 12H4.00003V16H0V12Z" />
  </svg>
);

Boxes16.defaultProps = {
  fill: "currentColor",
};
