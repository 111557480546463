import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { Addon, Entity, Operator } from "@/generated/addonsApi";
import { useGetAddonLiveStateList } from "@/shared/hooks/k8s-add-ons/useGetAddonLiveStateList";
import { TimeWindow } from "@/shared/types/TimeWindow";

export const useGetWorkflowPodsEvents = ({
  workflowRun,
  workflowTimeWindow,
  showOnlyPodsWithIssues,
}: {
  workflowRun: WorkflowRun;
  workflowTimeWindow: TimeWindow;
  showOnlyPodsWithIssues: boolean;
}) => {
  return useGetAddonLiveStateList({
    addon: Addon.Workflow,
    entity: Entity.WorkflowPod,
    getEntityListRequest: {
      filter: [
        {
          name: "clusterName",
          operator: Operator.Eq,
          value: [workflowRun.cluster],
        },
        {
          name: "namespace",
          operator: Operator.Eq,
          value: [workflowRun.namespace],
        },
        {
          name: "engine",
          operator: Operator.Eq,
          value: [workflowRun.engine],
        },
        {
          name: "dagId",
          operator: Operator.Eq,
          value: [workflowRun.dagId],
        },
        {
          name: "runId",
          operator: Operator.Eq,
          value: [workflowRun.name],
        },
        {
          name: "fromEpoch",
          operator: Operator.Eq,
          value: [workflowTimeWindow.start.getTime().toString()],
        },
        {
          name: "toEpoch",
          operator: Operator.Eq,
          value: [workflowTimeWindow.end.getTime().toString()],
        },
        {
          name: "onlyWithIssues",
          operator: Operator.Eq,
          value: [showOnlyPodsWithIssues.toString()],
        },
      ],
    },
  });
};
