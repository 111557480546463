import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import { useRiskManagementContext } from "../../../../../context/RiskManagementContext";

import { Header } from "./components/Header/Header";
import { Metadata } from "./components/Metadata/Metadata";
import { contentStyles, gap } from "./styles";
import { Tabs } from "./components/Tabs/Tabs";
import { OverviewTab } from "./components/Tabs/OverviewTab/OverviewTab";

const tab = "Overview";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  ${gap}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${contentStyles}
  ${gap}
`;
const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const RiskDrawer: React.FC = () => {
  const { isRiskDrawerOpen, setIsRiskDrawerOpen } = useRiskManagementContext();
  const closeCb = () => setIsRiskDrawerOpen(false);

  return (
    <Drawer anchor="right" open={isRiskDrawerOpen} onClose={closeCb}>
      <Container>
        <Header closeCb={closeCb} />
        <Metadata />
        <TabsContainer>
          <Content>
            <Tabs tabValue={tab} />
          </Content>
          <Divider />
        </TabsContainer>
        <Content>
          <OverviewTab />
        </Content>
      </Container>
    </Drawer>
  );
};
