import React, { useState } from "react";
import styled from "styled-components";

import { Severity, TabLabel, ViolationsData } from "../types";

import { useDetailsExpandedTableColumns } from "./useDetailsExpandedTableColumns";
import { RiskDetailsTabs } from "./RiskDetailsTabs";
import { RiskDetailsTable } from "./RiskDetailsTable";
import { FakeTableFooter } from "./FakeTableFooter";
import { RiskDetailsTableFilters } from "./RiskDetailsTableFilters";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 34px;
  margin-top: 24px;
`;

type RiskDetailsExpandedContentProps = { violations: ViolationsData };

export const RiskDetailsExpandedContent: React.FC<
  RiskDetailsExpandedContentProps
> = ({ violations }) => {
  const [tabValue, setTabValue] = useState<TabLabel>("all");

  const onTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue as TabLabel);
  };

  const severity = tabValue === "all" ? "critical" : (tabValue as Severity);
  const columns = useDetailsExpandedTableColumns(severity);
  const rows = violations.details.critical; //currently only critical data is available

  return (
    <Container>
      <RiskDetailsTableFilters />
      <RiskDetailsTabs
        onTabChange={onTabChange}
        tabValue={tabValue}
        violationTotals={violations.totals}
      />
      <RiskDetailsTable rows={rows} columns={columns} />
      <FakeTableFooter
        tabValue={tabValue}
        violationTotals={violations.totals}
      />
    </Container>
  );
};
