import React from "react";

import clusterViewHeader from "../assets/cluster1/clusterViewHeader.png";
import clusterViewContent from "../assets/cluster1/clusterViewContent.png";
import { RelativeContainer } from "../styles";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import { ReliabilityItem } from "./ReliabilityItem";
import { CostItem } from "./CostItem";
import { Header } from "./Header";
import { PoliciesItem } from "./PoliciesItem";
import { ReliabilityItemV2 } from "./ReliabilityItemV2";

export const ClusterView1: React.FC = () => {
  const { showClustersPageDemoV2 } = useOverridableFlags();

  return (
    <RelativeContainer>
      <Header headerImg={clusterViewHeader} />
      <img src={clusterViewContent} alt={"cluster view content"} />
      {showClustersPageDemoV2 ? <ReliabilityItemV2 /> : <ReliabilityItem />}
      <PoliciesItem />
      <CostItem />
    </RelativeContainer>
  );
};
