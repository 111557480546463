import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ifNotProp } from "styled-tools";

import { useEscape } from "../../../hooks";
import { Overlay } from "..";

/** @deprecated */
export type DrawerDirection = "right" | "left";
/** @deprecated */
export interface DrawerProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  width: string;
  direction?: DrawerDirection;
  offset?: string;
  openInScope?: boolean;
  onOverlayClick?(): void;
  onEscKeyPress?(): void;
  zIndex?: number;
  ariaLabel?: string;
}

const Container = styled.div.attrs({
  "data-testid": "drawer",
})<Omit<DrawerProps, "onOverlayClick" | "onEscKeyPress">>`
  position: absolute;
  ${({ direction, offset, open, width }) => {
    const roundBorderSide = direction === "left" ? "right" : "left";
    return `${direction}: ${
      open ? offset : `calc(${offset} - ${width} - 1rem)`
    };
    transition: ${direction} 0.25s;
    border-top-${roundBorderSide}-radius: 1rem;
    border-bottom-${roundBorderSide}-radius: 1rem;
    width: ${width};
    `;
  }}
  min-height: inherit;
  overflow-y: clip;
  height: 100%;
  height: -webkit-fill-available;
  background-color: white;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  ${({ zIndex }) => zIndex !== undefined && `z-index: ${zIndex}`};

  ${ifNotProp(
    "openInScope",
    css`
      position: fixed;
      top: 0;
    `
  )}
`;

/**
 * @deprecated Use MUI Drawer instead.
 */
export const Drawer: React.FC<DrawerProps> = ({
  onOverlayClick,
  onEscKeyPress,
  children,
  ariaLabel,
  ...rest
}) => {
  const { open, zIndex } = rest;
  useEscape(onEscKeyPress);

  return (
    <>
      {onOverlayClick && (
        <Overlay
          open={open}
          onClick={onOverlayClick}
          zIndex={zIndex !== undefined ? zIndex - 1 : undefined}
        />
      )}
      <Container role="dialog" aria-label={ariaLabel} {...rest}>
        {open && children}
      </Container>
    </>
  );
};

Drawer.defaultProps = {
  direction: "right",
  offset: "0rem",
  openInScope: false,
};
