import styled from "styled-components";

export const SelectContainer = styled.div<{
  width?: string;
  minWidth?: string;
  disabled?: boolean;
}>`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  ${({ disabled }) =>
    disabled &&
    `{
      pointer-events: none;
      opacity: 0.5;
    };
  `}
`;
