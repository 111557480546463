import { uniqBy } from "lodash";
import { useMemo } from "react";

import useAgents from "../../../../shared/hooks/useAgents";
import { ListKubernetesAgentsQuery } from "../../../../generated/graphql";

type InstallationCluster = {
  isActive: boolean;
  name: string;
};

const getInstallationClustersFromAgents = (
  agents:
    | ListKubernetesAgentsQuery["activeAgents"]
    | ListKubernetesAgentsQuery["inactiveAgents"]
    | undefined,
  installationId: string
): string[] => {
  return uniqBy(
    agents?.filter((agent) => agent.installationId === installationId),
    (agent) => agent.clusterName
  ).map((agent) => agent.clusterName);
};

export const useInstallationClusters = (
  installationId: string
): InstallationCluster[] => {
  const agents = useAgents();
  return useMemo(() => {
    const activeInstallationClusters = getInstallationClustersFromAgents(
      agents?.activeAgents,
      installationId
    );
    const inactiveInstallationClusters = getInstallationClustersFromAgents(
      agents?.inactiveAgents,
      installationId
    ).filter((cluster) => !activeInstallationClusters.includes(cluster));

    return [
      ...activeInstallationClusters.map((cluster) => ({
        isActive: true,
        name: cluster,
      })),
      ...inactiveInstallationClusters.map((cluster) => ({
        isActive: false,
        name: cluster,
      })),
    ];
  }, [agents?.activeAgents, agents?.inactiveAgents, installationId]);
};
