import { datadogRum } from "@datadog/browser-rum";

import { dispatchEvent } from "@/shared/hooks/analytics";
import { SessionData } from "@/generated/aiApi";

export type ResourceIdenfifier = {
  cluster: string;
  namespace?: string;
  name: string;
  kind: string;
  container?: string;
};

export function sendResourceInvestigationStateEvent(
  event: string,
  session: SessionData | null,
  resource: ResourceIdenfifier,
  flowType: string
) {
  const isStuck = session?.isStuck ?? false;
  const isAnalysisCompletedWithError =
    session?.problemShort === "The analysis has failed";
  const state = isAnalysisCompletedWithError
    ? "error"
    : isStuck
    ? "stuck"
    : session?.isComplete
    ? "completed_successfuly"
    : "loading";
  const data = {
    klaudiaSessionId: session?.id,
    type: "root_cause_analysis",
    cluster: resource.cluster,
    namespace: resource.namespace,
    name: resource.name,
    kind: resource.kind,
    state,
    isAnalysisCompletedWithError,
    isStuck,
    isComplete: session?.isComplete ?? false,
    flowType: flowType,
  };

  datadogRum.addAction(event, data);
  dispatchEvent(event, data);
}
