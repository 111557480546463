/* eslint-disable max-lines */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  ChangeEvent,
} from "react";
import styled from "styled-components";
import {
  Button,
  IconButton,
  Input,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader,
} from "@komodorio/design-system/deprecated";
import { Minus16, Plus16 } from "@komodorio/design-system/icons";
import { ChannelType } from "komodor-types";

import { FormTextInput, lightStyle, Spacer } from "../styles";
import { useFetchChannelConfiguration } from "../../../../notification/useFetchChannelConfiguration";
import Autocomplete from "../../../../common/controls/Autocomplete";
import { blueForUIText } from "../../../../../Colors";
import { useInsertGenericWebhook } from "../../../../notification/useInsertChannelConfiguration";
import PlusIcon from "../../../assets/plus.svg?react";
import successIcon from "../../../assets/success-small.svg";
import failureIcon from "../../../assets/failure-small.svg";
import {
  Grid,
  ResultIcon,
  TestMessage,
  TestState,
  ErrorMessage,
  Divider,
} from "../../../common/styles";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { WorkflowConfiguration } from "../../../common/types";
import { GenericWebhookConfiguration } from "../../../../notification/useFetchNotificationConfiguration";

import { ChannelLabel, HeaderGrid, WebhookTest } from "./common";

const testMessage = {
  type: "Test!",
};

//TODO: add check
/*const IntegrationConfig = Record({
  name: String,
  webhookUrl: String,
  //headers: Dictionary<string, string>,
});*/

const Label = styled.div`
  ${lightStyle}
  padding-bottom: 0.5rem;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  ${lightStyle}
  color: ${blueForUIText};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const WebhookSinkOutput: React.FC<{
  rule: WorkflowConfiguration;
  setRule: React.Dispatch<React.SetStateAction<WorkflowConfiguration>>;
}> = ({ rule: configData, setRule: setConfigData }) => {
  const webhookConfigurations = useFetchChannelConfiguration();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const loading = useMemo(() => {
    return webhookConfigurations === undefined;
  }, [webhookConfigurations]);

  const [currentWebhookId, setCurrentWebhookId] = useState(
    configData.sinks?.genericWebhook?.[0] ?? ""
  );

  const webhooksOptions = useMemo(() => {
    const configurations = webhookConfigurations
      ?.filter((w) => w?.type === ChannelType.GenericWebhook)
      .map((w) => {
        return {
          id: w?.id,
          configuration: w.configuration,
        };
      });
    return configurations ?? [];
  }, [webhookConfigurations]);

  const suggestions = useMemo(() => {
    return webhooksOptions.map((w) => {
      const c = w.configuration as unknown;
      const whConfig = c as GenericWebhookConfiguration;
      return {
        label: `${whConfig.name} (${whConfig.webhookUrl})`,
        value: w.id ?? "",
      };
    });
  }, [webhooksOptions]);

  const currentWebhook = useMemo(() => {
    return suggestions.find((s) => s.value === currentWebhookId);
  }, [currentWebhookId, suggestions]);

  useEffect(() => {
    setConfigData({
      ...configData,
      sinks: {
        ...configData.sinks,
        genericWebhook:
          currentWebhookId === "" ? undefined : [currentWebhookId],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWebhookId]);

  return loading ? null : (
    <>
      <ChannelLabel>
        <Label>Webhook Name</Label>
        <LinkButton onClick={() => setIsAddModalOpen(true)}>
          <PlusIcon /> Add New Webhook
        </LinkButton>
      </ChannelLabel>
      <Grid>
        <Autocomplete
          fieldname="webhook"
          placeholder="Webhook name"
          suggestions={suggestions}
          defaultSelected={currentWebhook}
          onSelectedChange={setCurrentWebhookId}
          allowDeletion
        />
      </Grid>
      {isAddModalOpen && (
        <AddWebhookModal
          isOpen
          handleClose={() => setIsAddModalOpen(false)}
          setChannel={setCurrentWebhookId}
        />
      )}
    </>
  );
};

export default WebhookSinkOutput;

const AddWebhookModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
  setChannel: (channel: string) => void;
}> = ({ isOpen, handleClose, setChannel }) => {
  const [webhookUrl, setWebhookUrl] = useState("");
  const [newWebhook, setNewWebhook] = useState("");
  const [webhookUrlValid, setWebhookUrlValid] = useState(true);
  const [newChannelValid, setNewChannelValid] = useState(true);
  const [testWebhookSuccess, setTestWebhookSuccess] = useState<boolean>();
  const insertGenericWebhookConfiguration = useInsertGenericWebhook();

  const validateWebhookUrl = () => {
    const valid = webhookUrl.match(/^https:[-a-zA-Z0-9@:%._+~#=/]+$/) != null;
    setWebhookUrlValid(valid);
    return valid;
  };

  const validateNewChannel = () => {
    const valid = newWebhook.match(/^@?[-\w]+$/) != null;
    setNewChannelValid(valid);
    return valid;
  };

  const handleAddChannel = () => {
    const webhookUrlValid = validateWebhookUrl();
    const newChannelValid = validateNewChannel();
    if (webhookUrlValid && newChannelValid) {
      (async function () {
        await insertGenericWebhookConfiguration({
          webhookUrl,
          name: newWebhook,
          headers,
        });
        setChannel(newWebhook);
        setWebhookUrl("");
        setNewWebhook("");
        handleClose();
      })();
    }
  };

  const handleTestWebhook = () => {
    setTestWebhookSuccess(undefined);
    if (validateWebhookUrl()) {
      (async function () {
        try {
          await fetch(webhookUrl, {
            mode: "no-cors",
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(testMessage),
          });
          setTestWebhookSuccess(true);
        } catch (e) {
          setTestWebhookSuccess(false);
        }
      })();
    }
  };

  const [headers, setHeaders] = useState<Record<string, string>[]>([]);

  const addHeader = useCallback(
    (header: { key: string; value: string }) => {
      const newHeaders = [...headers, header];
      setHeaders(newHeaders);
    },
    [headers]
  );

  const removeHeader = useCallback(
    (index: number) => {
      const newHeaders = headers.filter((_, i) => i !== index);
      setHeaders(newHeaders);
    },
    [headers]
  );

  const [currentHeader, setCurrentHeader] = useState({ key: "", value: "" });

  return (
    <Modal onRequestClose={handleClose} isOpen={isOpen}>
      <ModalHeader>Add New Webhook</ModalHeader>
      <Divider />
      <ModalContent>
        <Grid>
          <Label>Webhook URL</Label>
          <FormTextInput
            value={webhookUrl}
            placeholder="Enter url"
            isValid={webhookUrlValid}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setWebhookUrl(e.target.value)
            }
          />
          {!webhookUrlValid && (
            <ErrorMessage>A valid webhook URL is required</ErrorMessage>
          )}
          <br />
          <Label>Headers</Label>
          {headers.map(({ key, value }, index) => {
            return (
              <HeaderGrid>
                <Input key={index} disabled={true} value={key} />
                {":"}
                <Input disabled={true} key={index} value={value} />
                <IconButton
                  variant="default"
                  size="small"
                  noBorder
                  disabled={!headers.length}
                  icon={Minus16}
                  onClick={() => {
                    removeHeader(index);
                  }}
                />
              </HeaderGrid>
            );
          })}
          <HeaderGrid>
            <Input
              value={currentHeader.key}
              errorMessage={!currentHeader.key.length ? "" : undefined}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCurrentHeader({
                  key: e.target.value,
                  value: currentHeader.value,
                });
              }}
            />
            {":"}
            <Input
              value={currentHeader.value}
              errorMessage={!currentHeader.value.length ? "" : undefined}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCurrentHeader({
                  value: e.target.value,
                  key: currentHeader.key,
                });
              }}
            />
            <IconButton
              icon={Plus16}
              noBorder
              disabled={currentHeader.key === "" || currentHeader.value === ""}
              onClick={() => {
                addHeader(currentHeader);
                setCurrentHeader({ key: "", value: "" });
              }}
            />
          </HeaderGrid>
        </Grid>
        <Spacer />
        <Label>Webhook Name</Label>
        <Grid>
          <FormTextInput
            value={newWebhook}
            placeholder="Enter name"
            isValid={newChannelValid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNewWebhook(e.target.value)
            }
          />
        </Grid>
        {!newChannelValid && (
          <ErrorMessage>A valid channel name is required</ErrorMessage>
        )}
        <Spacer />
        <WebhookTest>
          <Button size="small" onClick={handleTestWebhook}>
            Test Webhook
          </Button>
          {testWebhookSuccess ? (
            <>
              <ResultIcon icon={successIcon} />
              <TestMessage testState={TestState.Success}>
                Test message sent
              </TestMessage>
            </>
          ) : testWebhookSuccess === false ? (
            <>
              <ResultIcon icon={failureIcon} />
              <TestMessage testState={TestState.Failed}>
                Sending test message failed
              </TestMessage>
            </>
          ) : (
            <></>
          )}
        </WebhookTest>
      </ModalContent>
      <Divider />
      <ModalActions>
        <Button variant="primary" onClick={handleAddChannel}>
          Add Webhook
        </Button>
      </ModalActions>
    </Modal>
  );
};
