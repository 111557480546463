import React from "react";

import { IconProps } from "../types";

export const AlertTriangleOutlined16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3793 12.7412L8.45377 2.77305C8.25702 2.42491 7.74298 2.42491 7.54623 2.77304L1.62068 12.7412C1.43086 13.0771 1.68056 13.4881 2.07445 13.4881H13.9255C14.3194 13.4881 14.5691 13.0771 14.3793 12.7412ZM9.81508 2.04441C9.02806 0.651863 6.97194 0.651862 6.18492 2.04441L0.25937 12.0126C-0.499937 13.3561 0.498862 15 2.07445 15H13.9255C15.5011 15 16.4999 13.3561 15.7406 12.0126L9.81508 2.04441Z"
      />
      <path d="M7.22319 5.64456C7.22319 5.22705 7.57098 4.88859 8 4.88859C8.42902 4.88859 8.77681 5.22705 8.77681 5.64456V9.18039C8.77681 9.5979 8.42902 9.93636 8 9.93636C7.57098 9.93636 7.22319 9.5979 7.22319 9.18039V5.64456Z" />
      <path d="M7.22319 11.7083C7.22319 11.2907 7.57098 10.9523 8 10.9523C8.42902 10.9523 8.77681 11.2907 8.77681 11.7083C8.77681 12.1258 8.42902 12.4642 8 12.4642C7.57098 12.4642 7.22319 12.1258 7.22319 11.7083Z" />
    </svg>
  );
};

AlertTriangleOutlined16.defaultProps = {
  fill: "currentColor",
};
