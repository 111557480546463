import React from "react";

import { IconProps } from "../types";

export const ArrowRight16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36616 2.96967C8.07326 3.26256 8.07326 3.73744 8.36616 4.03033L11.5858 7.25H2.39648C1.98227 7.25 1.64648 7.58579 1.64648 8C1.64648 8.41421 1.98227 8.75 2.39648 8.75H11.5858L8.36616 11.9697C8.07326 12.2626 8.07326 12.7374 8.36616 13.0303C8.65905 13.3232 9.13392 13.3232 9.42682 13.0303L14.1036 8.35355C14.2989 8.15829 14.2989 7.84171 14.1036 7.64645L9.42682 2.96967C9.13392 2.67678 8.65905 2.67678 8.36616 2.96967Z"
      />
    </svg>
  );
};

ArrowRight16.defaultProps = {
  fill: "currentColor",
};
