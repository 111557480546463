import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

import { Risks } from "./components/pages/Risks/Risks";
import { ManageChecks } from "./components/pages/ManageChecks/ManageChecks";
import { TopSection } from "./components/TopSection/TopSection";
import { riskManagementRoutes } from "./constants";
import { RiskManagementContextProvider } from "./context/RiskManagementContext";

const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${muiColors.gray[50]};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MaxWidthContainer = styled.div`
  min-width: 1000px;
  max-width: 1400px;
  flex-grow: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  & > div:nth-child(2) {
    flex-grow: 1;
  }
`;

const PageContainer = styled.div`
  margin-top: 24px;
`;

export const RiskManagementDemoView: React.FC = () => {
  return (
    <RiskManagementContextProvider>
      <MainContainer>
        <TopSection />
        <MaxWidthContainer>
          <PageContainer>
            <Routes>
              <Route path={riskManagementRoutes.riskPage} element={<Risks />} />
              <Route
                path={riskManagementRoutes.manageChecks}
                element={<ManageChecks />}
              />
              <Route path="*" element={<Navigate to="risks" />} />
            </Routes>
          </PageContainer>
        </MaxWidthContainer>
      </MainContainer>
    </RiskManagementContextProvider>
  );
};
