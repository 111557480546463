import React from "react";

import { IconProps } from "../types";

export const PlayBold16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.97386 1.34172C3.21467 1.21025 3.50804 1.22076 3.73882 1.36912L13.0722 7.36912C13.2868 7.50712 13.4166 7.7448 13.4166 8C13.4166 8.2552 13.2868 8.49288 13.0722 8.63088L3.73882 14.6309C3.50804 14.7792 3.21467 14.7898 2.97386 14.6583C2.73306 14.5268 2.58325 14.2744 2.58325 14V2C2.58325 1.72565 2.73306 1.47318 2.97386 1.34172Z"
      />
    </svg>
  );
};

PlayBold16.defaultProps = {
  fill: "currentColor",
};
