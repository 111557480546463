/* eslint-disable max-lines */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { cloneDeep, get, last, sortBy } from "lodash";
import { ResourceTableModelRow } from "komodor-types";
import Divider from "@mui/material/Divider";
import styled from "styled-components";

import {
  customColumns,
  extractServiceNameFromPodName,
  removeColumnsPodLogs,
  useLabelSelector,
} from "../../../../shared/utils/podUtils";
import type Node from "../../resources/node";
import { LinesLoader } from "../../../common/loaders/Line";
import ResourceListTable from "../../../common/ResourceListTable/ResourceListTable";
import { useInspectionResourceListAgentTask } from "../../../Inspection/utils/useInspectionResourceList";
import InspectionUpgradeRequired, {
  useInspectionNeedUpgradeAgent,
} from "../../../Inspection/InspectionUpgradeRequired";
import ResourceListErrorState from "../../../Inspection/ResourceListErrorState";
import { NoItemsContainer, Panel } from "../../../Inspection/styles";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import { withNodesProvider } from "../../../Inspection/NodesProvider";
import {
  KubernetesKindsMatchingService,
  KubernetesServiceTypeEnum,
} from "../../../../shared/types/ServiceVertex";
import { useLiveDataWithUrlParam } from "../../../../shared/hooks/ATM/useAtmResourcesListLiveResult";
import {
  getUniqKey,
  usePodsLogsEarlyFetchUpdate,
} from "../../common/usePodsLogsEarlyFetch";
import { usePodLogsInfo } from "../../../../shared/hooks/podLogs/usePodLogsInfo";
import { InspectionTableHeader } from "../../../common/ResourceListTable/ResourceListTableHead";
import { isServiceKind } from "../../../../shared/utils/serviceHelpers";
import { generateUid } from "../../../../shared/utils/generateUid";
import { relatedResources } from "../EventsTab/content/RelatedResourcesSelector";
import {
  useDrawerUrlState,
  useStateInSearchParams,
} from "../../../../shared/hooks/state";
import type { KomodorServiceResourceKind } from "../../../../shared/types/Resource";
import sortFn from "../../../Inspection/utils/getSortedColumns";
import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { filterInspectionResourcesBySearchParams } from "../../../Inspection/filters/useInspectionFilters";
import { Filters } from "../common/Filters";
import { AriaLabels } from "../../../../shared/config/ariaLabels";
import {
  LABEL_FILTER_TAB_PARAM_KEY,
  PODS_TAB_PREFIX,
  SEARCH_TERM_PARAM_KEY,
  STATUS_FILTER_TAB_PARAM_KEY,
} from "../../../../shared/config/urlSearchParamsKeys";
import { useIsElementInViewport } from "../../../../shared/hooks/useIntersectionObserver";
import { useReportEagerLoadingState } from "../../../../shared/hooks/datadog-rum/useReportEagerLoadingState";
import { dataDogViewNames } from "../../../../shared/constants/datadog";

import { buildControllerGroups } from "./controllerGroups";
import { usePodsAtmByResource } from "./usePodsAtmByResource";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";

const Padding = styled.div`
  padding-bottom: 1rem;
`;

interface ReplicaSet {
  id: string;
  runningPods: number;
  availablePods: number;
  lastModified: Date;
  startTime: Date;
}
interface PodsTabProps {
  resource: KomodorServiceResourceKind | Node | WorkflowRun;
  agentId: string;
  overridingColumns?: InspectionTableHeader[] | undefined;
}
// delete once all agents are above or equal to version 0.1.124
export const PodsContentOld: React.FC<PodsTabProps> = ({
  resource,
  agentId,
}) => {
  const labelSelector = useLabelSelector(
    "selector" in resource ? resource.selector : undefined
  );
  const location = useLocation();
  const labelSelectorUrl = new URLSearchParams(location.search).get(
    "labelSelector"
  );

  const [activePodsControllerId, setActivePodsControllerId] =
    useState<string>("");

  const [agentUpgradeRequired, agentInfo] = useInspectionNeedUpgradeAgent(
    agentId,
    resource.cluster
  );
  const { resourceList, refresh } = useInspectionResourceListAgentTask({
    kubernetesResource: KubernetesPodsResource,
    customColumns,
    labelSelector: labelSelector || labelSelectorUrl || "",
    namespace: resource.namespace,
    agentInfo,
    agentId,
    agentUpgradeRequired,
  });

  const filteredGroups = useMemo(
    () =>
      resourceList.rows?.filter((pod) => {
        const podName = pod?.name;
        const serviceType =
          KubernetesServiceTypeEnum[
            resource.kind as KubernetesKindsMatchingService
          ];
        const serviceNameFromPod = extractServiceNameFromPodName(
          podName,
          serviceType
        );
        return serviceNameFromPod === resource.name;
      }),
    [resourceList.rows, resource.kind, resource.name]
  );

  const groups = useMemo(
    () => buildControllerGroups(filteredGroups),
    [filteredGroups]
  );

  const replicaSets: ReplicaSet[] = useMemo(() => {
    const list: ReplicaSet[] = [];
    groups.forEach((g) => {
      list.push({
        id: g.name,
        runningPods: g.runningPods,
        availablePods: g.availablePods,
        lastModified: g.lastModified,
        startTime: g.lastModified,
      });
    });
    return list;
  }, [groups]);

  const podsTable = useMemo(() => {
    if (resourceList.fetching || resourceList.rows.length === 0) {
      return resourceList;
    }

    const controller = groups.get(activePodsControllerId);
    const copy = cloneDeep(resourceList);
    if (controller) {
      copy.rows = controller.rows;
    } else {
      const rs = last(sortBy(replicaSets, (s) => s.lastModified));
      if (!rs) {
        return {
          emptyResult: true,
          fetching: false,
          rows: [],
          errorMessage: "",
        };
      }
      setActivePodsControllerId(rs.id);
      return resourceList;
    }
    return copy;
  }, [resourceList, activePodsControllerId, groups, replicaSets]);

  if (resourceList.errorMessage) {
    return (
      <ResourceListErrorState
        resourceName={KubernetesPodsResource.NameInK8S}
        errorMessage={
          "We were unable to fetch your data, try reloading the page"
        }
        refreshCallback={refresh}
      />
    );
  }

  let podListTableContent;
  if (podsTable.fetching) {
    podListTableContent = <LinesLoader />;
  }

  if (agentUpgradeRequired && !podListTableContent) {
    podListTableContent = (
      <Panel>
        <InspectionUpgradeRequired />
      </Panel>
    );
  }

  if (
    (podsTable.emptyResult || podsTable.errorMessage !== "") &&
    !podListTableContent
  ) {
    let message = "There are no items to show";
    if (podsTable.errorMessage !== "") {
      message = podsTable.errorMessage;
    }
    podListTableContent = (
      <Panel>
        <NoItemsContainer>{message}</NoItemsContainer>
      </Panel>
    );
  }

  if (!podsTable.fetching && resource.cluster && !podListTableContent) {
    const columns = Object.keys(podsTable?.rows?.[0] ?? [])
      .filter((header) => !removeColumnsPodLogs.includes(header))
      .map((header) => ({
        name: header,
        clickable: false,
      }));
    podListTableContent = withNodesProvider(
      <ResourceListTable
        rows={podsTable.rows}
        overridingColumns={columns}
        cluster={resource.cluster}
        resourceType={KubernetesPodsResource}
        refreshResults={refresh}
        hidePageLabel
      />,
      resource.cluster
    );
  }

  return <>{podListTableContent}</>;
};

const DEFAULT_NUM_TOP_ERROR_PODS = 3;

export interface Pod {
  name: string;
  namespace: string;
  ready: string;
  status: string;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const sortPodsByStatus = (pods: ResourceTableModelRow[]): Pod[] => {
  return pods
    .map((p) => [p.status, p.ready, p.name, p.namespace])
    .sort(sortFn(["status"], 1, 0, KubernetesPodsResource))
    .map((p) => ({
      status: p[0],
      ready: p[1],
      name: p[2],
      namespace: p[3],
    }));
};

const PodsContentAtm: React.FC<PodsTabProps> = ({
  resource,
  agentId,
  overridingColumns,
}) => {
  const { isLiveDataSupported } = useLiveDataWithUrlParam(agentId);
  const { groupPodsByPhases } = useOverridableFlags();
  const setPodsLogsEarlyFetchedData = usePodsLogsEarlyFetchUpdate();

  const { data, fetching, isPaginating, refresh, errorMessage } =
    usePodsAtmByResource(resource, agentId);

  const divRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useIsElementInViewport({ ref: divRef });
  useReportEagerLoadingState({
    isDisplayed,
    loadingState: { pods: fetching },
    viewName: dataDogViewNames.resourceViewPodsTab,
  });

  const containerStatuses = get(resource, "containerStatuses", []);

  const containersOptions = useMemo(() => {
    return containerStatuses.map((c: { name: string }) => ({
      label: `Container: ${c.name}`,
      value: c.name,
    }));
  }, [containerStatuses]);

  const logsResponse = usePodLogsInfo({
    podName: data[0]?.name,
    agentId,
    namespace: data[0]?.namespace,
    clusterName: resource.cluster,
    controlledBy: data[0]?.id,
    selectedContainer: containersOptions[0],
  });

  const podsSearchParamsKey = relatedResources
    .get(KubernetesPodsResource)
    ?.searchParamKey(resource.kind);

  const [selectedPodsInUrl, setSelectedPodsInUrl] = useStateInSearchParams(
    podsSearchParamsKey ?? ""
  );
  const [isPodSetInUrl, setIsPodSetInUrl] = useState(false);

  const { numOfPodsInEvents } = useOverridableFlags();

  useEffect(() => {
    if (data.length >= 1 && !selectedPodsInUrl && !isPodSetInUrl) {
      if (!groupPodsByPhases && data.length > 1) {
        return;
      }

      const sortedPods = sortPodsByStatus(data);
      const topNPods = sortedPods.slice(
        0,
        groupPodsByPhases
          ? (numOfPodsInEvents as number) ?? DEFAULT_NUM_TOP_ERROR_PODS
          : 1
      );

      const podUidForEventsFetch = topNPods
        .map((pod) =>
          generateUid({
            name: pod.name,
            namespace: pod.namespace,
            cluster: resource.cluster,
          })
        )
        .join(",");

      setIsPodSetInUrl(true);
      setSelectedPodsInUrl(podUidForEventsFetch, true);
    }
  }, [
    data,
    groupPodsByPhases,
    isPodSetInUrl,
    resource.cluster,
    selectedPodsInUrl,
    setSelectedPodsInUrl,
    numOfPodsInEvents,
  ]);

  useEffect(() => {
    setPodsLogsEarlyFetchedData &&
      setPodsLogsEarlyFetchedData({
        podsLogsInfoResponse: logsResponse,
        key: getUniqKey(resource.cluster, data[0]?.namespace, data[0]?.name),
      });
  }, [logsResponse, data, resource.cluster, setPodsLogsEarlyFetchedData]);

  const resourceType = useMemo(() => {
    if (!overridingColumns) {
      return KubernetesPodsResource;
    }

    return {
      ...KubernetesPodsResource,
      Headers: overridingColumns,
    };
  }, [overridingColumns]);

  const filteredData = useMemo(() => {
    const filterPodsByControlledByRoot = (pod: ResourceTableModelRow) =>
      pod.controlledByRoot === resource.name;
    return isServiceKind(resource.kind)
      ? data.filter(filterPodsByControlledByRoot)
      : data;
  }, [data, resource.kind, resource.name]);

  const searchTermState = useDrawerUrlState<string>(
    PODS_TAB_PREFIX + SEARCH_TERM_PARAM_KEY
  );
  const statusFilterState = useDrawerUrlState<string[]>(
    PODS_TAB_PREFIX + STATUS_FILTER_TAB_PARAM_KEY
  );
  const labelFilterState = useDrawerUrlState<string[]>(
    PODS_TAB_PREFIX + LABEL_FILTER_TAB_PARAM_KEY
  );
  const filteredTableResults = filterInspectionResourcesBySearchParams({
    resourceList: {
      rows: filteredData,
      fetching,
      errorMessage,
      emptyResult: filteredData.length === 0,
    },
    kubernetesResource: KubernetesPodsResource,
    filterSearchTerm: searchTermState[0],
    filterStatus: statusFilterState[0],
    filterLabels: labelFilterState[0],
  });

  return (
    <div ref={divRef}>
      {errorMessage ? (
        <>
          <br />
          <ResourceListErrorState
            resourceName={KubernetesPodsResource.NameInK8S}
            errorMessage={errorMessage}
            refreshCallback={refresh}
          />
        </>
      ) : fetching ? (
        <LinesLoader marginTop="100px" />
      ) : (
        <>
          {filteredData.length > 1 && (
            <Padding>
              <Filters
                resourceList={filteredData}
                searchTermState={searchTermState}
                statusFilterState={statusFilterState}
                labelFilterState={labelFilterState}
                ariaLabel={AriaLabels.ResourceView.PodsTab.Filters}
              />
              <Divider />
            </Padding>
          )}
          <ResourceListTable
            rows={filteredTableResults}
            resourceType={resourceType}
            cluster={resource.cluster}
            refreshResults={refresh}
            isPaginating={isPaginating}
            showLiveDataButton={isLiveDataSupported}
          />
        </>
      )}
    </div>
  );
};

export default PodsContentAtm;
