import { useMemo } from "react";

import { useGetViolationChecks } from "../../../../../../../shared/hooks/reliability-api/violations/useGetViolationChecks";
import { getCheckLabel } from "../../../../../utils/getCheckLabel";
import {
  supportedDynamicCheckTypes,
  supportedStaticCheckTypes,
} from "../policyDrawerConstants";

import { useIsDynamicChecksTab } from "./useIsDynamicChecksTab";

export const useGetAllAvailableCheckTypes = () => {
  const { data } = useGetViolationChecks();
  const isDynamicChecksTab = useIsDynamicChecksTab();

  const supportedCheckTypes = isDynamicChecksTab
    ? supportedDynamicCheckTypes
    : supportedStaticCheckTypes;

  return useMemo(() => {
    const checks = data?.data?.checks;
    if (!checks) return [];
    return checks
      .filter((check) => supportedCheckTypes.includes(check.type))
      .map((check) => check);
  }, [data?.data?.checks, supportedCheckTypes]);
};

export const useAllCheckLabels = ({ sort }: { sort: boolean }) => {
  const allCheckTypes = useGetAllAvailableCheckTypes();

  return useMemo(() => {
    const allCheckLabels = allCheckTypes.map((check) => ({
      label: getCheckLabel(check.type),
      value: check.type,
    }));
    return sort
      ? allCheckLabels.sort((a, b) => a.label.localeCompare(b.label))
      : allCheckLabels;
  }, [allCheckTypes, sort]);
};
