import React from "react";

import { IconProps } from "../types";

export const ChevronDown16: React.FC<IconProps> = (svgProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7613 6.21934C11.9017 6.35997 11.9806 6.55059 11.9806 6.74934C11.9806 6.94809 11.9017 7.13871 11.7613 7.27934L8.51129 10.5293C8.37067 10.6698 8.18004 10.7487 7.98129 10.7487C7.78254 10.7487 7.59192 10.6698 7.45129 10.5293L4.20129 7.27934C4.06881 7.13717 3.99669 6.94912 4.00012 6.75482C4.00355 6.56052 4.08226 6.37513 4.21967 6.23772C4.35708 6.10031 4.54247 6.02159 4.73677 6.01817C4.93107 6.01474 5.11912 6.08686 5.26129 6.21934L7.98129 8.93934L10.7013 6.21934C10.8419 6.07889 11.0325 6 11.2313 6C11.43 6 11.6207 6.07889 11.7613 6.21934Z"
    />
  </svg>
);

ChevronDown16.defaultProps = {
  fill: "currentColor",
};
