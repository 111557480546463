import React from "react";
import Typography from "@mui/material/Typography";

import { gray } from "../../../../../themes/colors";
import { selectAriaLabels } from "../ariaLabels";

type LabelProps = {
  value?: React.ReactNode;
  className?: string;
};

export const Label: React.FC<LabelProps> = ({ value, className }) => {
  return value && typeof value === "string" ? (
    <Typography
      className={className}
      variant={"body2"}
      color={gray[800]}
      sx={{ marginBottom: "3px" }}
      aria-label={selectAriaLabels.label}
    >
      <label>{value}</label>
    </Typography>
  ) : (
    value
  );
};
