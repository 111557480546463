import { useState, useMemo, useEffect, useCallback } from "react";

import { INVESTIGATION_POLL_INTERVAL } from "../pod/constants";
import { useEnrichedSession } from "../common/useEnrichedSession";
import Resource from "../../resources";

import { sendResourceInvestigationStateEvent } from "./analytics";
import { getDrawerStateForMinimizedSession } from "./utils";
import { useIsAiEnabledForAccount } from "./useIsAiEnabledForAccount";

import { AnalyticEvents } from "@/shared/config/analyticsEvents";
import {
  KLAUDIA_SESSION_ID,
  DRAWER_STACK_STATE_URL_PARAM,
} from "@/shared/config/urlSearchParamsKeys";
import { useAiInvestigations } from "@/shared/context/aiInvestigations/useAiInvestigations";
import {
  useGetLatestSessionForResource,
  useGetSessionById,
} from "@/shared/hooks/ai-api/client/sessions/useGetSession";
import { usePostSession } from "@/shared/hooks/ai-api/client/sessions/usePostSession";
import { useDrawerUrlState } from "@/shared/hooks/state";
import { drawerAtSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import {
  useDrawersStackStore,
  DrawersStackState,
  getURLSearchParams,
} from "@/shared/store/drawersStackStore/drawersStackStore";
import {
  DrawerStatePush,
  DrawerStateType,
} from "@/shared/store/drawersStackStore/types";
import { copyContentToClipboard } from "@/shared/utils/copyContentToClipboard";
import type { MinimizedSession } from "@/shared/context/aiInvestigations/AiInvestigationsProvider";

export const useResourceAiInvestigation = ({
  requestId,
  resource,
  isHealthy,
  flowType,
  drawerData,
}: {
  requestId: string;
  resource: Resource;
  isHealthy: boolean;
  flowType: string;
  drawerData: DrawerStatePush;
}) => {
  const enabled = useIsAiEnabledForAccount();

  const [shouldPollSession, setShouldPollSession] = useState<boolean>(false);
  const [datadogLoadingActionSent, setDatadogLoadingActionSent] =
    useState<boolean>(false);
  const [sharedSessionId] = useDrawerUrlState<string>(KLAUDIA_SESSION_ID);

  const sessionResource = useMemo(
    () => ({
      requestId,
      cluster: resource.cluster,
      namespace: resource.namespace,
      name: resource.name,
      kind: resource.kind,
    }),
    [
      requestId,
      resource.cluster,
      resource.kind,
      resource.name,
      resource.namespace,
    ]
  );

  const {
    mutate: triggerSession,
    isError: isTriggerSessionError,
    isLoading: isTriggerSessionLoading,
    isSuccess: isTriggerSessionSuccess,
  } = usePostSession(sessionResource, {
    onMutate: () => addMinimizedSession(flowType, sessionResource, drawerData),
  });

  const {
    data,
    isError: isGetSessionError,
    refetch: refetchGetSession,
  } = useGetLatestSessionForResource(sessionResource, {
    enabled: enabled && sharedSessionId === null,
    refetchInterval: shouldPollSession
      ? INVESTIGATION_POLL_INTERVAL
      : undefined,
    staleTime: 0,
    cacheTime: 0,
  });

  const { data: sharedSessionData, isError: isGetSharedSessionError } =
    useGetSessionById(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      { id: sharedSessionId! },
      {
        enabled: enabled && sharedSessionId !== null,
      }
    );

  const session =
    sharedSessionId !== null ? sharedSessionData?.session : data?.session;

  useEffect(() => {
    const isInvestigationRunning =
      session?.isComplete === false && session.isStuck === false;
    const isSessionTriggered =
      session === null && isTriggerSessionSuccess && !isTriggerSessionLoading;
    const isNoSessionExists =
      session === undefined && !isGetSessionError && !isGetSharedSessionError;

    setShouldPollSession(
      isInvestigationRunning || isSessionTriggered || isNoSessionExists
    );
  }, [
    isGetSessionError,
    isGetSharedSessionError,
    isTriggerSessionLoading,
    isTriggerSessionSuccess,
    session,
  ]);

  useEffect(() => {
    if (
      enabled &&
      sharedSessionId === null &&
      session === null &&
      !isTriggerSessionSuccess &&
      !isHealthy
    ) {
      triggerSession();
    }
  }, [
    isHealthy,
    session,
    sharedSessionId,
    triggerSession,
    enabled,
    isTriggerSessionSuccess,
  ]);

  const isLoading = useMemo(
    () => !session || session?.isComplete === false,
    [session]
  );

  const onRetryCallback = useCallback(() => {
    if (isGetSessionError) {
      refetchGetSession();
    } else if (isTriggerSessionError || session?.isStuck) {
      triggerSession();
    }
  }, [
    isGetSessionError,
    isTriggerSessionError,
    refetchGetSession,
    session?.isStuck,
    triggerSession,
  ]);

  const isError = useMemo(
    () =>
      (!session && isGetSessionError) ||
      (!session && isGetSharedSessionError) ||
      isTriggerSessionError ||
      session?.isStuck,
    [isGetSessionError, isGetSharedSessionError, isTriggerSessionError, session]
  );
  const { addMinimizedSession } = useAiInvestigations();

  useEffect(() => {
    if (!enabled || !session) {
      return;
    }
    const isStuck = session.isStuck ?? false;
    if (session.isComplete || isStuck || !datadogLoadingActionSent) {
      sendResourceInvestigationStateEvent(
        AnalyticEvents.KlaudiaAi.Investigation,
        session,
        resource,
        drawerData.drawerType
      );
      setDatadogLoadingActionSent(true);
    }
  }, [
    datadogLoadingActionSent,
    drawerData.drawerType,
    enabled,
    resource,
    session,
  ]);

  const richSession = useEnrichedSession(resource, session ?? null);
  const currentDrawer = useDrawersStackStore(drawerAtSelector(-1));
  const onShareClicked = useCallback(
    async (path?: string) => {
      if (!richSession) {
        return;
      }
      const url = new URL(
        path
          ? window.location.protocol + window.location.host + path
          : window.location.href
      );
      let drawer = currentDrawer;
      if (!drawer) {
        const id = sessionResource.requestId;
        const minimizedSession: MinimizedSession = {
          session: null,
          sessionIdentifier: { id, type: flowType, ...sessionResource },
          ...drawerData,
        };
        const newDrawer = getDrawerStateForMinimizedSession(minimizedSession);
        if (newDrawer) {
          drawer = {
            index: 0,
            ...newDrawer,
          };
        }
      }

      if (!drawer) {
        return;
      }

      const drawerState: DrawersStackState = {
        drawersStack: [
          {
            ...drawer,
            urlStates: {
              ...(drawer.urlStates ?? {}),
              [KLAUDIA_SESSION_ID]: richSession.id,
            },
          } as DrawerStateType,
        ],
      };
      const searchParams = getURLSearchParams(
        DRAWER_STACK_STATE_URL_PARAM,
        drawerState
      );
      url.search = searchParams.toString();
      await copyContentToClipboard(url.toString());
    },
    [currentDrawer, drawerData, flowType, richSession, sessionResource]
  );

  return {
    enabled,
    isLoading,
    isError,
    onShareClicked,
    onRetryCallback,
    richSession,
  };
};
