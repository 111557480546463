import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../../shared/types";
import { useWindowResize } from "../../../../../../hooks";

const getAbsoluteWidth = (element: Element) => {
  if (!element) return 0;
  const box = element.getBoundingClientRect();
  return box.x + box.width;
};

type SetMaxChildrenInContainerParams<T extends MuiSelectionOptionValue> = {
  containerEl?: HTMLElement | null;
  hiddenContentEl?: HTMLElement | null;
  hiddenContentChipWidth?: number;
  selectedItems: MuiSelectionOption<T>[];
  enableLineBreaks?: boolean;
};

export const useSetMaxChildrenInContainer = <
  T extends MuiSelectionOptionValue
>({
  containerEl,
  hiddenContentEl,
  hiddenContentChipWidth,
  selectedItems,
  enableLineBreaks,
}: SetMaxChildrenInContainerParams<T>) => {
  const [maxChildren, setMaxChildren] = useState<number>(0);

  useLayoutEffect(() => {
    if (
      !containerEl ||
      !hiddenContentChipWidth ||
      !hiddenContentEl ||
      enableLineBreaks
    )
      return;

    const allChildren: HTMLCollection = hiddenContentEl.children;
    const containerWidth = getAbsoluteWidth(containerEl);

    let maxChildren = 0,
      index = 0;
    for (const child of allChildren) {
      const childWidth = getAbsoluteWidth(child);
      if (childWidth > containerWidth) {
        const prevChildWidth = getAbsoluteWidth(allChildren[index - 1]);
        if (prevChildWidth + hiddenContentChipWidth > containerWidth) {
          maxChildren = Math.max(index - 1, 1);
        } else {
          maxChildren = index;
        }
        break;
      } else {
        maxChildren = index + 1;
      }
      index++;
    }
    setMaxChildren(maxChildren);
  }, [
    containerEl,
    enableLineBreaks,
    hiddenContentChipWidth,
    hiddenContentEl,
    selectedItems,
  ]);

  return enableLineBreaks ? 0 : Math.max(maxChildren, 1);
};

export const useGetMaxContainerWidth = (
  divRef: React.RefObject<HTMLDivElement>
) => {
  const [maxWidth, setMaxWidth] = useState<number>(0);
  const lastWindowWidth = useRef<number>(0);

  const onResize = useCallback(() => {
    if (divRef.current && window.innerWidth !== lastWindowWidth.current) {
      lastWindowWidth.current = window.innerWidth;
      setMaxWidth(divRef.current.clientWidth);
    }
  }, [divRef]);

  const { callback: debouncedOnResize } = useDebouncedCallback(onResize, 100);

  useWindowResize(debouncedOnResize);

  return maxWidth;
};
