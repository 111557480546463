import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutocompleteProps } from "@mui/material";

type OptionalBoolean = boolean | undefined;

export interface WrappedAutoCompleteProps<
  T,
  Multiple extends OptionalBoolean,
  DisableClearable extends OptionalBoolean,
  FreeSolo extends OptionalBoolean = undefined
> extends Omit<
    AutocompleteProps<T | string, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  > {
  renderInput?: AutocompleteProps<
    T | string,
    Multiple,
    DisableClearable,
    FreeSolo
  >["renderInput"];

  label?: string;
  options?: T[];
  placeholder?: string;
}

export const MultiTagInput = <T,>({
  id,
  label,
  placeholder,
  options,
  limitTags,
  multiple,
  freeSolo,
  ...rest
}: WrappedAutoCompleteProps<
  T,
  OptionalBoolean,
  OptionalBoolean,
  OptionalBoolean
>) => {
  return (
    <Autocomplete
      multiple={multiple ?? false}
      freeSolo={freeSolo ?? false}
      limitTags={limitTags ?? -1}
      id={id}
      options={options ?? []}
      getOptionLabel={(option: T | string) => option.toString()}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      {...rest}
    />
  );
};
