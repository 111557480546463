import React from "react";

import { LogoIconProps } from "../types";

export const LaunchDarkly: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="LaunchDarkly logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M12.2857 21.7612C12.3536 21.9131 12.5122 22 12.6934 22C12.8067 22 12.9199 21.9566 12.9652 21.8697L22.7961 12.4899C22.9321 12.3379 23 12.1642 23 11.9905C23 11.8168 22.9321 11.6214 22.7961 11.4911L12.9652 2.13304C12.8746 2.04619 12.7614 2.00277 12.6481 2.00277C12.4895 1.98105 12.3083 2.08962 12.2404 2.24161C12.1724 2.37188 12.1951 2.54558 12.2857 2.67586L17.2238 9.18961L8.59342 5.82417C8.52547 5.80246 8.48017 5.78074 8.41221 5.78074C8.23099 5.78074 8.04977 5.91102 7.98182 6.08472C7.91386 6.25842 8.00447 6.45383 8.16303 6.5624L15.7967 10.8398L2.43211 11.578C2.13764 11.5997 2.00172 11.8168 2.00172 12.0122C1.97907 12.2511 2.18294 12.4465 2.43211 12.4465L15.7967 13.1847L8.20834 17.4621C8.04977 17.5706 7.95917 17.7661 8.02713 17.9398C8.07243 18.1135 8.25364 18.2437 8.4575 18.2437C8.52546 18.2437 8.57077 18.222 8.63873 18.2003L17.2691 14.8349L12.331 21.3269C12.2177 21.4572 12.2177 21.6092 12.2857 21.7612Z"
        fill="black"
      />
    </svg>
  );
};
