import { useEffect, useMemo, useRef } from "react";
import { isEqual } from "lodash";
import { GridPaginationModel } from "@mui/x-data-grid/models/gridPaginationProps";

import { GenericFilter } from "@/generated/addonsApi";

type ResetCurrentPageOnFiltersParams = {
  filtersList: GenericFilter[];
  setPaginationModel: (value: Partial<GridPaginationModel>) => void;
  currentPage: number;
};

export const useResetCurrentPageOnFiltersChange = ({
  filtersList,
  setPaginationModel,
  currentPage,
}: ResetCurrentPageOnFiltersParams) => {
  const lastFiltersUsed = useRef<GenericFilter[]>(filtersList);

  const haveFiltersChanged = useMemo(
    () => !isEqual(lastFiltersUsed.current, filtersList),
    [filtersList]
  );

  useEffect(() => {
    if (filtersList && filtersList.length > 0 && haveFiltersChanged) {
      setPaginationModel({ page: 0 });
    }
    lastFiltersUsed.current = filtersList;
  }, [filtersList, filtersList.length, haveFiltersChanged, setPaginationModel]);

  // prevent sending two requests when changing filters by using page value that will be set by the effect above
  const pageToUse = haveFiltersChanged ? 0 : currentPage;

  return { pageToUse };
};
