import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { HighRequestsToLimitRatioDrawer } from "../components/drawers/HighRequestsToLimitRatio/HighRequestsToLimitRatioDrawer";
import { CpuThrottledDrawer } from "../components/drawers/CpuThrottledDrawer/CpuThrottledDrawer";
import { K8sEOL } from "../components/drawers/K8sEOL/K8sEOL";
import { DeprecatedApi } from "../components/drawers/DeprecatedApi/DeprecatedApi";
import { NoisyNeighbor } from "../components/drawers/NoisyNeighbor/NoisyNeighbor";
import { GenericErrorMessage } from "../../../../../../../shared/components/GenericErrorMessage";
import { notifyDDError } from "../../../../../../../shared/hooks/exceptionManagement";
import { CheckType } from "../../../../../../../generated/reliabilityApi";
import { ContainerRestarts } from "../components/drawers/ContainerRestarts/ContainerRestarts";
import { HpaReachedMax } from "../components/drawers/HpaReachedMax/HpaReachedMax";
import { UnderProvisioned } from "../components/drawers/UnderProvisioned/UnderProvisioned";
import { getCheckLabel } from "../../../../../utils/getCheckLabel";
import { SinglePointOfFailureDrawer } from "../components/drawers/SinglePointOfFailure/SinglePointOfFailureDrawer";
import { HpaMin1 } from "../components/drawers/HpaMin1/HpaMin1";
import { MissingPDB } from "../components/drawers/MissingPDB/MissingPDB";
import { MissingTSC } from "../components/drawers/MissingTSC/MissingTSC";
import { DeploymentMissingReplicas } from "../components/drawers/DeploymentMissingReplicas/DeploymentMissingReplicas";
import { ReadinessProbeMissing } from "../components/drawers/ReadinessProbeMissing/ReadinessProbeMissing";
import { PodPriority } from "../components/drawers/PodPriority/PodPriority";
import { MissingHPA } from "../components/drawers/MissingHPA/MissingHPA";
import { MissingMemoryLimit } from "../components/drawers/MissingMemoryLimit/MissingMemoryLimit";
import { MissingCpuRequests } from "../components/drawers/MissingCpuRequests/MissingCpuRequests";
import { MissingMemoryRequests } from "../components/drawers/MissingMemoryRequests/MissingMemoryRequests";
import { MissingCpuLimit } from "../components/drawers/MissingCpuLimit/MissingCpuLimit";
import { LivenessProbeMissing } from "../components/drawers/LivenessProbeMissing/LivenessProbeMissing";

import { useViolation } from "./useViolation";
import { useReportDrawerStartView } from "./useReportDrawerLoadingTime";

const StyledContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DrawerContent: React.FC = () => {
  const { data, error } = useViolation();
  const { checkType } = data?.data.violation ?? {};

  useReportDrawerStartView();
  useEffect(() => {
    if (error) {
      notifyDDError(error as Error);
    }
  }, [error]);

  const content = useMemo(() => {
    if (error) {
      return (
        <StyledContainer>
          <GenericErrorMessage />
        </StyledContainer>
      );
    }

    switch (checkType) {
      case CheckType.RequestsLimitsRatio:
        return <HighRequestsToLimitRatioDrawer />;
      case CheckType.ThrottledCpu:
        return <CpuThrottledDrawer />;
      case CheckType.KubernetesEndOfLife:
        return <K8sEOL />;
      case CheckType.DeprecatedApis:
        return <DeprecatedApi />;
      case CheckType.NoisyNeighbor:
        return <NoisyNeighbor />;
      case CheckType.RestartingContainers:
        return <ContainerRestarts />;
      case CheckType.HpaMax:
        return <HpaReachedMax />;
      case CheckType.UnderProvisionedWorkloads:
        return <UnderProvisioned />;
      case CheckType.SinglePointOfFailure:
        return <SinglePointOfFailureDrawer />;
      // static violations
      case CheckType.HpaMinAvailability:
        return <HpaMin1 />;
      case CheckType.MissingPdb:
        return <MissingPDB />;
      case CheckType.MissingTopologySpreadConstraint:
        return <MissingTSC />;
      case CheckType.DeploymentMissingReplicas:
        return <DeploymentMissingReplicas />;
      case CheckType.ReadinessProbeMissing:
        return <ReadinessProbeMissing />;
      case CheckType.PriorityClassNotSet:
        return <PodPriority />;
      case CheckType.MissingHpa:
        return <MissingHPA />;
      case CheckType.LivenessProbeMissing:
        return <LivenessProbeMissing />;
      case CheckType.MemoryLimitsMissing:
        return <MissingMemoryLimit />;
      case CheckType.MemoryRequestsMissing:
        return <MissingMemoryRequests />;
      case CheckType.CpuLimitsMissing:
        return <MissingCpuLimit />;
      case CheckType.CpuRequestsMissing:
        return <MissingCpuRequests />;
      default:
        return (
          <StyledContainer>
            <LinesLoader />
          </StyledContainer>
        );
    }
  }, [checkType, error]);

  const ariaLabel = checkType ? `${getCheckLabel(checkType)} drawer` : "";
  return <div aria-label={ariaLabel}>{content}</div>;
};
