import { useMemo } from "react";
import { clone, sortBy } from "lodash";

import { getFormattedAccounts } from "../utils";
import { Account } from "../../../generated/accounts";
import { FormattedAccount } from "../types";

type AccountsResponse = {
  frequentlyUsedAccounts: FormattedAccount[];
  infrequentlyUsedAccounts: FormattedAccount[];
};

const accountsResponse: AccountsResponse = {
  frequentlyUsedAccounts: [],
  infrequentlyUsedAccounts: [],
};

export const useGetAccountByUsageFrequency = (
  parsedFavoriteAccounts: string[],
  unsortedAccounts: Account[] | undefined
) =>
  useMemo(() => {
    if (!unsortedAccounts) return accountsResponse;
    const formattedAccounts = getFormattedAccounts(unsortedAccounts);
    const { frequentlyUsedAccounts, infrequentlyUsedAccounts } =
      formattedAccounts.reduce<AccountsResponse>((acc, account) => {
        if (parsedFavoriteAccounts.includes(account.label)) {
          acc.frequentlyUsedAccounts.push(account);
        } else {
          acc.infrequentlyUsedAccounts.push(account);
        }
        return acc;
      }, clone(accountsResponse));
    const sortedInfrequentlyUsedAccounts = sortBy(
      infrequentlyUsedAccounts,
      ({ label }) => label
    );

    return {
      infrequentlyUsedAccounts: sortedInfrequentlyUsedAccounts,
      frequentlyUsedAccounts,
    };
  }, [parsedFavoriteAccounts, unsortedAccounts]);
