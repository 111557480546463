import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ServiceIdentifierType } from "komodor-types";

import { Dictionary } from "../../types/Dictionary";
import { DataState } from "../../../components/appView/types/DataState";
import {
  AppView,
  FetchAppViewServicesState,
} from "../../../components/appView/sections/AppView/types/appViewsTypes";

import { storedLastAppViewsData } from "./constants";

export type AppViewsState = {
  appViewsById: Dictionary<AppView> | undefined;
  dataState: DataState;
  selectedAppViewId: string | undefined;
  currentAppView: AppView;
  serviceIds: string[];
  servicesUIDs: string[];
  fetchAppViewServicesState: FetchAppViewServicesState;
};

export type AppViewsActions = {
  onAppViewUpsert: (appView: AppView) => void;
  onAppViewDeletion: (appViewId: string) => void;
  setSelectedAppViewId: (appViewId: string | undefined) => void;
  setDataState: (state: Partial<DataState>) => void;
  setAppViewsById: (appViews: Dictionary<AppView>) => void;
  setServiceIds: (serviceIds: string[]) => void;
  setServicesUIDs: (servicesUIDs: string[]) => void;
  setFetchAppViewServicesState: (
    state: Partial<FetchAppViewServicesState>
  ) => void;
};

export type AppViewsStore = AppViewsState & AppViewsActions;
export const WORKSPACE_ID_URL_PARAM = "workspaceId";

export const getInitialAppViewId = (): string | undefined => {
  let inUrl = new URLSearchParams(window.location.search).get(
    WORKSPACE_ID_URL_PARAM
  );
  if (inUrl === "null") {
    inUrl = null;
  }
  const inLocalStorage = storedLastAppViewsData.get();
  let parsedLocalStorage;
  try {
    parsedLocalStorage = inLocalStorage ? JSON.parse(inLocalStorage) : {};
  } catch (e) {
    parsedLocalStorage = {};
  }
  return inUrl ?? parsedLocalStorage.id ?? undefined;
};

export const initialState: AppViewsState = {
  selectedAppViewId: getInitialAppViewId(),
  appViewsById: undefined,
  dataState: { error: false, loading: false },
  fetchAppViewServicesState: {
    noServicesFound: false,
    hasFetchError: false,
    hasFinishedFetching: false,
  },
  currentAppView: {
    id: "",
    name: "",
    serviceIdentifiers: [],
    serviceIdentifierType: ServiceIdentifierType.SERVICE_ID,
  },
  serviceIds: [],
  servicesUIDs: [],
};

export const useAppViewsStore = create(
  immer<AppViewsStore>((set) => ({
    ...initialState,
    appViewsById: undefined,
    onAppViewUpsert: (appView: AppView) => {
      set((state) => ({
        appViewsById: { ...state.appViewsById, [appView.id]: appView },
      }));
    },
    onAppViewDeletion: (appViewId: string) => {
      set((state) => {
        delete state.appViewsById?.[appViewId];
        set({ appViewsById: state.appViewsById });
      });
    },
    setSelectedAppViewId: (appViewId: string | undefined) => {
      set({ selectedAppViewId: appViewId });
    },
    setDataState: (partialDataState: Partial<DataState>) => {
      set((state) => ({
        dataState: { ...state.dataState, ...partialDataState },
      }));
    },
    setAppViewsById: (appViews: Dictionary<AppView>) => {
      set({ appViewsById: appViews });
    },
    setServiceIds: (serviceIds: string[]) => {
      set({ serviceIds });
    },
    setServicesUIDs: (servicesUIDs: string[]) => {
      set({ servicesUIDs });
    },
    setFetchAppViewServicesState: (
      fetchAppViewServicesState: Partial<FetchAppViewServicesState>
    ) => {
      set((state) => ({
        fetchAppViewServicesState: {
          ...state.fetchAppViewServicesState,
          ...fetchAppViewServicesState,
        },
      }));
    },
  }))
);

export const resetAppViewsStore = (): void => {
  const {
    appViewsById,
    selectedAppViewId,
    currentAppView,
    dataState,
    serviceIds,
    servicesUIDs,
    fetchAppViewServicesState,
  } = initialState;
  useAppViewsStore.setState({
    appViewsById,
    currentAppView,
    selectedAppViewId,
    serviceIds,
    servicesUIDs,
    dataState,
    fetchAppViewServicesState,
  });
};
