import React from "react";

import { IconProps } from "../types";

export const MagicAi24: React.FC<IconProps> = (svgProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.918213 8.54898C5.42978 8.54898 9.08713 4.89163 9.08713 0.380066H9.08716V0.380093C9.08716 4.89166 12.7445 8.54901 17.2561 8.54901C12.7445 8.54901 9.08716 12.2064 9.08716 16.7179C9.08716 12.2064 5.42981 8.54901 0.91824 8.54901H0.918213V8.54898ZM11.7673 18.205C14.8891 18.205 17.4211 15.7868 17.4322 12.7997C17.4432 15.782 19.967 18.1972 23.0818 18.2051C19.9637 18.2129 17.4378 20.6333 17.4321 23.62C17.4265 20.6284 14.8924 18.205 11.7672 18.205L11.7672 18.205H11.7673Z"
    />
  </svg>
);

MagicAi24.defaultProps = {
  fill: "currentColor",
};
