import lodash from "lodash";

interface Utms {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}
const utmsOptions = [
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_content",
  "utm_term",
];

const parseUrlUtms = (searchPath: string): Utms => {
  const searchParams = new URLSearchParams(searchPath);
  const params = Object.fromEntries(searchParams);
  const utmParams = lodash.pick(params, utmsOptions);
  return utmParams;
};

export default parseUrlUtms;
