import React from "react";
import { parseISO } from "date-fns";
import { uniqBy } from "lodash";
import { palette } from "@komodorio/design-system";
import {
  AlertTriangle16,
  CheckBold16,
  ClockFilled16,
  CloseBold16,
  PlayBold16,
  PauseFilled16,
} from "@komodorio/design-system/icons";
import * as uuid from "uuid";

import EventGroup, { EventBase } from "../index";
import {
  PodEvent,
  PodEventsResponseDataInner,
  PodGroup,
  PodGroups,
  PodNativeEventsResponseDataInner,
} from "../../../../generated/resourcesApi";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { PodGroupDetails } from "../../ProcessList/details/PodPhaseDetails/PodGroupDetails";
import { parseKomodorUid } from "../../../../shared/hooks/resources-api/resourcesAPIUtils";
import PodShape from "../shapes/pod.svg?react";
import { getMessageFromConditions } from "../../ProcessList/details/PodPhaseDetails/utils";

import { PodPhase, StatusCondition } from "./types";

export const POD_GROUP_TYPE = "Pod phase";
export const PDD_GROUP_EVENT_TYPE = "Pod phase event";
export const EventKindPodEvent = "PodEvent";
export const EventKindNativePodEvent = "NativePodEvent";

export type PodEventData = EventBase & PodEvent;

interface GroupStyling {
  backgroundColor: string;
  fillColor: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
const GroupStylingMapping: Record<string, Record<string, GroupStyling>> = {
  [PodPhase.Pending]: {
    Normal: {
      backgroundColor: palette.gray[200],
      fillColor: palette.gray[800],
      icon: ClockFilled16,
    },
    Warning: {
      backgroundColor: palette.yellow[200],
      fillColor: palette.orange[500],
      icon: ClockFilled16,
    },
  },
  [PodPhase.NotReady]: {
    Warning: {
      backgroundColor: palette.yellow[200],
      fillColor: palette.orange[500],
      icon: PauseFilled16,
    },
    Error: {
      backgroundColor: palette.pink[100],
      fillColor: palette.pink[500],
      icon: AlertTriangle16,
    },
  },
  [PodPhase.Ready]: {
    Normal: {
      backgroundColor: palette.green[100],
      fillColor: palette.green[700],
      icon: PlayBold16,
    },
  },
  [PodPhase.Succeeded]: {
    Normal: {
      backgroundColor: palette.green[100],
      fillColor: palette.green[700],
      icon: CheckBold16,
    },
  },
  [PodPhase.Failed]: {
    Error: {
      backgroundColor: palette.pink[100],
      fillColor: palette.pink[500],
      icon: AlertTriangle16,
    },
  },
  [PodPhase.Deleted]: {
    Normal: {
      backgroundColor: palette.gray[200],
      fillColor: palette.gray[800],
      icon: CloseBold16,
    },
  },
};

export default class PodPhaseGroup implements EventGroup {
  readonly icon;
  readonly backgroundColor;
  readonly fillColor;
  readonly details: string;
  readonly endTime: Date;
  readonly id: string;
  readonly serviceId: string;
  readonly startTime: Date;
  readonly status: string;
  readonly summary: string;
  readonly type = POD_GROUP_TYPE;
  readonly podName: string;
  readonly namespace: string;
  readonly clusterName: string;
  readonly title: string;
  readonly phase: string;
  readonly phaseStatus: string;
  readonly statusConditions: object[];
  readonly isCompleted: boolean;
  readonly events: PodEventData[];

  constructor(
    podPhase: PodGroup,
    serviceId: string,
    podName: string,
    namespace: string,
    clusterName: string
  ) {
    const endTime = parseISO(podPhase.endTime ?? "");
    this.phase = podPhase.name ?? "";
    this.phaseStatus = podPhase.status ?? "";
    this.status = this.getStatus();
    this.title = `Pod ${this.status.toLowerCase()}`;
    this.podName = podName;
    this.events =
      podPhase.events?.map((p) => toPodEventData(p, serviceId)) ?? [];
    const lastEvent = this.events[this.events.length - 1];
    this.id = podPhase.id ?? uuid.v4();
    this.serviceId = podPhase.serviceId ?? serviceId;
    this.startTime = parseISO(podPhase.startTime ?? "");
    this.endTime =
      endTime >= this.startTime
        ? endTime
        : lastEvent?.eventTime ?? this.startTime;
    this.isCompleted = endTime >= this.startTime;
    this.summary = `${podName} is ${this.status ?? ""}`;
    this.details = this.getDetails();
    this.clusterName = clusterName;
    this.namespace = namespace;
    const phase = GroupStylingMapping[this.phase]?.[this.phaseStatus];
    this.icon = phase?.icon ?? PodShape;
    this.backgroundColor = phase?.backgroundColor ?? "";
    this.fillColor = phase?.fillColor ?? "";
    this.statusConditions = podPhase.statusConditions ?? [];
  }

  renderEventDetails(): JSX.Element {
    return <PodGroupDetails eventGroup={this} />;
  }

  getStatus(): string {
    if (this.phase === PodPhase.Ready) {
      return "Running (Ready)";
    } else if (this.phase === PodPhase.NotReady) {
      return "Running (Not Ready)";
    }
    return this.phase;
  }

  getDetails(): string {
    const event = this.events.find((e) => e.highlight === true);
    return (
      event?.message ??
      event?.waitingMessage ??
      getMessageFromConditions(event?.statusConditions as StatusCondition[]) ??
      ""
    );
  }

  renderLineLabel(): string {
    return this.status;
  }
}

export const groupGroupedPodEvents = (
  events: PodGroups[],
  serviceId: string
): PodPhaseGroup[] => {
  const podToGroups = events?.map((groups: PodGroups) => {
    return {
      komodorUid: groups.komodorUid,
      groups: groups.groups ?? [],
    };
  });

  const mappedEvents = podToGroups?.flatMap((podData) => {
    return podData.groups.map((group) => {
      const parsedUid = parseKomodorUid(podData.komodorUid ?? "");
      return new PodPhaseGroup(
        group,
        serviceId,
        parsedUid?.name ?? "",
        parsedUid?.namespace ?? "",
        parsedUid?.cluster ?? ""
      );
    });
  });

  return uniqBy(
    mappedEvents,
    (e) =>
      `${e.podName}-${e.serviceId}-${e.phaseStatus}-${e.status}-${e.startTime}`
  ).filter(
    (e) =>
      e.phase !== "" && e.phaseStatus !== "" && !isNaN(e.startTime.getTime())
  );
};

const toPodEventData = (e: PodEvent, serviceId: string): PodEventData => {
  return {
    ...e,
    ...{
      type: PDD_GROUP_EVENT_TYPE,
      serviceId,
      eventTime: parseISO(e.eventTime ?? ""),
    },
  } as PodEventData;
};

export const nativePodEventToPodEvent = (
  e: PodNativeEventsResponseDataInner,
  serviceId: string
): PodEventData => {
  return {
    ...toPodEventData(e, serviceId),
    ...{
      eventKind: EventKindNativePodEvent,
      reason: e.reason,
      message: e.message,
      severityType: e.severityType,
      firstTimestamp: e.firstTimestamp,
      lastTimestamp: e.lastTimestamp,
      count: e.count,
      statusReason: "",
      waitingMessage: "",
    },
  } as PodEventData;
};

export const podEventToPodEventData = (
  e: PodEventsResponseDataInner,
  serviceId: string
): PodEventData => {
  return {
    ...toPodEventData(e, serviceId),
    ...{
      reason: e.reason,
      message: e.message,
      severityType: "",
      eventKind: EventKindPodEvent,
      statusConditions: e.statusConditions,
      containerStatus: e.containerStatus,
      //initContainerStatus: e.initContainerStatus,
      statusReason: e.statusReason,
      waitingMessage: e.waitingReason,
    },
  } as PodEventData;
};
