import React from "react";

import { IconProps } from "../types";

export const ArrowLeft24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5304 4.96967C12.8233 5.26256 12.8233 5.73744 12.5304 6.03033L7.3107 11.25H18.5C18.9143 11.25 19.25 11.5858 19.25 12C19.25 12.4142 18.9143 12.75 18.5 12.75H7.3107L12.5304 17.9697C12.8233 18.2626 12.8233 18.7374 12.5304 19.0303C12.2375 19.3232 11.7626 19.3232 11.4697 19.0303L4.79293 12.3536C4.59767 12.1583 4.59767 11.8417 4.79293 11.6464L11.4697 4.96967C11.7626 4.67678 12.2375 4.67678 12.5304 4.96967Z"
      />
    </svg>
  );
};

ArrowLeft24.defaultProps = {
  fill: "currentColor",
};
