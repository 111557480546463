import styled from "styled-components";
import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import MuiTypography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { muiPalette } from "@komodorio/design-system";

import { useOverridableFlags } from "../../../../shared/context/featureFlags/OverridableFlags";
import { RbacPolicyType } from "../../../../generated/auth";

import {
  ErrorMessage,
  HorizontalContainer,
  InformationMessage,
} from "./common";

const tooltip =
  "Static policies are editable manually and only change when you edit them. Dynamic policies are generated automatically based on their tags and are modified whenever a cluster with the matching tags is added or removed, the cluster tags must be a subset of the policy tags.";
const STATIC_INFO =
  "Static policies are editable manually and only change when you edit them.";
const WILDCARD_INFO = `Namespace pattern (Wildcard) policies use an asterisk (*), to replace or represent one or more characters. For example, "comp*" matches anything starting with "comp" (i.e "comp," "complete," and "computer" are all matched)`;
const DYNAMIC_INFO = `Dynamic policies are generated automatically based on their tags and are modified whenever a cluster with tha matching tags is added or removed, the cluster tags must be a subset of the policy tags (the statements will be read-only and controlled by your agents' tags)`;

const POLICY_TYPE_INPUT_NAME = "policy-type-choice";

const PolicyTypeChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const validatePolicyType = (type: string | undefined) => {
  if (!type) {
    return "Please select policy type";
  }
  if (!Object.values(RbacPolicyType).includes(type as RbacPolicyType)) {
    return "Please select valid policy type";
  }
  return undefined;
};

interface PolicyTypeChoiceProps {
  onChange: (value: string) => void;
  errorMessage?: string;
  currentlyChecked?: RbacPolicyType;
  disabled?: boolean;
}

export const PolicyTypeChoice: React.FC<PolicyTypeChoiceProps> = ({
  onChange,
  errorMessage,
  currentlyChecked,
  disabled,
}) => {
  const { allowRbacNamespaceWildcard } = useOverridableFlags();
  return (
    <PolicyTypeChoiceContainer>
      <HorizontalContainer>
        <Typography variant="text" size="medium">
          Policy Type
        </Typography>
        <Tooltip title={tooltip}>
          <InfoOutlined
            sx={{
              fontSize: "16px",
              color: muiPalette?.text?.secondary,
            }}
          />
        </Tooltip>
      </HorizontalContainer>
      <RadioGroup
        row={true}
        defaultValue={RbacPolicyType.Static}
        onChange={({ target }) => onChange(target.value)}
      >
        <FormControlLabel
          sx={{ marginLeft: "0" }}
          value={RbacPolicyType.Static}
          control={
            <Radio
              size="small"
              name={POLICY_TYPE_INPUT_NAME}
              id={"policytype-static"}
              checked={currentlyChecked === RbacPolicyType.Static}
              disabled={disabled}
            />
          }
          label={<MuiTypography variant="body2">Static</MuiTypography>}
        />
        {allowRbacNamespaceWildcard ? (
          <FormControlLabel
            sx={{ marginLeft: "0" }}
            value={RbacPolicyType.Wildcard}
            control={
              <Radio
                size="small"
                name={POLICY_TYPE_INPUT_NAME}
                id={"policytype-namespace-pattern"}
                checked={currentlyChecked === RbacPolicyType.Wildcard}
                disabled={disabled}
              />
            }
            label={
              <MuiTypography variant="body2">
                Namespace(s) pattern
              </MuiTypography>
            }
          />
        ) : null}
        <FormControlLabel
          sx={{ marginLeft: "0" }}
          value={RbacPolicyType.DynamicTag}
          control={
            <Radio
              size="small"
              name={POLICY_TYPE_INPUT_NAME}
              id={"policytype-dynamictags"}
              checked={currentlyChecked === RbacPolicyType.DynamicTag}
              disabled={disabled}
            />
          }
          label={
            <MuiTypography variant="body2">Dynamic (via Tags)</MuiTypography>
          }
        />
      </RadioGroup>
      <InformationMessage>
        {currentlyChecked === RbacPolicyType.Static
          ? STATIC_INFO
          : currentlyChecked === RbacPolicyType.Wildcard
          ? WILDCARD_INFO
          : DYNAMIC_INFO}
      </InformationMessage>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </PolicyTypeChoiceContainer>
  );
};
