import React from "react";

import { IconProps } from "../types";

export const Compare16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M4.06066 5.5C3.76777 5.20711 3.29289 5.20711 3 5.5C2.70711 5.79289 2.70711 6.26777 3 6.56066L4.46967 8.03033L3 9.5C2.70711 9.79289 2.70711 10.2678 3 10.5607C3.29289 10.8536 3.76777 10.8536 4.06066 10.5607L6.06066 8.56066C6.35355 8.26777 6.35355 7.79289 6.06066 7.5L4.06066 5.5Z" />
      <path d="M12.4393 5.5C12.7322 5.20711 13.2071 5.20711 13.5 5.5C13.7929 5.79289 13.7929 6.26777 13.5 6.56066L12.0303 8.03033L13.5 9.5C13.7929 9.79289 13.7929 10.2678 13.5 10.5607C13.2071 10.8536 12.7322 10.8536 12.4393 10.5607L10.4393 8.56066C10.1464 8.26777 10.1464 7.79289 10.4393 7.5L12.4393 5.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 16C0.447716 16 0 15.5523 0 15V1C0 0.447716 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1V15C16 15.5523 15.5523 16 15 16H1ZM1.5 14.5V1.5H7.5L7.5 14.5H1.5ZM9 1.5H14.5V14.5H9L9 1.5Z"
      />
    </svg>
  );
};

Compare16.defaultProps = {
  fill: "currentColor",
};
