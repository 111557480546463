import React from "react";

import { IconProps } from "../types";

export const ArrowNorthEast16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1542 11.5115C11.74 11.5115 11.4042 11.1757 11.4042 10.7615L11.4042 6.20821L4.90639 12.7061C4.6135 12.9989 4.13863 12.9989 3.84573 12.7061C3.55284 12.4132 3.55284 11.9383 3.84573 11.6454L10.3436 5.14755L5.79028 5.14755C5.37606 5.14755 5.04028 4.81176 5.04028 4.39755C5.04028 3.98334 5.37606 3.64755 5.79028 3.64755L12.4042 3.64755C12.6804 3.64755 12.9042 3.87141 12.9042 4.14755L12.9042 10.7615C12.9042 11.1757 12.5685 11.5115 12.1542 11.5115Z"
      />
    </svg>
  );
};

ArrowNorthEast16.defaultProps = {
  fill: "currentColor",
};
