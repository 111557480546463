import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import { LightningBolt16 } from "@komodorio/design-system/icons";
import React from "react";
import styled from "styled-components";

export const Insights = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

export const InsightContainer = styled.div`
  width: fit-content;
`;

export const InsightTooltipContainer = styled.div`
  padding: 1rem;
  max-height: 20rem;
  overflow-y: auto;
`;

export const InsightTooltipDetails = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 0.35rem 1rem;
  align-items: center;
`;

export const Flex = styled.div<{ isCursorPointer?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  ${({ isCursorPointer = true }) => isCursorPointer && "cursor: pointer;"}
`;

const InsightText = styled(Typography).attrs({ size: "medium" })`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 0.25rem;
`;

export const Insight: React.FC<{
  children: React.ReactNode;
  isCursorPointer?: boolean;
}> = ({ children, isCursorPointer = true }) => (
  <Flex isCursorPointer={isCursorPointer}>
    <LightningBolt16 color={palette.orange[400]} />
    <InsightText>{children}</InsightText>
  </Flex>
);

export const Cursor = styled.span<{ cursor: "default" | "pointer" }>`
  cursor: ${({ cursor }) => cursor};
`;
