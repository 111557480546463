import React from "react";

import { IconProps } from "../types";

export const Pencil24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5001 1.66492C18.0362 1.66492 17.5912 1.84912 17.2631 2.17704L14.4761 4.96304L3.67209 15.276C3.45433 15.4838 3.29383 15.7442 3.20609 16.032L1.53309 21.532C1.4938 21.6619 1.4905 21.8001 1.52353 21.9317C1.55656 22.0633 1.62469 22.1835 1.72065 22.2795C1.81661 22.3754 1.93681 22.4436 2.06844 22.4766C2.20007 22.5096 2.33819 22.5063 2.46809 22.467L7.99109 20.787C8.25286 20.7072 8.49227 20.5672 8.69009 20.378L19.5181 10.043L19.5301 10.03L22.3231 7.23704C22.651 6.90889 22.8352 6.46396 22.8352 6.00004C22.8352 5.53613 22.651 5.0912 22.3231 4.76304L19.7371 2.17704C19.4089 1.84912 18.964 1.66492 18.5001 1.66492ZM4.70809 16.361L18.3231 3.23804C18.3463 3.21476 18.3739 3.19629 18.4043 3.18369C18.4346 3.17108 18.4672 3.1646 18.5001 3.1646C18.533 3.1646 18.5655 3.17108 18.5959 3.18369C18.6263 3.19629 18.6539 3.21476 18.6771 3.23804L21.2631 5.82404C21.2864 5.84727 21.3048 5.87485 21.3174 5.90523C21.33 5.9356 21.3365 5.96816 21.3365 6.00104C21.3365 6.03393 21.33 6.06649 21.3174 6.09686C21.3048 6.12723 21.2864 6.15482 21.2631 6.17804L7.65409 19.293C7.62585 19.3203 7.59159 19.3405 7.55409 19.352L3.37709 20.623L4.64109 16.469C4.65375 16.4279 4.67683 16.3907 4.70809 16.361Z"
      />
    </svg>
  );
};

Pencil24.defaultProps = {
  fill: "currentColor",
};
