import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { capitalize } from "lodash";

import { Dictionary } from "../../../../../../../../shared/types/Dictionary";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const LineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Square = styled.div<{ color?: string }>`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

const StyledTypography = styled(Typography)`
  white-space: nowrap;
`;

type ViolationsBreakdownLegendProps = {
  data: Dictionary<number>;
  colorChart: Dictionary<string | undefined>;
};

export const ViolationsBreakdownLegend: React.FC<
  ViolationsBreakdownLegendProps
> = ({ data, colorChart }) => {
  const content = useMemo(() => {
    return Object.entries(data).map(([key, value]) => {
      return (
        <LineContainer key={key}>
          <Square color={colorChart[key]} />
          <StyledTypography variant={"body2"}>
            {capitalize(key)}: {value}
          </StyledTypography>
        </LineContainer>
      );
    });
  }, [colorChart, data]);
  return <Container>{content}</Container>;
};
