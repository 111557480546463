import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { muiPalette } from "@komodorio/design-system";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Metadata } from "@komodorio/design-system/komodor-ui";
import styled from "styled-components";

import { AriaLabels } from "../../../../../shared/config/ariaLabels";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";
import PodPhaseGroup from "../../../../common/EventGroup/groupedPodEvent/PodPhaseGroup";
import { EventsSection } from "../../../../common/ProcessList/details/PodPhaseDetails/EventsSection";

const PodPhaseContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.5fr 1fr 1fr;
  align-items: center;
  column-gap: 1rem;
`;

const AccordionCommonStyle = {
  borderTop: "none",
  boxShadow:
    "0px 2px 5px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.02)",
  borderRadius: "4px",
};

export const PodPhase: React.FC<{ phase: PodPhaseGroup }> = ({ phase }) => {
  const Icon = phase.icon;
  const { format } = useDateFormatter({ timeZoneName: undefined });

  return (
    <Accordion
      sx={{ overflow: "hidden" }}
      aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}`}
      id={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}`}
    >
      <AccordionSummary
        aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-summary`}
        sx={{
          backgroundColor: muiPalette.background?.paper,
          borderBottom: `1px solid ${muiPalette.divider}`,
          padding: "0.5rem 1rem",
          background: "var(--background-paper-elevation-1, #FFF)",
          ...AccordionCommonStyle,
        }}
        expandIcon={<ExpandMore />}
      >
        <PodPhaseContainer>
          <Icon
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-icon`}
            color={phase.fillColor}
          />
          <Metadata
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-phase`}
            title={"Phase"}
            value={phase.phase}
          />
          <Metadata
            aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-started`}
            title={"Started"}
            value={format(phase.startTime)}
          />
        </PodPhaseContainer>
      </AccordionSummary>
      <AccordionDetails
        aria-label={`${AriaLabels.PodPhases.PodEvents.Accordion}-${phase.id}-details`}
        sx={AccordionCommonStyle}
      >
        <EventsSection eventGroup={phase} />
      </AccordionDetails>
    </Accordion>
  );
};
