import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import { gray } from "../../../../../themes/colors";
import {
  MuiSelectionOption,
  MuiSelectionOptionValue,
} from "../../shared/types";
import { DISABLED_ITEM_OPACITY } from "../../shared/constants";
import { freeSoloItemPrompt } from "../advancedMultiSelectConstants";
import { convertValueToFreeSoloOption } from "../advancedMultiSelectUtils";

const StyledCheckbox = styled(Checkbox)<{ $presentAsPlaceholder: boolean }>`
  && {
    padding: 4px;
    visibility: ${({ $presentAsPlaceholder }) =>
      $presentAsPlaceholder ? "hidden" : "visible"};
  }
`;

const DisabledCheckbox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${gray[200]};
  border-radius: 2px;
  background-color: ${gray[100]};
  margin: 6px;
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    padding: 4px 0;
    overflow: hidden;
    & > p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

const DisabledMenuItem = styled(StyledMenuItem)`
  && {
    cursor: default;
    opacity: ${DISABLED_ITEM_OPACITY};
    width: fit-content;
    &:hover {
      background-color: unset;
    }
  }
`;

type GetEnabledItemsProps<T extends MuiSelectionOptionValue> = {
  enabledOptions: MuiSelectionOption<T>[];
  selectedOptions: MuiSelectionOption<T>[];
  onItemSelect: (value: T) => void;
  pause: boolean;
};
export const useGetEnabledItems = <T extends MuiSelectionOptionValue>({
  enabledOptions,
  selectedOptions,
  onItemSelect,
  pause,
}: GetEnabledItemsProps<T>) => {
  return useMemo(
    () =>
      pause
        ? []
        : enabledOptions.map((option) => {
            const isFreeSoloOption = option.label.includes(freeSoloItemPrompt);
            return (
              <StyledMenuItem
                key={option.value}
                value={option.value}
                aria-label={option.label}
                onClick={() => onItemSelect(option.value)}
              >
                <StyledCheckbox
                  checked={
                    !!selectedOptions.find(
                      (selectedOption) => selectedOption.value === option.value
                    )
                  }
                  $presentAsPlaceholder={isFreeSoloOption}
                />

                <Typography variant={"body3"}>{option.label}</Typography>
              </StyledMenuItem>
            );
          }),
    [enabledOptions, onItemSelect, pause, selectedOptions]
  );
};

type GetDisabledItemsProps<T extends MuiSelectionOptionValue> = {
  disabledOptions: MuiSelectionOption<T>[];
  pause: boolean;
};

export const useGetDisabledItems = <T extends MuiSelectionOptionValue>({
  disabledOptions,
  pause,
}: GetDisabledItemsProps<T>) => {
  return useMemo(
    () =>
      pause
        ? []
        : disabledOptions.map((option) => {
            return (
              <Tooltip
                title={option.disabledTooltipContent}
                placement={"top"}
                key={option.value}
              >
                <DisabledMenuItem
                  key={option.value}
                  value={option.value}
                  aria-label={option.label}
                >
                  <DisabledCheckbox />
                  <Typography variant={"body3"}>{option.label}</Typography>
                </DisabledMenuItem>
              </Tooltip>
            );
          }),
    [disabledOptions, pause]
  );
};

type FilteredOptions<T extends MuiSelectionOptionValue> = {
  enabledOptions: MuiSelectionOption<T>[];
  disabledOptions: MuiSelectionOption<T>[];
  freeSoloOption?: MuiSelectionOption<T>[];
};

type GetOptionsBySearchTermParams<T extends MuiSelectionOptionValue> = {
  freeSolo: boolean;
  options: MuiSelectionOption<T>[];
  searchTerm: string;
};

export const useGetOptionsBySearchTerm = <T extends MuiSelectionOptionValue>({
  options,
  searchTerm,
  freeSolo,
}: GetOptionsBySearchTermParams<T>) => {
  return useMemo(() => {
    const filteredOptionsBySearch = options.filter(({ label }) =>
      label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const isSearchTermAnExistingOption = options.some(
      ({ label }) => label.toLowerCase() === searchTerm.toLowerCase()
    );

    const { enabledOptions, disabledOptions } = filteredOptionsBySearch.reduce<
      FilteredOptions<T>
    >(
      (acc, curr) => {
        if (curr.disabled) {
          acc.disabledOptions.push(curr);
        } else {
          acc.enabledOptions.push(curr);
        }
        return acc;
      },
      { enabledOptions: [], disabledOptions: [] }
    );

    if (freeSolo && !isSearchTermAnExistingOption && searchTerm) {
      enabledOptions.push({
        value: searchTerm as T,
        label: convertValueToFreeSoloOption(searchTerm),
      });
    }
    return {
      filteredOptionsBySearch,
      enabledOptions,
      disabledOptions,
    };
  }, [freeSolo, options, searchTerm]);
};
