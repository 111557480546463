import styled from "styled-components";

/**
 * @deprecated
 */
export const Overlay = styled.div.attrs({
  "data-testid": "overlay",
  role: "dialog",
})<{
  open: boolean;
  zIndex?: number;
}>`
  position: fixed;
  top: 0;
  right: 0;
  display: ${({ open }) => (open ? undefined : "none")};
  width: 100vw;
  height: 100vh;
  ${({ zIndex }) => zIndex !== undefined && `z-index: ${zIndex}`};
`;
