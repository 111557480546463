import React from "react";

import { IconProps } from "../types";

export const InfoCircleOutlined24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M13 6.5C13 6.76522 12.8946 7.01957 12.7071 7.20711C12.5196 7.39464 12.2652 7.5 12 7.5C11.7348 7.5 11.4804 7.39464 11.2929 7.20711C11.1054 7.01957 11 6.76522 11 6.5C11 6.23478 11.1054 5.98043 11.2929 5.79289C11.4804 5.60536 11.7348 5.5 12 5.5C12.2652 5.5 12.5196 5.60536 12.7071 5.79289C12.8946 5.98043 13 6.23478 13 6.5ZM10 10.25C10 10.0511 10.079 9.86032 10.2197 9.71967C10.3603 9.57902 10.5511 9.5 10.75 9.5H12.25C12.4489 9.5 12.6397 9.57902 12.7803 9.71967C12.921 9.86032 13 10.0511 13 10.25V16.5H13.75C13.9489 16.5 14.1397 16.579 14.2803 16.7197C14.421 16.8603 14.5 17.0511 14.5 17.25C14.5 17.4489 14.421 17.6397 14.2803 17.7803C14.1397 17.921 13.9489 18 13.75 18H10.75C10.5511 18 10.3603 17.921 10.2197 17.7803C10.079 17.6397 10 17.4489 10 17.25C10 17.0511 10.079 16.8603 10.2197 16.7197C10.3603 16.579 10.5511 16.5 10.75 16.5H11.5V11H10.75C10.5511 11 10.3603 10.921 10.2197 10.7803C10.079 10.6397 10 10.4489 10 10.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C7.06408 3.50089 9.48044 2.5 12 2.5C14.5196 2.5 16.9359 3.50089 18.7175 5.28249C20.4991 7.06408 21.5 9.48044 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C9.48044 21.5 7.06408 20.4991 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12Z"
      />
    </svg>
  );
};

InfoCircleOutlined24.defaultProps = {
  fill: "currentColor",
};
