import { useCallback } from "react";
import { ServiceIdentifierType } from "komodor-types";
import { useQueryClient } from "@tanstack/react-query";

import { useCreationPageContext } from "../context/CreationPageContext";
import { useCreateAppViewMutation } from "../../../../../../../generated/graphql";
import { boolDictionaryToStringList } from "../utils/creationPageUtils";
import { AppView } from "../../../types/appViewsTypes";
import { useAppViewsStore } from "../../../../../../../shared/store/appViewsStore/appViewsStore";
import {
  onAppViewUpsertSelector,
  setSelectedAppViewIdSelector,
} from "../../../../../../../shared/store/appViewsStore/appViewStoreSelectors";

import { useVerifyCreationForm } from "./useVerifyCreationForm";
import {
  useSetAppViewAndNavigate,
  useVerifyServerResponse,
} from "./creationPageHooks";
import { useGetAllAgentsInClustersSupportAtmData } from "./useDoesAgentSupportAtmData";

import { WORKSPACES_PATH } from "@/shared/hooks/workspaces-api/workspaces/useGetWorkspaces";

export const useSaveAppView = (): (() => Promise<void>) => {
  const queryClient = useQueryClient();
  const {
    selectedServiceIds,
    selectedServiceIdentifiers,
    appViewType,
    appName,
    setErrorState,
    description,
    setIsSavingAppView,
  } = useCreationPageContext();
  const onAppViewUpsert = useAppViewsStore(onAppViewUpsertSelector);
  const setSelectedAppViewId = useAppViewsStore(setSelectedAppViewIdSelector);

  const verifyCreationForm = useVerifyCreationForm();
  const verifyServerResponse = useVerifyServerResponse();
  const setAppViewAndNavigate = useSetAppViewAndNavigate();
  const getAllAgentsInClustersSupportAtmData =
    useGetAllAgentsInClustersSupportAtmData();

  const [, saveAppView] = useCreateAppViewMutation();

  return useCallback(async () => {
    if (!verifyCreationForm()) return;
    setErrorState({ appNameError: undefined });

    if (!getAllAgentsInClustersSupportAtmData()) {
      setErrorState({ agentVersionNeedsUpgradeError: true });
      return;
    }

    const serviceIdentifiers =
      (appViewType === ServiceIdentifierType.SERVICE_ID
        ? boolDictionaryToStringList(selectedServiceIds)
        : selectedServiceIdentifiers) ?? [];

    setIsSavingAppView(true);
    const { data, error } = await saveAppView({
      name: appName,
      description,
      serviceIdentifierType: appViewType,
      serviceIdentifiers,
    });
    setIsSavingAppView(false);

    const newlyCreatedAppViewId = data?.insert_app_view_one?.id || "";
    if (!verifyServerResponse(error, newlyCreatedAppViewId)) {
      return;
    }
    const newAppView: AppView = {
      id: newlyCreatedAppViewId,
      name: appName,
      description,
      serviceIdentifierType: appViewType ?? ServiceIdentifierType.SERVICE_ID,
      serviceIdentifiers,
    };
    setAppViewAndNavigate(newAppView);

    onAppViewUpsert(newAppView);
    queryClient.invalidateQueries([WORKSPACES_PATH]);
    setSelectedAppViewId(newlyCreatedAppViewId);
  }, [
    verifyCreationForm,
    setErrorState,
    getAllAgentsInClustersSupportAtmData,
    appViewType,
    selectedServiceIds,
    selectedServiceIdentifiers,
    setIsSavingAppView,
    saveAppView,
    appName,
    description,
    verifyServerResponse,
    setAppViewAndNavigate,
    onAppViewUpsert,
    queryClient,
    setSelectedAppViewId,
  ]);
};
