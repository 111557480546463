"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AccountPlan;
(function (AccountPlan) {
    AccountPlan["free"] = "free";
    AccountPlan["trial"] = "trial";
    AccountPlan["business"] = "business";
    AccountPlan["enterprise"] = "enterprise";
})(AccountPlan || (AccountPlan = {}));
exports.default = AccountPlan;
