import React from "react";
import styled from "styled-components";
import Chip from "@mui/material/Chip";
import { ChipProps } from "@mui/material/Chip/Chip";
import { capitalize } from "lodash";

import { Severity } from "../pages/Risks/RiskContent/RisksList/types";
import { SeverityToColorScheme } from "../../constants";

const StyledChip = styled(Chip)<{ severity: Severity }>`
  && {
    background-color: ${({ severity }) =>
      SeverityToColorScheme[severity].backgroundColor};
    color: ${({ severity }) => SeverityToColorScheme[severity].color};
    border-color: ${({ severity }) =>
      SeverityToColorScheme[severity].borderColor};
  }
`;

type SeverityChipProps = ChipProps & {
  severity: Severity;
  label?: string;
};

export const SeverityChip: React.FC<SeverityChipProps> = ({
  severity,
  label,
  ...rest
}) => {
  return (
    <StyledChip
      severity={severity}
      size={"small"}
      variant={"outlined"}
      label={label || capitalize(severity)}
      {...rest}
    />
  );
};
