import { useMemo } from "react";
import {
  Record,
  Partial,
  String,
  Static,
  Boolean,
  Array as RTArray,
} from "runtypes";

import {
  FetchLastAgentInfoQuery,
  useFetchAgentInfoByClusterSubscription,
  useFetchAgentInfoByIdQuery,
  useFetchAllActiveAgentInfosQuery,
  useFetchLastAgentInfoQuery,
} from "../../../generated/graphql";
import { useQueryRefreshWithVariables } from "../useQueryWithVariables";
import useSubscriptionWithVariables from "../useSubscriptionWithVariables";

export const ConfigurationType = Partial({
  appmetadata: Partial({
    version: String,
  }),
  watchnamespace: String.Or(RTArray(String)),
  enableagenttaskexecution: Boolean,
  enableagenttaskexecutionv2: Boolean.optional(),
  enablehelm: Boolean.optional(),
  daemon: Partial({
    enabled: Boolean,
  }),
  networkmapper: Partial({
    enable: Boolean,
  }),
  actions: Partial({
    basic: Boolean,
    advanced: Boolean,
    podexec: Boolean,
    portforward: Boolean,
  }),
  resources: Partial({
    job: Boolean,
    cronjob: Boolean,
    secret: Boolean,
    argoworkflows: Partial({
      cronworkflows: Boolean,
      workflows: Boolean,
      workflowtemplates: Boolean,
      clusterworkflowtemplates: Boolean,
    }),
  }),
  chartversion: String.optional(),
  monitoringproviders: RTArray(
    Record({
      type: String,
    })
  ).optional(),
});
export type Configuration = Static<typeof ConfigurationType>;

export type AgentInfo = FetchLastAgentInfoQuery["kubernetes_agent_info"][0] & {
  configuration: Configuration;
};

export const parseAgentInfo = (
  e: FetchLastAgentInfoQuery["kubernetes_agent_info"][0]
): AgentInfo => ({
  ...e,
  configuration: ConfigurationType.guard(e.configuration)
    ? e.configuration
    : {
        appmetadata: { version: "" },
        enableagenttaskexecution: false,
        enableagenttaskexecutionv2: false,
        watchnamespace: "all",
        resources: { job: false, cronjob: false },
      },
});

export interface AgentProperties {
  version: string;
  helmChartVersion?: string;
  clusterName: string;
  inactive: boolean;
  enableAgentTaskExecution: boolean;
  enableAgentTaskExecutionV2?: boolean;
  enableHelm?: boolean;
  watchNamespace: string | string[];
  daemon?: {
    enabled?: boolean;
  };
  networkMapper?: {
    enabled?: boolean;
  };
  actions: {
    basic: boolean;
    advanced: boolean;
    podExec?: boolean;
    portForward?: boolean;
  };
  resources: {
    job: boolean;
    cronJob: boolean;
    secret: boolean;
    argoWorkflows?: {
      cronWorkflows: boolean;
      workflows: boolean;
      workflowTemplates: boolean;
      clusterWorkflowTemplates: boolean;
    };
  };
  monitoringProviders: {
    type: string;
  }[];
}

export const agentInfoToProperties = (
  agentInfo: AgentInfo
): AgentProperties => {
  const agentInfoProps = {
    version: agentInfo.configuration?.appmetadata?.version ?? "0.0.0",
    clusterName: agentInfo.clusterName,
    inactive: agentInfo.inactive,
    watchNamespace: agentInfo.configuration?.watchnamespace ?? "all",
    enableAgentTaskExecution:
      !!agentInfo.configuration?.enableagenttaskexecution,
    enableAgentTaskExecutionV2:
      !!agentInfo.configuration?.enableagenttaskexecutionv2,
    daemon: {
      enabled: !!agentInfo.configuration?.daemon?.enabled,
    },
    networkMapper: {
      enabled: !!agentInfo.configuration?.networkmapper?.enable,
    },
    actions: {
      basic: !!agentInfo.configuration.actions?.basic,
      advanced: !!agentInfo.configuration.actions?.advanced,
      podExec: !!agentInfo.configuration.actions?.podexec,
      portForward: !!agentInfo.configuration.actions?.portforward,
    },
    resources: {
      cronJob: !!agentInfo.configuration.resources?.cronjob,
      job: !!agentInfo.configuration.resources?.job,
      secret: !!agentInfo.configuration.resources?.secret,
      argoWorkflows: {
        cronWorkflows:
          !!agentInfo.configuration.resources?.argoworkflows?.cronworkflows,
        workflows:
          !!agentInfo.configuration.resources?.argoworkflows?.workflows,
        workflowTemplates:
          !!agentInfo.configuration.resources?.argoworkflows?.workflowtemplates,
        clusterWorkflowTemplates:
          !!agentInfo.configuration.resources?.argoworkflows
            ?.clusterworkflowtemplates,
      },
    },
    monitoringProviders: agentInfo.configuration.monitoringproviders ?? [],
    enableHelm: agentInfo.configuration.enablehelm,
    helmChartVersion: agentInfo.configuration.chartversion,
  };
  if (agentInfoProps.version === "development") {
    agentInfoProps.version = "999.0.0+development";
  }
  return agentInfoProps;
};

type AgentInfoQueryResponse = {
  agentProperties: AgentProperties | null;
  fetching: boolean;
};

export const useAgentInfoById = (
  agentId: string | undefined | null,
  cluster?: string
): AgentInfoQueryResponse => {
  const fetchAgentByIdVars = useMemo(
    () => (agentId ? { agentId } : null),
    [agentId]
  );

  const [data, , fetching] = useQueryRefreshWithVariables(
    useFetchAgentInfoByIdQuery,
    fetchAgentByIdVars
  );

  return useMemo(() => {
    let agent = data?.kubernetes_agent_info[0];
    if (cluster) {
      agent = data?.kubernetes_agent_info.find(
        (kai) => kai.clusterName === cluster
      );
    }
    if (!agent) {
      return { agentProperties: null, fetching };
    }

    const agentInfo = parseAgentInfo(agent);
    return { agentProperties: agentInfoToProperties(agentInfo), fetching };
  }, [data?.kubernetes_agent_info, cluster, fetching]);
};

export const useAgentInfoSubscriptionById = (
  agentId: string | undefined
): AgentProperties | null => {
  const fetchAgentByIdVars = useMemo(
    () => (agentId ? { agentId } : null),
    [agentId]
  );

  const data = useSubscriptionWithVariables(
    useFetchAgentInfoByClusterSubscription,
    fetchAgentByIdVars
  );
  if (!data?.kubernetes_agent_info[0]) {
    return null;
  }
  const agentInfo = parseAgentInfo(data.kubernetes_agent_info[0]);
  return agentInfoToProperties(agentInfo);
};

export const useAllActiveAgentInfos = (): AgentProperties[] | null => {
  const [{ data }] = useFetchAllActiveAgentInfosQuery();

  if (!data?.kubernetes_agent_info) {
    return null;
  }

  const agentInfos = data.kubernetes_agent_info.map(parseAgentInfo);
  return agentInfos.map(agentInfoToProperties);
};

const useAgentInfo = (): AgentProperties | null => {
  const [{ data }] = useFetchLastAgentInfoQuery();

  if (!data?.kubernetes_agent_info[0]) {
    return null;
  }

  const agentInfo = parseAgentInfo(data.kubernetes_agent_info[0]);
  return agentInfoToProperties(agentInfo);
};

export default useAgentInfo;
