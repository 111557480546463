import { sortBy, uniqBy } from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Kubernetes_Agent_Info,
  ListKubernetesAgentsQuery,
  useListKubernetesAgentsQuery,
} from "../../generated/graphql";
import { isSandbox } from "../utils/sandbox";
import { LocalStorageItem } from "../utils/localStorageSettings";

import { useStateInLocalStorage } from "./state";
import useQueryPolling from "./useQueryPolling";
import { useIsAccountSetUp } from "./useUserMetadata/utils";

export type AgentType = Pick<
  Kubernetes_Agent_Info,
  | "id"
  | "clusterName"
  | "configuration"
  | "inactive"
  | "lastSeenAlive"
  | "createdAt"
  | "installationId"
>;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isAgentsInstalledLocalStorage = new LocalStorageItem(
  "isAgentsInstalled"
);

const AgentsContext = createContext<ListKubernetesAgentsQuery | undefined>(
  undefined
);

const useSaveLocalStorageAgentsState = (
  agents: ListKubernetesAgentsQuery | undefined
): void => {
  const [isAgentsInstalled, setIsAgentsInstalled] = useStateInLocalStorage(
    isAgentsInstalledLocalStorage,
    ""
  );
  useEffect(() => {
    if (isSandbox()) return;
    if (isAgentsInstalled === "true") return;
    const isActiveAgents = agents?.activeAgents?.length ? "true" : "";
    setIsAgentsInstalled(isActiveAgents);
  }, [agents, isAgentsInstalled, setIsAgentsInstalled]);
};

export const AgentsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const isAccountSetUp = useIsAccountSetUp();
  const [agentsData, setAgentsData] = useState<
    ListKubernetesAgentsQuery | undefined
  >();
  const [data] = useQueryPolling(
    useListKubernetesAgentsQuery({ pause: !isAccountSetUp }),
    15000,
    !isAccountSetUp
  );
  //avoid rerendering when data is the same
  useEffect(() => {
    if (JSON.stringify(data) === JSON.stringify(agentsData)) {
      return;
    }
    setAgentsData(data);
  }, [agentsData, data]);
  useSaveLocalStorageAgentsState(data);
  return (
    <AgentsContext.Provider value={agentsData}>
      {children}
    </AgentsContext.Provider>
  );
};

const useAgents = (): ListKubernetesAgentsQuery | undefined =>
  useContext(AgentsContext);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const findActiveAgent = (
  agents: ListKubernetesAgentsQuery | undefined,
  clusterName: string | undefined
): string | undefined => {
  if (!clusterName) {
    return undefined;
  }
  return findActiveAgentByClusterName(agents, clusterName)?.id;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const findActiveAgentByClusterName = (
  agents: ListKubernetesAgentsQuery | undefined,
  clusterName: string
): AgentType | undefined => {
  const agentWithTheClusterName = agents?.activeAgents.filter(
    (a) => a.clusterName === clusterName
  );
  return agentWithTheClusterName?.sort(
    (a, b) =>
      new Date(b.lastSeenAlive).getTime() - new Date(a.lastSeenAlive).getTime()
  )?.[0];
};

const useFindActiveAgent = (
  agents: ListKubernetesAgentsQuery | undefined,
  clusterName: string | undefined
): string | undefined => {
  return useMemo(() => {
    return findActiveAgent(agents, clusterName);
  }, [agents, clusterName]);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgent = (
  clusterName: string | undefined
): string | undefined => {
  const agents = useAgents();
  return useFindActiveAgent(agents, clusterName);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgentInfo = (
  clusterName: string | undefined
): AgentType | undefined => {
  const agents = useAgents();
  return useMemo(() => {
    if (!clusterName) return undefined;
    return findActiveAgentByClusterName(agents, clusterName);
  }, [agents, clusterName]);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useActiveAgents =
  (): ListKubernetesAgentsQuery["activeAgents"] => {
    const allAgents = useAgents();
    return useMemo(() => {
      if (!allAgents) return [];
      return sortBy(uniqBy(allAgents.activeAgents, "clusterName"), [
        "clusterName",
      ]);
    }, [allAgents]);
  };

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export default useAgents;
