import React, { useCallback, useEffect, useState } from "react";
import {
  Divider,
  Link,
  Tooltip,
  Typography,
} from "@komodorio/design-system/deprecated";
import { formatDistance } from "date-fns";

import {
  InsightTooltipContainer,
  InsightTooltipDetails,
} from "../../common/Insight";
import { getColor } from "../../../../../Status";
import EventGroup from "../../../../../../EventGroup";
import { AriaLabels } from "../../../../../../../../shared/config/ariaLabels";
import { useMinifiedMonitorEventGroupById } from "../../../../../../../monitorsView/useWorkflowsRuns";
import { SameIssueInClusterInsightDataParsed } from "../../common/types";

export const SAME_ISSUE_IN_CLUSTER_TOOLTIP = "same-reason-in-cluster-tooltip";

export const SameIssueInClusterTooltip: React.FC<{
  issues: SameIssueInClusterInsightDataParsed[];
  issueStartTime: Date;
  setCorrelatedEvent: (event: EventGroup | null) => void;
  serviceId: string;
}> = ({ issues, issueStartTime, setCorrelatedEvent, serviceId }) => {
  const [eventId, setEventId] = useState<string>();
  const correlatedIssue = useMinifiedMonitorEventGroupById({
    runId: eventId ?? "",
    serviceId,
    type: "availability",
  });
  const handleClick = useCallback(
    (issueId: string) => {
      if (eventId === issueId && correlatedIssue) {
        setCorrelatedEvent(correlatedIssue);
      } else {
        setEventId(issueId);
      }
    },
    [correlatedIssue, eventId, setCorrelatedEvent]
  );

  useEffect(() => {
    if (correlatedIssue) {
      setCorrelatedEvent(correlatedIssue);
    }
  }, [correlatedIssue, setCorrelatedEvent]);
  return (
    <Tooltip id={SAME_ISSUE_IN_CLUSTER_TOOLTIP} variant="light">
      <InsightTooltipContainer
        aria-label={AriaLabels.AvailabilityTriage.SameIssuesInsightTooltip}
      >
        {issues.map((issue, i) => (
          <React.Fragment key={issue.id}>
            <InsightTooltipDetails>
              <Typography>Started</Typography>
              <Typography bold>
                {formatDistance(issueStartTime, issue.startTime)} before current
                issue
              </Typography>
              <Typography>Namespace</Typography>
              <Typography bold>{issue.namespace}</Typography>
              <Typography>Service</Typography>
              <Typography bold>{issue.resourceName}</Typography>
              <Typography>Reason</Typography>
              <Typography bold>{issue.reasons.join(", ")}</Typography>
              <Typography>Status</Typography>
              <Typography variant="uppercase" color={getColor(issue.status)}>
                {issue.status}
              </Typography>
              <Link
                onClick={() => handleClick(issue.id)}
                aria-label={
                  AriaLabels.AvailabilityTriage.SameIssuesInsightTooltipLink
                }
              >
                View details
              </Link>
            </InsightTooltipDetails>
            {i < issues.length - 1 && <Divider spacing="1rem" />}
          </React.Fragment>
        ))}
      </InsightTooltipContainer>
    </Tooltip>
  );
};
