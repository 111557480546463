import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { SeverityChip } from "../../../../../../../shared/SeverityChip";
import { firstSeenDate, lastEvaluatedDate, title } from "../../../texts";
import { sectionGap } from "../../../../../../../../constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 592px;
`;

const Title = styled.div`
  display: flex;
  gap: 8px;
`;

const TimeContainer = styled.div`
  display: flex;
  gap: ${sectionGap};
`;

const TimeItemContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const Details: React.FC = () => {
  return (
    <Container>
      <Title>
        <Typography variant="h3">{title}</Typography>
        <SeverityChip severity="critical" />
      </Title>
      <Time firstSeen={firstSeenDate} lastEvaluated={lastEvaluatedDate} />
    </Container>
  );
};

const Time: React.FC<{ firstSeen: string; lastEvaluated: string }> = ({
  firstSeen,
  lastEvaluated,
}) => (
  <TimeContainer>
    <TimeItem title="First seen" value={firstSeen} />
    <TimeItem title="Last evaluated" value={lastEvaluated} />
  </TimeContainer>
);

const TimeItem: React.FC<{ title: string; value: string }> = ({
  title,
  value,
}) => (
  <TimeItemContainer>
    <Typography variant="body2" color={"text.secondary"}>
      {title}:
    </Typography>
    <Typography variant="body2" color={"text.primary"}>
      {value}
    </Typography>
  </TimeItemContainer>
);
