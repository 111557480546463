import { useMemo } from "react";

import { useAccountName } from "./useAccountName";
import useKomodorServices from "./useKomodorServices";
import { parseKomodorUid } from "./resources-api/resourcesAPIUtils";

export const useGetServiceIdFromKomodorUid = (
  komodorUid?: string,
  serviceShouldExist = true
) => {
  const accountName = useAccountName();
  const servicesDic = useKomodorServices().servicesAsDictionary.all;

  return useMemo(() => {
    if (!komodorUid) return;
    const {
      kind = "",
      cluster = "",
      namespace = "",
      name = "",
    } = parseKomodorUid(komodorUid) ?? {};

    const serviceId = `${accountName}.${cluster}-${namespace}.${name}`;

    if (servicesDic[serviceId] || !serviceShouldExist)
      return { serviceId, serviceKind: kind };

    return undefined;
  }, [accountName, komodorUid, serviceShouldExist, servicesDic]);
};
