import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";

import { theme } from "../../../theme";
import { IconProps } from "../../../icons/types";

const buttonTextStyle = css`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
`;
const normalButtonTextStyle = css`
  ${buttonTextStyle}
  font-size: 14px;
  line-height: 20px;
`;
const smallButtonTextStyle = css`
  ${buttonTextStyle}
  font-size: 12px;
  line-height: 16px;
`;

const primaryButtonStyle = css`
  color: ${theme.button.primary.text};
  background-color: ${theme.button.primary.bg};
  border: 1px solid ${theme.button.primary.bg};
  :hover:enabled {
    background-color: ${theme.button.primary.hoverBg};
    border: 1px solid ${theme.button.primary.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.button.primary.pressedBg};
    border: 1px solid ${theme.button.primary.pressedBg};
  }
  :disabled {
    background-color: ${theme.button.primary.disabledBg};
    border: 1px solid ${theme.button.primary.disabledBg};
    color: ${theme.button.primary.textDisabled};
    cursor: not-allowed;
  }
`;
const secondaryButtonStyle = css`
  color: ${theme.button.secondary.text};
  background-color: ${theme.button.secondary.bg};
  border: 1px solid ${theme.button.secondary.border};
  :hover:enabled {
    background-color: ${theme.button.secondary.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.button.secondary.pressedBg};
  }
  :disabled {
    color: ${theme.button.secondary.textDisabled};
    cursor: not-allowed;
  }
`;
const defaultButtonStyle = css`
  color: ${theme.button.text};
  background-color: ${theme.button.bg};
  border: 1px solid ${theme.button.border};
  :hover:enabled {
    background-color: ${theme.button.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.button.pressedBg};
  }
  :disabled {
    color: ${theme.button.textDisabled};
    cursor: not-allowed;
  }
`;

const dangerButtonStyle = css`
  color: ${theme.button.danger.text};
  background-color: ${theme.button.danger.bg};
  border: 1px solid ${theme.button.danger.bg};
  :hover:enabled {
    background-color: ${theme.button.danger.hoverBg};
    border: 1px solid ${theme.button.danger.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.button.danger.pressedBg};
    border: 1px solid ${theme.button.danger.pressedBg};
  }
  :disabled {
    color: ${theme.button.danger.textDisabled};
    background-color: ${theme.button.danger.disabledBg};
    cursor: not-allowed;
    border: 1px solid ${theme.button.danger.disabledBg};
  }
`;

const dangerOutlineButtonStyle = css`
  color: ${theme.button.dangerOutline.text};
  background-color: ${theme.button.dangerOutline.bg};
  border: 1px solid ${theme.button.dangerOutline.border};
  :hover:enabled {
    background-color: ${theme.button.dangerOutline.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.button.dangerOutline.pressedBg};
  }
  :disabled {
    color: ${theme.button.dangerOutline.textDisabled};
    cursor: not-allowed;
  }
`;

/** @deprecated */
export type ButtonSize = "small" | "medium" | "large";
/** @deprecated */
export type ButtonVariant =
  | "primary"
  | "secondary"
  | "default"
  | "danger"
  | "dangerOutline";
/** @deprecated */
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  size?: ButtonSize;
  icon?: React.FC<IconProps>;
  width?: string;
}

const Container = styled.button<ButtonProps>`
  border: none;
  ${switchProp("size", {
    small: smallButtonTextStyle,
    medium: normalButtonTextStyle,
    large: normalButtonTextStyle,
  })}
  ${switchProp("variant", {
    primary: primaryButtonStyle,
    secondary: secondaryButtonStyle,
    default: defaultButtonStyle,
    danger: dangerButtonStyle,
    dangerOutline: dangerOutlineButtonStyle,
  })}
  cursor: pointer;
  padding: ${switchProp("size", {
    small: "5px 11px",
    medium: "5px 15px",
    large: "9px 15px",
  })};
  display: flex;
  border-radius: 4px;
  height: max-content;
  width: ${({ width }) => width};
  column-gap: 0.3rem;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
`;

/**
 * @deprecated Use MUI Button instead.
 */
export const Button: React.FC<ButtonProps> = ({
  icon: Icon,
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      {Icon && <Icon />}
      {children}
    </Container>
  );
};

Button.defaultProps = {
  variant: "default",
  size: "medium",
  disabled: false,
  width: "fit-content",
};
