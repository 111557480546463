import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import criticalIssuesItemClose from "../assets/cluster2/criticalIssuesItemClose.png";
import criticalIssuesItemOpen from "../assets/cluster2/criticalIssuesItemOpen.png";
import { ClickableArea } from "../styles";
import { SERVICES } from "../../routes/routes";

import { Item } from "./Item";

const SelectableArea = styled(ClickableArea)`
  idth: 6.5%;
  height: 8.4%;
  top: 29.5%;
  left: 90.9%;
  border-radius: 4px;
`;

export const CriticalIssuesItem: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Item
      closedImg={criticalIssuesItemClose}
      openedImg={criticalIssuesItemOpen}
      noHover
    >
      <SelectableArea
        onClick={() =>
          navigate(`/${SERVICES}/demo.production-demo.events-puller-service`)
        }
      />
    </Item>
  );
};
