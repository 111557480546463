import { useCallback } from "react";

import useKomodorServices from "../../../../../../../shared/hooks/useKomodorServices";
import useAgents from "../../../../../../../shared/hooks/useAgents";
import { useCreationPageContext } from "../context/CreationPageContext";
import { doesAgentSupportAtmLiveData } from "../utils/creationPageUtils";

export const useGetAllAgentsInClustersSupportAtmData = (): (() => boolean) => {
  const { selectedServiceIds } = useCreationPageContext();
  const unscopedServices = useKomodorServices().unscopedServices;
  const agents = useAgents();

  return useCallback(() => {
    const foundServices = unscopedServices?.filter(
      (service) => selectedServiceIds[service.id]
    );
    return !!foundServices?.every((service) => {
      const clusterName = service.k8sCluster;
      return doesAgentSupportAtmLiveData(agents, clusterName);
    });
  }, [agents, selectedServiceIds, unscopedServices]);
};
