/* eslint-disable max-lines */
import React, { useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { subHours } from "date-fns";
import { ResourceTableModelRow } from "komodor-types";

import { Timeframe } from "../../../shared/types/TimeWindow";
import Divider from "../../monitorsView/common/Divider";
import { AgentType, useActiveAgentInfo } from "../../../shared/hooks/useAgents";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import { getRoutePathFromKubernetesResource } from "../inspectionConfiguration/SupportedKubernetesResources";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ResourceListErrorState from "../ResourceListErrorState";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import useInspectionFilters from "../filters/useInspectionFilters";
import { Container } from "../styles";
import { KubernetesResource } from "../inspectionConfiguration/SupportedResourcesTypes";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import MainViewContent from "../getMainViewContent";
import { AgentProperties } from "../../../shared/hooks/useAgentInfo/useAgentInfo";
import { INSPECTION_PERMISSIONS_ERROR } from "../../../shared/hooks/ATM/constants/atmResources";
import { useFilteredClusters } from "../filters/useFilteredClusters";
// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { InspectionFilters } from "../filters/InspectionFilters";
import { KubernetesStatefulSetResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesStatefulSetResource";
import { KubernetesDaemonSetResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesDaemonSetResource";
import { KubernetesDeploymentResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesDeploymentResource";
import useKomodorServices from "../../../shared/hooks/useKomodorServices";
import { ServiceStatus } from "../../ResourceView/headers/KomodorServiceHealthAvailability";
import { buildServiceId } from "../../../shared/utils/serviceHelpers";
import { KubernetesJobResource } from "../inspectionConfiguration/supportedResourcesTypes/KubernetesJobResource";
import { useAccountName } from "../../../shared/hooks/useAccountName";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import { NamespacesResult } from "./InspectionViewAtm";
import { useReportLoadingTimeToDatadog } from "./inpectionViewsHooks";

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const initialTimeWindow = {
  start: subHours(new Date(), 1),
  end: new Date(),
  timeframe: Timeframe.LastHour,
};
export interface InspectionComponentProps {
  kubernetesResource: KubernetesResource;
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  agentInfo: AgentProperties | null;
  agentId: string;
}
interface InspectionParams {
  cluster: string | undefined;
  onClusterChange: (clusterName: string) => void;
  agentId: string | undefined;
  agentInfo: AgentType | undefined;
}

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useInspectionParams = (
  kubernetesResource: KubernetesResource
): InspectionParams => {
  const resourceName = kubernetesResource.NameInK8S;
  useEffect(() => {
    dispatchEvent(AnalyticEvents.Inspection.Inspection_resource_list_start, {
      resourceName: resourceName,
    });
  }, [resourceName]);

  const { cluster } = useParams<{
    cluster: string | undefined;
  }>();
  const navigate = useNavigate();
  const onClusterChange = useCallback(
    (clusterName: string) => {
      const route = getRoutePathFromKubernetesResource(
        kubernetesResource,
        clusterName
      );
      if (route !== window.location.pathname) {
        navigate(route);
      }
    },
    [kubernetesResource, navigate]
  );

  const agentInfo = useActiveAgentInfo(cluster);

  return {
    cluster,
    onClusterChange,
    agentInfo,
    agentId: agentInfo?.id,
  };
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isDeploymentSTSOrDS = (kind: string): boolean =>
  [
    KubernetesDeploymentResource.Kind,
    KubernetesStatefulSetResource.Kind,
    KubernetesDaemonSetResource.Kind,
  ].includes(kind);

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const isJobFn = (kind: string): boolean =>
  KubernetesJobResource.Kind === kind;

const getResourceName = (kubernetesResource: KubernetesResource) => {
  return kubernetesResource.isDeletedPods
    ? "DeletedPods"
    : kubernetesResource.NameInK8S;
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const useResourceListWithMonitors = (
  kind: string,
  resourceList: ResourceListResponse,
  cluster: string
): ResourceListResponse => {
  const accountName = useAccountName();
  const { servicesAsDictionary } = useKomodorServices();

  const isService = isDeploymentSTSOrDS(kind);
  const isJob = isJobFn(kind);

  return useMemo(() => {
    return isService || isJob
      ? {
          ...resourceList,
          rows: resourceList.rows.map((row) => {
            const serviceId = buildServiceId(
              accountName,
              cluster,
              row.namespace,
              row.name
            );
            const { healthy } = servicesAsDictionary.all[serviceId] ?? {};
            return isService
              ? {
                  ...row,
                  health:
                    healthy === undefined
                      ? ""
                      : healthy
                      ? ServiceStatus.HEALTHY
                      : ServiceStatus.UNHEALTHY,
                }
              : { ...row, status: row.status };
          }),
        }
      : resourceList;
  }, [
    isService,
    isJob,
    resourceList,
    accountName,
    cluster,
    servicesAsDictionary.all,
  ]);
};
export interface ResourceListResponse {
  rows: ResourceTableModelRow[];
  fetching: boolean;
  errorMessage: string;
  isPaginating?: boolean;
  emptyResult: boolean;
}
const InspectionViewComponent: React.FC<{
  cluster: string;
  onClusterChange: (clusterName: string) => void;
  agentUpgradeRequired?: boolean;
  resourceList: ResourceListResponse;
  kubernetesResource: KubernetesResource;
  refresh: () => void;
  namespaces?: NamespacesResult;
  shouldUseLiveData?: boolean;
}> = ({
  cluster,
  onClusterChange,
  agentUpgradeRequired = false,
  resourceList,
  kubernetesResource,
  refresh,
  namespaces: namespacesResult,
  shouldUseLiveData = false,
}) => {
  const resourceListWithMonitors = useResourceListWithMonitors(
    kubernetesResource.Kind,
    resourceList,
    cluster
  );
  const filteredTableResults = useInspectionFilters(
    resourceListWithMonitors,
    kubernetesResource
  );
  const { filteredClusterSuggestions, clusterToUse } =
    useFilteredClusters(cluster);

  const hasError = !!(
    resourceListWithMonitors.errorMessage || namespacesResult?.errorMessage
  );

  useReportLoadingTimeToDatadog({
    kubernetesResourceName: getResourceName(kubernetesResource),
    clusterName: cluster,
    isFetching: hasError ? false : resourceList.fetching,
    hasError,
  });

  return (
    <Container>
      <InspectionFilters
        kubernetesResource={kubernetesResource}
        resourceList={resourceListWithMonitors}
        selectedCluster={clusterToUse}
        onClusterChange={onClusterChange}
        namespaces={namespacesResult?.namespaces}
        clustersOptions={filteredClusterSuggestions}
      />
      <Divider />
      <br />
      {hasError ? (
        <ResourceListErrorState
          resourceName={kubernetesResource.NameInK8S}
          errorMessage={
            namespacesResult?.errorMessage ||
            resourceListWithMonitors.errorMessage
          }
          refreshCallback={
            resourceListWithMonitors.errorMessage !==
            INSPECTION_PERMISSIONS_ERROR
              ? refresh
              : undefined
          }
        />
      ) : (
        <MainViewContent
          clusterName={clusterToUse}
          agentUpgradeRequired={agentUpgradeRequired}
          resourceList={resourceListWithMonitors}
          kubernetesResource={kubernetesResource}
          filteredTableResults={filteredTableResults}
          refresh={refresh}
          shouldUseLiveData={shouldUseLiveData}
        />
      )}
    </Container>
  );
};
export default InspectionViewComponent;
