/* tslint:disable */
/* eslint-disable */
/**
 * Addons API
 * This is an API that exposes addons related functionality
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { COLLECTION_FORMATS, RequiredError } from './base';

        
/**
 * 
 * @export
 */
export const Addon = {
    CertManager: 'certManager',
    ClusterAutoScaler: 'clusterAutoScaler',
    Workflow: 'workflow'
} as const;
export type Addon = typeof Addon[keyof typeof Addon];


export function instanceOfAddon(value: any): boolean {
    for (const key in Addon) {
        if (Object.prototype.hasOwnProperty.call(Addon, key)) {
            if (Addon[key as keyof typeof Addon] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AddonFromJSON(json: any): Addon {
    return AddonFromJSONTyped(json, false);
}

export function AddonFromJSONTyped(json: any, ignoreDiscriminator: boolean): Addon {
    return json as Addon;
}

export function AddonToJSON(value?: Addon | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const Aggregation = {
    Count: 'count',
    Sum: 'sum',
    Min: 'min',
    Max: 'max'
} as const;
export type Aggregation = typeof Aggregation[keyof typeof Aggregation];


export function instanceOfAggregation(value: any): boolean {
    for (const key in Aggregation) {
        if (Object.prototype.hasOwnProperty.call(Aggregation, key)) {
            if (Aggregation[key as keyof typeof Aggregation] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AggregationFromJSON(json: any): Aggregation {
    return AggregationFromJSONTyped(json, false);
}

export function AggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Aggregation {
    return json as Aggregation;
}

export function AggregationToJSON(value?: Aggregation | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface Certificate
 */
export interface Certificate {
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'issuerName': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'secretName': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'age': string;
    /**
     * 
     * @type {string}
     * @memberof Certificate
     */
    'expiration'?: string;
    /**
     * 
     * @type {CertificateStatusEnum}
     * @memberof Certificate
     */
    'status': CertificateStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof Certificate
     */
    'workloads'?: Array<string>;
}




/**
 * Check if a given object implements the Certificate interface.
 */
export function instanceOfCertificate(value: object): value is Certificate {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('issuerName' in value) || value['issuerName'] === undefined) return false;
    if (!('secretName' in value) || value['secretName'] === undefined) return false;
    if (!('age' in value) || value['age'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CertificateFromJSON(json: any): Certificate {
    return CertificateFromJSONTyped(json, false);
}

export function CertificateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Certificate {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'id': json['id'],
        'uid': json['uid'],
        'name': json['name'],
        'clusterName': json['clusterName'],
        'namespace': json['namespace'],
        'issuerName': json['issuerName'],
        'secretName': json['secretName'],
        'age': json['age'],
        'expiration': json['expiration'] == null ? undefined : json['expiration'],
        'status': CertificateStatusEnumFromJSON(json['status']),
        'workloads': json['workloads'] == null ? undefined : json['workloads'],
    };
}

export function CertificateToJSON(value?: Certificate | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'id': value['id'],
        'uid': value['uid'],
        'name': value['name'],
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'issuerName': value['issuerName'],
        'secretName': value['secretName'],
        'age': value['age'],
        'expiration': value['expiration'],
        'status': CertificateStatusEnumToJSON(value['status']),
        'workloads': value['workloads'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateController
 */
export interface CertificateController {
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'version': string;
    /**
     * 
     * @type {SupportedK8sVersions}
     * @memberof CertificateController
     */
    'supportedK8sVersions': SupportedK8sVersions;
    /**
     * 
     * @type {string}
     * @memberof CertificateController
     */
    'status': CertificateControllerStatusEnum;
}


/**
 * @export
 */
export const CertificateControllerStatusEnum = {
    Healthy: 'healthy',
    Unhealthy: 'unhealthy'
} as const;
export type CertificateControllerStatusEnum = typeof CertificateControllerStatusEnum[keyof typeof CertificateControllerStatusEnum];



/**
 * Check if a given object implements the CertificateController interface.
 */
export function instanceOfCertificateController(value: object): value is CertificateController {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('version' in value) || value['version'] === undefined) return false;
    if (!('supportedK8sVersions' in value) || value['supportedK8sVersions'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CertificateControllerFromJSON(json: any): CertificateController {
    return CertificateControllerFromJSONTyped(json, false);
}

export function CertificateControllerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateController {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'id': json['id'],
        'uid': json['uid'],
        'name': json['name'],
        'clusterName': json['clusterName'],
        'namespace': json['namespace'],
        'version': json['version'],
        'supportedK8sVersions': SupportedK8sVersionsFromJSON(json['supportedK8sVersions']),
        'status': json['status'],
    };
}

export function CertificateControllerToJSON(value?: CertificateController | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'id': value['id'],
        'uid': value['uid'],
        'name': value['name'],
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'version': value['version'],
        'supportedK8sVersions': SupportedK8sVersionsToJSON(value['supportedK8sVersions']),
        'status': value['status'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateFull
 */
export interface CertificateFull {
    /**
     * 
     * @type {string}
     * @memberof CertificateFull
     */
    'type': string;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof CertificateFull
     */
    'json': { [key: string]: any; };
    /**
     * 
     * @type {CertificateFullObject}
     * @memberof CertificateFull
     */
    'object': CertificateFullObject;
}


/**
 * Check if a given object implements the CertificateFull interface.
 */
export function instanceOfCertificateFull(value: object): value is CertificateFull {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('json' in value) || value['json'] === undefined) return false;
    if (!('object' in value) || value['object'] === undefined) return false;
    return true;
}

export function CertificateFullFromJSON(json: any): CertificateFull {
    return CertificateFullFromJSONTyped(json, false);
}

export function CertificateFullFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFull {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'json': json['json'],
        'object': CertificateFullObjectFromJSON(json['object']),
    };
}

export function CertificateFullToJSON(value?: CertificateFull | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'json': value['json'],
        'object': CertificateFullObjectToJSON(value['object']),
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullHeader
 */
export interface CertificateFullHeader {
    /**
     * 
     * @type {string}
     * @memberof CertificateFullHeader
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullHeader
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullHeader
     */
    'clusterName': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullHeader
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullHeader
     */
    'age': string;
    /**
     * 
     * @type {number}
     * @memberof CertificateFullHeader
     */
    'generation': number;
    /**
     * 
     * @type {CertificateStatusEnum}
     * @memberof CertificateFullHeader
     */
    'status': CertificateStatusEnum;
}




/**
 * Check if a given object implements the CertificateFullHeader interface.
 */
export function instanceOfCertificateFullHeader(value: object): value is CertificateFullHeader {
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('age' in value) || value['age'] === undefined) return false;
    if (!('generation' in value) || value['generation'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CertificateFullHeaderFromJSON(json: any): CertificateFullHeader {
    return CertificateFullHeaderFromJSONTyped(json, false);
}

export function CertificateFullHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullHeader {
    if (json == null) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'name': json['name'],
        'clusterName': json['clusterName'],
        'namespace': json['namespace'],
        'age': json['age'],
        'generation': json['generation'],
        'status': CertificateStatusEnumFromJSON(json['status']),
    };
}

export function CertificateFullHeaderToJSON(value?: CertificateFullHeader | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'uid': value['uid'],
        'name': value['name'],
        'clusterName': value['clusterName'],
        'namespace': value['namespace'],
        'age': value['age'],
        'generation': value['generation'],
        'status': CertificateStatusEnumToJSON(value['status']),
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullMetadata
 */
export interface CertificateFullMetadata {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CertificateFullMetadata
     */
    'labels': { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CertificateFullMetadata
     */
    'annotations': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof CertificateFullMetadata
     */
    'creationTimestamp': string;
}


/**
 * Check if a given object implements the CertificateFullMetadata interface.
 */
export function instanceOfCertificateFullMetadata(value: object): value is CertificateFullMetadata {
    if (!('labels' in value) || value['labels'] === undefined) return false;
    if (!('annotations' in value) || value['annotations'] === undefined) return false;
    if (!('creationTimestamp' in value) || value['creationTimestamp'] === undefined) return false;
    return true;
}

export function CertificateFullMetadataFromJSON(json: any): CertificateFullMetadata {
    return CertificateFullMetadataFromJSONTyped(json, false);
}

export function CertificateFullMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullMetadata {
    if (json == null) {
        return json;
    }
    return {
        
        'labels': json['labels'],
        'annotations': json['annotations'],
        'creationTimestamp': json['creationTimestamp'],
    };
}

export function CertificateFullMetadataToJSON(value?: CertificateFullMetadata | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'labels': value['labels'],
        'annotations': value['annotations'],
        'creationTimestamp': value['creationTimestamp'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullObject
 */
export interface CertificateFullObject {
    /**
     * 
     * @type {CertificateFullHeader}
     * @memberof CertificateFullObject
     */
    'header': CertificateFullHeader;
    /**
     * 
     * @type {CertificateFullRelatedResources}
     * @memberof CertificateFullObject
     */
    'relatedResources': CertificateFullRelatedResources;
    /**
     * 
     * @type {CertificateFullMetadata}
     * @memberof CertificateFullObject
     */
    'metadata': CertificateFullMetadata;
    /**
     * 
     * @type {CertificateFullSpec}
     * @memberof CertificateFullObject
     */
    'spec': CertificateFullSpec;
    /**
     * 
     * @type {CertificateFullStatus}
     * @memberof CertificateFullObject
     */
    'status': CertificateFullStatus;
}


/**
 * Check if a given object implements the CertificateFullObject interface.
 */
export function instanceOfCertificateFullObject(value: object): value is CertificateFullObject {
    if (!('header' in value) || value['header'] === undefined) return false;
    if (!('relatedResources' in value) || value['relatedResources'] === undefined) return false;
    if (!('metadata' in value) || value['metadata'] === undefined) return false;
    if (!('spec' in value) || value['spec'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CertificateFullObjectFromJSON(json: any): CertificateFullObject {
    return CertificateFullObjectFromJSONTyped(json, false);
}

export function CertificateFullObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullObject {
    if (json == null) {
        return json;
    }
    return {
        
        'header': CertificateFullHeaderFromJSON(json['header']),
        'relatedResources': CertificateFullRelatedResourcesFromJSON(json['relatedResources']),
        'metadata': CertificateFullMetadataFromJSON(json['metadata']),
        'spec': CertificateFullSpecFromJSON(json['spec']),
        'status': CertificateFullStatusFromJSON(json['status']),
    };
}

export function CertificateFullObjectToJSON(value?: CertificateFullObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'header': CertificateFullHeaderToJSON(value['header']),
        'relatedResources': CertificateFullRelatedResourcesToJSON(value['relatedResources']),
        'metadata': CertificateFullMetadataToJSON(value['metadata']),
        'spec': CertificateFullSpecToJSON(value['spec']),
        'status': CertificateFullStatusToJSON(value['status']),
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullRelatedResources
 */
export interface CertificateFullRelatedResources {
    /**
     * 
     * @type {CertificateRelatedResource}
     * @memberof CertificateFullRelatedResources
     */
    'issuer': CertificateRelatedResource;
    /**
     * 
     * @type {CertificateRelatedResource}
     * @memberof CertificateFullRelatedResources
     */
    'certificateRequest': CertificateRelatedResource;
    /**
     * 
     * @type {CertificateRelatedResource}
     * @memberof CertificateFullRelatedResources
     */
    'order': CertificateRelatedResource;
    /**
     * 
     * @type {CertificateRelatedResource}
     * @memberof CertificateFullRelatedResources
     */
    'challenge': CertificateRelatedResource;
    /**
     * 
     * @type {CertificateRelatedResource}
     * @memberof CertificateFullRelatedResources
     */
    'secret': CertificateRelatedResource;
    /**
     * 
     * @type {Array<CertificateRelatedResource>}
     * @memberof CertificateFullRelatedResources
     */
    'workloads'?: Array<CertificateRelatedResource>;
}


/**
 * Check if a given object implements the CertificateFullRelatedResources interface.
 */
export function instanceOfCertificateFullRelatedResources(value: object): value is CertificateFullRelatedResources {
    if (!('issuer' in value) || value['issuer'] === undefined) return false;
    if (!('certificateRequest' in value) || value['certificateRequest'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('challenge' in value) || value['challenge'] === undefined) return false;
    if (!('secret' in value) || value['secret'] === undefined) return false;
    return true;
}

export function CertificateFullRelatedResourcesFromJSON(json: any): CertificateFullRelatedResources {
    return CertificateFullRelatedResourcesFromJSONTyped(json, false);
}

export function CertificateFullRelatedResourcesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullRelatedResources {
    if (json == null) {
        return json;
    }
    return {
        
        'issuer': CertificateRelatedResourceFromJSON(json['issuer']),
        'certificateRequest': CertificateRelatedResourceFromJSON(json['certificateRequest']),
        'order': CertificateRelatedResourceFromJSON(json['order']),
        'challenge': CertificateRelatedResourceFromJSON(json['challenge']),
        'secret': CertificateRelatedResourceFromJSON(json['secret']),
        'workloads': json['workloads'] == null ? undefined : ((json['workloads'] as Array<any>).map(CertificateRelatedResourceFromJSON)),
    };
}

export function CertificateFullRelatedResourcesToJSON(value?: CertificateFullRelatedResources | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'issuer': CertificateRelatedResourceToJSON(value['issuer']),
        'certificateRequest': CertificateRelatedResourceToJSON(value['certificateRequest']),
        'order': CertificateRelatedResourceToJSON(value['order']),
        'challenge': CertificateRelatedResourceToJSON(value['challenge']),
        'secret': CertificateRelatedResourceToJSON(value['secret']),
        'workloads': value['workloads'] == null ? undefined : ((value['workloads'] as Array<any>).map(CertificateRelatedResourceToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullSpec
 */
export interface CertificateFullSpec {
    /**
     * 
     * @type {Array<string>}
     * @memberof CertificateFullSpec
     */
    'dnsNames': Array<string>;
    /**
     * 
     * @type {CertificateFullSpecIssuerRef}
     * @memberof CertificateFullSpec
     */
    'issuerRef': CertificateFullSpecIssuerRef;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullSpec
     */
    'secretName': string;
}


/**
 * Check if a given object implements the CertificateFullSpec interface.
 */
export function instanceOfCertificateFullSpec(value: object): value is CertificateFullSpec {
    if (!('dnsNames' in value) || value['dnsNames'] === undefined) return false;
    if (!('issuerRef' in value) || value['issuerRef'] === undefined) return false;
    if (!('secretName' in value) || value['secretName'] === undefined) return false;
    return true;
}

export function CertificateFullSpecFromJSON(json: any): CertificateFullSpec {
    return CertificateFullSpecFromJSONTyped(json, false);
}

export function CertificateFullSpecFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullSpec {
    if (json == null) {
        return json;
    }
    return {
        
        'dnsNames': json['dnsNames'],
        'issuerRef': CertificateFullSpecIssuerRefFromJSON(json['issuerRef']),
        'secretName': json['secretName'],
    };
}

export function CertificateFullSpecToJSON(value?: CertificateFullSpec | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dnsNames': value['dnsNames'],
        'issuerRef': CertificateFullSpecIssuerRefToJSON(value['issuerRef']),
        'secretName': value['secretName'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullSpecIssuerRef
 */
export interface CertificateFullSpecIssuerRef {
    /**
     * 
     * @type {string}
     * @memberof CertificateFullSpecIssuerRef
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullSpecIssuerRef
     */
    'kind': string;
}


/**
 * Check if a given object implements the CertificateFullSpecIssuerRef interface.
 */
export function instanceOfCertificateFullSpecIssuerRef(value: object): value is CertificateFullSpecIssuerRef {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('kind' in value) || value['kind'] === undefined) return false;
    return true;
}

export function CertificateFullSpecIssuerRefFromJSON(json: any): CertificateFullSpecIssuerRef {
    return CertificateFullSpecIssuerRefFromJSONTyped(json, false);
}

export function CertificateFullSpecIssuerRefFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullSpecIssuerRef {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'kind': json['kind'],
    };
}

export function CertificateFullSpecIssuerRefToJSON(value?: CertificateFullSpecIssuerRef | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'kind': value['kind'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateFullStatus
 */
export interface CertificateFullStatus {
    /**
     * 
     * @type {string}
     * @memberof CertificateFullStatus
     */
    'notBefore'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullStatus
     */
    'notAfter'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateFullStatus
     */
    'renewalTime'?: string;
    /**
     * 
     * @type {Array<CertificateStatusConditions>}
     * @memberof CertificateFullStatus
     */
    'conditions'?: Array<CertificateStatusConditions>;
}


/**
 * Check if a given object implements the CertificateFullStatus interface.
 */
export function instanceOfCertificateFullStatus(value: object): value is CertificateFullStatus {
    return true;
}

export function CertificateFullStatusFromJSON(json: any): CertificateFullStatus {
    return CertificateFullStatusFromJSONTyped(json, false);
}

export function CertificateFullStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateFullStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'notBefore': json['notBefore'] == null ? undefined : json['notBefore'],
        'notAfter': json['notAfter'] == null ? undefined : json['notAfter'],
        'renewalTime': json['renewalTime'] == null ? undefined : json['renewalTime'],
        'conditions': json['conditions'] == null ? undefined : ((json['conditions'] as Array<any>).map(CertificateStatusConditionsFromJSON)),
    };
}

export function CertificateFullStatusToJSON(value?: CertificateFullStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'notBefore': value['notBefore'],
        'notAfter': value['notAfter'],
        'renewalTime': value['renewalTime'],
        'conditions': value['conditions'] == null ? undefined : ((value['conditions'] as Array<any>).map(CertificateStatusConditionsToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface CertificateRelatedResource
 */
export interface CertificateRelatedResource {
    /**
     * 
     * @type {string}
     * @memberof CertificateRelatedResource
     */
    'komodorUid': string;
    /**
     * 
     * @type {RelatedResourceStatus}
     * @memberof CertificateRelatedResource
     */
    'status'?: RelatedResourceStatus;
    /**
     * 
     * @type {string}
     * @memberof CertificateRelatedResource
     */
    'type': string;
}


/**
 * Check if a given object implements the CertificateRelatedResource interface.
 */
export function instanceOfCertificateRelatedResource(value: object): value is CertificateRelatedResource {
    if (!('komodorUid' in value) || value['komodorUid'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function CertificateRelatedResourceFromJSON(json: any): CertificateRelatedResource {
    return CertificateRelatedResourceFromJSONTyped(json, false);
}

export function CertificateRelatedResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateRelatedResource {
    if (json == null) {
        return json;
    }
    return {
        
        'komodorUid': json['komodorUid'],
        'status': json['status'] == null ? undefined : RelatedResourceStatusFromJSON(json['status']),
        'type': json['type'],
    };
}

export function CertificateRelatedResourceToJSON(value?: CertificateRelatedResource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'komodorUid': value['komodorUid'],
        'status': RelatedResourceStatusToJSON(value['status']),
        'type': value['type'],
    };
}

        /**
 * 
 * @export
 * @interface CertificateStatusConditions
 */
export interface CertificateStatusConditions {
    /**
     * 
     * @type {string}
     * @memberof CertificateStatusConditions
     */
    'type': CertificateStatusConditionsTypeEnum;
    /**
     * 
     * @type {ConditionStatus}
     * @memberof CertificateStatusConditions
     */
    'status': ConditionStatus;
    /**
     * 
     * @type {string}
     * @memberof CertificateStatusConditions
     */
    'lastTransitionTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateStatusConditions
     */
    'reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof CertificateStatusConditions
     */
    'message'?: string;
}


/**
 * @export
 */
export const CertificateStatusConditionsTypeEnum = {
    Ready: 'Ready',
    Issuing: 'Issuing'
} as const;
export type CertificateStatusConditionsTypeEnum = typeof CertificateStatusConditionsTypeEnum[keyof typeof CertificateStatusConditionsTypeEnum];



/**
 * Check if a given object implements the CertificateStatusConditions interface.
 */
export function instanceOfCertificateStatusConditions(value: object): value is CertificateStatusConditions {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    return true;
}

export function CertificateStatusConditionsFromJSON(json: any): CertificateStatusConditions {
    return CertificateStatusConditionsFromJSONTyped(json, false);
}

export function CertificateStatusConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateStatusConditions {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'status': ConditionStatusFromJSON(json['status']),
        'lastTransitionTime': json['lastTransitionTime'] == null ? undefined : json['lastTransitionTime'],
        'reason': json['reason'] == null ? undefined : json['reason'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function CertificateStatusConditionsToJSON(value?: CertificateStatusConditions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'status': ConditionStatusToJSON(value['status']),
        'lastTransitionTime': value['lastTransitionTime'],
        'reason': value['reason'],
        'message': value['message'],
    };
}

        
/**
 * 
 * @export
 */
export const CertificateStatusEnum = {
    Expired: 'expired',
    Failed: 'failed',
    Renewing: 'renewing',
    Valid: 'valid',
    Unknown: 'unknown'
} as const;
export type CertificateStatusEnum = typeof CertificateStatusEnum[keyof typeof CertificateStatusEnum];


export function instanceOfCertificateStatusEnum(value: any): boolean {
    for (const key in CertificateStatusEnum) {
        if (Object.prototype.hasOwnProperty.call(CertificateStatusEnum, key)) {
            if (CertificateStatusEnum[key as keyof typeof CertificateStatusEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CertificateStatusEnumFromJSON(json: any): CertificateStatusEnum {
    return CertificateStatusEnumFromJSONTyped(json, false);
}

export function CertificateStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CertificateStatusEnum {
    return json as CertificateStatusEnum;
}

export function CertificateStatusEnumToJSON(value?: CertificateStatusEnum | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const ConditionStatus = {
    True: 'True',
    False: 'False',
    Unknown: 'Unknown'
} as const;
export type ConditionStatus = typeof ConditionStatus[keyof typeof ConditionStatus];


export function instanceOfConditionStatus(value: any): boolean {
    for (const key in ConditionStatus) {
        if (Object.prototype.hasOwnProperty.call(ConditionStatus, key)) {
            if (ConditionStatus[key as keyof typeof ConditionStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ConditionStatusFromJSON(json: any): ConditionStatus {
    return ConditionStatusFromJSONTyped(json, false);
}

export function ConditionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConditionStatus {
    return json as ConditionStatus;
}

export function ConditionStatusToJSON(value?: ConditionStatus | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const Entity = {
    Controller: 'controller',
    Certificate: 'certificate',
    WorkflowDag: 'workflowDag',
    WorkflowRun: 'workflowRun',
    WorkflowPod: 'workflowPod'
} as const;
export type Entity = typeof Entity[keyof typeof Entity];


export function instanceOfEntity(value: any): boolean {
    for (const key in Entity) {
        if (Object.prototype.hasOwnProperty.call(Entity, key)) {
            if (Entity[key as keyof typeof Entity] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EntityFromJSON(json: any): Entity {
    return EntityFromJSONTyped(json, false);
}

export function EntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entity {
    return json as Entity;
}

export function EntityToJSON(value?: Entity | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface EntityAggregation
 */
export interface EntityAggregation {
    /**
     * 
     * @type {string}
     * @memberof EntityAggregation
     */
    'key'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityAggregation
     */
    'value'?: number;
}


/**
 * Check if a given object implements the EntityAggregation interface.
 */
export function instanceOfEntityAggregation(value: object): value is EntityAggregation {
    return true;
}

export function EntityAggregationFromJSON(json: any): EntityAggregation {
    return EntityAggregationFromJSONTyped(json, false);
}

export function EntityAggregationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityAggregation {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function EntityAggregationToJSON(value?: EntityAggregation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface EntityAggregationResponse
 */
export interface EntityAggregationResponse {
    /**
     * 
     * @type {string}
     * @memberof EntityAggregationResponse
     */
    'mostRecentUpdate': string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityAggregationResponse
     */
    'isPaginating': boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityAggregationResponse
     */
    'sessionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityAggregationResponse
     */
    'count': number;
    /**
     * 
     * @type {Array<EntityAggregation>}
     * @memberof EntityAggregationResponse
     */
    'items': Array<EntityAggregation>;
}


/**
 * Check if a given object implements the EntityAggregationResponse interface.
 */
export function instanceOfEntityAggregationResponse(value: object): value is EntityAggregationResponse {
    if (!('mostRecentUpdate' in value) || value['mostRecentUpdate'] === undefined) return false;
    if (!('isPaginating' in value) || value['isPaginating'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('items' in value) || value['items'] === undefined) return false;
    return true;
}

export function EntityAggregationResponseFromJSON(json: any): EntityAggregationResponse {
    return EntityAggregationResponseFromJSONTyped(json, false);
}

export function EntityAggregationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityAggregationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'mostRecentUpdate': json['mostRecentUpdate'],
        'isPaginating': json['isPaginating'],
        'sessionId': json['sessionId'] == null ? undefined : json['sessionId'],
        'count': json['count'],
        'items': ((json['items'] as Array<any>).map(EntityAggregationFromJSON)),
    };
}

export function EntityAggregationResponseToJSON(value?: EntityAggregationResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mostRecentUpdate': value['mostRecentUpdate'],
        'isPaginating': value['isPaginating'],
        'sessionId': value['sessionId'],
        'count': value['count'],
        'items': ((value['items'] as Array<any>).map(EntityAggregationToJSON)),
    };
}

        /**
* @type EntityDataFull
* 
* @export
*/
export type EntityDataFull = { type: 'CertificateFull' } & CertificateFull | { type: 'WorkflowDag' } & WorkflowDag | { type: 'WorkflowPod' } & WorkflowPod | { type: 'WorkflowRun' } & WorkflowRun;


export function EntityDataFullFromJSON<T extends keyof EntityDataFullTypeMap>(json: any): EntityDataFullType<T> {
  const type = json['type'];
  return EntityDataFullFromJSONTyped<T>(type, json, false);
}

type EntityDataFullTypeMap = {
    'CertificateFull': { type: 'CertificateFull' } & CertificateFull;
    'WorkflowDag': { type: 'WorkflowDag' } & WorkflowDag;
    'WorkflowPod': { type: 'WorkflowPod' } & WorkflowPod;
    'WorkflowRun': { type: 'WorkflowRun' } & WorkflowRun;
};

type EntityDataFullType<T extends keyof EntityDataFullTypeMap> = EntityDataFullTypeMap[T];

export function EntityDataFullFromJSONTyped<T extends keyof EntityDataFullTypeMap>(type: T, json: any, ignoreDiscriminator: boolean): EntityDataFullTypeMap[T] {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'CertificateFull':
      return Object.assign({}, CertificateFullFromJSONTyped(json, true), { type: 'CertificateFull' } as const) as EntityDataFullTypeMap[T];
    case 'WorkflowDag':
      return Object.assign({}, WorkflowDagFromJSONTyped(json, true), { type: 'WorkflowDag' } as const) as EntityDataFullTypeMap[T];
    case 'WorkflowPod':
      return Object.assign({}, WorkflowPodFromJSONTyped(json, true), { type: 'WorkflowPod' } as const) as EntityDataFullTypeMap[T];
    case 'WorkflowRun':
      return Object.assign({}, WorkflowRunFromJSONTyped(json, true), { type: 'WorkflowRun' } as const) as EntityDataFullTypeMap[T];
    default:
      throw new Error(`No variant of EntityDataFull exists with 'type=${json['type']}'`);
  }
}

export function EntityDataFullToJSON(value?: EntityDataFull | null): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'CertificateFull':
      return CertificateFullToJSON(value);
    case 'WorkflowDag':
      return WorkflowDagToJSON(value);
    case 'WorkflowPod':
      return WorkflowPodToJSON(value);
    case 'WorkflowRun':
      return WorkflowRunToJSON(value);
    default:
      throw new Error(`No variant of EntityDataFull exists with 'type=${value['type']}'`);
  }

}

        /**
 * 
 * @export
 * @interface EntityListResponse
 */
export interface EntityListResponse {
    /**
     * 
     * @type {string}
     * @memberof EntityListResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof EntityListResponse
     */
    'mostRecentUpdate': string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityListResponse
     */
    'isPaginating': boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityListResponse
     */
    'sessionId'?: string;
    /**
     * 
     * @type {Array<EntityObj>}
     * @memberof EntityListResponse
     */
    'data': Array<EntityObj>;
    /**
     * 
     * @type {ResponseMeta}
     * @memberof EntityListResponse
     */
    'meta': ResponseMeta;
}


/**
 * Check if a given object implements the EntityListResponse interface.
 */
export function instanceOfEntityListResponse(value: object): value is EntityListResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('mostRecentUpdate' in value) || value['mostRecentUpdate'] === undefined) return false;
    if (!('isPaginating' in value) || value['isPaginating'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('meta' in value) || value['meta'] === undefined) return false;
    return true;
}

export function EntityListResponseFromJSON(json: any): EntityListResponse {
    return EntityListResponseFromJSONTyped(json, false);
}

export function EntityListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityListResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'mostRecentUpdate': json['mostRecentUpdate'],
        'isPaginating': json['isPaginating'],
        'sessionId': json['sessionId'] == null ? undefined : json['sessionId'],
        'data': ((json['data'] as Array<any>).map(EntityObjFromJSON)),
        'meta': ResponseMetaFromJSON(json['meta']),
    };
}

export function EntityListResponseToJSON(value?: EntityListResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'mostRecentUpdate': value['mostRecentUpdate'],
        'isPaginating': value['isPaginating'],
        'sessionId': value['sessionId'],
        'data': ((value['data'] as Array<any>).map(EntityObjToJSON)),
        'meta': ResponseMetaToJSON(value['meta']),
    };
}

        /**
* @type EntityObj
* 
* @export
*/
export type EntityObj = { type: 'Certificate' } & Certificate | { type: 'CertificateController' } & CertificateController | { type: 'WorkflowDag' } & WorkflowDag | { type: 'WorkflowPod' } & WorkflowPod | { type: 'WorkflowRun' } & WorkflowRun;


export function EntityObjFromJSON<T extends keyof EntityObjTypeMap>(json: any): EntityObjType<T> {
  const type = json['type'];
  return EntityObjFromJSONTyped<T>(type, json, false);
}

type EntityObjTypeMap = {
    'Certificate': { type: 'Certificate' } & Certificate;
    'CertificateController': { type: 'CertificateController' } & CertificateController;
    'WorkflowDag': { type: 'WorkflowDag' } & WorkflowDag;
    'WorkflowPod': { type: 'WorkflowPod' } & WorkflowPod;
    'WorkflowRun': { type: 'WorkflowRun' } & WorkflowRun;
};

type EntityObjType<T extends keyof EntityObjTypeMap> = EntityObjTypeMap[T];

export function EntityObjFromJSONTyped<T extends keyof EntityObjTypeMap>(type: T, json: any, ignoreDiscriminator: boolean): EntityObjTypeMap[T] {
  if (json == null) {
    return json;
  }
  switch (json['type']) {
    case 'Certificate':
      return Object.assign({}, CertificateFromJSONTyped(json, true), { type: 'Certificate' } as const) as EntityObjTypeMap[T];
    case 'CertificateController':
      return Object.assign({}, CertificateControllerFromJSONTyped(json, true), { type: 'CertificateController' } as const) as EntityObjTypeMap[T];
    case 'WorkflowDag':
      return Object.assign({}, WorkflowDagFromJSONTyped(json, true), { type: 'WorkflowDag' } as const) as EntityObjTypeMap[T];
    case 'WorkflowPod':
      return Object.assign({}, WorkflowPodFromJSONTyped(json, true), { type: 'WorkflowPod' } as const) as EntityObjTypeMap[T];
    case 'WorkflowRun':
      return Object.assign({}, WorkflowRunFromJSONTyped(json, true), { type: 'WorkflowRun' } as const) as EntityObjTypeMap[T];
    default:
      throw new Error(`No variant of EntityObj exists with 'type=${json['type']}'`);
  }
}

export function EntityObjToJSON(value?: EntityObj | null): any {
  if (value == null) {
    return value;
  }
  switch (value['type']) {
    case 'Certificate':
      return CertificateToJSON(value);
    case 'CertificateController':
      return CertificateControllerToJSON(value);
    case 'WorkflowDag':
      return WorkflowDagToJSON(value);
    case 'WorkflowPod':
      return WorkflowPodToJSON(value);
    case 'WorkflowRun':
      return WorkflowRunToJSON(value);
    default:
      throw new Error(`No variant of EntityObj exists with 'type=${value['type']}'`);
  }

}

        /**
 * 
 * @export
 * @interface EntityResetFiltersResponse
 */
export interface EntityResetFiltersResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityResetFiltersResponse
     */
    'filters': Array<string>;
}


/**
 * Check if a given object implements the EntityResetFiltersResponse interface.
 */
export function instanceOfEntityResetFiltersResponse(value: object): value is EntityResetFiltersResponse {
    if (!('filters' in value) || value['filters'] === undefined) return false;
    return true;
}

export function EntityResetFiltersResponseFromJSON(json: any): EntityResetFiltersResponse {
    return EntityResetFiltersResponseFromJSONTyped(json, false);
}

export function EntityResetFiltersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityResetFiltersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'filters': json['filters'],
    };
}

export function EntityResetFiltersResponseToJSON(value?: EntityResetFiltersResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filters': value['filters'],
    };
}

        /**
 * 
 * @export
 * @interface EntityResourceResponse
 */
export interface EntityResourceResponse {
    /**
     * 
     * @type {string}
     * @memberof EntityResourceResponse
     */
    'type': string;
    /**
     * 
     * @type {EntityDataFull}
     * @memberof EntityResourceResponse
     */
    'data': EntityDataFull;
}


/**
 * Check if a given object implements the EntityResourceResponse interface.
 */
export function instanceOfEntityResourceResponse(value: object): value is EntityResourceResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function EntityResourceResponseFromJSON(json: any): EntityResourceResponse {
    return EntityResourceResponseFromJSONTyped(json, false);
}

export function EntityResourceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EntityResourceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'data': EntityDataFullFromJSON(json['data']),
    };
}

export function EntityResourceResponseToJSON(value?: EntityResourceResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'data': EntityDataFullToJSON(value['data']),
    };
}

        /**
 * 
 * @export
 * @interface GenericFilter
 */
export interface GenericFilter {
    /**
     * 
     * @type {string}
     * @memberof GenericFilter
     */
    'name': string;
    /**
     * 
     * @type {Operator}
     * @memberof GenericFilter
     */
    'operator': Operator;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenericFilter
     */
    'value': Array<string>;
}




/**
 * Check if a given object implements the GenericFilter interface.
 */
export function instanceOfGenericFilter(value: object): value is GenericFilter {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('operator' in value) || value['operator'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function GenericFilterFromJSON(json: any): GenericFilter {
    return GenericFilterFromJSONTyped(json, false);
}

export function GenericFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericFilter {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'operator': OperatorFromJSON(json['operator']),
        'value': json['value'],
    };
}

export function GenericFilterToJSON(value?: GenericFilter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'operator': OperatorToJSON(value['operator']),
        'value': value['value'],
    };
}

        /**
 * 
 * @export
 * @interface GetEntityAggregationsRequest
 */
export interface GetEntityAggregationsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetEntityAggregationsRequest
     */
    'mostRecentDataFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEntityAggregationsRequest
     */
    'groupBy': string;
    /**
     * 
     * @type {Aggregation}
     * @memberof GetEntityAggregationsRequest
     */
    'aggregation': Aggregation;
    /**
     * 
     * @type {Array<GenericFilter>}
     * @memberof GetEntityAggregationsRequest
     */
    'filter'?: Array<GenericFilter>;
}




/**
 * Check if a given object implements the GetEntityAggregationsRequest interface.
 */
export function instanceOfGetEntityAggregationsRequest(value: object): value is GetEntityAggregationsRequest {
    if (!('groupBy' in value) || value['groupBy'] === undefined) return false;
    if (!('aggregation' in value) || value['aggregation'] === undefined) return false;
    return true;
}

export function GetEntityAggregationsRequestFromJSON(json: any): GetEntityAggregationsRequest {
    return GetEntityAggregationsRequestFromJSONTyped(json, false);
}

export function GetEntityAggregationsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEntityAggregationsRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mostRecentDataFrom': json['mostRecentDataFrom'] == null ? undefined : json['mostRecentDataFrom'],
        'groupBy': json['groupBy'],
        'aggregation': AggregationFromJSON(json['aggregation']),
        'filter': json['filter'] == null ? undefined : ((json['filter'] as Array<any>).map(GenericFilterFromJSON)),
    };
}

export function GetEntityAggregationsRequestToJSON(value?: GetEntityAggregationsRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mostRecentDataFrom': value['mostRecentDataFrom'],
        'groupBy': value['groupBy'],
        'aggregation': AggregationToJSON(value['aggregation']),
        'filter': value['filter'] == null ? undefined : ((value['filter'] as Array<any>).map(GenericFilterToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface GetEntityListRequest
 */
export interface GetEntityListRequest {
    /**
     * 
     * @type {string}
     * @memberof GetEntityListRequest
     */
    'mostRecentDataFrom'?: string;
    /**
     * 
     * @type {Array<GenericFilter>}
     * @memberof GetEntityListRequest
     */
    'filter'?: Array<GenericFilter>;
    /**
     * 
     * @type {string}
     * @memberof GetEntityListRequest
     */
    'sort'?: string;
    /**
     * 
     * @type {Order}
     * @memberof GetEntityListRequest
     */
    'order'?: Order;
    /**
     * 
     * @type {number}
     * @memberof GetEntityListRequest
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetEntityListRequest
     */
    'pageSize'?: number;
}




/**
 * Check if a given object implements the GetEntityListRequest interface.
 */
export function instanceOfGetEntityListRequest(value: object): value is GetEntityListRequest {
    return true;
}

export function GetEntityListRequestFromJSON(json: any): GetEntityListRequest {
    return GetEntityListRequestFromJSONTyped(json, false);
}

export function GetEntityListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEntityListRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mostRecentDataFrom': json['mostRecentDataFrom'] == null ? undefined : json['mostRecentDataFrom'],
        'filter': json['filter'] == null ? undefined : ((json['filter'] as Array<any>).map(GenericFilterFromJSON)),
        'sort': json['sort'] == null ? undefined : json['sort'],
        'order': json['order'] == null ? undefined : OrderFromJSON(json['order']),
        'page': json['page'] == null ? undefined : json['page'],
        'pageSize': json['pageSize'] == null ? undefined : json['pageSize'],
    };
}

export function GetEntityListRequestToJSON(value?: GetEntityListRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mostRecentDataFrom': value['mostRecentDataFrom'],
        'filter': value['filter'] == null ? undefined : ((value['filter'] as Array<any>).map(GenericFilterToJSON)),
        'sort': value['sort'],
        'order': OrderToJSON(value['order']),
        'page': value['page'],
        'pageSize': value['pageSize'],
    };
}

        /**
 * 
 * @export
 * @interface GetEntityRequest
 */
export interface GetEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof GetEntityRequest
     */
    'clusterName': string;
}


/**
 * Check if a given object implements the GetEntityRequest interface.
 */
export function instanceOfGetEntityRequest(value: object): value is GetEntityRequest {
    if (!('clusterName' in value) || value['clusterName'] === undefined) return false;
    return true;
}

export function GetEntityRequestFromJSON(json: any): GetEntityRequest {
    return GetEntityRequestFromJSONTyped(json, false);
}

export function GetEntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEntityRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'clusterName': json['clusterName'],
    };
}

export function GetEntityRequestToJSON(value?: GetEntityRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'clusterName': value['clusterName'],
    };
}

        /**
 * 
 * @export
 * @interface MonitorData
 */
export interface MonitorData {
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'eventTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'closedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'type'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitorData
     */
    'reasons'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MonitorData
     */
    'services'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'sourceEventId'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'cluster'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'namespace'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'shortResourceName'?: string;
    /**
     * 
     * @type {string}
     * @memberof MonitorData
     */
    'accountId'?: string;
}


/**
 * Check if a given object implements the MonitorData interface.
 */
export function instanceOfMonitorData(value: object): value is MonitorData {
    if (!('id' in value) || value['id'] === undefined) return false;
    return true;
}

export function MonitorDataFromJSON(json: any): MonitorData {
    return MonitorDataFromJSONTyped(json, false);
}

export function MonitorDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitorData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'eventTime': json['eventTime'] == null ? undefined : json['eventTime'],
        'closedAt': json['closedAt'] == null ? undefined : json['closedAt'],
        'type': json['type'] == null ? undefined : json['type'],
        'reasons': json['reasons'] == null ? undefined : json['reasons'],
        'services': json['services'] == null ? undefined : json['services'],
        'sourceEventId': json['sourceEventId'] == null ? undefined : json['sourceEventId'],
        'cluster': json['cluster'] == null ? undefined : json['cluster'],
        'namespace': json['namespace'] == null ? undefined : json['namespace'],
        'shortResourceName': json['shortResourceName'] == null ? undefined : json['shortResourceName'],
        'accountId': json['accountId'] == null ? undefined : json['accountId'],
    };
}

export function MonitorDataToJSON(value?: MonitorData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdAt': value['createdAt'],
        'eventTime': value['eventTime'],
        'closedAt': value['closedAt'],
        'type': value['type'],
        'reasons': value['reasons'],
        'services': value['services'],
        'sourceEventId': value['sourceEventId'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'shortResourceName': value['shortResourceName'],
        'accountId': value['accountId'],
    };
}

        
/**
 * 
 * @export
 */
export const Operator = {
    Eq: 'eq',
    In: 'in',
    Gt: 'gt',
    Gte: 'gte',
    Lt: 'lt',
    Lte: 'lte',
    Like: 'like'
} as const;
export type Operator = typeof Operator[keyof typeof Operator];


export function instanceOfOperator(value: any): boolean {
    for (const key in Operator) {
        if (Object.prototype.hasOwnProperty.call(Operator, key)) {
            if (Operator[key as keyof typeof Operator] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OperatorFromJSON(json: any): Operator {
    return OperatorFromJSONTyped(json, false);
}

export function OperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Operator {
    return json as Operator;
}

export function OperatorToJSON(value?: Operator | null): any {
    return value as any;
}

        
/**
 * 
 * @export
 */
export const Order = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type Order = typeof Order[keyof typeof Order];


export function instanceOfOrder(value: any): boolean {
    for (const key in Order) {
        if (Object.prototype.hasOwnProperty.call(Order, key)) {
            if (Order[key as keyof typeof Order] === value) {
                return true;
            }
        }
    }
    return false;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    return json as Order;
}

export function OrderToJSON(value?: Order | null): any {
    return value as any;
}

        /**
 * 
 * @export
 * @interface PodEvent
 */
export interface PodEvent {
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'eventTime': string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'eventKind': string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'statusReason': string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof PodEvent
     */
    'count'?: number;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'statusConditions'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'containerStatus'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodEvent
     */
    'initContainerStatus'?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PodEvent
     */
    'waitingMessage'?: string;
}


/**
 * Check if a given object implements the PodEvent interface.
 */
export function instanceOfPodEvent(value: object): value is PodEvent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('eventTime' in value) || value['eventTime'] === undefined) return false;
    if (!('eventKind' in value) || value['eventKind'] === undefined) return false;
    if (!('statusReason' in value) || value['statusReason'] === undefined) return false;
    if (!('reason' in value) || value['reason'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function PodEventFromJSON(json: any): PodEvent {
    return PodEventFromJSONTyped(json, false);
}

export function PodEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'eventTime': json['eventTime'],
        'eventKind': json['eventKind'],
        'statusReason': json['statusReason'],
        'reason': json['reason'],
        'message': json['message'],
        'count': json['count'] == null ? undefined : json['count'],
        'statusConditions': json['statusConditions'] == null ? undefined : json['statusConditions'],
        'containerStatus': json['containerStatus'] == null ? undefined : json['containerStatus'],
        'initContainerStatus': json['initContainerStatus'] == null ? undefined : json['initContainerStatus'],
        'waitingMessage': json['waitingMessage'] == null ? undefined : json['waitingMessage'],
    };
}

export function PodEventToJSON(value?: PodEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'eventTime': value['eventTime'],
        'eventKind': value['eventKind'],
        'statusReason': value['statusReason'],
        'reason': value['reason'],
        'message': value['message'],
        'count': value['count'],
        'statusConditions': value['statusConditions'],
        'containerStatus': value['containerStatus'],
        'initContainerStatus': value['initContainerStatus'],
        'waitingMessage': value['waitingMessage'],
    };
}

        /**
 * 
 * @export
 * @interface PodGroup
 */
export interface PodGroup {
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'serviceId': string;
    /**
     * 
     * @type {Array<object>}
     * @memberof PodGroup
     */
    'statusConditions': Array<object>;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'endTime': string;
    /**
     * 
     * @type {string}
     * @memberof PodGroup
     */
    'status': PodGroupStatusEnum;
    /**
     * 
     * @type {Array<PodEvent>}
     * @memberof PodGroup
     */
    'events': Array<PodEvent>;
}


/**
 * @export
 */
export const PodGroupStatusEnum = {
    Normal: 'Normal',
    Warning: 'Warning',
    Error: 'Error'
} as const;
export type PodGroupStatusEnum = typeof PodGroupStatusEnum[keyof typeof PodGroupStatusEnum];



/**
 * Check if a given object implements the PodGroup interface.
 */
export function instanceOfPodGroup(value: object): value is PodGroup {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('serviceId' in value) || value['serviceId'] === undefined) return false;
    if (!('statusConditions' in value) || value['statusConditions'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    if (!('endTime' in value) || value['endTime'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('events' in value) || value['events'] === undefined) return false;
    return true;
}

export function PodGroupFromJSON(json: any): PodGroup {
    return PodGroupFromJSONTyped(json, false);
}

export function PodGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): PodGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'serviceId': json['serviceId'],
        'statusConditions': json['statusConditions'],
        'id': json['id'],
        'startTime': json['startTime'],
        'endTime': json['endTime'],
        'status': json['status'],
        'events': ((json['events'] as Array<any>).map(PodEventFromJSON)),
    };
}

export function PodGroupToJSON(value?: PodGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'serviceId': value['serviceId'],
        'statusConditions': value['statusConditions'],
        'id': value['id'],
        'startTime': value['startTime'],
        'endTime': value['endTime'],
        'status': value['status'],
        'events': ((value['events'] as Array<any>).map(PodEventToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface RelatedResourceStatus
 */
export interface RelatedResourceStatus {
    /**
     * 
     * @type {string}
     * @memberof RelatedResourceStatus
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RelatedResourceStatus
     */
    'message'?: string;
}


/**
 * Check if a given object implements the RelatedResourceStatus interface.
 */
export function instanceOfRelatedResourceStatus(value: object): value is RelatedResourceStatus {
    return true;
}

export function RelatedResourceStatusFromJSON(json: any): RelatedResourceStatus {
    return RelatedResourceStatusFromJSONTyped(json, false);
}

export function RelatedResourceStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelatedResourceStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

export function RelatedResourceStatusToJSON(value?: RelatedResourceStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'message': value['message'],
    };
}

        /**
 * 
 * @export
 * @interface ResponseMeta
 */
export interface ResponseMeta {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'pageSize': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeta
     */
    'totalResults': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseMeta
     */
    'hasMoreResults': boolean;
}


/**
 * Check if a given object implements the ResponseMeta interface.
 */
export function instanceOfResponseMeta(value: object): value is ResponseMeta {
    if (!('pageSize' in value) || value['pageSize'] === undefined) return false;
    if (!('page' in value) || value['page'] === undefined) return false;
    if (!('totalResults' in value) || value['totalResults'] === undefined) return false;
    if (!('hasMoreResults' in value) || value['hasMoreResults'] === undefined) return false;
    return true;
}

export function ResponseMetaFromJSON(json: any): ResponseMeta {
    return ResponseMetaFromJSONTyped(json, false);
}

export function ResponseMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'pageSize': json['pageSize'],
        'page': json['page'],
        'totalResults': json['totalResults'],
        'hasMoreResults': json['hasMoreResults'],
    };
}

export function ResponseMetaToJSON(value?: ResponseMeta | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'pageSize': value['pageSize'],
        'page': value['page'],
        'totalResults': value['totalResults'],
        'hasMoreResults': value['hasMoreResults'],
    };
}

        /**
 * 
 * @export
 * @interface SupportedK8sVersions
 */
export interface SupportedK8sVersions {
    /**
     * 
     * @type {string}
     * @memberof SupportedK8sVersions
     */
    'min': string;
    /**
     * 
     * @type {string}
     * @memberof SupportedK8sVersions
     */
    'max': string;
}


/**
 * Check if a given object implements the SupportedK8sVersions interface.
 */
export function instanceOfSupportedK8sVersions(value: object): value is SupportedK8sVersions {
    if (!('min' in value) || value['min'] === undefined) return false;
    if (!('max' in value) || value['max'] === undefined) return false;
    return true;
}

export function SupportedK8sVersionsFromJSON(json: any): SupportedK8sVersions {
    return SupportedK8sVersionsFromJSONTyped(json, false);
}

export function SupportedK8sVersionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedK8sVersions {
    if (json == null) {
        return json;
    }
    return {
        
        'min': json['min'],
        'max': json['max'],
    };
}

export function SupportedK8sVersionsToJSON(value?: SupportedK8sVersions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'min': value['min'],
        'max': value['max'],
    };
}

        /**
 * 
 * @export
 * @interface WorkflowDag
 */
export interface WorkflowDag {
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'komodorWorkflowId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'dagId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'workflowEngine': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowDag
     */
    'namespace': string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowDag
     */
    'runs': number;
    /**
     * 
     * @type {WorkflowRun}
     * @memberof WorkflowDag
     */
    'lastRun': WorkflowRun;
}


/**
 * Check if a given object implements the WorkflowDag interface.
 */
export function instanceOfWorkflowDag(value: object): value is WorkflowDag {
    if (!('komodorWorkflowId' in value) || value['komodorWorkflowId'] === undefined) return false;
    if (!('dagId' in value) || value['dagId'] === undefined) return false;
    if (!('workflowEngine' in value) || value['workflowEngine'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('runs' in value) || value['runs'] === undefined) return false;
    if (!('lastRun' in value) || value['lastRun'] === undefined) return false;
    return true;
}

export function WorkflowDagFromJSON(json: any): WorkflowDag {
    return WorkflowDagFromJSONTyped(json, false);
}

export function WorkflowDagFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowDag {
    if (json == null) {
        return json;
    }
    return {
        
        'komodorWorkflowId': json['komodorWorkflowId'],
        'dagId': json['dagId'],
        'workflowEngine': json['workflowEngine'],
        'type': json['type'],
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'runs': json['runs'],
        'lastRun': WorkflowRunFromJSON(json['lastRun']),
    };
}

export function WorkflowDagToJSON(value?: WorkflowDag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'komodorWorkflowId': value['komodorWorkflowId'],
        'dagId': value['dagId'],
        'workflowEngine': value['workflowEngine'],
        'type': value['type'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'runs': value['runs'],
        'lastRun': WorkflowRunToJSON(value['lastRun']),
    };
}

        /**
 * 
 * @export
 * @interface WorkflowPod
 */
export interface WorkflowPod {
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'taskId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'namespace': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'startTime': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowPod
     */
    'endTime'?: string;
    /**
     * 
     * @type {Array<PodGroup>}
     * @memberof WorkflowPod
     */
    'phases': Array<PodGroup>;
    /**
     * 
     * @type {Array<MonitorData>}
     * @memberof WorkflowPod
     */
    'issues': Array<MonitorData>;
}


/**
 * Check if a given object implements the WorkflowPod interface.
 */
export function instanceOfWorkflowPod(value: object): value is WorkflowPod {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('taskId' in value) || value['taskId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    if (!('uid' in value) || value['uid'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    if (!('phases' in value) || value['phases'] === undefined) return false;
    if (!('issues' in value) || value['issues'] === undefined) return false;
    return true;
}

export function WorkflowPodFromJSON(json: any): WorkflowPod {
    return WorkflowPodFromJSONTyped(json, false);
}

export function WorkflowPodFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowPod {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'taskId': json['taskId'],
        'name': json['name'],
        'cluster': json['cluster'],
        'namespace': json['namespace'],
        'uid': json['uid'],
        'startTime': json['startTime'],
        'endTime': json['endTime'] == null ? undefined : json['endTime'],
        'phases': ((json['phases'] as Array<any>).map(PodGroupFromJSON)),
        'issues': ((json['issues'] as Array<any>).map(MonitorDataFromJSON)),
    };
}

export function WorkflowPodToJSON(value?: WorkflowPod | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'taskId': value['taskId'],
        'name': value['name'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
        'uid': value['uid'],
        'startTime': value['startTime'],
        'endTime': value['endTime'],
        'phases': ((value['phases'] as Array<any>).map(PodGroupToJSON)),
        'issues': ((value['issues'] as Array<any>).map(MonitorDataToJSON)),
    };
}

        /**
 * 
 * @export
 * @interface WorkflowRun
 */
export interface WorkflowRun {
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'runId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'dagId': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'startedAt': string;
    /**
     * 
     * @type {number}
     * @memberof WorkflowRun
     */
    'durationSec': number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowRun
     */
    'hasIssues': boolean;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof WorkflowRun
     */
    'status': WorkflowStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'engine': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'cluster': string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowRun
     */
    'namespace': string;
}




/**
 * Check if a given object implements the WorkflowRun interface.
 */
export function instanceOfWorkflowRun(value: object): value is WorkflowRun {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('runId' in value) || value['runId'] === undefined) return false;
    if (!('dagId' in value) || value['dagId'] === undefined) return false;
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('durationSec' in value) || value['durationSec'] === undefined) return false;
    if (!('hasIssues' in value) || value['hasIssues'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('engine' in value) || value['engine'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('cluster' in value) || value['cluster'] === undefined) return false;
    if (!('namespace' in value) || value['namespace'] === undefined) return false;
    return true;
}

export function WorkflowRunFromJSON(json: any): WorkflowRun {
    return WorkflowRunFromJSONTyped(json, false);
}

export function WorkflowRunFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowRun {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'runId': json['runId'],
        'dagId': json['dagId'],
        'startedAt': json['startedAt'],
        'durationSec': json['durationSec'],
        'hasIssues': json['hasIssues'],
        'status': WorkflowStatusFromJSON(json['status']),
        'engine': json['engine'],
        'type': json['type'],
        'cluster': json['cluster'],
        'namespace': json['namespace'],
    };
}

export function WorkflowRunToJSON(value?: WorkflowRun | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'runId': value['runId'],
        'dagId': value['dagId'],
        'startedAt': value['startedAt'],
        'durationSec': value['durationSec'],
        'hasIssues': value['hasIssues'],
        'status': WorkflowStatusToJSON(value['status']),
        'engine': value['engine'],
        'type': value['type'],
        'cluster': value['cluster'],
        'namespace': value['namespace'],
    };
}

        
/**
 * 
 * @export
 */
export const WorkflowStatus = {
    Running: 'running',
    Completed: 'completed'
} as const;
export type WorkflowStatus = typeof WorkflowStatus[keyof typeof WorkflowStatus];


export function instanceOfWorkflowStatus(value: any): boolean {
    for (const key in WorkflowStatus) {
        if (Object.prototype.hasOwnProperty.call(WorkflowStatus, key)) {
            if (WorkflowStatus[key as keyof typeof WorkflowStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function WorkflowStatusFromJSON(json: any): WorkflowStatus {
    return WorkflowStatusFromJSONTyped(json, false);
}

export function WorkflowStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowStatus {
    return json as WorkflowStatus;
}

export function WorkflowStatusToJSON(value?: WorkflowStatus | null): any {
    return value as any;
}

    
        /**
* Get entity resource for addon and entity
* @param params AddonsApiGetEntityRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getEntityUrl = (params: AddonsApiGetEntityRequest, baseUrl: string): string => {
    // verify required parameter 'addon' is not null or undefined
    assertParamExists('getEntity', 'addon', params.addon)
    // verify required parameter 'entity' is not null or undefined
    assertParamExists('getEntity', 'entity', params.entity)
    // verify required parameter 'name' is not null or undefined
    assertParamExists('getEntity', 'name', params.name)
    // verify required parameter 'uid' is not null or undefined
    assertParamExists('getEntity', 'uid', params.uid)
    const localVarPath = `/api/v1/addons/{addon}/{entity}/{name}/{uid}`
        .replace(`{${"addon"}}`, encodeURIComponent(String(params.addon)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(params.entity)))
        .replace(`{${"name"}}`, encodeURIComponent(String(params.name)))
        .replace(`{${"uid"}}`, encodeURIComponent(String(params.uid)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get aggregations for addon entities
* @param params AddonsApiGetEntityAggregationsRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getEntityAggregationsUrl = (params: AddonsApiGetEntityAggregationsRequest, baseUrl: string): string => {
    // verify required parameter 'addon' is not null or undefined
    assertParamExists('getEntityAggregations', 'addon', params.addon)
    // verify required parameter 'entity' is not null or undefined
    assertParamExists('getEntityAggregations', 'entity', params.entity)
    const localVarPath = `/api/v1/addons/{addon}/{entity}/aggregations`
        .replace(`{${"addon"}}`, encodeURIComponent(String(params.addon)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(params.entity)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};
/**
* Get list for entities
* @param params AddonsApiGetEntityListRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getEntityListUrl = (params: AddonsApiGetEntityListRequest, baseUrl: string): string => {
    // verify required parameter 'addon' is not null or undefined
    assertParamExists('getEntityList', 'addon', params.addon)
    // verify required parameter 'entity' is not null or undefined
    assertParamExists('getEntityList', 'entity', params.entity)
    const localVarPath = `/api/v1/addons/{addon}/{entity}`
        .replace(`{${"addon"}}`, encodeURIComponent(String(params.addon)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(params.entity)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    if (params.accountId !== undefined) {
            localVarQueryParameter['accountId'] = params.accountId;
    }
    localVarHeaderParameter['Content-Type'] = 'application/json';
    setSearchParams(localVarUrlObj, localVarQueryParameter);
    return toPathString(localVarUrlObj);
};
/**
* Get reset filters for addon
* @param params AddonsApiGetEntityResetFiltersRequest
* @param baseUrl string
* @throws {RequiredError}
*/
export const getEntityResetFiltersUrl = (params: AddonsApiGetEntityResetFiltersRequest, baseUrl: string): string => {
    // verify required parameter 'addon' is not null or undefined
    assertParamExists('getEntityResetFilters', 'addon', params.addon)
    // verify required parameter 'entity' is not null or undefined
    assertParamExists('getEntityResetFilters', 'entity', params.entity)
    const localVarPath = `/api/v1/addons/{addon}/{entity}/reset-filters`
        .replace(`{${"addon"}}`, encodeURIComponent(String(params.addon)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(params.entity)));
    const localVarUrlObj = new URL(`${baseUrl}${localVarPath}`);
    const localVarQueryParameter = {} as Record<string, unknown>;
    const localVarHeaderParameter = {} as Record<string, unknown>;
    localVarHeaderParameter['Content-Type'] = 'application/json';
    return toPathString(localVarUrlObj);
};

/**
* Request parameters for getEntity operation in AddonsApi.
* @export
* @interface AddonsApiGetEntityRequest
*/
export interface AddonsApiGetEntityRequest {
    /**
    * 
    * @type {Addon}
    * @memberof AddonsApiGetEntity
    */
    readonly addon: Addon

    /**
    * 
    * @type {Entity}
    * @memberof AddonsApiGetEntity
    */
    readonly entity: Entity

    /**
    * 
    * @type {string}
    * @memberof AddonsApiGetEntity
    */
    readonly name: string

    /**
    * 
    * @type {string}
    * @memberof AddonsApiGetEntity
    */
    readonly uid: string

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof AddonsApiGetEntity
    */
    readonly accountId?: string

    /**
    * 
    * @type {GetEntityRequest}
    * @memberof AddonsApiGetEntity
    */
    readonly getEntityRequest?: GetEntityRequest
}

/**
* Request parameters for getEntityAggregations operation in AddonsApi.
* @export
* @interface AddonsApiGetEntityAggregationsRequest
*/
export interface AddonsApiGetEntityAggregationsRequest {
    /**
    * 
    * @type {Addon}
    * @memberof AddonsApiGetEntityAggregations
    */
    readonly addon: Addon

    /**
    * 
    * @type {Entity}
    * @memberof AddonsApiGetEntityAggregations
    */
    readonly entity: Entity

    /**
    * 
    * @type {GetEntityAggregationsRequest}
    * @memberof AddonsApiGetEntityAggregations
    */
    readonly getEntityAggregationsRequest?: GetEntityAggregationsRequest
}

/**
* Request parameters for getEntityList operation in AddonsApi.
* @export
* @interface AddonsApiGetEntityListRequest
*/
export interface AddonsApiGetEntityListRequest {
    /**
    * 
    * @type {Addon}
    * @memberof AddonsApiGetEntityList
    */
    readonly addon: Addon

    /**
    * 
    * @type {Entity}
    * @memberof AddonsApiGetEntityList
    */
    readonly entity: Entity

    /**
    * used internally in order to impersonate an account
    * @type {string}
    * @memberof AddonsApiGetEntityList
    */
    readonly accountId?: string

    /**
    * 
    * @type {GetEntityListRequest}
    * @memberof AddonsApiGetEntityList
    */
    readonly getEntityListRequest?: GetEntityListRequest
}

/**
* Request parameters for getEntityResetFilters operation in AddonsApi.
* @export
* @interface AddonsApiGetEntityResetFiltersRequest
*/
export interface AddonsApiGetEntityResetFiltersRequest {
    /**
    * 
    * @type {Addon}
    * @memberof AddonsApiGetEntityResetFilters
    */
    readonly addon: Addon

    /**
    * 
    * @type {Entity}
    * @memberof AddonsApiGetEntityResetFilters
    */
    readonly entity: Entity
}


    
