import { ReactNode, useCallback, useMemo } from "react";

import { useDrawersStackStore } from "@/shared/store/drawersStackStore/drawersStackStore";
import { pushDrawerSelector } from "@/shared/store/drawersStackStore/drawersStackSelectors";
import { DrawerType } from "@/shared/store/drawersStackStore/types";
import { LiveStateDrawerProps } from "@/components/k8sAddons/LiveStateDrawer/liveStateDrawerTypes";
import { Addon, Entity } from "@/generated/addonsApi";
import { CertificateDrawer } from "@/components/k8sAddons/addons/CertManager/CertificateDrawer/CertificateDrawer";

export const useOpenLiveStateDrawer = () => {
  const pushDrawer = useDrawersStackStore(pushDrawerSelector);

  return useCallback(
    (props: LiveStateDrawerProps) => {
      pushDrawer({
        drawerType: DrawerType.K8sAddonLiveStateDrawer,
        ...props,
      });
    },
    [pushDrawer]
  );
};

export const useAddonEntityDrawer = (addon: Addon, entity: Entity) => {
  return useMemo<ReactNode>(() => {
    switch (addon) {
      case Addon.CertManager: {
        switch (entity) {
          case Entity.Certificate:
            return <CertificateDrawer />;
        }
      }
    }
    return null;
  }, [addon, entity]);
};
