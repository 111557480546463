import {
  CheckType,
  Scope,
  ViolationSupportingData,
} from "../../../generated/reliabilityApi";
import { getCpuThrottledTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/CpuThrottledDrawer/utils";
import { getK8sEOLTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/K8sEOL/utils";
import { getDeprecatedApiTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/DeprecatedApi/Details/DetailsUtils";
import { getNoisyNeighborTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/NoisyNeighbor/utils";
import { getRequestsLimitsRatioTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/HighRequestsToLimitRatio/Details/DetailsUtils";
import { getContainerRestartsTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/ContainerRestarts/containerRestartsUtils";
import { getHpaReachedMaxTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/HpaReachedMax/hpaReachedMaxUtils";
import { getUnderProvisionedTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/UnderProvisioned/underProvisionedUtils";
import { checkTypeToDescription } from "../constants/checkTypeToDescription";
import { PageRoutes, ViolationsGroupBy } from "../ReliabilityTypes";
import { ReliabilityRoutes } from "../constants/reliabilityConstants";
import { getViolationsTableTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/SinglePointOfFailure/utils";
import { getCertificateExpirationTitle } from "../components/pages/violations/ViolationsDrawer/components/drawers/CertificateExpiration/utils";

import { getCheckLabel } from "./getCheckLabel";

import { Dictionary } from "@/shared/types/Dictionary";

export const getAccumulatedViolations = (violations: Dictionary<number>) =>
  Object.values(violations).reduce((acc, curr) => acc + curr, 0);

export const getTotalViolationsFromCountDict = (dict: Dictionary<number>) =>
  Object.values(dict).reduce((acc, curr) => acc + curr, 0);

export const getSortedSeveritiesEntries = (dict: Dictionary<number>) => {
  const desiredOrder = ["high", "medium", "low"];

  return Object.entries(dict).sort((a, b) => {
    return desiredOrder.indexOf(a[0]) - desiredOrder.indexOf(b[0]);
  });
};

export const isIgnoreRuleScopeAllSelected = (scope: Scope) =>
  scope.clusterNames.length === 0 &&
  scope.namespaces?.length === 0 &&
  scope.shortResourceNames?.length === 0;

export const getCheckDescription = (checkType: CheckType) => {
  return checkTypeToDescription[checkType];
};

export const getViolationTitle = (
  checkType: CheckType | undefined,
  supportingData: ViolationSupportingData | undefined
): string | undefined => {
  if (!checkType) return;
  switch (checkType) {
    case CheckType.ThrottledCpu:
      return getCpuThrottledTitle(supportingData);
    case CheckType.KubernetesEndOfLife:
      return getK8sEOLTitle(supportingData);
    case CheckType.DeprecatedApis:
      return getDeprecatedApiTitle(supportingData);
    case CheckType.NoisyNeighbor:
      return getNoisyNeighborTitle(supportingData);
    case CheckType.RequestsLimitsRatio:
      return getRequestsLimitsRatioTitle(supportingData);
    case CheckType.RestartingContainers:
      return getContainerRestartsTitle(supportingData);
    case CheckType.HpaMax:
      return getHpaReachedMaxTitle(supportingData);
    case CheckType.UnderProvisionedWorkloads:
      return getUnderProvisionedTitle(supportingData);
    case CheckType.SinglePointOfFailure:
      return getViolationsTableTitle(supportingData);
    case CheckType.CertificateExpiration:
      return getCertificateExpirationTitle(supportingData);
    default:
      return getCheckLabel(checkType);
  }
};

export const getIsBestPracticesTab = (selectedTab: PageRoutes) =>
  selectedTab === ReliabilityRoutes.bestPractices;

type TotalViolstionsText = {
  groupText: string | undefined;
  suffixText: string;
};

export const getGroupsTotalViolationsTexts = ({
  selectedTab,
  groupBy,
}: {
  selectedTab: PageRoutes;
  groupBy?: ViolationsGroupBy;
}): TotalViolstionsText => {
  const isBestPracticesTab = getIsBestPracticesTab(selectedTab);
  let groupText;

  switch (groupBy) {
    case "Impact":
      groupText = "impacts";
      break;
    case "Cluster":
      groupText = "clusters";
      break;
    case "CheckType":
      groupText = `${isBestPracticesTab ? "best practice " : ""}checks`;
      break;
  }

  return {
    groupText,
    suffixText: "violations",
  };
};
