import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";

import { Typography, Tag, Popover } from "../../deprecated";
import { theme } from "../../../theme";
import { palette } from "../../../palette";
import { hasScrollBar } from "../../../utils/hasScrollbar";
import { Size } from "../../../types";

const ValueText = styled(Typography).attrs({
  bold: true,
  color: theme.foreground.fgPrimary,
})<{ isClickable: boolean }>`
  :hover {
    ${({ isClickable }) =>
      isClickable && "text-decoration: underline; cursor: pointer;"}
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StyledPopoverContent = styled.div<{ hasScrollbar: boolean }>`
  padding: 16px 20px;
  padding-right: ${({ hasScrollbar }) => (hasScrollbar ? "30px" : "20px")};
`;

const StyledTag = styled((props) => <Tag {...props} />)`
  border: 1px solid ${palette.gray[500]};
  padding: 1px 8px;
  font-size: 10px;
  font-weight: 600;
  transition: background-color 0.2s, color 0.2s;
  cursor: pointer;
  color: ${palette.gray[700]};
  &:hover {
    background-color: ${palette.gray[500]};
    color: ${palette.gray[100]};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: 4px;
`;

export type TagMultiSelectProps = {
  values: string[];
  size?: Size;
  onValueClick?: () => void;
};
export const MultiOptionsTag: React.FC<TagMultiSelectProps> = ({
  values,
  onValueClick,
  size,
}: TagMultiSelectProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const [hasScrollbar, setContentHasScrollbar] = useState(false);
  const popupParentRef = useRef<HTMLDivElement>(null);
  const popoverContentRef = useRef<HTMLDivElement>(null);

  const onTagClick = () => {
    setShowPopover(true);
  };

  useEffect(() => {
    setContentHasScrollbar(
      hasScrollBar(popoverContentRef.current?.parentElement)
    );
  }, [showPopover]);

  const popoverContent = useMemo(
    () => (
      <StyledPopoverContent
        ref={popoverContentRef}
        hasScrollbar={hasScrollbar}
        aria-label={"additional values content"}
      >
        {values.slice(1, values.length).map((value) => {
          return <StyledTypography key={value}>{value}</StyledTypography>;
        })}
      </StyledPopoverContent>
    ),
    [hasScrollbar, values]
  );

  const extraEntries = (values?.length ?? 1) - 1;
  return (
    <FlexContainer ref={popupParentRef}>
      <ValueText
        isClickable={!!onValueClick}
        onClick={onValueClick}
        size={size}
      >
        {values[0]}
      </ValueText>
      <Popover
        isOpen={showPopover}
        maxHeight="150px"
        maxWidth="300px"
        align="start"
        handleClose={() => setShowPopover(false)}
        content={popoverContent}
        parentElement={popupParentRef.current ?? undefined}
      >
        <StyledTag
          color={palette.gray[50]}
          variant="round"
          onClick={onTagClick}
        >
          +{extraEntries}
        </StyledTag>
      </Popover>
    </FlexContainer>
  );
};
