enum EventTableHeader {
  EventType = "Event type",
  Summary = "Summary",
  Details = "Details",
  Service = "Service",
  Status = "Status",
}

enum EventType {
  PodEvent = "Pod event",
  Issue = "Issue",
}

enum HpaStatusConditions {
  AbleToScale = "AbleToScale",
  ScalingActive = "ScalingActive",
  ScalingLimited = "ScalingLimited",
}

export { EventTableHeader, EventType, HpaStatusConditions };
