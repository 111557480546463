import styled from "styled-components";

// Right to left
export const FlexRows = styled.div`
  display: flex;
  flex-direction: row;
`;

// Top to bottom
export const FlexColumns = styled.div`
  display: flex;
  flex-direction: column;
`;
