import Popover, { PopoverProps } from "@mui/material/Popover";
import React from "react";
import Divider from "@mui/material/Divider";
import styled from "styled-components";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { SearchBar } from "../Select/shared/components/SearchBar/SearchBar";
import { gray } from "../../../themes/colors";

const StyledSearchBar = styled(SearchBar)`
  margin: 0px 16px;
  padding: 0px;
  padding-bottom: 1.5px;
`;

const StyledMenuItem = styled(MenuItem)`
  &.Mui-selected {
    font-weight: 700;
  }
`;

export type TabbedSelectPopoverProps = {
  query: string;
  onQueryChange: (query: string) => void;
  loading?: boolean;
  tabs: { label: string; value: string }[];
  items: { label: string; value: string }[];
  selectedTab: string;
  onSelectTab: (tab: string) => void;
  selectedItem: string;
  onSelectItem: (item: string) => void;
  children?: React.ReactNode;
} & PopoverProps;

export function TabbedSelectPopover({
  query,
  onQueryChange,
  loading,
  tabs,
  items,
  selectedTab,
  onSelectTab,
  selectedItem,
  onSelectItem,
  children,
  ...popoverProps
}: TabbedSelectPopoverProps) {
  return (
    <Popover
      {...popoverProps}
      slotProps={{
        paper: {
          sx: {
            overflow: "hidden",
            paddingTop: "16px",
            boxSizing: "border-box",
            width: "360px",
            height: "330px",
            display: "flex",
            flexDirection: "column",
          },
        },
      }}
    >
      <StyledSearchBar
        searchPlaceholder="Search"
        onSearch={function (searchText: string): void {
          onQueryChange(searchText);
        }}
        searchValue={query}
      />
      <Tabs
        sx={{
          padding: "0px 16px",
          minHeight: "42px",
          borderBottom: `1px solid ${gray[200]}`,
        }}
        value={selectedTab}
        onChange={(_, newValue) => onSelectTab(newValue)}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            sx={{
              padding: "9px 16px 8px 16px",
              minHeight: "42px",
              letterSpacing: "0.4px",
            }}
            label={tab.label}
            value={tab.value}
            aria-label={`${tab.label} tab`}
          />
        ))}
      </Tabs>
      <MenuList
        sx={{
          flexGrow: 1,
          position: "relative",
          overflowY: "auto",
        }}
        dense={true}
      >
        {loading && (
          <LinearProgress
            sx={{
              height: "2px",
              position: "absolute",
              left: "0px",
              right: "0px",
              top: "0px",
            }}
          />
        )}
        {items.length === 0 && !loading ? (
          <Box
            paddingTop="48px"
            sx={{ color: gray[500] }}
            display="flex"
            justifyContent="center"
          >
            <Typography sx={{ color: "text.secondary" }} variant="body2">
              No matching values found
            </Typography>
          </Box>
        ) : null}
        {items.map((item) => (
          <StyledMenuItem
            key={item.value}
            value={item.value}
            selected={selectedItem === item.value}
            onClick={() => onSelectItem(item.value)}
            aria-label={item.label}
          >
            {item.label}
          </StyledMenuItem>
        ))}
      </MenuList>
      {children ? (
        <>
          <Divider />
          <Box padding="6px 16px">{children}</Box>
        </>
      ) : null}
    </Popover>
  );
}
