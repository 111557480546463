import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Add from "@mui/icons-material/Add";

import { useRiskManagementContext } from "../../../../context/RiskManagementContext";
import { checksData } from "../manageChecksStaticData";
import { ReliabilityCheckByCategory } from "../../../../riskManagementTypes";

import { useIsReliabilityChecksDataEmpty } from "./manageChecksHooks";
import { ManageChecksTables } from "./ManageChecksTables/ManageChecksTables";

const Container = styled.div``;

const TopSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TopSectionTextContainer = styled.div`
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  height: 32px;
  & > svg {
    margin-right: 6px;
  }
`;

export const ManageChecksContent: React.FC = () => {
  const { reliabilityChecksData, setReliabilityChecksData } =
    useRiskManagementContext();
  const isReliabilityChecksDataEmpty = useIsReliabilityChecksDataEmpty();

  useLayoutEffect(() => {
    if (!isReliabilityChecksDataEmpty) return;
    const checksByCategory = checksData.reduce<ReliabilityCheckByCategory>(
      (acc, curr) => {
        acc[curr.category].push(curr);
        return acc;
      },
      {
        "Resource optimization": [],
        "Infrastructure issues": [],
        Standardization: [],
      }
    );
    setReliabilityChecksData(checksByCategory);
  }, [
    isReliabilityChecksDataEmpty,
    reliabilityChecksData,
    setReliabilityChecksData,
  ]);

  return (
    <Container>
      <TopSectionContainer>
        <TopSectionTextContainer>
          <Typography
            variant={"h3"}
            color={"text.primary"}
            sx={{ lineHeight: 1.8 }}
          >
            Reliability checks
          </Typography>
          <Typography variant={"body2"} color={"text.primary"}>
            Define and manage the checks that Komodor will perform on you
            environment
          </Typography>
        </TopSectionTextContainer>
        <StyledButton variant={"contained"}>
          <Add fontSize={"small"} /> Add check
        </StyledButton>
      </TopSectionContainer>
      <ManageChecksTables />
    </Container>
  );
};
