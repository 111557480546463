import React from "react";
import styled from "styled-components";
import { LinesLoader as DSLinesLoader } from "@komodorio/design-system/komodor-ui";

const Loader = styled(DSLinesLoader).attrs<
  { marginTop?: string } & React.HTMLAttributes<HTMLDivElement>,
  { marginTop: string } & React.HTMLAttributes<HTMLDivElement>
>(({ className, marginTop, ...rest }) => ({
  className: className,
  marginTop: marginTop || "0",
  ...rest,
}))`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.marginTop};
`;

export const LinesLoader: React.FC<{
  marginTop?: string;
  className?: string;
}> = ({ className, marginTop }) => (
  <Loader
    className={className}
    marginTop={marginTop}
    data-e2e-selector={"loader"}
    aria-label={"loader"}
  ></Loader>
);
