import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

import Input from "../../../common/controls/Input";
import { textStyle } from "../../../common/typography";
import { gray13 } from "../../../../Colors";
import { AnimatedKeyboardArrowDown } from "../../riskManagementStyles";

import { Filter } from "./types";

const MINIMUM_ITEMS_TO_SHOW_SEARCH = 5;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

interface FiltersSectionProps {
  filterSectionData: Filter;
}

const StyledInput = styled(Input)`
  ${textStyle};
  background: white;
  margin-top: 6px;
  border: 1 solid ${gray13};
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    padding: 6px;
  }
`;

export const FiltersSection: React.FC<FiltersSectionProps> = ({
  filterSectionData,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const items = useMemo(
    () =>
      filterSectionData.items
        .filter((item) => item.includes(searchTerm))
        .map((name) => <CheckboxItem name={name} key={name} />),
    [filterSectionData.items, searchTerm]
  );

  return (
    <Container>
      <Title onClick={() => setIsOpen(!isOpen)}>
        <AnimatedKeyboardArrowDown open={isOpen} color="action" />
        <Typography variant="h5" color={"text.primary"}>
          {filterSectionData.title}
        </Typography>
      </Title>
      {isOpen && (
        <>
          {filterSectionData.items.length >= MINIMUM_ITEMS_TO_SHOW_SEARCH && (
            <StyledInput
              placeholder="Search"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          )}
          {items}
        </>
      )}
    </Container>
  );
};

const CheckboxItem: React.FC<{ name: string }> = ({ name }) => {
  return (
    <CheckboxContainer>
      <StyledCheckbox size="small" />
      <Typography variant="body2" color={"text.primary"}>
        {name}
      </Typography>
    </CheckboxContainer>
  );
};
