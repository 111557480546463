import React from "react";

export const UnattendedViolationsGraph: React.FC = () => {
  return (
    <svg viewBox="0 0 134 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 32V15L38.8641 20L131 10V32H1Z" fill="#FFEBF4" />
      <path
        d="M38.8641 20L38.7986 20.4957L38.8582 20.5036L38.918 20.4971L38.8641 20ZM128.333 10C128.333 11.4728 129.527 12.6667 131 12.6667C132.473 12.6667 133.667 11.4728 133.667 10C133.667 8.52724 132.473 7.33333 131 7.33333C129.527 7.33333 128.333 8.52724 128.333 10ZM38.918 20.4971L131.054 10.4971L130.946 9.50292L38.8101 19.5029L38.918 20.4971ZM38.9295 19.5043L1.06546 14.5043L0.934543 15.4957L38.7986 20.4957L38.9295 19.5043Z"
        fill="#B50356"
      />
    </svg>
  );
};
