import React from "react";

import { LogoIconProps } from "../types";

export const Vmware: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="OpenShift logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M5.68057 9.27458C5.47565 8.83197 4.98385 8.63525 4.51665 8.84016C4.04944 9.04508 3.87731 9.56146 4.09043 10.0041L6.04121 14.2417C6.34449 14.9056 6.67235 15.2499 7.2789 15.2499C7.92643 15.2499 8.21331 14.8728 8.51658 14.2417C8.51658 14.2417 10.2133 10.5369 10.2379 10.4959C10.2543 10.4549 10.3116 10.3401 10.4838 10.3401C10.6313 10.3401 10.7543 10.4549 10.7543 10.6106V14.2335C10.7543 14.7909 11.0657 15.2499 11.6559 15.2499C12.2542 15.2499 12.5739 14.7909 12.5739 14.2335V11.2664C12.5739 10.6926 12.9837 10.3237 13.5411 10.3237C14.0985 10.3237 14.4673 10.709 14.4673 11.2664V14.2335C14.4673 14.7909 14.7788 15.2499 15.3689 15.2499C15.9673 15.2499 16.2869 14.7909 16.2869 14.2335V11.2664C16.2869 10.6926 16.6968 10.3237 17.2541 10.3237C17.8115 10.3237 18.1804 10.709 18.1804 11.2664V14.2335C18.1804 14.7909 18.4918 15.2499 19.082 15.2499C19.6803 15.2499 20 14.7909 20 14.2335V10.8565C20 9.61884 19 8.75 17.8033 8.75C16.6066 8.75 15.8525 9.57785 15.8525 9.57785C15.4509 9.06147 14.9017 8.75 13.9755 8.75C12.9919 8.75 12.1313 9.57785 12.1313 9.57785C11.7296 9.06147 11.0493 8.75 10.492 8.75C9.62312 8.75 8.93461 9.13524 8.50839 10.0942L7.26251 13.0286L5.68057 9.27458Z"
        fill="#6A6667"
      />
    </svg>
  );
};
