/* eslint-disable import/no-duplicates */
import {
  addMinutes,
  format,
  intervalToDuration,
  parseISO,
  startOfDay,
  subMinutes,
} from "date-fns";
import { enUS } from "date-fns/locale";
/* eslint-enable import/no-duplicates */
import { zonedTimeToUtc } from "date-fns-tz";

import { Dictionary } from "../types/Dictionary";

/** This custom locale supports options for formatDistanceStrict()
 *  Add appropriate options (e.g. lessThanXSeconds) for more date-fns functions
 **/
const formatDistanceLocale: Dictionary<string> = {
  xSeconds: "s",
  xMinutes: "m",
  xHours: "h",
  xDays: "d",
  xWeeks: "w",
  xMonths: "mo",
  xYears: "y",
};

/**
  formatDistanceStrict(subHours(new Date, 2), new Date(), {
    addSuffix: true,
    locale: getCustomDateLocale(),
  });
 output: 2h ago
 **/
export const getCustomDateLocale = (): Locale => {
  return {
    ...enUS,
    formatDistance: (token, count, options) => {
      const result = `${count}${formatDistanceLocale[token]}`;
      if (options.addSuffix) {
        if (options.comparison > 0) {
          return "in " + result;
        } else {
          return result + " ago";
        }
      }
      return result;
    },
  };
};

const formatUnit: Dictionary<string> = {
  seconds: "s",
  minutes: "m",
  hours: "h",
  days: "d",
  weeks: "w",
  months: "mo",
  years: "y",
};

export const getAge = (startDate: Date): string => {
  const duration = intervalToDuration({
    start: startDate,
    end: new Date(),
  });
  if (duration.minutes) {
    delete duration.seconds;
  }
  return Object.entries(duration)
    .map(([unit, value]) => (value ? `${value}${formatUnit[unit]}` : ""))
    .join("");
};

export const formatTimeIntervalFromDates = (
  startDate: Date,
  endDate: Date
): string => {
  const diffMs = endDate.getTime() - startDate.getTime();
  return formatTimeInterval(diffMs);
};

export const formatDateWithUtcSuffix = (
  dateStr: string | undefined | null
): string => {
  // This function is used for a specific case where the eventTime field of an event
  // is in UTC but the date string does not have a UTC postfix.
  if (!dateStr) {
    return "";
  }
  const date = new Date(dateStr);
  if (date.toString() === "Invalid Date") {
    return "";
  }
  date.setUTCHours(date.getHours());
  date.setUTCMinutes(date.getMinutes());
  date.setUTCSeconds(date.getSeconds());
  date.setUTCMilliseconds(date.getMilliseconds());

  return date.toISOString();
};

export const formatTimeInterval = (interval: number): string => {
  const hours = Math.floor(interval / (1000 * 60 * 60)); // convert milliseconds to hours
  if (hours > 100) {
    return `${Math.floor(hours / 24)} Days`;
  }

  const minutes = Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60)); // convert remaining milliseconds to minutes
  const seconds = Math.floor((interval % (1000 * 60)) / 1000); // convert remaining milliseconds to seconds
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const getTodayDate = (): string => format(new Date(), "MM-dd-yyyy");

export const convertZonedTimeToUtc = (date: Date): Date =>
  zonedTimeToUtc(date, "Etc/UTC");

export const convertLocalDateToUtc = (zonedDate: Date): number =>
  zonedDate.valueOf() + zonedDate.getTimezoneOffset() * 60 * 1000;

export const removeTimezoneOffset = (date: Date): Date => {
  const offset = date.getTimezoneOffset();

  return Math.sign(offset) !== -1
    ? addMinutes(date, offset)
    : subMinutes(date, Math.abs(offset));
};

export const getStartOfDayInUtc = (date: Date | number): Date => {
  const startOfDayDate = startOfDay(date);
  return convertZonedTimeToUtc(startOfDayDate);
};

export const isValidDate = (
  date: Date | string | undefined | null
): boolean => {
  if (!date) return false;
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  return !Number.isNaN(parsedDate.getTime());
};

export const dateComparator = (a: string, b: string) => {
  const dateA = new Date(Date.parse(a));
  const dateB = new Date(Date.parse(b));

  const dateAIsValid = isValidDate(dateA);
  const dateBIsValid = isValidDate(dateB);

  if (!dateAIsValid && !dateBIsValid) {
    return 0;
  } else if (!dateAIsValid) {
    return 1;
  } else if (!dateBIsValid) {
    return -1;
  }

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
};

export const daysHumanDurationLastTwoUnits = (startDate: Date): string => {
  const duration = intervalToDuration({
    start: startDate,
    end: new Date(),
  });
  return parseDurationToDaysTwoUnits(duration);
};

export const parseDurationToDaysTwoUnits = (duration: Duration): string => {
  if (duration.days) {
    delete duration.seconds;
    delete duration.minutes;
  } else if (duration.hours) {
    delete duration.seconds;
  }
  return Object.entries(duration)
    .filter(([, value]) => value)
    .map(([unit, value]) => `${value}${formatUnit[unit]}`)
    .join(" ");
};

export const formatDuration = (
  formatter: (start: Date, end: Date) => string,
  start: Date | string | undefined | null,
  end: Date | string | undefined | null
): string => {
  const startDate = typeof start === "string" ? parseISO(start) : start;
  const endDate = typeof end === "string" ? parseISO(end) : end;
  if (isValidDate(startDate) && isValidDate(endDate)) {
    return formatter(startDate as Date, endDate as Date);
  } else if (isValidDate(startDate)) {
    return formatter(startDate as Date, new Date());
  }
  return "";
};
