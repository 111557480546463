import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import useZoom, { ChartEvent } from "../common/EventsChart/useZoom";
import { getTickComponent, TickComponent } from "../common/EventsChart/ticks";
import useTicks from "../common/EventsChart/ticks/useTicks";
import { NUM_OF_TICKS } from "../common/EventsChart/TimelineChart";

import { EventData, GraphDataPoint, ZoomProps } from "./types";

type MetricsZoomParams = {
  data: GraphDataPoint[];
  zoomProps?: ZoomProps;
  timelineTicksNum?: number;
  events?: EventData[];
  setTimeframe?: (start: Date, end: Date) => void;
  onZoom?: () => void;
};

export const useMetricsZoom = ({
  data,
  zoomProps,
  timelineTicksNum = NUM_OF_TICKS,
  events,
  setTimeframe,
  onZoom,
}: MetricsZoomParams): {
  ticks: number[];
  domain: number[];
  zoom: JSX.Element;
  onMouseUp: () => void;
  onMouseMove: (ce: ChartEvent) => void;
  onMouseDown: (ce: ChartEvent, e: SyntheticEvent) => void;
  currentData: GraphDataPoint[];
  TickComponent: TickComponent;
  eventsData?: EventData[];
} => {
  const [start, setStart] = useState(new Date(data[0]?.time));
  const [end, setEnd] = useState(new Date(data?.[data?.length - 1]?.time));

  const onZoomChange = useCallback(
    (startTime: number, endTime: number) => {
      onZoom?.();
      zoomProps?.onZoom(true);
      const s = new Date(startTime);
      const e = new Date(endTime);
      setStart(s);
      setEnd(e);
      setTimeframe?.(s, e);
    },
    [onZoom, zoomProps, setTimeframe]
  );
  const { onMouseDown, onMouseMove, onMouseUp, zoom } = useZoom(
    onZoomChange,
    "recharts-cartesian-grid-bg"
  );

  const currentData = useMemo(() => {
    if (zoom && zoomProps?.zoomed) {
      return data.filter(
        (d) => d.time >= start.getTime() && d.time <= end.getTime()
      );
    }
    return data;
  }, [data, end, start, zoom, zoomProps?.zoomed]);

  const ticks = useTicks(start, end, timelineTicksNum);
  const TickComponent = useMemo(() => {
    return getTickComponent(start, end);
  }, [end, start]);

  const domain = useMemo(() => {
    return [start.getTime(), end.getTime()];
  }, [end, start]);

  useEffect(() => {
    if (zoom && !zoomProps?.zoomed) {
      setStart(new Date(data[0]?.time));
      setEnd(new Date(data?.[data?.length - 1]?.time));
    }
  }, [data, zoom, zoomProps?.zoomed]);

  const eventsData = useMemo(() => {
    const filteredEvents = events?.filter(
      (d) => d.time && d.time >= start.getTime() && d.time <= end.getTime()
    );
    filteredEvents?.push({
      time: currentData[0]?.time,
      highlight: false,
    });
    filteredEvents?.push({
      time: currentData[currentData?.length - 1]?.time,
      highlight: false,
    });
    return filteredEvents;
  }, [events, end, start, currentData]);
  return {
    currentData,
    ticks,
    TickComponent,
    domain,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    zoom,
    eventsData,
  };
};
