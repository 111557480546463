import React from "react";

import { IconProps } from "../types";

export const Scale16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M14.7613 10.78C14.8938 10.6378 14.9659 10.4498 14.9625 10.2555C14.959 10.0612 14.8803 9.87579 14.7429 9.73838C14.6055 9.60096 14.4201 9.52225 14.2258 9.51882C14.0315 9.5154 13.8435 9.58752 13.7013 9.72L11.9813 11.44L11.9813 2.75C11.9813 2.55109 11.9023 2.36032 11.7616 2.21967C11.621 2.07902 11.4302 2 11.2313 2C11.0324 2 10.8416 2.07902 10.701 2.21967C10.5603 2.36032 10.4813 2.55109 10.4813 2.75L10.4813 11.44L8.76129 9.72C8.61912 9.58752 8.43107 9.5154 8.23677 9.51882C8.04247 9.52225 7.85708 9.60096 7.71967 9.73838C7.58226 9.87579 7.50354 10.0612 7.50012 10.2555C7.49669 10.4498 7.56881 10.6378 7.70129 10.78L10.7013 13.78C10.8419 13.9205 11.0325 13.9993 11.2313 13.9993C11.43 13.9993 11.6207 13.9204 11.7613 13.78L14.7613 10.78V10.78ZM8.26129 5.22C8.33498 5.28866 8.39408 5.37146 8.43507 5.46346C8.47606 5.55546 8.49811 5.65477 8.49988 5.75548C8.50166 5.85618 8.48314 5.95621 8.44541 6.0496C8.40769 6.14299 8.35155 6.22782 8.28033 6.29904C8.20911 6.37026 8.12428 6.4264 8.03089 6.46412C7.9375 6.50184 7.83747 6.52037 7.73677 6.51859C7.63607 6.51681 7.53675 6.49477 7.44475 6.45378C7.35275 6.41279 7.26995 6.35369 7.20129 6.28L5.48129 4.56L5.48129 13.25C5.48129 13.4489 5.40227 13.6397 5.26162 13.7803C5.12097 13.921 4.9302 14 4.73129 14C4.53238 14 4.34161 13.921 4.20096 13.7803C4.06031 13.6397 3.98129 13.4489 3.98129 13.25L3.98129 4.56L2.26129 6.28C2.11912 6.41248 1.93107 6.4846 1.73677 6.48118C1.54247 6.47775 1.35708 6.39903 1.21967 6.26162C1.08226 6.12421 1.00354 5.93882 1.00012 5.74452C0.996688 5.55022 1.06881 5.36218 1.20129 5.22L4.20129 2.22C4.34192 2.07955 4.53254 2.00066 4.73129 2.00066C4.93004 2.00066 5.12067 2.07955 5.26129 2.22L8.26129 5.22V5.22Z" />
    </svg>
  );
};

Scale16.defaultProps = {
  fill: "currentColor",
};
