import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import clustersPage from "../assets/clustersPage.png";
import { clustersDemoRoutes } from "../constants";
import { ClickableArea, RelativeContainer } from "../styles";

const SelectableRow = styled(ClickableArea)<{ top: string }>`
  width: 96.8%;
  height: 7.1%;
  top: ${({ top }) => top};
  left: 1.6%;
`;

export const ClustersPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <RelativeContainer>
      <img src={clustersPage} alt={"clusters page"} />
      <SelectableRow
        top={"50.7%"}
        onClick={() => navigate(clustersDemoRoutes.clusterView1)}
      />
      <SelectableRow
        top={"57.8%"}
        onClick={() => navigate(clustersDemoRoutes.clusterView2)}
      />
    </RelativeContainer>
  );
};
