import { datadogRum } from "@datadog/browser-rum";

export type DDError = Error & {
  // dd_fingerprint allows custom grouping of error in Datadog Error Tracking
  dd_fingerprint?: string;
};

export const ExceptionMetadataKeys = {
  Datadog: "Datadog",
};

export const notifyDDError = (error: DDError, context?: object | undefined) => {
  datadogRum.addError(error, context);
};

export const getDatadogReplayUrl = (): string => {
  const datadogInternalCtx = datadogRum.getInternalContext();
  const datadogSessionId = datadogInternalCtx?.session_id;

  if (!datadogSessionId) {
    return "https://app.datadoghq.com/rum/replay/sessions";
  }

  return `https://app.datadoghq.com/rum/replay/sessions/${datadogSessionId}`;
};
