import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { StyledPaper } from "../styles";

const FlexContainer = styled.div`
  display: flex;
`;

const ContentContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 6px;
  align-items: center;
  flex-grow: 1;
`;

const RightAlignedTypography = styled(Typography)`
  align-self: flex-start;
`;

const TrendAndGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
`;

const TrendContainer = styled.div`
  display: flex;
  gap: 6px;
`;

type SmallStatsCardProps = {
  title: string;
  mainStat: number;
  trend: string;
  epoch: string;
  graphComponent: React.ReactNode;
};

export const SmallStatsCard: React.FC<SmallStatsCardProps> = ({
  title,
  mainStat,
  trend,
  epoch,
  graphComponent,
}) => {
  return (
    <StyledPaper>
      <Typography variant={"h5"}>{title}</Typography>
      <ContentContainer>
        <RightAlignedTypography variant={"h2"} color={"text.primary"}>
          {mainStat}
        </RightAlignedTypography>

        <TrendAndGraphContainer>
          {graphComponent}
          <TrendContainer>
            <Typography variant={"body3"} color={"text.secondary"}>
              {trend}
            </Typography>
            <Typography variant={"body3"} color={"text.secondary"}>
              {epoch}
            </Typography>
          </TrendContainer>
        </TrendAndGraphContainer>
      </ContentContainer>
    </StyledPaper>
  );
};
