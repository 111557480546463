import React from "react";

import { IconProps } from "../../types";
import { theme } from "../../..";

export const Secret: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 18.035 17.5"
      {...svgProps}
    >
      <path
        d="M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z"
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "Sans",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          direction: "ltr",
          baselineShift: "baseline",
          textAnchor: "start",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          marker: "none",
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <g transform="translate(-.936 -.4)">
        <path
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#fff",
            strokeWidth: 0.79374999,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeOpacity: 1,
          }}
          d="M10.414 8.091h3.709"
        />
        <path
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#fff",
            strokeWidth: 0.79400003,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          d="M8.298 5.975h5.825"
        />
        <path
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#fff",
            strokeWidth: 0.79374999,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeOpacity: 1,
          }}
          d="M5.784 5.975h1.458"
        />
        <path
          style={{
            fill: "#fff",
            fillRule: "evenodd",
            stroke: svgProps.fill,
            strokeWidth: 0.26458332,
            strokeLinecap: "square",
            strokeMiterlimit: 10,
            strokeOpacity: 1,
          }}
          d="M7.718 8.432h0c0-1.167 1.011-2.112 2.259-2.112 1.247 0 2.258.945 2.258 2.112h-1.056 0c0-.583-.538-1.056-1.202-1.056-.664 0-1.202.473-1.202 1.056z"
        />
        <path
          style={{
            fill: "none",
            fillRule: "evenodd",
            stroke: "#fff",
            strokeWidth: 0.79374999,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeOpacity: 1,
          }}
          d="M10.414 10.208h3.709M8.298 12.325h5.825M5.784 12.325h1.458"
        />
        <path
          style={{
            fill: "#fff",
            fillRule: "evenodd",
            stroke: svgProps.fill,
            strokeWidth: 0.26458332,
            strokeLinecap: "square",
            strokeMiterlimit: 10,
            strokeOpacity: 1,
          }}
          d="M7.162 8.37h5.617v3.657H7.162z"
        />
        <circle
          r=".555"
          cy="10.198"
          cx="9.971"
          style={{
            opacity: 1,
            fill: svgProps.fill,
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.26478875,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 10,
            strokeDasharray: ".52957746,.26478873",
            strokeDashoffset: 5.01860619,
            strokeOpacity: 1,
          }}
        />
      </g>
    </svg>
  );
};

Secret.defaultProps = {
  fill: theme.kubernetesIcon,
};
