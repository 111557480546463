import React from "react";
import Popover from "@mui/material/Popover";

import { Command, CommandProps } from "../Command/Command";

type CommandPopperProps = CommandProps & {
  onClose: () => void;
};

export const CommandPopper = ({
  onClose,
  ...rest
}: CommandPopperProps): JSX.Element => {
  const esc = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Popover
      anchorEl={document.body}
      elevation={10}
      onClose={onClose}
      disableRestoreFocus={true}
      slotProps={{
        root: {
          slotProps: {
            backdrop: {
              invisible: false,
              onClick: onClose,
            },
          },
        },
        paper: {
          sx: {
            borderRadius: "8px",
            width: "671px",
            overflowY: "hidden",
          },
        },
      }}
      open={true}
      anchorOrigin={{
        vertical: 128,
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Command {...rest} onKeyDown={esc} />
    </Popover>
  );
};
