import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import { muiColors } from "@komodorio/design-system";

const Container = styled.div`
  cursor: pointer;
  position: relative;
`;

const FlexContainer = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ selected }) => (selected ? selectionColor : unselectedColor)};
  transition: color 0.3s ease-in-out;
`;

const selectionColor = muiColors.indigo[500];
const unselectedColor = muiColors.gray[500];

const Line = styled.div<{ selected: boolean }>`
  width: ${({ selected }) => (selected ? "110%" : "0")};
  height: 2px;
  background-color: ${selectionColor};
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const StyledTypography = styled(Typography)`
  && {
    padding: 10px;
    transition: color 0.3s ease-in-out;
  }
`;

type TopSectionButtonProps = {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: React.ReactNode;
};

export const TopSectionButton: React.FC<TopSectionButtonProps> = ({
  label,
  isSelected,
  onClick,
  icon,
}) => {
  return (
    <Container onClick={onClick}>
      <FlexContainer selected={isSelected}>
        {icon}
        <StyledTypography
          variant={"h5"}
          color={isSelected ? selectionColor : unselectedColor}
        >
          {label}
        </StyledTypography>
      </FlexContainer>
      <Line selected={isSelected} />
    </Container>
  );
};
