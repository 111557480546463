import { Timeframe } from "../../../../../../shared/types/TimeWindow";

const mapping = {
  [Timeframe.Last30Minutes]: "30-minutes",
  [Timeframe.LastHour]: "hour",
  [Timeframe.Last4Hours]: "4-hours",
  [Timeframe.Last24Hours]: "24-hours",
  [Timeframe.Last48Hours]: "48-hours",
  [Timeframe.Last7Days]: "7-days",
  [Timeframe.Last30Days]: "30-days",
  [Timeframe.Custom]: "custom",
};

export const getLabelForTimeframe = (tf: Timeframe): string => mapping[tf];

const labelToTimeframe = Object.fromEntries(
  Object.values(Timeframe)
    .flatMap((v) => (typeof v === "string" ? [] : v))
    .map((tf) => [getLabelForTimeframe(tf), tf])
);

export const getTimeframeForLabel = (
  label: string,
  ifNotFound: Timeframe
): Timeframe => labelToTimeframe[label] ?? ifNotFound;
