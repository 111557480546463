import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { RelativeContainer } from "../reliabilityDemoStyles";
import { RELIABILITY_DEMO_ROUTE } from "../../routes/routes";
import { routes } from "../reliabilityDemoConstants";
import page2Image from "../assets/page2.png";

import { NodePressureDrawerContainer } from "./NodePressureDrawerHandler/NodePressureDrawerHandler";
import { EndOfLifeDrawerContainer } from "./EndOfLifeDrawerHandler/EndOfLifeDrawerHandler";

const BreadCrumbClickArea = styled.div`
  position: absolute;
  cursor: pointer;
  width: 7%;
  height: 2.5%;
  top: 1.5%;
  left: 1.5%;
`;

const ReliabilityChecksClickArea = styled.div`
  position: absolute;
  cursor: pointer;
  width: 20%;
  height: 2.5%;
  top: 9%;
  left: 11%;
`;

export const DetailsPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <RelativeContainer>
      <BreadCrumbClickArea
        onClick={() =>
          navigate(`${RELIABILITY_DEMO_ROUTE}/${routes.overviewPage}`)
        }
      />
      <ReliabilityChecksClickArea
        onClick={() =>
          navigate(`${RELIABILITY_DEMO_ROUTE}/${routes.manageChecksPage}`)
        }
      />
      <img src={page2Image} alt={"loading page"} />
      <NodePressureDrawerContainer />
      <EndOfLifeDrawerContainer />
    </RelativeContainer>
  );
};
