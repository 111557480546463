import { useEffect, useState } from "react";

import { useListAccounts } from "./client/account/useListAccounts";

import { useShouldAllowAccountSwitcher } from "@/components/AccountSwitcher/hooks/useShouldAllowAccountSwitcher";
import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { Account } from "@/generated/accounts";

export const useAccountsService = () => {
  const enabled = useShouldAllowAccountSwitcher();
  const { isKomodorAdmin } = useUserMetadata();
  const [accountsWithDisplayValue, setAccountsWithDisplayValue] = useState<
    Account[] | undefined
  >(undefined);

  const originalAccountsList = useListAccounts({
    enabled,
    params: { disabled: false },
  });
  useEffect(() => {
    if (!originalAccountsList.data) return;
    if (isKomodorAdmin) {
      setAccountsWithDisplayValue(
        originalAccountsList.data.map((account: Account) => ({
          ...updateAccountDisplayName(account),
        }))
      );
    } else {
      setAccountsWithDisplayValue(
        originalAccountsList.data.map((account: Account) => ({
          ...account,
          displayName: account.name,
        }))
      );
    }
  }, [isKomodorAdmin, originalAccountsList.data]);
  return { ...originalAccountsList, data: accountsWithDisplayValue };
};

// This is an exported function instead of anon in order to spyOn in tests, making sure it's not called for non-admins
export const updateAccountDisplayName = (account: Account) => {
  return {
    ...account,
    displayName: account?.displayName
      ? `${account.displayName} (${account.name})`
      : account.name,
  };
};
