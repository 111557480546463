import React from "react";

import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";
import { Details } from "./Details/Details";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const CpuThrottledDrawer: React.FC = () => {
  const { data } = useViolation();
  const { reliabilityBestPracticesTab } = useOverridableFlags();

  const { supportingData, komodorUid, headerProps, summaryProps } =
    useGetCommonDrawerProps(data?.data.violation);

  if (!data?.data.violation) return null;

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription()}
              <Details
                komodorUid={komodorUid ?? ""}
                supportingDataContainers={
                  supportingData?.throttledCPU?.containers ?? []
                }
                checkType={data?.data.violation?.checkType}
              />
              {!!reliabilityBestPracticesTab && (
                <ViolationsTableByIds
                  title="Related best practice violations"
                  ids={data.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              )}
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={howDescription()}
        />
      }
    />
  );
};
