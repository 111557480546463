import React from "react";
import Typography from "@mui/material/Typography";

import { useViolation } from "../../../hooks/useViolation";
import { useGetCommonDrawerProps } from "../../../hooks/drawerContentHooks";
import { DrawerBase } from "../../shared/DrawerBase/DrawerBase";
import { OverviewContent } from "../../shared/DrawerBase/OverviewContent";
import { ViolationsTableByIds } from "../../../../ViolationsBreakdown/ViolationsTableByIds/ViolationsTableByIds";

import { getWhatDescription, getWhyDescription, howDescription } from "./texts";
import { getTotalContainerRestarts } from "./containerRestartsUtils";
import { Details } from "./Details";

import { ViolationsTableType } from "@/components/reliability/ReliabilityTypes";
import { useOverridableFlags } from "@/shared/context/featureFlags/OverridableFlags";

export const ContainerRestarts: React.FC = () => {
  const { data } = useViolation();
  const { reliabilityBestPracticesTab } = useOverridableFlags();
  const { supportingData, komodorUid, headerProps, summaryProps } =
    useGetCommonDrawerProps(data?.data.violation);

  const { containers } = supportingData?.restartingContainers ?? {};

  if (!data?.data.violation || !containers || !komodorUid) return null;

  const totalRestarts = getTotalContainerRestarts(containers);

  return (
    <DrawerBase
      header={headerProps}
      summary={{ data: summaryProps }}
      content={
        <OverviewContent
          whatDescription={
            <>
              {getWhatDescription(containers.length, totalRestarts)}{" "}
              <Details komodorUid={komodorUid} containers={containers} />
              {!!reliabilityBestPracticesTab && (
                <ViolationsTableByIds
                  title="Related best practice violations"
                  ids={data?.data.violation.dependsOnViolationsIds}
                  violationTableType={ViolationsTableType.impactAnalysis}
                />
              )}
            </>
          }
          whyDescription={getWhyDescription()}
          howDescription={
            <Typography variant="body2" color="text.primary">
              {howDescription}
            </Typography>
          }
        />
      }
    />
  );
};
