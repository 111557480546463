import { Button } from "@komodorio/design-system/deprecated";
import { NewWindow16, Plus16 } from "@komodorio/design-system/icons";
import React, { useMemo, useState } from "react";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";

import { useTimezone } from "../../../shared/context/TimezoneProvider";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import useDateFormatter from "../../../shared/hooks/useDateFormatter";
import DataTable, { Column } from "../../common/table/DataTable";
import { ActionsCol, Buttons, CopyText, Header } from "../styles";
import { APIKey } from "../../../generated/auth";
import { useGetApiKeys } from "../../../shared/hooks/auth-service/client/apiKeys/useGetApiKeys";

import DeleteKeyModal from "./DeleteKeyModal";
import GenerateKeyModal from "./GenerateKeyModal";

import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";

const ApiKeys: React.FC = () => {
  const [search, setSearch] = useState("");
  const [openAddApiKey, setOpenAddApiKey] = useState(false);
  const [apiKeyToDelete, setApiKeyToDelete] = useState<APIKey>();

  const { format } = useDateFormatter({ timeZoneName: undefined });
  const { timeZoneName } = useTimezone();

  const { data: apiKeys, refetch } = useGetApiKeys();
  const columns: Column<APIKey>[] = [
    { name: "Description", selector: (ak) => ak.name },
    {
      name: "API Key",
      selector: (ak) => <CopyText text={ak.key} obfuscatedChar={"****"} />,
    },
    {
      name: `Created At (${timeZoneName})`,
      selector: (ak) => format(new Date(ak.createdAt)),
    },
    {
      name: "",
      selector: (ak) => (
        <ActionsCol>
          <Button
            variant="dangerOutline"
            size="small"
            onClick={() => setApiKeyToDelete(ak)}
          >
            Delete
          </Button>
        </ActionsCol>
      ),
    },
  ];

  const filteredApiKeys = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return apiKeys
      ? sortBy(
          apiKeys.filter((ak) => {
            return [ak.name, ak.key].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          (ak) => ak.createdAt
        )
      : [];
  }, [apiKeys, search]);

  return (
    <SettingsViewLayoutWrapper title={"API Keys"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={search}
          showCloseIcon={true}
          ariaLabel="Search api keys"
          onSearch={(value) => setSearch(value)}
        />
        <Buttons>
          <Button
            variant="secondary"
            icon={NewWindow16}
            onClick={() =>
              window.open(
                "https://api.komodor.com/api/docs/index.html",
                "_blank"
              )
            }
          >
            API Documentation
          </Button>
          <Button
            variant="primary"
            icon={Plus16}
            onClick={() => setOpenAddApiKey(true)}
          >
            Generate Key
          </Button>
        </Buttons>
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredApiKeys}
        columns={columns}
        getRowId={(ak) => ak.id}
        highlightedRows
      />
      {openAddApiKey && (
        <ValidationsProvider>
          <GenerateKeyModal
            open
            handleClose={() => setOpenAddApiKey(false)}
            refreshKeys={refetch}
          />
        </ValidationsProvider>
      )}
      {apiKeyToDelete && (
        <DeleteKeyModal
          open
          handleClose={() => setApiKeyToDelete(undefined)}
          apiKey={apiKeyToDelete}
          refreshKeys={refetch}
        />
      )}
    </SettingsViewLayoutWrapper>
  );
};

export default ApiKeys;
