import React from "react";

import { IconProps } from "../types";

export const Workflows16: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 11H16V9H10V11ZM4 15H16V13H4V15ZM4 7H12V5H10H4V7ZM0 1V3H6V1H0Z"
    />
  </svg>
);

Workflows16.defaultProps = {
  fill: "currentColor",
};
