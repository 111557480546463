import React from "react";
import { Record, Unknown } from "runtypes";
import MuiTypography from "@mui/material/Typography";

import CopyComponent from "../../../common/Copy";
import { ExternalLinkHoverUnderline } from "../../../common/Link/ExternalLink";
import { isSandbox } from "../../../../shared/utils/sandbox";
import { useHasPermissions } from "../../../../shared/hooks/useUserMetadata/rbac";

import { Installation } from "./useInstallationSubscription";

export const Info: React.FC<{
  label: string;
  className?: string;
  children?: React.ReactNode;
}> = ({ label, className, children }) => (
  <div className={className}>
    <div>{label}</div>
    <MuiTypography fontWeight={700} variant={"body2"} color={"text.primary"}>
      {children}
    </MuiTypography>
  </div>
);

const stringOrNull = (v: unknown) => (typeof v === "string" ? v : null);

export type GenericInfo = React.FC<{ installation: Installation }>;

const WebhookUrlConfig = Record({ webhookPath: Unknown });

export const WebhookUrl: GenericInfo = ({ installation }) => {
  if (!WebhookUrlConfig.guard(installation.configuration)) {
    return null;
  }
  return (
    <CopyComponent
      label="Webhook url:"
      value={stringOrNull(installation.configuration.webhookPath) ?? "N/A"}
    />
  );
};

const hiddenInstallationId = String.fromCharCode(9679).repeat(4);

export const APIKeyFromId: GenericInfo = ({ installation }) => {
  const value = isSandbox() ? hiddenInstallationId : installation.id;
  const { canManageIntegrations } = useHasPermissions();
  if (!canManageIntegrations) {
    return null;
  }
  return <CopyComponent label="API key:" value={value} />;
};
export const APIKeyFromLookupId: GenericInfo = ({ installation }) => {
  const value = isSandbox()
    ? hiddenInstallationId
    : installation.lookupId ?? "N/A";

  const { canManageIntegrations } = useHasPermissions();
  if (!canManageIntegrations) {
    return null;
  }
  return <CopyComponent label="API key:" value={value} />;
};

const ClusterNameConfig = Record({ clusterName: Unknown });
export const ClusterName: GenericInfo = ({ installation }) => {
  if (!ClusterNameConfig.guard(installation.configuration)) {
    return null;
  }
  return (
    <Info label="Cluster:">
      <div style={{ whiteSpace: "pre-line" }}>
        {stringOrNull(installation.configuration.clusterName) ?? "N/A"}
      </div>
    </Info>
  );
};

const ddConfig = Record({ ddApiKey: Unknown, ddAppKey: Unknown });
export const Datadog: GenericInfo = ({ installation }) => {
  const { canManageIntegrations } = useHasPermissions();
  if (!canManageIntegrations) {
    return null;
  }

  if (!ddConfig.guard(installation.configuration)) {
    return null;
  }
  const apiKey = isSandbox()
    ? hiddenInstallationId
    : (installation.configuration.ddApiKey as string);
  const appKey = isSandbox()
    ? hiddenInstallationId
    : (installation.configuration.ddAppKey as string);
  const maskedApiKey = "*".repeat(8) + apiKey.slice(-4);
  const maskedAppKey = "*".repeat(8) + appKey.slice(-4);
  return [
    <Info label="API Key">{maskedApiKey}</Info>,
    <Info label="Application Key">{maskedAppKey}</Info>,
  ];
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const renderDocs =
  (docsUrl: string): GenericInfo =>
  () =>
    (
      <ExternalLinkHoverUnderline href={docsUrl}>
        View documentation
      </ExternalLinkHoverUnderline>
    );
