import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Download from "@mui/icons-material/Download";

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  > svg {
    height: 16px;
  }
`;

export const ExportButton: React.FC = () => {
  return (
    <StyledButton variant={"outlined"}>
      <Download fontSize={"small"} />
      Export
    </StyledButton>
  );
};
