"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowType = void 0;
var WorkflowType;
(function (WorkflowType) {
    WorkflowType["OOMKilled"] = "OOMKilled";
    WorkflowType["PVC"] = "PVC";
    WorkflowType["Node"] = "node";
    WorkflowType["Service"] = "service";
    WorkflowType["Availability"] = "availability";
    WorkflowType["Job"] = "job";
    WorkflowType["CronJob"] = "cronJob";
    WorkflowType["Deploy"] = "deploy";
    WorkflowType["StandalonePod"] = "pod";
})(WorkflowType = exports.WorkflowType || (exports.WorkflowType = {}));
