import { GridColDef } from "@mui/x-data-grid-pro";
import React from "react";

import { ReliabilityCheckData } from "../../../../../riskManagementTypes";

import { ActionIcons } from "./ActionIcons";
import { ActiveCheckSwitch } from "./ActiveCheckSwitch";
import { ManageChecksTableText } from "./ManageChecksTableText";

export const useReliabilityChecksColumns = () => {
  const columns: GridColDef<ReliabilityCheckData>[] = [
    {
      field: "Active",
      headerName: "Active",
      minWidth: 100,
      maxWidth: 100,
      renderCell: (params) => {
        return <ActiveCheckSwitch data={params.row} />;
      },
    },
    {
      field: "Check name",
      headerName: "Check name",
      flex: 1,
      renderCell: (params) => {
        return <ManageChecksTableText data={params.row} />;
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 150,
      maxWidth: 150,
      renderCell: () => {
        return <ActionIcons />;
      },
    },
  ];

  return columns;
};
