import React from "react";

import { IconProps } from "../types";

export const Reliability24: React.FC<IconProps> = (svgProps) => (
  <svg
    role={svgProps.onClick ? "button" : "img"}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0003 21.8093C9.69148 21.2481 7.78039 19.9489 6.26707 17.9117C4.75374 15.8745 3.99707 13.6122 3.99707 11.125V5.2598L12.0003 2.1908L17.3251 4.23265L15.7233 5.83438L12.0003 4.40875L6.0721 6.68175V11.125C6.0721 13.0759 6.63378 14.8457 7.75715 16.4343C8.88051 18.0229 10.2949 19.0979 12.0003 19.6593C13.7058 19.0979 15.1202 18.0229 16.2435 16.4343C17.1633 15.1336 17.7066 13.7121 17.8733 12.1697L20.0036 10.0394V11.125C20.0036 13.6122 19.247 15.8745 17.7336 17.9117C16.2203 19.9489 14.3092 21.2481 12.0003 21.8093ZM7.69275 11.1299L11.8074 15.2446L21.8036 5.25435L20.3612 3.81195L11.8324 12.3408L9.15415 9.66251L7.69275 11.1299Z"
    />
  </svg>
);

Reliability24.defaultProps = {
  fill: "currentColor",
};
