import React, { useEffect } from "react";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import ArgoWorkflow from "../../resources/argoWorkflow";
import { useDeletedPodsProperties } from "../../../Inspection/historicalFetch/useResourceListFromDatabase";
import { Timeframe } from "../../../../shared/types/TimeWindow";

import PodsTab from "./PodsTab";

export const ArgoWFPodsTab: React.FC<{ resource: ArgoWorkflow }> = ({
  resource,
}) => {
  const {
    showDeletedPodsParam,
    setShowDeletedPodsParam,
    setDeletedPodsTimeWindow,
  } = useDeletedPodsProperties();

  useEffect(() => {
    if (showDeletedPodsParam === null) {
      setShowDeletedPodsParam(true, true);
      setDeletedPodsTimeWindow(
        { timeframe: Timeframe.Last48Hours },
        { replace: true }
      );
    }
  }, [setDeletedPodsTimeWindow, setShowDeletedPodsParam, showDeletedPodsParam]);

  return <PodsTab resource={resource} />;
};
