import { useEffect, useState } from "react";

export const useControlAutoFocus = (popoverOpen: boolean) => {
  const [shouldAutoFocusFirstItem, setShouldAutoFocusFirstItem] =
    useState(false);

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "ArrowDown") {
        setShouldAutoFocusFirstItem(true);
      }
    };

    if (popoverOpen) {
      document.addEventListener("keydown", down);
    } else {
      document.removeEventListener("keydown", down);
      setShouldAutoFocusFirstItem(false);
    }

    return () => document.removeEventListener("keydown", down);
  }, [popoverOpen]);

  return { shouldAutoFocusFirstItem, setShouldAutoFocusFirstItem };
};
