"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleToPoliciesMapping = exports.RbacRoles = exports.RbacPolicies = void 0;
exports.RbacPolicies = {
    RbacPolicyViewer: {
        name: "default-read-only",
        statements: [
            {
                actions: ["view:all"],
                resources: [
                    {
                        cluster: "*",
                    },
                ],
            },
        ],
    },
    RbacPolicyAccountAdmin: {
        name: "default-allow-all",
        statements: [
            {
                actions: ["*"],
                resources: [
                    {
                        cluster: "*",
                    },
                ],
            },
        ],
    },
    RbacPolicyBasicActions: {
        name: "default-basic-actions",
        statements: [
            {
                actions: [
                    "delete:pod",
                    "scale:deployment",
                    "scale:statefulset",
                    "restart:deployment",
                    "restart:statefulset",
                    "rerun:job",
                    "exec:pod",
                    "forward:port",
                ],
                resources: [
                    {
                        cluster: "*",
                    },
                ],
            },
        ],
    },
    RbacPolicyKubectlAccess: {
        name: "default-allow-get-kubeconfig",
        statements: [
            {
                actions: ["get:kubeconfig"],
                resources: [
                    {
                        cluster: "*",
                    },
                ],
            },
        ],
    },
};
exports.RbacRoles = {
    RbacRoleAccountAdmin: "account-admin",
    RbacRoleDeveloper: "developer",
    RbacRoleViewer: "viewer",
};
exports.RoleToPoliciesMapping = {
    [exports.RbacRoles.RbacRoleAccountAdmin]: [exports.RbacPolicies.RbacPolicyAccountAdmin],
    [exports.RbacRoles.RbacRoleViewer]: [exports.RbacPolicies.RbacPolicyViewer],
    [exports.RbacRoles.RbacRoleDeveloper]: [
        exports.RbacPolicies.RbacPolicyViewer,
        exports.RbacPolicies.RbacPolicyBasicActions,
    ],
};
