import React from "react";

import { IconProps } from "../types";

export const LightningBoltCircle24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role={svgProps.onClick ? "button" : "img"}
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <rect width="24" height="24" rx="12" />
      <path
        d="M11.5 7L7.5 13H10.5L7.5 19L16.5 11.2857H13.5L16.5 7H11.5Z"
        fill="white"
      />
    </svg>
  );
};

LightningBoltCircle24.defaultProps = {
  fill: "currentColor",
};
