import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import reliabilityChecksImage from "../assets/reliabilityChecksPage.png";
import { RELIABILITY_DEMO_ROUTE } from "../../routes/routes";
import { routes } from "../reliabilityDemoConstants";

const Container = styled.div`
  position: relative;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

const BreadCrumbClickArea = styled.div`
  position: absolute;
  cursor: pointer;
  width: 11%;
  height: 1.5%;
  top: 0.5%;
  left: 1.5%;
`;

export const ManageChecksPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <img src={reliabilityChecksImage} alt={"loading page"} />
      <BreadCrumbClickArea
        onClick={() =>
          navigate(`${RELIABILITY_DEMO_ROUTE}/${routes.overviewPage}`)
        }
      />
    </Container>
  );
};
