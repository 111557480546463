import React, { useMemo } from "react";
import styled from "styled-components";
import { capitalize } from "lodash";
import Typography from "@mui/material/Typography";

import { RiskListObject, Severity } from "../types";
import { SeverityChip } from "../../../../../shared/SeverityChip";
import { getAccumulatedViolations } from "../../risksListUtils";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
`;

type RiskCardViolationsBreakdownProps = {
  violations: RiskListObject["violations"];
};

export const RiskCardViolationsBreakdown: React.FC<
  RiskCardViolationsBreakdownProps
> = ({ violations }) => {
  const totalViolations = useMemo(() => {
    return getAccumulatedViolations(violations.totals);
  }, [violations]);

  const violationsBreakdown = useMemo(() => {
    return Object.entries(violations.totals).map(([violationKey, val]) => {
      const label = `${val} ${capitalize(violationKey)} `;
      return (
        <SeverityChip
          severity={violationKey as Severity}
          key={violationKey}
          label={label}
        />
      );
    });
  }, [violations]);
  return (
    <Container>
      <Typography variant={"h5"}>{totalViolations} Violations</Typography>
      {violationsBreakdown}
    </Container>
  );
};
