import React from "react";

import { IconProps } from "../types";

export const Shell24: React.FC<IconProps> = (svgProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    cursor={svgProps.onClick ? "pointer" : undefined}
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.5h16a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5ZM2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6Zm4.239 9.808a.75.75 0 0 0 1.06 0l3.252-3.25a.75.75 0 0 0 0-1.06l-3.252-3.25a.75.75 0 1 0-1.06 1.06l2.721 2.72-2.721 2.72a.75.75 0 0 0 0 1.06ZM11 15.75a.75.75 0 0 1 .75-.75h5.5a.75.75 0 0 1 0 1.5h-5.5a.75.75 0 0 1-.75-.75Z"
    />
  </svg>
);

Shell24.defaultProps = {
  fill: "currentColor",
};
