import React from "react";
import { muiColors, theme } from "@komodorio/design-system";
import Typography from "@mui/material/Typography";
import { AlertTriangle16 } from "@komodorio/design-system/icons";
import styled from "styled-components";

// [86bxfq1fu] fix dependency cycle
// eslint-disable-next-line import/no-cycle
import PodPhaseGroup from "../../../EventGroup/groupedPodEvent/PodPhaseGroup";
import useDateFormatter from "../../../../../shared/hooks/useDateFormatter";

import { Section } from "./styles";

const Title = styled.div`
  display: flex;
  column-gap: 0.4rem;
  width: max-content;
  align-items: center;
`;

export const HeaderSection: React.FC<{
  eventGroup: PodPhaseGroup;
}> = ({ eventGroup }) => {
  const { format } = useDateFormatter({ timeZoneName: undefined });
  const Icon = AlertTriangle16;

  return (
    <Section>
      <div>
        <Title>
          {eventGroup.phaseStatus !== "Normal" && (
            <Icon color={muiColors.pink[500]} />
          )}
          <Typography variant="h3">{eventGroup.getStatus()}</Typography>
          <Typography variant="h3">-</Typography>
          <Typography variant="h3" style={{ fontWeight: "normal" }}>
            Pod phase
          </Typography>
        </Title>
        <Typography variant="body2" color={theme.foreground.fgSubtle}>
          <div>
            <Typography variant={"body2"} color={muiColors.gray[600]}>
              {`Began on ${format(eventGroup.startTime)}`}
            </Typography>
            {eventGroup.isCompleted && (
              <Typography variant={"body2"} color={muiColors.gray[600]}>
                {`Ended on ${format(eventGroup.endTime)}`}
              </Typography>
            )}
          </div>
        </Typography>
      </div>
    </Section>
  );
};
