import React from "react";

import { IconProps } from "../types";

export const Cordon16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H15C15.5523 0 16 0.447715 16 1V6C16 6.05252 15.996 6.10409 15.9882 6.15442C15.5633 5.61865 15.0618 5.14652 14.5 4.75469V1.5H1.5V5.5H5.60102C5.14845 5.94348 4.75585 6.4479 4.43648 7H1C0.447716 7 0 6.55228 0 6V1ZM3.95963 8H1C0.447715 8 0 8.44772 0 9V14C0 14.5523 0.447716 15 1 15H5.13789C4.75614 14.5456 4.43109 14.042 4.17363 13.5H1.5V9.5H3.57089C3.64537 8.97932 3.77714 8.47712 3.95963 8ZM3.50124 10.6331C3.20166 10.8058 3 11.1293 3 11.5C3 11.9777 3.33499 12.3772 3.78289 12.4764C3.6107 11.8902 3.51315 11.2721 3.50124 10.6331ZM7.85657 4.01632C7.52437 4.1519 7.20493 4.31226 6.90053 4.49512C6.39495 4.44519 6 4.01872 6 3.5C6 2.94772 6.44772 2.5 7 2.5C7.55228 2.5 8 2.94772 8 3.5C8 3.68892 7.94761 3.8656 7.85657 4.01632ZM4 2.5C3.44772 2.5 3 2.94772 3 3.5C3 4.05228 3.44772 4.5 4 4.5C4.55228 4.5 5 4.05228 5 3.5C5 2.94772 4.55228 2.5 4 2.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 16C13.5376 16 16 13.5376 16 10.5C16 7.46243 13.5376 5 10.5 5C7.46243 5 5 7.46243 5 10.5C5 13.5376 7.46243 16 10.5 16ZM9.875 13H8V8H9.875V13ZM13 13H11.125V8H13V13Z"
      />
    </svg>
  );
};

Cordon16.defaultProps = {
  fill: "currentColor",
};
