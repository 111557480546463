import React from "react";
import Drawer from "@mui/material/Drawer";

import failsToRenewCertificatesDrawer from "../assets/failsToRenewCertificatesDrawer.png";
import { CloseButtonArea } from "../styles";

type FailsToRenewCertificatesDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const FailsToRenewCertificatesDrawer: React.FC<
  FailsToRenewCertificatesDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img
        src={failsToRenewCertificatesDrawer}
        alt={"fails to renew certificates drawer"}
        width={864}
      />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};
