import React from "react";

import { AriaLabels } from "../../../shared/config/ariaLabels";

import { PageName, usePages } from "./pages";
import { AppBarLink } from "./AppBarLink";

const name = PageName.Clusters;

export const ClustersLink: React.FC = () => {
  const pages = usePages();
  const page = pages[name];
  return page.shouldShow ? (
    <AppBarLink
      text={name}
      route={page.url}
      icon={page.icon && <page.icon />}
      end={true}
      locked={page.locked}
      ariaLabel={AriaLabels.AppBar.Clusters}
    />
  ) : null;
};
