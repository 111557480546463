import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ClickableArea } from "../styles";
import {
  COST_OPTIMIZATION,
  DEMO,
  EVENTS_ROUTE,
  RELIABILITY,
  SERVICES,
} from "../../routes/routes";
import { clustersDemoRoutes } from "../constants";
import { ReliabilityRoutes } from "../../reliability/constants/reliabilityConstants";

const Container = styled.div`
  position: relative;
  > img {
    width: 100%;
  }
`;

const Tabs = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 22% 18% 15% 12.5% 12.5% 20%;
  width: 37.2%;
  height: 17%;
  top: 81%;
  left: 8%;
`;

const ClickableTabArea = styled(ClickableArea)`
  position: relative;
  border-radius: 4px;
`;

export const Header: React.FC<{ headerImg: string }> = ({ headerImg }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <img src={headerImg} alt={"cluster view header"} />
      <Tabs>
        <ClickableTabArea onClick={() => navigate(`/${SERVICES}`)} />
        <ClickableTabArea
          onClick={() =>
            navigate(`/${RELIABILITY}/${ReliabilityRoutes.violations}`)
          }
        />
        <ClickableTabArea
          onClick={() => navigate(`/${DEMO}/${clustersDemoRoutes.policies}`)}
        />
        <ClickableTabArea onClick={() => navigate(`/${COST_OPTIMIZATION}`)} />
        <ClickableTabArea onClick={() => navigate(EVENTS_ROUTE)} />
        <ClickableTabArea
          onClick={() => navigate("/main/resources/workloads/pods/production")}
        />
      </Tabs>
    </Container>
  );
};
