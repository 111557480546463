import React from "react";

import { IconProps } from "../types";

export const Minus16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M7.25 7.25H2.75C2.33579 7.25 2 7.58579 2 8C2 8.41421 2.33579 8.75 2.75 8.75H7.25H8.75H13.25C13.6642 8.75 14 8.41421 14 8C14 7.58579 13.6642 7.25 13.25 7.25H8.75H7.25Z" />
    </svg>
  );
};

Minus16.defaultProps = {
  fill: "currentColor",
};
