import { palette } from "./palette";

/**
 * @deprecated Use `muiPalette` instead.
 */
export const theme = {
  foreground: {
    fgPrimary: palette.gray[800],
    fgSubtle: palette.gray[600],
    fgDisabled: palette.gray[400],
    fgWhite: palette.white[0],
    fgDarkBlue: palette.darkBlue[500],
    fgPink: palette.pink[500],
    fgGreen: palette.green[700],
    fgOrange: palette.orange[500],
    fgOnDark: palette.gray[200],
  },
  background: {
    bgDark: palette.darkBlue[900],
    bgWhite: palette.white[0],
    bgGrayLight: palette.gray[50],
    bgGray: palette.gray[100],
    bgGrayDark: palette.gray[200],
    bgOrange: palette.orange[500],
    bgPinkLight: palette.pink[100],
    bgYellowLight: palette.yellow[200],
    bgGreenLight: palette.green[100],
    bgBlueLight: palette.blue[100],
  },
  border: {
    borderGray: palette.gray[200],
    borderGrayDarker: palette.gray[500],
    borderPink: palette.pink[500],
    borderGreen: palette.green[500],
    borderOrange: palette.orange[500],
    borderDarkBlue: palette.darkBlue[500],
  },
  text: palette.black[0],
  icon: palette.gray[600],
  button: {
    bg: palette.white[0],
    border: palette.gray[200],
    text: palette.black[0],
    hoverBg: palette.gray[100],
    pressedBg: palette.gray[200],
    textDisabled: palette.gray[400],
    primary: {
      bg: palette.darkBlue[500],
      text: palette.white[0],
      hoverBg: palette.darkBlue[600],
      pressedBg: palette.darkBlue[700],
      disabledBg: palette.gray[200],
      textDisabled: palette.gray[400],
    },
    secondary: {
      bg: palette.white[0],
      border: palette.gray[300],
      text: palette.darkBlue[500],
      hoverBg: palette.blue[50],
      pressedBg: palette.blue[100],
      textDisabled: palette.gray[400],
    },
    danger: {
      bg: palette.pink[500],
      text: palette.white[0],
      hoverBg: palette.pink[600],
      pressedBg: palette.pink[700],
      disabledBg: palette.gray[200],
      textDisabled: palette.gray[400],
    },
    dangerOutline: {
      bg: palette.white[0],
      border: palette.gray[300],
      text: palette.pink[500],
      hoverBg: palette.pink[50],
      pressedBg: palette.pink[100],
      textDisabled: palette.gray[400],
    },
  },
  iconButton: {
    bg: palette.white[0],
    border: palette.gray[300],
    icon: palette.gray[600],
    hoverBg: palette.gray[100],
    pressedBg: palette.gray[200],
    iconDisabled: palette.gray[400],
    danger: {
      border: palette.pink[100],
      icon: palette.pink[500],
      hoverBg: palette.pink[50],
      pressedBg: palette.pink[100],
    },
  },
  divider: palette.gray[200],
  editor: {
    bg: palette.white[0],
    default: palette.blue[500],
    lineNumbers: palette.gray[600],
    activeLine: palette.gray[100],
    selector: palette.gray[300],
    key: palette.gray[800],
    string: palette.green[700],
    number: palette.red[700],
    boolean: palette.purple[500],
    comment: palette.gray[400],
    foldBorder: palette.gray[600],
    foldBg: palette.gray[200],
  },
  menu: {
    item: {
      hoverBg: palette.gray[100],
      pressedBg: palette.gray[200],
      text: palette.black[0],
      textDisabled: palette.gray[400],
      danger: {
        hoverBg: palette.pink[50],
        pressedBg: palette.pink[100],
        text: palette.pink[500],
      },
    },
  },
  kubernetesIcon: palette.blue[500],
  message: {
    background: palette.white[0],
  },
  progressBar: {
    background: palette.gray[600],
    lineColor: palette.green[500],
  },
};
