export const pinkBrand = "#FC1683";
export const pinkForUI = "#FFD2E8";
export const pinkForUI2 = "#FFD2E7";

export const redForDelete = "#fc3357";
export const redError = "#d80329";

export const darkBlueBrand = "#061431";
export const blackForCode = "#141518";

export const blackGreyText = "#3B3D45";

export const blueBrand = "#1347FF";
export const blueForUI = "#D6EFFE";
export const blueForUI2 = "#B3D7FF";
export const blueForUIText = "#007AFF";
export const lightBlue = "#DBEDFF";

export const greenBrand = "#1BE99A";
export const greenForUI = "#A4F8D7";
export const greenForUIText = "#1FA470";

export const yellowForUI = "#FCE492";
export const yellowForUIText = "#FD9800";
export const yellowHighlightText = "#ffd976";

export const lightYellowForUI = "#FFEDAF";

export const gray1 = "#333333";
export const gray2 = "#4F4F4F";
export const gray3 = "#828282";
export const gray4 = "#BDBDBD";
export const gray5 = "#E0E0E0";
export const gray6 = "#F2F2F2";
export const gray7 = "#f8f8f8";
export const gray8 = "#EEF2F7";
export const gray9 = "#EAEDF0";
export const gray10 = "#707583";
export const gray11 = "#bcc0c8";
export const gray12 = "#b3b6bc";
export const gray13 = "#DCDDDF";
export const gray14 = "#eceff2";
export const gray15 = "#EFF2F5";
export const gray16 = "#6e7a96";
export const gray17 = "#9e9fa3";
export const darkGray = "#3D4048";
export const darkGray2 = "#d3d5d9";
export const darkGray3 = "#3d4246";
export const grayBorder = "#cad1db";
export const grayReferenceLine = "#DCE3EF";

export const transparentGray1 = "#16181F70";

export const disabledGray = "#A6AFBD";
export const disabledGray2 = "#B3B6BC";
export const disabledGray3 = "#DCDDDF";

export const grayAppBackground = "#F4F7FA";
export const grayAppBackground2 = "#ECEFF2";

export const offWhite = "#ffffff";
export const offBlack = "#000000";
export const lightGray = "#4A5469";
export const lightPink = "#FEDCEC";
export const lightPurple = "#E9DBFF";
export const lightPurple2 = "#E6D9FA";
export const lightOrange = "#FCE79C";
export const cyan = "#C2F9F4";
export const disabledBlue = "#388fed";
