import React from "react";

import { IconProps } from "../types";

export const Close24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.72007 5.72003C5.8607 5.57958 6.05132 5.50069 6.25007 5.50069C6.44882 5.50069 6.63945 5.57958 6.78007 5.72003L12.0001 10.94L17.2201 5.72003C17.2887 5.64634 17.3715 5.58724 17.4635 5.54625C17.5555 5.50526 17.6548 5.48322 17.7556 5.48144C17.8563 5.47966 17.9563 5.49819 18.0497 5.53591C18.1431 5.57363 18.2279 5.62977 18.2991 5.70099C18.3703 5.77221 18.4265 5.85705 18.4642 5.95043C18.5019 6.04382 18.5204 6.14385 18.5187 6.24455C18.5169 6.34526 18.4948 6.44457 18.4539 6.53657C18.4129 6.62857 18.3538 6.71137 18.2801 6.78003L13.0601 12L18.2801 17.22C18.3538 17.2887 18.4129 17.3715 18.4539 17.4635C18.4948 17.5555 18.5169 17.6548 18.5187 17.7555C18.5204 17.8562 18.5019 17.9562 18.4642 18.0496C18.4265 18.143 18.3703 18.2279 18.2991 18.2991C18.2279 18.3703 18.1431 18.4264 18.0497 18.4642C17.9563 18.5019 17.8563 18.5204 17.7556 18.5186C17.6548 18.5168 17.5555 18.4948 17.4635 18.4538C17.3715 18.4128 17.2887 18.3537 17.2201 18.28L12.0001 13.06L6.78007 18.28C6.6379 18.4125 6.44985 18.4846 6.25555 18.4812C6.06125 18.4778 5.87586 18.3991 5.73845 18.2617C5.60104 18.1242 5.52233 17.9389 5.5189 17.7446C5.51547 17.5503 5.58759 17.3622 5.72007 17.22L10.9401 12L5.72007 6.78003C5.57962 6.63941 5.50073 6.44878 5.50073 6.25003C5.50073 6.05128 5.57962 5.86066 5.72007 5.72003Z"
      />
    </svg>
  );
};

Close24.defaultProps = {
  fill: "currentColor",
};
