import { palette } from "../../../palette";

import { Timeframe } from "./types";

export const defaultTimeFrameToDateFormat: Record<Timeframe, string> = {
  [Timeframe.Last30Minutes]: "HH:mm:ss",
  [Timeframe.LastHour]: "HH:mm:ss",
  [Timeframe.Last4Hours]: "HH:mm:ss",
  [Timeframe.Last24Hours]: "HH:mm:ss",
  [Timeframe.Last48Hours]: "HH:mm",
  [Timeframe.Last7Days]: "MM/dd",
  [Timeframe.Custom]: "MM/dd",
};

export const defaultTotalBars = 30;

export const defaultBarColors = [
  palette.pink["300"],
  palette.pink["500"],
  palette.pink["700"],
  palette.pink["900"],
];
