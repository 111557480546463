import { AggregationsListItemProps } from "@/components/k8sAddons/components/AggregationsListItem";
import { now } from "@/components/k8sAddons/constants/time";
import { SeverityColor } from "@/components/k8sAddons/types";
import {
  CertificateControllerStatusEnum,
  CertificateStatusConditions,
  CertificateStatusEnum,
  Operator,
} from "@/generated/addonsApi";

const sevenDaysFromNow = now + 7 * 24 * 60 * 60 * 1000;
const thirtyDaysFromNow = now + 30 * 24 * 60 * 60 * 1000;

export const aggregationFiltersList: AggregationsListItemProps[] = [
  {
    title: "Total Certs",
    filterCategory: "name",
    color: "info",
  },
  {
    title: "Valid Certs",
    filterCategory: "status",
    color: "success",
    filter: {
      value: ["valid"],
      operator: Operator.In,
      name: "status",
    },
  },
  {
    title: "Expired Certs",
    filterCategory: "status",
    color: "error",
    filter: {
      value: ["expired"],
      operator: Operator.In,
      name: "status",
    },
  },
  {
    title: "Failed renewal",
    filterCategory: "status",
    color: "error",
    filter: {
      value: ["failed renewal"],
      operator: Operator.In,
      name: "status",
    },
  },
  {
    title: "Expiring within 7 days",
    filterCategory: "status",
    color: "warning",
    filter: {
      value: [new Date(sevenDaysFromNow).toISOString()],
      operator: Operator.Lte,
      name: "expiration",
    },
  },
  {
    title: "Expiring within 30 days",
    filterCategory: "status",
    color: "warning",
    filter: {
      value: [new Date(thirtyDaysFromNow).toISOString()],
      operator: Operator.Lte,
      name: "expiration",
    },
  },
];

export const certificateStatusToColor: Record<
  CertificateStatusEnum,
  SeverityColor
> = {
  [CertificateStatusEnum.Expired]: "error",
  [CertificateStatusEnum.Failed]: "error",
  [CertificateStatusEnum.Renewing]: "warning",
  [CertificateStatusEnum.Valid]: "success",
  [CertificateStatusEnum.Unknown]: "info",
};

export const certificateControllerStatusToColor: Record<
  CertificateControllerStatusEnum,
  SeverityColor
> = {
  [CertificateControllerStatusEnum.Healthy]: "success",
  [CertificateControllerStatusEnum.Unhealthy]: "error",
};

export const SEARCH_TABLE_PLACEHOLDER = "Search for certificates";

export const conditionPropertyToTitle: Record<
  keyof CertificateStatusConditions,
  string
> = {
  type: "Type",
  status: "Status",
  reason: "Reason",
  message: "Message",
  lastTransitionTime: "Last updated",
};
