import { muiColors } from "@komodorio/design-system";
import React, { useMemo } from "react";
import styled from "styled-components";

import { GetSummaryDataOutput } from "../../../violationDrawerTypes";
import { reliabilityArialLabels } from "../../../../../../../reliabilityArialLabels";

import { horizontalPadding } from "./styles";
import { MetadataItem } from "./MetadataItem";
import { SummaryLink } from "./SummaryLink";

const Container = styled.div<{ bgcolor: string }>`
  display: flex;
  gap: 32px;
  padding: 16px ${horizontalPadding}px;
  background-color: ${({ bgcolor }) => bgcolor};
`;

const StyledTextLink = styled(SummaryLink)`
  && {
    font-size: 14px;
  }
`;

const {
  violationDrawer: { metadataContainer: metadataContainerAriaLabel },
} = reliabilityArialLabels;

export interface SummaryProps {
  data?: GetSummaryDataOutput;
  backgroundColor?: string;
}

export const Summary: React.FC<SummaryProps> = ({
  data,
  backgroundColor = muiColors.gray[50],
}) => {
  const content = useMemo(
    () =>
      data?.metadataItems?.map(({ label, value, onClick }) => {
        const itemValue: string = typeof value === "string" ? value : "";

        const valueToUse =
          onClick != undefined ? (
            <StyledTextLink text={itemValue} onClick={onClick} />
          ) : (
            value
          );

        return <MetadataItem key={label} label={label} value={valueToUse} />;
      }),
    [data?.metadataItems]
  );

  return (
    <Container
      bgcolor={backgroundColor ?? ""}
      aria-label={metadataContainerAriaLabel}
    >
      {content}
    </Container>
  );
};
