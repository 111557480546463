import React from "react";

import { IconProps } from "../types";

export const Integration16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2992 3.9C9.61476 3.9 9.01713 3.43671 8.84653 2.77389C8.65773 2.04034 7.99008 1.5 7.2 1.5C6.40992 1.5 5.74227 2.04034 5.55347 2.77389C5.38287 3.43671 4.78524 3.9 4.10081 3.9H1.5V5.1989C2.90937 5.78663 3.9 7.17763 3.9 8.8C3.9 10.4224 2.90937 11.8134 1.5 12.4011V14.5H3.5989C4.18663 13.0906 5.57763 12.1 7.2 12.1C8.82237 12.1 10.2134 13.0906 10.8011 14.5H12.1V11.8992C12.1 11.2148 12.5633 10.6171 13.2261 10.4465C13.9597 10.2577 14.5 9.59008 14.5 8.8C14.5 8.00992 13.9597 7.34227 13.2261 7.15347C12.5633 6.98287 12.1 6.38524 12.1 5.70081V3.9H10.2992ZM9.6 16C9.6 14.6745 8.52548 13.6 7.2 13.6C5.87452 13.6 4.8 14.6745 4.8 16H0.999999C0.447715 16 0 15.5523 0 15V11.2C1.32548 11.2 2.4 10.1255 2.4 8.8C2.4 7.47452 1.32548 6.4 0 6.4V3.4C0 2.84771 0.447715 2.4 1 2.4H4.10081C4.45605 1.01982 5.70893 0 7.2 0C8.69107 0 9.94395 1.01982 10.2992 2.4H12.6C13.1523 2.4 13.6 2.84772 13.6 3.4V5.70081C14.9802 6.05605 16 7.30893 16 8.8C16 10.2911 14.9802 11.544 13.6 11.8992V15C13.6 15.5523 13.1523 16 12.6 16H9.6Z"
      />
    </svg>
  );
};

Integration16.defaultProps = {
  fill: "currentColor",
};
