import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { useRiskManagementContext } from "../../../../../context/RiskManagementContext";
import { ReliabilityCheckCategory } from "../../../../../riskManagementTypes";
import { reliabilityChecksCategoryOrder } from "../../../../../constants";

import { ManageChecksTable } from "./ManageChecksTable";
import { ActiveChecksStatus } from "./ActiveChecksStatus";

const Container = styled.div``;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 10px 0;
`;

export const ManageChecksTables: React.FC = () => {
  const { reliabilityChecksData } = useRiskManagementContext();

  const tablesContent = useMemo(() => {
    return Object.values(reliabilityChecksCategoryOrder).map((key) => {
      const category = key as ReliabilityCheckCategory;
      const rowsData = reliabilityChecksData[category];

      return (
        <div key={key}>
          <TextContainer>
            <Typography variant={"h4"} color={"text.primary"}>
              {key}
            </Typography>
            <ActiveChecksStatus category={category} />
          </TextContainer>
          <ManageChecksTable rows={rowsData} />
        </div>
      );
    });
  }, [reliabilityChecksData]);

  return <Container>{tablesContent}</Container>;
};
