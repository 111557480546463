import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import useUserMetadata from "@/shared/hooks/useUserMetadata/useUserMetadata";
import { useGetUserById } from "@/shared/hooks/auth-service/client/users/useGetUserById";
import { useFormValidations } from "@/shared/context/ValidationsProvider";
import { useUpdateUser } from "@/shared/hooks/auth-service/client/users/useUpdateUser";
import { AnalyticEvents, dispatchEvent } from "@/shared/hooks/analytics";

const validateName = (name: string | undefined) =>
  !name ? "Please fill your name" : undefined;

export const useProfilePage = () => {
  const { userId } = useUserMetadata();

  const { data: user, refetch } = useGetUserById({ id: userId });
  const userRoles = user?.rbacRoles?.map((r) => ({
    label: r.name,
    value: r.id,
  }));

  const [name, setName] = useState<string>();
  const { errors, setError } = useFormValidations();

  useEffect(() => {
    if (name === undefined && user) {
      setName(user.displayName);
    }
  }, [name, user]);

  const { mutateAsync: updateUserInDB } = useUpdateUser();
  const { callback: debouncedUpdateUser } = useDebouncedCallback(
    async (id: string, displayName: string) => {
      await updateUserInDB({
        id,
        updateUserRequest: {
          displayName,
        },
      });
      dispatchEvent(AnalyticEvents.Settings.EditProfileName, {
        name: displayName,
      });
      refetch();
    },
    750
  );

  const handleNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setName(e.target.value);
      const error = validateName(e.target.value);
      setError("name", error);
      if (user && !error) {
        debouncedUpdateUser(user.id, e.target.value);
      }
    },
    [debouncedUpdateUser, setError, user]
  );
  return {
    errors,
    handleNameChange,
    name,
    user,
    userRoles,
  };
};
