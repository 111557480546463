import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import {
  AdvancedMultiSelect,
  IntegrationCard,
  MuiSelectionOption,
  SearchField,
} from "@komodorio/design-system/komodor-ui";
import React, { useMemo } from "react";

import { SettingsViewVerticalLayout } from "@/components/Settings/SettingsViewVerticalLayout";
import { useInstallations } from "@/pages/organization-settings/configurations/useInstallations";
import { CenteredLoader } from "@/shared/components/CenteredLoader";
import { Install } from "@/components/integrations/management/available/Install";
import { Integration } from "@/components/integrations/management/definitions";

const INSTALLATION_STATE_OPTIONS = [
  { label: "Installed Integration", value: 1 },
  { label: "Uninstalled Integration", value: 0 },
];

export const IntegrationsPage: React.FC = () => {
  const [installationState, setInstallationState] = React.useState<
    MuiSelectionOption<number>[]
  >([]);
  const {
    getInstallationByType,
    isFetching,
    filterIntegrations,
    searchTerm,
    availableIntegrations,
    installedIntegrations,
    sortByInstallationStatus,
  } = useInstallations();

  const content = useMemo(() => {
    return availableIntegrations
      .filter(
        ([integrationType]: [
          integrationType: string,
          integration: Integration
        ]) => {
          // This select has only 2 options.  having them both checked or unchecked is the same.
          if (
            !installationState?.length ||
            installationState.length === INSTALLATION_STATE_OPTIONS.length
          ) {
            return true;
          }

          if (installationState[0].value) {
            return installedIntegrations.has(integrationType);
          }

          return !installedIntegrations.has(integrationType);
        }
      )
      .sort(sortByInstallationStatus)
      .map(([integrationType, integration]) => {
        const isInstalled = installedIntegrations.has(integrationType);

        const CTA = (
          <Install
            integration={integration}
            isInstalled={isInstalled}
            newTab={true}
          />
        );

        return (
          <Grid item key={integration.title} sx={{ flexShrink: 0 }}>
            <IntegrationCard
              Logo={integration.logo}
              {...integration}
              logoSize={50}
              isInstalled={isInstalled}
              installationDetails={getInstallationByType(integrationType)}
              InstallCTA={CTA}
            />
          </Grid>
        );
      });
  }, [
    sortByInstallationStatus,
    installationState,
    availableIntegrations,
    getInstallationByType,
    installedIntegrations,
  ]);

  if (isFetching) {
    return (
      <SettingsViewVerticalLayout title="Integrations">
        <CenteredLoader />
      </SettingsViewVerticalLayout>
    );
  }

  return (
    <SettingsViewVerticalLayout title="Integrations">
      <Stack direction="column" rowGap={2}>
        <Stack direction="row" columnGap={1}>
          <SearchField
            value={searchTerm}
            onSearch={filterIntegrations}
            placeholder={"Search"}
            showCloseIcon={true}
            ariaLabel={"Search integrations"}
            label={"Search Integrations"}
          />
          <AdvancedMultiSelect
            options={INSTALLATION_STATE_OPTIONS}
            value={installationState}
            placeholder="Select installation state"
            width={"250px"}
            onChange={setInstallationState}
            ariaLabel={"Select installation state"}
            enableSearch={false}
            enableSelectAll={false}
            label={"Installation State"}
          />
        </Stack>
        <Grid container columns={4} columnGap={2} rowGap={2}>
          {content}
        </Grid>
      </Stack>
    </SettingsViewVerticalLayout>
  );
};
