import React from "react";

import {
  ContainerSectionFull,
  PodsEvents,
} from "../../../monitorsView/common/availabilityComponents";
import { PodOutputWithLogs } from "../../../monitorsView/workflowRunView/CommonTypes";
import { useInvestigationModeStore } from "../../../../shared/store/investigationModeStore/investigationModeStore";
import { issueSelector } from "../../../../shared/store/investigationModeStore/investigationModeSelectors";
import { AriaLabels } from "../../../../shared/config/ariaLabels";

const PodInformation: React.FC<{
  selectedPod: PodOutputWithLogs;
  startTime: string;
}> = ({ selectedPod, startTime }) => {
  const issue = useInvestigationModeStore(issueSelector);
  if (!issue) {
    return null;
  }
  return (
    <>
      <div
        aria-label={AriaLabels.InvestigationMode.UnhealthyPodsStep.PodEvents}
      >
        <PodsEvents pod={selectedPod} />
      </div>
      <div>
        <ContainerSectionFull
          containers={selectedPod.containers}
          podName={selectedPod.name}
          clusterName={issue.cluster ?? ""}
          namespace={issue.namespace ?? ""}
          eventTime={new Date(startTime)}
          ariaLabel={
            AriaLabels.InvestigationMode.UnhealthyPodsStep.ContainerRow
          }
        />
      </div>
    </>
  );
};

export default PodInformation;
