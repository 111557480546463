import { useEffect } from "react";

type Props = {
  currentIsPaginating: boolean;
  fetchedIsPaginating: boolean | undefined;
  setIsPaginating: (isPaginating: boolean) => void;
};

export const useSetIsPaginating = ({
  currentIsPaginating,
  fetchedIsPaginating,
  setIsPaginating,
}: Props) => {
  useEffect(() => {
    if (fetchedIsPaginating === false && currentIsPaginating) {
      setIsPaginating(false);
    }
  }, [currentIsPaginating, fetchedIsPaginating, setIsPaginating]);
};
