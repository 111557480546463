import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";

import { matchAgeWithUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellUtils";
import { DateUnits } from "@/components/k8sAddons/components/table/AgeCell/ageCellTypes";

const allDateStrUnits: DateUnits[] = ["d", "h", "m"];

type AgeCellProps = {
  age: string;
  includeDateUnits?: DateUnits[];
};

export const AgeCell: React.FC<AgeCellProps> = ({
  age,
  includeDateUnits = allDateStrUnits,
}) => {
  const valueToUse = useMemo(() => {
    if (includeDateUnits.length === 0) {
      return age;
    }

    return matchAgeWithUnits(includeDateUnits, age);
  }, [age, includeDateUnits]);

  return <Typography variant={"body2"}>{valueToUse}</Typography>;
};
