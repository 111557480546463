import React, { JSX } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { CardContent } from "@mui/material";
import Chip from "@mui/material/Chip";
import { muiColors, muiTheme } from "../../../themes";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { MenuBook } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { palette } from "../../../themes/palette";

const getPrettyDate = (date: Date) => {
  return format(date, "MMM d, p");
};

export type LogoProps = React.SVGProps<SVGSVGElement>;

interface InstallationDetailsProps {
  installedBy: string;
  installedAt: Date;
  documentationLink?: string;
}

const InstallationDetails = ({
  installedBy,
  installedAt,
  documentationLink,
}: InstallationDetailsProps) => (
  <Stack spacing={2}>
    <Typography variant="body3" color={muiTheme.palette.text.secondary}>
      Installed on {getPrettyDate(installedAt)}
    </Typography>
    <Grid container columns={2}>
      <Grid item xs={1}>
        <Typography variant="overline2" color={muiColors.gray[600]}>
          installed by
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="overline2" color={muiColors.gray[600]}>
          documentation
        </Typography>
      </Grid>
      <Grid item xs={1} alignContent="center">
        <Typography variant="body3">{installedBy}</Typography>
      </Grid>
      <Grid item xs={1}>
        {documentationLink && (
          <IconButton
            color="primary"
            size="small"
            href={documentationLink}
            target="_blank"
          >
            <MenuBook />
          </IconButton>
        )}
      </Grid>
    </Grid>
  </Stack>
);

interface IntegrationCardProps {
  Logo: React.FC<LogoProps>;
  logoSize: number;
  title: string;
  description: string;
  onInstall?: () => void;
  isInstalled?: boolean;
  installationDetails?: InstallationDetailsProps;
  buttonText?: string;
  InstallCTA?: JSX.Element;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  Logo,
  logoSize,
  title,
  description,
  isInstalled,
  installationDetails,
  InstallCTA,
  buttonText = "Install Integration",
  onInstall,
}) => {
  const InstallButton = () => {
    if (InstallCTA) {
      return InstallCTA;
    }
    return (
      <Button
        variant="outlined"
        sx={{ width: "fit-content" }}
        onClick={onInstall}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Card sx={{ width: 448, minHeight: 150 }}>
      <CardContent>
        <Stack direction="row" spacing={2} width="100%">
          <Logo width={logoSize} height={logoSize} style={{ flexShrink: 0 }} />
          <Stack spacing={1} useFlexGap sx={{ flexWrap: "wrap" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3">{title}</Typography>
              {isInstalled && (
                <Chip
                  label="Installed"
                  size="small"
                  color="success"
                  sx={{
                    height: "16px",
                    lineHeight: "16px",
                    fontSize: "12px",
                    padding: "0 4px",
                    backgroundColor: palette.success.light,
                    border: `1px solid ${palette.success.main}`,
                  }}
                />
              )}
            </Stack>
            {isInstalled ? (
              <InstallationDetails {...installationDetails} />
            ) : (
              <Stack
                direction="column"
                minHeight={90}
                justifyContent={"space-between"}
              >
                <Typography variant="body2">{description}</Typography>
                <InstallButton />
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
