import React from "react";
import { GridColDef } from "@mui/x-data-grid-pro";

import { Severity, ViolationDetails } from "../types";
import { SeverityChip } from "../../../../../shared/SeverityChip";
import { ActionsSelect } from "../../../../../shared/ActionsSelect";

import { RiskDetailsTableViolation } from "./RiskDetailsTableViolation";

export const useDetailsExpandedTableColumns = (severity: Severity) => {
  const columns: GridColDef<ViolationDetails>[] = [
    {
      field: "Severity",
      headerName: "Severity",
      minWidth: 10,
      renderCell: () => {
        return <SeverityChip severity={severity} />;
      },
    },
    {
      field: "Violation",
      headerName: "Violation",
      flex: 1,
      renderCell: (params) => {
        return <RiskDetailsTableViolation details={params.row} />;
      },
    },
    {
      field: "Status",
      headerName: "Status",
      maxWidth: 140,
      minWidth: 140,
      renderCell: () => {
        return <ActionsSelect />;
      },
    },
  ];

  return columns;
};
