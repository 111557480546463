import { createTheme } from "@mui/material/styles";

import { components } from "./components";
import { palette } from "./palette";
import { shadows } from "./shadows";
import { typography } from "./typography";

export const muiTheme = createTheme({
  components,
  palette,
  shadows,
  typography,
});
