import React from "react";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { ViolationsBreakdownSeverityChart } from "../../../../../../assets/ViolationsBreakdownSeverityChart";
import {
  getViolationsBreakdown,
  getViolationsByTypeBreakdown,
} from "../../risksListUtils";
import { ViolationsBreakdownByTypeChart } from "../../../../../../assets/ViolationsBreakdownByTypeChart";
import { StyledPaper } from "../styles";

import { ViolationsBreakdownGraph } from "./ViolationsBreakdownGraph";
import {
  severityToLegendColorScheme,
  violationTypeToColorScheme,
} from "./constants";

const Container = styled(StyledPaper)`
  align-items: center;
  & > :first-child {
    margin-right: auto;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 100px;
`;

export const ViolationsBreakdown: React.FC = () => {
  return (
    <Container>
      <Typography variant={"h4"}>Violations breakdown</Typography>
      <FlexContainer>
        <ViolationsBreakdownGraph
          title={"Severity"}
          data={getViolationsBreakdown()}
          graphComponent={<ViolationsBreakdownSeverityChart />}
          colorChart={severityToLegendColorScheme}
        />
        <ViolationsBreakdownGraph
          title={"Type"}
          data={getViolationsByTypeBreakdown()}
          graphComponent={<ViolationsBreakdownByTypeChart />}
          colorChart={violationTypeToColorScheme}
        />
      </FlexContainer>
    </Container>
  );
};
