/* eslint-disable no-restricted-globals */

export const prefix = (() => {
  // Cannot use the dynamic appConfig here because it is not loaded yet on root level.
  const host = window.location.host;
  if (host.startsWith("local.app")) return "@komodor-dev/";
  if (host.startsWith("lab-")) return "@komodor-dev/";
  if (host.startsWith("staging.app")) return "@komodor-staging/";
  if (host.startsWith("app")) return "@komodor/";
  return "@komodor/";
})();

export class LocalStorageItem {
  readonly key: string;
  constructor(key: string, customPrefix?: string) {
    this.key = `${customPrefix ?? prefix}${key}`;
  }
  get(): string | null {
    return localStorage.getItem(this.key);
  }
  set(value: string): void {
    localStorage.setItem(this.key, value);
  }
  remove(): void {
    localStorage.removeItem(this.key);
  }
}

export const cleanupKomodorLocalStorage = (): void => {
  Object.keys(localStorage)
    .filter((key) => key.startsWith(prefix))
    .forEach((key) => localStorage.removeItem(key));
};
