import React from "react";

import { LogoIconProps } from "../types";

export const Sentry: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Sentry logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M19.7007 16.7405C20.0622 17.3683 20.0972 18.0285 19.7968 18.5503C19.4968 19.0726 18.9098 19.3717 18.1864 19.3717H16.8229C16.8391 19.1175 16.8498 18.8611 16.8498 18.6029C16.8498 18.3136 16.8364 18.028 16.8167 17.7433L17.7135 17.7424C17.934 17.7424 18.1122 17.5632 18.1122 17.3422C18.1122 17.2756 18.0957 17.2101 18.0642 17.1514L12.3482 7.21622C12.3137 7.15475 12.2634 7.10359 12.2025 7.068C12.1417 7.03242 12.0725 7.01368 12.002 7.01373C11.9332 7.01375 11.8657 7.03154 11.8059 7.06538C11.7461 7.09922 11.6961 7.14795 11.6607 7.20685L10.7531 8.78447C13.5113 10.8638 15.3656 14.0851 15.6194 17.7424C15.6391 18.0266 15.6526 18.3123 15.6526 18.6019C15.6526 18.8602 15.6418 19.1162 15.6261 19.3708H11.1734C11.1976 19.1175 11.2115 18.8615 11.2115 18.6019C11.2115 18.3114 11.1945 18.0249 11.163 17.7424C10.9431 15.7296 9.95772 13.9458 8.50671 12.687L7.88154 13.7729C8.97775 14.7932 9.72598 16.1827 9.92941 17.7424C9.96628 18.024 9.9869 18.3105 9.9869 18.6019C9.9869 18.8615 9.96984 19.1184 9.94065 19.3708H5.81306C5.08958 19.3708 4.5026 19.0712 4.20261 18.5489C3.90219 18.0271 3.93719 17.3674 4.29874 16.7391L5.1462 15.2868C5.63033 15.5472 6.06783 15.8862 6.44085 16.29L5.93568 17.1505C5.90429 17.2092 5.88776 17.2748 5.88755 17.3414C5.88745 17.3938 5.8977 17.4458 5.9177 17.4942C5.9377 17.5427 5.96706 17.5868 6.00411 17.6239C6.04115 17.661 6.08516 17.6905 6.1336 17.7106C6.18204 17.7307 6.23397 17.741 6.28641 17.741L8.2884 17.742C8.12047 16.7823 7.67768 15.9164 7.04357 15.2306C6.6699 14.8278 6.23341 14.4869 5.7475 14.2261L7.98304 10.3418C8.4577 10.6072 8.9086 10.913 9.33074 11.2558C11.2574 12.819 12.5584 15.1278 12.7941 17.7415H13.9923C13.748 14.6859 12.2126 11.9914 9.93391 10.2076C9.50602 9.87246 9.05323 9.57041 8.57945 9.30407L10.4876 5.9889C10.8487 5.36061 11.401 5 12.002 5C12.6024 5 13.1543 5.36061 13.5158 5.9889L19.7007 16.7405Z"
        fill="#FB2D26"
      />
    </svg>
  );
};
