import React, {
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
} from "react";
import styled from "styled-components";

import { theme } from "../../../theme";

import { InputContainer, InputContainerProps } from "./InputContainer";

/** @deprecated */
export type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, "size"> &
  Omit<InputContainerProps, "onChange"> & { ref?: HTMLInputElement };

const StyledInput = styled.input`
  /* disable input default style*/
  border: none;
  padding: unset;
  color: unset;
  font-family: unset;
  font-style: unset;
  font-weight: unset;
  font-size: unset;
  line-height: unset;

  background-color: ${theme.background.bgWhite};
  grid-area: input;
  width: 100%;
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${theme.background.bgGray};
    cursor: not-allowed;
  }
  :read-only:enabled {
    cursor: pointer;
  }
  ::placeholder {
    opacity: 0.5;
  }
`;

/**
 * @deprecated Use MUI TextField instead.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ size, ...rest }, ref?: MutableRefObject<HTMLInputElement>) => {
    return (
      <InputContainer size={size} {...rest}>
        <StyledInput {...rest} ref={ref} />
      </InputContainer>
    );
  }
);

Input.defaultProps = {
  type: "text",
  size: "small",
  width: "100%",
  disabled: false,
  readOnly: false,
  noBorder: false,
};

Input.displayName = "Input";
