import React from "react";

import { IconProps } from "../types";

export const AlertTriangleOutlined24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3793 19.7412L12.4538 3.77305C12.257 3.42491 11.743 3.42491 11.5462 3.77304L2.62068 19.7412C2.43086 20.0771 2.68056 20.4881 3.07445 20.4881H20.9255C21.3194 20.4881 21.5691 20.0771 21.3793 19.7412ZM13.8151 3.04441C13.0281 1.65186 10.9719 1.65186 10.1849 3.04441L1.25937 19.0126C0.500063 20.3561 1.49886 22 3.07445 22H20.9255C22.5011 22 23.4999 20.3561 22.7406 19.0126L13.8151 3.04441Z"
      />
      <path d="M11.2232 8.64456C11.2232 8.22705 11.571 7.88859 12 7.88859C12.429 7.88859 12.7768 8.22705 12.7768 8.64456V14.1804C12.7768 14.5979 12.429 14.9364 12 14.9364C11.571 14.9364 11.2232 14.5979 11.2232 14.1804V8.64456Z" />
      <path d="M11.2232 17.244C11.2232 16.8265 11.571 16.4881 12 16.4881C12.429 16.4881 12.7768 16.8265 12.7768 17.244C12.7768 17.6615 12.429 18 12 18C11.571 18 11.2232 17.6615 11.2232 17.244Z" />
    </svg>
  );
};

AlertTriangleOutlined24.defaultProps = {
  fill: "currentColor",
};
