import styled, { css } from "styled-components";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

export const PagePadding = css`
  padding: 0 24px;
`;

export const PageContainer = styled.div`
  display: flex;
  ${PagePadding};
  gap: 20px;
  & > div:first-child {
    width: 215px;
    min-width: 215px;
  }
  & > div:last-child {
    flex-grow: 1;
    padding-left: 10px;
  }
`;

export const AnimatedKeyboardArrowDown = styled(KeyboardArrowDown)<{
  open: boolean;
}>`
  && {
    cursor: pointer;
    transition: transform 0.2s;
    transform: rotate(${({ open }) => (open ? "0deg" : "-90deg")});
  }
`;
