import React from "react";

import { LogoIconProps } from "../types";

export const K3s: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="K3s logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.45898 2.07031C2.07812 2.07031 0.958984 3.18945 0.958984 4.57031V17.5703C0.958984 18.9512 2.07812 20.0703 3.45898 20.0703H20.459C21.8398 20.0703 22.959 18.9512 22.959 17.5703V4.57031C22.959 3.18945 21.8398 2.07031 20.459 2.07031H3.45898ZM11.583 4.44141C11.4443 4.48438 11.3174 4.55078 11.208 4.64062C11.0713 4.75 10.9619 4.89258 10.8906 5.05664C10.833 5.19141 10.8008 5.33789 10.8008 5.49219V10.209C10.8008 10.8125 11.291 11.3027 11.8955 11.3027C12.5 11.3027 12.9902 10.8125 12.9902 10.207V5.49023C12.9902 4.88672 12.5 4.39648 11.8955 4.39648C11.7871 4.39648 11.6826 4.41211 11.583 4.44141ZM13.2373 13.6387C12.9375 14.1641 13.1201 14.832 13.6455 15.1328L17.875 17.5469C18.4004 17.8457 19.0693 17.6621 19.3691 17.1387C19.668 16.6133 19.4854 15.9434 18.96 15.6445L14.7305 13.2305C14.2051 12.9316 13.5361 13.1133 13.2373 13.6387ZM10.1797 15.1328C10.7051 14.832 10.8877 14.1641 10.5879 13.6387C10.4414 13.3809 10.2061 13.2051 9.94238 13.1289C9.66797 13.0508 9.3623 13.0781 9.09473 13.2305L4.86523 15.6445C4.33984 15.9434 4.15723 16.6133 4.45605 17.1387C4.75586 17.6621 5.4248 17.8457 5.9502 17.5469L10.1797 15.1328Z"
        fill="#FFC61C"
      />
    </svg>
  );
};
