import { UserMetadata } from "../hooks/useUserMetadata/useUserMetadata";

export const formatUserMetadataForSegment = (
  userMetadata: UserMetadata
): UserMetadata => {
  if (userMetadata?.trialEndAt === "null") {
    return { ...userMetadata, trialEndAt: "" };
  }
  return userMetadata;
};
