import React, { useMemo, useState } from "react";
import { theme } from "@komodorio/design-system";
import { IconButton, Tag } from "@komodorio/design-system/deprecated";
import {
  Reload16,
  Pencil16,
  Plus16,
  Trash16,
} from "@komodorio/design-system/icons";
import { sortBy } from "lodash";
import { SearchField } from "@komodorio/design-system/komodor-ui";
import Divider from "@mui/material/Divider";

import { useHasPermissions } from "../../../shared/hooks/useUserMetadata/rbac";
import useUserMetadata from "../../../shared/hooks/useUserMetadata/useUserMetadata";
import { ValidationsProvider } from "../../../shared/context/ValidationsProvider";
import DataTable, { Column } from "../../common/table/DataTable";
import { ACCOUNT_ADMIN_RBAC_ROLE } from "../Roles/Roles";
import { ActionsCol, Buttons, Flex, Header } from "../styles";
import { ButtonSupportFreemium } from "../../Freemium/Buttons";
import { restrictionsNames } from "../../Freemium/Restrictions";
import { useGetUsers } from "../../../shared/hooks/auth-service/client/users/useGetUsers";
import { User } from "../../../generated/auth";
import { useOverridableFlags } from "../../../shared/context/featureFlags/OverridableFlags";

import BulkUsersFormModal from "./BulkUsersFormModal";
import DeleteUserModal from "./DeleteUserModal";
import UserFormModal from "./UserFormModal";
import { getFilteredUserRoles, getTimeLeft } from "./utils";

import { RestoreUserModal } from "@/components/Settings/Users/RestoreUserModal";
import { SettingsViewLayoutWrapper } from "@/components/Settings/SettingsViewLayoutWrapper";

const getStatus = (u: User) => {
  return u.deletedAt ? "" : u.lastLoginAt ? "Active" : "Awaiting first login";
};

const createFilteredRoleGetter =
  (filterTemporaryRoles: boolean) => (user: User) => {
    const filteredRoles = getFilteredUserRoles(user, filterTemporaryRoles);

    return (
      <Flex>
        {filteredRoles?.map((role) => (
          <Tag
            key={role.name}
            color={
              filterTemporaryRoles
                ? theme.background.bgYellowLight
                : theme.background.bgGray
            }
          >
            {filterTemporaryRoles
              ? `${role.name} (expires in ${getTimeLeft(role.expiration)})`
              : role.name}{" "}
          </Tag>
        ))}
      </Flex>
    );
  };

const Users: React.FC = () => {
  const [search, setSearch] = useState("");
  const { data: users, refetch } = useGetUsers();
  const isOnlyOneAccountAdmin =
    users &&
    users.filter((u) =>
      u.rbacRoles?.some((role) => role.name === ACCOUNT_ADMIN_RBAC_ROLE)
    ).length === 1;

  const { showTemporaryRoles: showTemporaryRolesFF } = useOverridableFlags();

  const [openAddUser, setOpenAddUser] = useState(false);
  const [openBulkAddUsers, setOpenBulkAddUsers] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User>();
  const [userToDelete, setUserToDelete] = useState<User>();
  const [userToRestore, setUserToRestore] = useState<User>();

  const { canManageUsers } = useHasPermissions();
  const { userId } = useUserMetadata();
  const getTemporaryRoles = useMemo(() => createFilteredRoleGetter(true), []);
  const getPermanentRoles = useMemo(() => createFilteredRoleGetter(false), []);
  const columns: Column<User>[] = [
    { name: "Name", selector: (u) => u.displayName },
    { name: "Email", selector: (u) => u.email },
    { name: "Roles", selector: getPermanentRoles },
    { name: "Status", selector: getStatus },
    {
      name: "",
      selector: (u) =>
        canManageUsers && !u.deletedAt ? (
          <ActionsCol>
            <IconButton
              icon={Trash16}
              variant="danger"
              noBorder
              onClick={() => setUserToDelete(u)}
              disabled={u.id === userId}
            />
            <IconButton
              icon={Pencil16}
              noBorder
              onClick={() => setUserToEdit(u)}
            />
          </ActionsCol>
        ) : canManageUsers && u.deletedAt ? (
          <ActionsCol>
            <IconButton
              icon={Reload16}
              noBorder
              onClick={() => setUserToRestore(u)}
              disabled={u.id === userId}
            />
          </ActionsCol>
        ) : null,
    },
  ];
  if (showTemporaryRolesFF) {
    columns.splice(3, 0, {
      name: "Temporary Roles",
      selector: getTemporaryRoles,
    });
  }

  const filteredUsers = useMemo(() => {
    const lowerCaseFilter = search.toLowerCase();
    return users
      ? sortBy(
          users.filter((u) => {
            return [u.displayName, u.email].some((s) => {
              return s.toLowerCase().includes(lowerCaseFilter);
            });
          }),
          [(u) => u.deletedAt !== null, (u) => u.displayName]
        )
      : [];
  }, [users, search]);

  return (
    <SettingsViewLayoutWrapper title={"Users"}>
      <Header>
        <SearchField
          size="medium"
          width="15rem"
          placeholder="Search"
          value={search}
          showCloseIcon={true}
          ariaLabel="Search users"
          onSearch={(value) => setSearch(value)}
        />
        {canManageUsers && (
          <Buttons>
            <ButtonSupportFreemium
              variant="primary"
              icon={Plus16}
              onClick={() => setOpenBulkAddUsers(true)}
              freemiumKey={restrictionsNames.USERS}
            >
              Bulk add
            </ButtonSupportFreemium>
            <ButtonSupportFreemium
              variant="primary"
              icon={Plus16}
              onClick={() => setOpenAddUser(true)}
              freemiumKey={restrictionsNames.USERS}
            >
              Add user
            </ButtonSupportFreemium>
          </Buttons>
        )}
      </Header>
      <Divider orientation="horizontal" sx={{ margin: "1rem 0" }} />
      <DataTable
        data={filteredUsers.map((user) => {
          return { disabled: !!user.deletedAt, ...user };
        })}
        columns={columns}
        getRowId={(u) => u.id}
        highlightedRows
      />
      {openBulkAddUsers && (
        <ValidationsProvider>
          <BulkUsersFormModal
            open
            handleClose={() => setOpenBulkAddUsers(false)}
            refreshUsers={refetch}
          />
        </ValidationsProvider>
      )}
      {openAddUser && (
        <ValidationsProvider>
          <UserFormModal
            open
            handleClose={() => setOpenAddUser(false)}
            refreshUsers={refetch}
          />
        </ValidationsProvider>
      )}
      {!!userToEdit && (
        <ValidationsProvider>
          <UserFormModal
            open
            handleClose={() => setUserToEdit(undefined)}
            user={userToEdit}
            refreshUsers={refetch}
            isOnlyOneAccountAdmin={isOnlyOneAccountAdmin}
          />
        </ValidationsProvider>
      )}
      {!!userToRestore && (
        <RestoreUserModal
          open
          handleClose={() => setUserToRestore(undefined)}
          user={userToRestore}
          refreshUsers={refetch}
        />
      )}
      {userToDelete && (
        <DeleteUserModal
          open
          handleClose={() => setUserToDelete(undefined)}
          user={userToDelete}
          refreshUsers={refetch}
        />
      )}
    </SettingsViewLayoutWrapper>
  );
};

export default Users;
