import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { muiColors } from "@komodorio/design-system";

import { FeatureTag, TagText } from "../../shared/components/FeatureTag";

const Root = styled.section`
  margin: 43px 55px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
`;

const Content = styled.div`
  display: flex;
  gap: 54px;
`;

const CheckList = styled.div`
  border: 2px solid ${muiColors.gray[300]};
  border-radius: 6px;
  padding-inline: 24px;
  padding-block: 22px;

  & ul {
    margin: 0;
    padding-left: 0px;
    list-style-type: none;
  }
  & li {
    margin-top: 16px;
  }
`;

type FeaturePreviewPageProps = {
  title: string;
  children: React.ReactNode;
  bullets: string[];
  note?: string;
  img: React.ReactNode;
  tag: TagText;
};

export const FeaturePreviewPage = ({
  title,
  children,
  bullets,
  note,
  img,
  tag,
}: FeaturePreviewPageProps): JSX.Element => {
  return (
    <Root>
      <Header>
        <Typography variant="h3" color="text.primary">
          {title}
        </Typography>
        <FeatureTag tag={tag} />
      </Header>
      <Content>
        <Box
          sx={{
            display: "flex",
            flexBasis: "434px",
            flexGrow: "0",
            gap: "32px",
            flexDirection: "column",
          }}
        >
          {typeof children === "string" ? (
            <Typography variant="body1" color="text.secondary">
              {children}
            </Typography>
          ) : (
            children
          )}
          <CheckList>
            <Typography variant="h4">Included</Typography>
            <ul>
              {bullets.map((item) => (
                <li key={item}>
                  <Box
                    sx={{ display: "flex", gap: "7px", alignItems: "center" }}
                  >
                    <CheckCircle fontSize="small" color="primary" />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color={muiColors.gray[800]}
                    >
                      {item}
                    </Typography>
                  </Box>
                </li>
              ))}
            </ul>
          </CheckList>
          {note ? (
            <Typography
              variant="body2"
              color="text.secondary"
              fontWeight={500}
              fontStyle="italic"
            >
              {note}
            </Typography>
          ) : null}
        </Box>
        <Box sx={{ flexGrow: "2", maxWidth: "1170px" }}>{img}</Box>
      </Content>
    </Root>
  );
};
