import React from "react";
import Drawer from "@mui/material/Drawer";

import notHighResiliencyDrawer from "../assets/notHighResiliencyDrawer.png";
import { CloseButtonArea } from "../styles";

type NotHighResiliencyDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const NotHighResiliencyDrawer: React.FC<
  NotHighResiliencyDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img
        src={notHighResiliencyDrawer}
        alt={"not high resiliency drawer"}
        width={864}
      />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};
