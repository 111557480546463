import React, { useState } from "react";
import styled from "styled-components";

import { SelectableClickableArea } from "../../reliabilityDemoStyles";

import { RowDetailsDrawer } from "./RowDetailsDrawer";
import { EndOfLifeDrawer } from "./EndOfLifeDrawer";

const EndOfLifeClickableArea = styled(SelectableClickableArea)<{
  selected: boolean;
}>`
  width: 29.3%;
  height: 18.8%;
  top: 46.9%;
  left: 35.3%;
`;

export const EndOfLifeDrawerContainer: React.FC = () => {
  const [endOfLifeIsOpen, setEndOfLifeIsOpen] = useState(false);
  const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
  return (
    <>
      <EndOfLifeClickableArea
        onClick={() => setEndOfLifeIsOpen(true)}
        selected={endOfLifeIsOpen}
      />
      <EndOfLifeDrawer
        open={endOfLifeIsOpen}
        setOpen={setEndOfLifeIsOpen}
        onRowClick={() => setDetailsDrawerOpen(true)}
      />
      <RowDetailsDrawer
        open={detailsDrawerOpen}
        setOpen={setDetailsDrawerOpen}
      />
    </>
  );
};
