import React, { useEffect } from "react";

import {
  detailsFieldsTexts,
  INPUT_FIELD_SMALL_WIDTH,
} from "../policyDrawerConstants";
import { useTriggerAllFields } from "../hooks/useTriggerAllFields";
import { useRegisterDetailFields } from "../hooks/useRegisterDetailFields";

import { InputField } from "./InputField";
import { SelectField } from "./SelectField";

import { useGetAllClusterNamesAsOptions } from "@/components/reliability/components/pages/policies/PolicyDrawer/hooks/useGetAllClusterNamesAsOptions";
import { usePolicyDrawerContext } from "@/components/reliability/components/pages/policies/PolicyDrawer/context/usePolicyDrawerContext";

export const PolicyDrawerDetails: React.FC = () => {
  const onDetailsBlur = useTriggerAllFields();
  useRegisterDetailFields();
  const clusterOptions = useGetAllClusterNamesAsOptions();
  const formRef = React.useRef<HTMLFormElement>(null);
  const { setFormDetailsRef } = usePolicyDrawerContext();

  useEffect(() => {
    if (formRef.current) {
      setFormDetailsRef(formRef);
    }
  }, [setFormDetailsRef]);

  return (
    <form onBlurCapture={onDetailsBlur} ref={formRef}>
      <InputField
        inputType={"name"}
        label={"Policy name"}
        maxWidth={INPUT_FIELD_SMALL_WIDTH}
        placeholder={"Enter name"}
      />
      <InputField
        inputType={"priority"}
        label={"Priority"}
        maxWidth={INPUT_FIELD_SMALL_WIDTH}
        type={"number"}
        tooltipContent={detailsFieldsTexts.priority.tooltipContent}
      />
      <InputField
        inputType={"description"}
        label={"Description"}
        placeholder={"(Optional)"}
      />
      <SelectField
        inputType={"scope.clusterRegexes"}
        label={"Policy scope"}
        options={clusterOptions}
        helperText={detailsFieldsTexts.policyScope.helperText}
        placeHolder={detailsFieldsTexts.policyScope.placeHolder}
      />
    </form>
  );
};
