import React from "react";

import { IconProps } from "../types";

export const Check24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7803 4.96967C21.0732 5.26256 21.0732 5.73744 20.7803 6.03033L9.98744 16.8232C9.30402 17.5066 8.19598 17.5066 7.51256 16.8232L3.21967 12.5303C2.92678 12.2374 2.92678 11.7626 3.21967 11.4697C3.51256 11.1768 3.98744 11.1768 4.28033 11.4697L8.57322 15.7626C8.67085 15.8602 8.82914 15.8602 8.92678 15.7626L19.7197 4.96967C20.0126 4.67678 20.4874 4.67678 20.7803 4.96967Z"
      />
    </svg>
  );
};

Check24.defaultProps = {
  fill: "currentColor",
};
