import { ResourceTableModelRow } from "komodor-types";
import { useMemo } from "react";
import { compact } from "lodash";

import useAtmResourcesListLiveResult, {
  useLiveDataWithUrlParam,
} from "../../../../shared/hooks/ATM/useAtmResourcesListLiveResult";
import { useParseResultToInspectionList } from "../../../../shared/hooks/ATM/useParseInspectionResult";
import { useAgentInfoById } from "../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { useLabelSelector } from "../../../../shared/utils/podUtils";
import { KubernetesPodsResource } from "../../../Inspection/inspectionConfiguration/SupportedResourcesTypes";
import useAppendMetricsToResourceList from "../../../Inspection/metrics/appendMetricsToResourceList";
import useInspectionMetrics from "../../../Inspection/metrics/useInspectionMetrics";
import type Node from "../../resources/node";
import { KomodorServiceResourceKind } from "../../../../shared/types/Resource";
import useUserMetadata from "../../../../shared/hooks/useUserMetadata/useUserMetadata";

import { fieldSelectorByResource } from "./utils";
import { useAppendDeletedPodsByOwnerResource } from "./useAppendDeletedPods";

import type WorkflowRun from "@/components/ResourceView/resources/workflowRun";

const accountNameToSortLabels = "smarsh";
const labelPatternRegEx = /^([^\s]+=[^\s]+)(,([^\s]+=[^\s]+))*$/;

export const usePodsAtmByResource = (
  resource: KomodorServiceResourceKind | Node | WorkflowRun,
  agentId: string,
  disableDeletedPods = false
): {
  data: ResourceTableModelRow[];
  fetching: boolean;
  isPaginating: boolean;
  refresh: () => void;
  errorMessage: string;
} => {
  const { accountName } = useUserMetadata();

  const labelSelector = useLabelSelector(
    "selector" in resource ? resource.selector : undefined
  );

  const labelSelectorToUse = useMemo(() => {
    // temporary patch
    if (
      accountName === accountNameToSortLabels &&
      labelSelector.match(labelPatternRegEx)
    ) {
      return labelSelector.split(",").sort().join(",");
    }
    return labelSelector;
  }, [accountName, labelSelector]);

  const { isLiveActive } = useLiveDataWithUrlParam(agentId);

  const namespaces = useMemo(
    () => compact([resource.namespace]),
    [resource.namespace]
  );

  const fieldSelector = useMemo(() => {
    return fieldSelectorByResource(resource.kind, resource.name);
  }, [resource.kind, resource.name]);

  const { result, status, refresh, errorMessage } =
    useAtmResourcesListLiveResult({
      resourceKind: KubernetesPodsResource.Kind,
      agentId,
      cluster: resource.cluster,
      namespaces,
      labelSelector: labelSelectorToUse,
      isLive: isLiveActive,
      fieldSelector,
    });
  const parsedResourceList = useParseResultToInspectionList(
    status,
    errorMessage,
    result?.data,
    result?.isPaginating
  );

  const [resourceListWithDeletedPods, refreshWithDeletedPods] =
    useAppendDeletedPodsByOwnerResource(
      resource,
      parsedResourceList,
      refresh,
      disableDeletedPods
    );

  const { agentProperties: agentInfo } = useAgentInfoById(agentId);
  const {
    metricsResult,
    isFetching: isFetchingMetrics,
    errorBringingMetrics,
  } = useInspectionMetrics({
    kubernetesResource: KubernetesPodsResource,
    agentInfo,
    agentId,
  });
  const resourceListWithMetrics = useAppendMetricsToResourceList(
    resourceListWithDeletedPods,
    metricsResult,
    isFetchingMetrics,
    errorBringingMetrics,
    KubernetesPodsResource
  );

  return {
    data: resourceListWithMetrics.rows,
    fetching: resourceListWithMetrics.fetching,
    isPaginating: resourceListWithMetrics.isPaginating ?? true,
    refresh: refreshWithDeletedPods,
    errorMessage: resourceListWithMetrics.errorMessage,
  };
};
