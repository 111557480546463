import React from "react";

import { IconProps } from "../types";

export const CloseBold16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M13.4697 4.41966C13.7626 4.12676 13.7626 3.65189 13.4697 3.359L12.641 2.53033C12.3481 2.23744 11.8732 2.23744 11.5803 2.53033L8 6.11067L4.41966 2.53033C4.12676 2.23744 3.65189 2.23744 3.359 2.53033L2.53033 3.359C2.23744 3.65189 2.23744 4.12676 2.53033 4.41966L6.11067 8L2.53033 11.5803C2.23744 11.8732 2.23744 12.3481 2.53033 12.641L3.359 13.4697C3.65189 13.7626 4.12676 13.7626 4.41966 13.4697L8 9.88933L11.5803 13.4697C11.8732 13.7626 12.3481 13.7626 12.641 13.4697L13.4697 12.641C13.7626 12.3481 13.7626 11.8732 13.4697 11.5803L9.88933 8L13.4697 4.41966Z" />
    </svg>
  );
};

CloseBold16.defaultProps = {
  fill: "currentColor",
};
