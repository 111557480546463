import React from "react";

import { Addon, Entity } from "@/generated/addonsApi";
import { AddonContextProvider } from "@/components/k8sAddons/context/AddonContext";
import { PageContainer } from "@/components/k8sAddons/styles";
import { DagsTable } from "@/components/k8sAddons/addons/workflows/DagsListPage/DagsTable";
import { Filters } from "@/components/k8sAddons/addons/workflows/DagsListPage/Filters";
import ResponsiveLayout from "@/components/common/ResponsiveLayout";

export const DagsListPage: React.FC = () => {
  return (
    <AddonContextProvider
      addonType={Addon.Workflow}
      addonEntity={Entity.WorkflowDag}
    >
      <ResponsiveLayout>
        <br />
        <PageContainer>
          <Filters />
          <DagsTable />
        </PageContainer>
      </ResponsiveLayout>
    </AddonContextProvider>
  );
};
