"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MetadataKeys = void 0;
exports.MetadataKeys = {
    UserID: "komodor.user.id",
    UserRole: "komodor.user.role",
    AccountID: "komodor.account.id",
    AccountName: "komodor.account.name",
    ClusterName: "komodor.cluster.name",
    RequestID: "komodor.request_id",
};
