"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineTablesBatches = exports.EventDot = exports.tableName = exports.count = exports.eventType = void 0;
const runtypes_1 = require("runtypes");
const EventTypes_1 = require("../EventTypes");
exports.eventType = "type";
exports.count = "count";
exports.tableName = "tableName";
exports.EventDot = (0, runtypes_1.Record)({
    [exports.count]: runtypes_1.String,
    [exports.eventType]: runtypes_1.String.Or(runtypes_1.Null),
});
const EventDots = (0, runtypes_1.Array)(exports.EventDot);
const TimelineResponse = (0, runtypes_1.Dictionary)(EventDots, EventTypes_1.EventRunTypes);
exports.TimelineTablesBatches = (0, runtypes_1.Array)((0, runtypes_1.Record)({
    timeStart: runtypes_1.Number,
    timeEnd: runtypes_1.Number,
    data: TimelineResponse,
}));
