import React from "react";

import { IconProps } from "../types";

export const Deploy24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M12.005 22.7999C17.9274 22.7999 22.7999 17.9276 22.7999 12.0051C22.7999 6.07249 17.9274 1.20001 11.9948 1.20001C6.06225 1.20001 1.19995 6.07249 1.19995 12.0051C1.19995 17.9276 6.07243 22.7999 12.005 22.7999ZM17.6115 12.0051C17.6115 12.4128 17.4586 12.7492 17.1528 13.0346L13.4729 16.5412C13.2079 16.7858 12.9734 16.8878 12.637 16.8878C12.0254 16.8878 11.5973 16.4291 11.5973 15.7971C11.5973 15.481 11.7298 15.1753 11.9541 14.9918L13.1875 13.9011L14.217 13.1366L12.1477 13.2691H7.55048C6.85733 13.2691 6.36804 12.7186 6.36804 12.0051C6.36804 11.2814 6.86752 10.7309 7.55048 10.7309H12.1477L14.217 10.8634L13.1569 10.0989L11.9541 9.00821C11.7502 8.82474 11.5973 8.51893 11.5973 8.20292C11.5973 7.57094 12.0254 7.12243 12.637 7.12243C12.9734 7.12243 13.2079 7.21417 13.4729 7.45881L17.1528 10.9756C17.4688 11.261 17.6115 11.5872 17.6115 12.0051Z" />
    </svg>
  );
};

Deploy24.defaultProps = {
  fill: "currentColor",
};
