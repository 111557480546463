import React, { useEffect, useState } from "react";

type IntersectionObserverParams<T> = {
  ref: React.RefObject<T>;
  onShow?: () => void;
  onHide?: () => void;
  options?: IntersectionObserverInit;
};

/** to have this hook behave properly, memoize {options} and wrap {onShow, onHide} with useCallback**/
export const useIntersectionObserver = <T extends Element>({
  ref,
  options,
  onShow,
  onHide,
}: IntersectionObserverParams<T>): void => {
  useEffect(() => {
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      isIntersecting ? onShow?.() : onHide?.();
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onHide, onShow, options, ref]);
};

type IsElementInViewportParams<T> = {
  ref: React.RefObject<T>;
  options?: IntersectionObserverInit;
};

export const useIsElementInViewport = <T extends Element>({
  ref,
  options,
}: IsElementInViewportParams<T>) => {
  const [isInViewport, setIsInViewport] = useState(false);

  useIntersectionObserver({
    ref: ref,
    onShow: () => {
      !isInViewport && setIsInViewport(true);
    },
    onHide: () => {
      isInViewport && setIsInViewport(false);
    },
    options,
  });

  return isInViewport;
};
