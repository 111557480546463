import React from "react";
import { Typography } from "@komodorio/design-system/deprecated";
import { MultiTagInput } from "@komodorio/design-system/komodor-ui";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";
import { PolicyTags } from "komodor-types";

import { ErrorMessage, HorizontalContainer, PreservedHeight } from "./common";

const tooltip =
  "You can identify your policies with tags as `key:value` pairs. For example you could use `team:backend` and `env:production` to identify a policy that applies to the backend team in production.";

const PolicyTagsInputContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const policyTagsToArray = (tags: PolicyTags): string[] => {
  return Object.entries(tags).map(([key, value]) => `${key}:${value}`);
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const parsePolicyTags = (tags: string[] | string | null): PolicyTags => {
  // Input: ["key1:value1", "key2:value2", "keynovalue"]
  // Output: { key1: value1, key2: value2, keynovalue: undefined }
  if (!tags || tags.length === 0) {
    return {};
  }
  return Object.entries(tags)
    .map(([, tagString]) => {
      const [key, value] = tagString.split(":");
      return { [key]: value };
    })
    .reduce((result, value) => {
      return { ...result, ...value };
    });
};

// [CU-86bx58peb] fix fast refresh
// eslint-disable-next-line react-refresh/only-export-components
export const validatePolicyTags = (tags: string[] | string | null) => {
  const parsedTags = parsePolicyTags(tags);
  if (Object.keys(parsedTags).length === 0) {
    return undefined;
  }
  const invalidTags = Object.entries(parsedTags).filter(
    ([key, value]) => !key || !value
  );
  if (invalidTags.length > 0) {
    return "Both key and value are required for each tag";
  }
  return undefined;
};

interface PolicyTagsInputProps {
  onChange: (value: string | string[] | null) => void;
  errorMessage?: string;
  value?: string[];
}

export const PolicyTagsInput: React.FC<PolicyTagsInputProps> = ({
  onChange,
  errorMessage,
  value,
}) => {
  return (
    <PolicyTagsInputContainer>
      <HorizontalContainer title={tooltip}>
        <Typography variant="text" size="medium">
          Tags
        </Typography>
        <InfoOutlined fontSize={"small"} />
      </HorizontalContainer>
      <PreservedHeight>
        <MultiTagInput<string>
          freeSolo
          multiple
          onChange={(_, value) => onChange(value)}
          id="policy-tags-input"
          placeholder={"Insert any `key:value` tags here - Press Enter to add"}
          size={"small"}
          value={value}
          sx={{ borderWidth: "1px" }}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </PreservedHeight>
    </PolicyTagsInputContainer>
  );
};
