import React from "react";

import { IconProps } from "../types";

export const PodsAndLogs16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M1 1.75C1 1.33579 1.33579 1 1.75 1H14.25C14.6642 1 15 1.33579 15 1.75C15 2.16421 14.6642 2.5 14.25 2.5H1.75C1.33579 2.5 1 2.16421 1 1.75Z" />
      <path d="M1 5.75C1 5.33579 1.33579 5 1.75 5H9.25C9.66421 5 10 5.33579 10 5.75C10 6.16421 9.66421 6.5 9.25 6.5H1.75C1.33579 6.5 1 6.16421 1 5.75Z" />
      <path d="M1 9.75C1 9.33579 1.33579 9 1.75 9H5.25C5.66421 9 6 9.33579 6 9.75C6 10.1642 5.66421 10.5 5.25 10.5H1.75C1.33579 10.5 1 10.1642 1 9.75Z" />
      <path d="M1 13.75C1 13.3358 1.33579 13 1.75 13H14.25C14.6642 13 15 13.3358 15 13.75C15 14.1642 14.6642 14.5 14.25 14.5H1.75C1.33579 14.5 1 14.1642 1 13.75Z" />
    </svg>
  );
};

PodsAndLogs16.defaultProps = {
  fill: "currentColor",
};
