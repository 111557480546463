import { muiColors } from "@komodorio/design-system";

import { Dictionary } from "../../shared/types/Dictionary";

import { PageRoutes, ReliabilityCheckCategory } from "./riskManagementTypes";
import {
  RiskUrgency,
  Severity,
} from "./components/pages/Risks/RiskContent/RisksList/types";

export const riskManagementRoutes: Dictionary<PageRoutes> = {
  riskPage: "risks",
  manageChecks: "checks",
};

type ColorScheme = {
  backgroundColor?: string;
  color?: string;
  borderColor?: string;
};

export const RiskUrgencyToSeverity: Record<RiskUrgency, Severity> = {
  urgent: "critical",
  moderate: "medium",
  low: "low",
};

export const SeverityToColorScheme: Record<Severity, ColorScheme> = {
  critical: {
    backgroundColor: muiColors.pink[25],
    color: muiColors.pink[800],
    borderColor: muiColors.pink[300],
  },
  medium: {
    backgroundColor: muiColors.orange[25],
    color: muiColors.orange[700],
    borderColor: muiColors.orange[300],
  },
  low: {
    backgroundColor: muiColors.yellow[50],
    color: muiColors.yellow[800],
    borderColor: muiColors.yellow[500],
  },
};

export const reliabilityChecksCategoryOrder: ReliabilityCheckCategory[] = [
  "Standardization",
  "Infrastructure issues",
  "Resource optimization",
];

export const reliabilityCheckCategoryToLabel: Record<
  ReliabilityCheckCategory,
  string
> = {
  "Infrastructure issues": "Infrastructure",
  Standardization: "Standardization",
  "Resource optimization": "Optimization",
};

export const sectionGap = "16px";
