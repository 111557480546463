import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import OpenInNew from "@mui/icons-material/OpenInNew";

import { MetadataItem } from "../../../../../shared/MetadataItem";
import memoryGraphUrl from "../../../../../../../../../../assets/drawer/memorygraph.svg";
import memoryGraphBottomUrl from "../../../../../../../../../../assets/drawer/memorygraphbottom.svg";
import { sectionGap } from "../../../../../../../../../../constants";

import { contentStyles } from "./styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${contentStyles};
  gap: ${sectionGap};
`;

const NodeMetadataContainer = styled.div`
  display: flex;
  gap: 32px;
`;

const MetadataContentContainer = styled.div`
  display: flex;
  gap: 4px;
`;

const NodeMetadata: React.FC = () => (
  <NodeMetadataContainer>
    <MetadataItem label="Node" variant="body2">
      <MetadataContentContainer>
        <Typography variant="body2">
          ip-10-0-1-44.us-east-2.compute.internal
        </Typography>
        <OpenInNew fontSize="small" color="primary" />
      </MetadataContentContainer>
    </MetadataItem>
    <MetadataItem label="Cluster" variant="body2">
      <Typography variant="body2">prod</Typography>
    </MetadataItem>
  </NodeMetadataContainer>
);

export const NodeData: React.FC = () => {
  return (
    <Container>
      <NodeMetadata />
      <Divider />
      <Typography variant="h5" color="text.primary">
        Node memory usage
      </Typography>
      <img src={memoryGraphUrl} />
      <img src={memoryGraphBottomUrl} />
    </Container>
  );
};
