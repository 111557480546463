import React from "react";

import { IconProps } from "../types";

export const Share16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18666 11.7443L13.3037 7.6506L9.18666 4.18795V6.89635H7.52515C5.53777 6.89635 4.3918 7.40148 3.70537 8.16345C3.19084 8.73461 2.79389 9.60332 2.61085 10.9058C2.87247 10.6376 3.1583 10.3962 3.47175 10.1838C4.37135 9.57419 5.39104 9.27614 6.49388 9.17068C6.82871 9.13866 7.17265 9.12419 7.52515 9.12419H9.18666V11.7443ZM6.18666 10.7211C4.59442 10.9777 3.51847 11.7667 2.71204 13.3563C2.46008 13.8562 2.14352 13.9726 1.80111 13.9726C1.34887 13.9726 1 13.5207 1 12.651C1 7.67978 3.06737 5.39635 7.52515 5.39635H7.68666V2.95865C7.68666 2.42454 8.05491 2 8.57176 2C8.91417 2 9.15967 2.15064 9.53438 2.52041L14.6511 6.82385C14.9031 7.0772 15 7.3648 15 7.625C15 7.87836 14.9031 8.1728 14.6511 8.42616L9.53438 13.5138C9.19197 13.8562 8.91417 14 8.55884 14C8.05491 14 7.68666 13.6097 7.68666 13.0756V10.6242H7.52515C7.21332 10.6242 6.91754 10.637 6.63666 10.6639C6.48222 10.6786 6.33228 10.6977 6.18666 10.7211Z"
      />
    </svg>
  );
};

Share16.defaultProps = {
  fill: "currentColor",
};
