import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Logout16 } from "@komodorio/design-system/icons";

import {
  OpenDownloadKubeconfigModalButton,
  RenderButtonProps,
} from "@/components/Settings/General/KubeconfigDownloader/SelectClustersModal";

export const GetKubeconfigItem = () => (
  <OpenDownloadKubeconfigModalButton
    renderButton={(props: RenderButtonProps) => (
      <MenuItem divider {...props}>
        <Typography variant="body2">Get Kubeconfig...</Typography>
      </MenuItem>
    )}
  />
);

export const LogOutItem = (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Logout16 />
    <Typography variant="body2">Logout</Typography>
  </Stack>
);
