import React from "react";
import styled from "styled-components";
import Search from "@mui/icons-material/Search";
import {
  MuiSelectionOption,
  SingleSelect,
} from "@komodorio/design-system/komodor-ui";
import { muiColors } from "@komodorio/design-system";

import { sectionGap } from "../../../../../../constants";

const Container = styled.div`
  display: flex;
  gap: ${sectionGap};
  margin-bottom: ${sectionGap};
`;

const InputWrapper = styled.div`
  border: 1px solid ${muiColors.gray[300]};
  border-radius: 4px;
  padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  & input {
    border: none;
    outline: none;
    color: ${muiColors.gray[800]};
    font-family: Roboto, sans-serif;
    &::placeholder {
      color: ${muiColors.gray[500]};
    }
  }
`;

const checkTypesOptions: MuiSelectionOption<string>[] = [
  { value: "All check types", label: "All check types" },
];

const StatusOptions: MuiSelectionOption<string>[] = [
  { value: "All statuses", label: "All statuses" },
];

export const RiskDetailsTableFilters: React.FC = () => {
  return (
    <Container>
      <InputWrapper>
        <Search color={"action"} />
        <input placeholder={"Search..."} />
      </InputWrapper>
      <SingleSelect
        options={checkTypesOptions}
        value={checkTypesOptions[0]}
        width={"156px"}
      />
      <SingleSelect
        options={StatusOptions}
        value={StatusOptions[0]}
        width={"156px"}
      />
    </Container>
  );
};
