import { ServiceReplicas } from "./ServiceVertex";
import { JobState } from "./job";
import { Dictionary } from "./Dictionary";

export interface ServiceAttribute {
  key: string;
  serviceId?: string | null;
  value?: string | null;
}

export interface Tag {
  label: string;
  value: string;
}
export interface ServiceInfo extends Labeled, Annotated {
  id: string;
  healthy: boolean;
  currentlyDeploying: boolean;
  title: string;
  tags: Tag[];
  lastModified: Date;
  env: string;
  k8sCluster: string;
  replicas?: ServiceReplicas;
  kind?: string | null;
  agentId?: string;
  isDeleted: boolean;
  jobState: JobState;
  jobLastRunTime: Date | undefined;
}

export interface Labeled {
  labels?: Dictionary<string>;
}

export const isLabeled = (object: unknown): object is Labeled =>
  (object as Labeled).labels !== undefined;

export interface Annotated {
  annotations?: Dictionary<string>;
}
