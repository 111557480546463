import React, { useEffect, useState } from "react";
import { palette } from "@komodorio/design-system";
import { Typography } from "@komodorio/design-system/deprecated";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";

import {
  DateTimeSelector,
  defaultOptions,
} from "../../common/DateTimeSelector";
import { Timeframe } from "../../../shared/types/TimeWindow";
import { AnalyticEvents } from "../../../shared/config/analyticsEvents";
import { dispatchEvent } from "../../../shared/hooks/analytics";
import UpgradeRequired from "../../common/UpdateAgentModal/upgradeRequired";
import { useActiveAgent } from "../../../shared/hooks/useAgents";
import { doesAgentVersionSupportShowDeletedPods } from "../../../shared/utils/agent/agent";
import { StrictExternalLink } from "../../common/Link/ExternalLink";
import { links } from "../../integrations/installation/kubernetes/steps/InstallCommand";
import { UpgradeCommands } from "../../common/upgradeAgentCta/UpgradeAgentCTA";
import { useDeletedPodsProperties } from "../historicalFetch/useResourceListFromDatabase";
import { useNeedUpgradeAgent } from "../../common/UpdateAgentModal/hooks";
import { useGetHelmInstallationCommand } from "../../../shared/hooks/useGetHelmInstallationCommand";

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
  align-items: center;
`;

const Control = styled.div`
  display: flex;
  align-items: center;
`;

const timeOptions = defaultOptions.slice(0, -2);
interface DeletedPodsSwitchProps {
  clusterName: string | undefined;
}

const UpdateMessage = (
  <Typography size="medium">
    An update to the Komodor agent is required to enable showing deleted pods.
    <br />
    <br />
    Learn more about{" "}
    <StrictExternalLink
      href={links.defaultK8sPermissions}
      color={palette.darkBlue[500]}
    >
      required permissions
    </StrictExternalLink>
  </Typography>
);

const DeletedPodsSwitch: React.FC<DeletedPodsSwitchProps> = ({
  clusterName,
}) => {
  const {
    deletedPodsTimeWindow,
    setDeletedPodsTimeWindow,
    showDeletedPodsParam,
    setShowDeletedPodsParam,
  } = useDeletedPodsProperties();

  const [displayUpgradeRequired, setDisplayUpgradeRequired] = useState(false);
  const agentId = useActiveAgent(clusterName);
  const agentUpgradeRequired = useNeedUpgradeAgent(
    agentId,
    clusterName,
    doesAgentVersionSupportShowDeletedPods
  );

  const handleSwitcherChange = () => {
    if (agentUpgradeRequired) {
      setDisplayUpgradeRequired(true);
      dispatchEvent(
        AnalyticEvents.Inspection.Inspection_show_deleted_pods_upgrade_agent
      );
    } else {
      if (showDeletedPodsParam) {
        dispatchEvent(
          AnalyticEvents.Inspection.Inspection_show_deleted_pods_toggled,
          { timeframe: "Off" }
        );
      }
      setShowDeletedPodsParam(!showDeletedPodsParam);
    }
  };

  useEffect(() => {
    if (showDeletedPodsParam) {
      dispatchEvent(
        AnalyticEvents.Inspection.Inspection_show_deleted_pods_toggled,
        { timeframe: Timeframe[deletedPodsTimeWindow.timeframe] }
      );
    }
  }, [deletedPodsTimeWindow, showDeletedPodsParam]);
  const commandStr = useGetHelmInstallationCommand(
    UpgradeCommands.NEW_UPGRADE_COMMAND
  );
  return (
    <Container>
      <Control>
        <Checkbox
          inputProps={{ "aria-label": "deleted-pods" }}
          checked={!!showDeletedPodsParam}
          onChange={handleSwitcherChange}
          size="small"
          disableRipple
          sx={{ paddingBlock: 0 }}
        />
        <Typography>Show deleted pods</Typography>
      </Control>
      <DateTimeSelector
        disabled={!showDeletedPodsParam}
        timeWindow={deletedPodsTimeWindow}
        setTimeWindow={setDeletedPodsTimeWindow}
        options={timeOptions}
        className={`noBackground ${showDeletedPodsParam ? "" : "disabled"}`}
        forceRightAlign={false}
      />
      {displayUpgradeRequired && (
        <UpgradeRequired
          handleClose={() => setDisplayUpgradeRequired(false)}
          updateMessage={UpdateMessage}
          upgradeCommand={commandStr}
          desiredFeature="showing deleted pods"
        />
      )}
    </Container>
  );
};

export default DeletedPodsSwitch;
