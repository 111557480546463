"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KomodorContext = void 0;
class KomodorContext {
    constructor(installation, account, user, allowedActions, apiKey) {
        this.installation = installation;
        this.account = account;
        this.user = user;
        this.allowedActions = allowedActions;
        this.apiKey = apiKey;
        this.installation = installation;
        this.account = account;
        this.user = user;
        this.allowedActions = allowedActions;
        this.apiKey = apiKey;
    }
}
exports.KomodorContext = KomodorContext;
