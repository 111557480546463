import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { JiraIcon } from "../../../../../../../../../../assets/drawer/JiraIcon";

import { Information } from "./Information";
import { whyDescription, whyTitle } from "./texts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: inherit;
  width: 720px;
`;

const TextAndButtonContainer = styled.div`
  && {
    margin-bottom: 16px;
    > button {
      width: fit-content;
      display: flex;
      gap: 8px;
    }
    > p,
    > button {
      margin-bottom: 8px;
    }
  }
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

export const Explanation: React.FC = () => {
  return (
    <Container>
      <Information title={whyTitle} description={whyDescription} />
      <div>
        <Title variant={"subtitle2"}>How to fix this violation?</Title>
        <TextAndButtonContainer>
          <Typography variant={"body2"}>
            1. Set Memory Limits to <b>8192 Mb</b>
          </Typography>
          <Button variant="outlined" color="primary">
            Set limit
          </Button>
        </TextAndButtonContainer>
        <TextAndButtonContainer>
          <Typography variant={"body2"}>
            2. Reduce the memory footprint of the application
          </Typography>
          <Button variant="outlined" color="primary">
            <JiraIcon /> Open Jira ticket
          </Button>
        </TextAndButtonContainer>
      </div>
    </Container>
  );
};
