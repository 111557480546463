import { subDays, subHours, subMinutes } from "date-fns";

import {
  Timeframe,
  TimeWindow,
} from "../../../../../../shared/types/TimeWindow";

import { getLabelForTimeframe } from "./timeframeLabel";

export const parseTimeWindowDataToUrlData = (timeWindow: TimeWindow) => {
  const { start, end, timeframe } = timeWindow;
  return {
    urlTimeWindow: `${start.getTime()}-${end.getTime()}`,
    urlTimeframe: getLabelForTimeframe(timeframe),
  };
};

export type NonCustomTimeframe = Exclude<Timeframe, Timeframe.Custom>;
export const getStartTime: Record<NonCustomTimeframe, (d: Date) => Date> = {
  [Timeframe.Last30Minutes]: (d) => subMinutes(d, 30),
  [Timeframe.LastHour]: (d) => subHours(d, 1),
  [Timeframe.Last4Hours]: (d) => subHours(d, 4),
  [Timeframe.Last24Hours]: (d) => subDays(d, 1),
  [Timeframe.Last48Hours]: (d) => subDays(d, 2),
  [Timeframe.Last7Days]: (d) => subDays(d, 7),
  [Timeframe.Last30Days]: (d) => subDays(d, 30),
};

const getUpdatedTimeWindow = (
  current: TimeWindow,
  updated: Partial<TimeWindow>
): TimeWindow => {
  if (
    updated.timeframe === undefined ||
    updated.timeframe === Timeframe.Custom
  ) {
    return {
      start: updated.start ?? current.start,
      end: updated.end ?? current.end,
      timeframe: Timeframe.Custom,
    };
  }

  const end = new Date();
  const start = getStartTime[updated.timeframe](end);
  const timeframe = updated.timeframe;
  return { start, end, timeframe };
};

export default getUpdatedTimeWindow;
