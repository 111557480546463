import Typography from "@mui/material/Typography";
import { TypographyProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface MetadataItemProps {
  label: string;
  variant?: TypographyProps["variant"];
  children: React.ReactNode;
}

export const MetadataItem: React.FC<MetadataItemProps> = ({
  label,
  variant = "overline2",
  children,
}) => (
  <Container>
    <Typography variant={variant} color={"text.secondary"}>
      {label}
    </Typography>
    {children}
  </Container>
);
