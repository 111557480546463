"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitedUserSchema = exports.UserRole = void 0;
const yup_1 = require("yup");
var UserRole;
(function (UserRole) {
    UserRole["User"] = "user";
    UserRole["AccountAdmin"] = "account-admin";
    UserRole["KomodorAdmin"] = "komodor-admin";
    UserRole["Anonymous"] = "anonymous";
    UserRole["UnauthorizedUser"] = "unauthorized-user";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
exports.InvitedUserSchema = (0, yup_1.object)({
    email: (0, yup_1.string)().email().required(),
    displayName: (0, yup_1.string)()
        .matches(/^[a-zA-Z0-9. _-]+$/) // Matches only letters, numbers, spaces, dots, underscores, and hyphens
        .min(1)
        .max(100)
        .required(),
});
