import { PaletteOptions, SimplePaletteColorOptions } from "@mui/material";

import {
  blue,
  gray,
  indigo,
  lightBlue,
  orange,
  pink,
  teal,
  yellow,
} from "./colors";

type KomoPaletteOptions = {
  running: SimplePaletteColorOptions;
  primary?: SimplePaletteColorOptions;
  secondary?: SimplePaletteColorOptions;
  error?: SimplePaletteColorOptions;
  warning?: SimplePaletteColorOptions;
  caution?: SimplePaletteColorOptions;
  success?: SimplePaletteColorOptions;
  info?: SimplePaletteColorOptions;
  backdrop?: string;
} & PaletteOptions;

export const palette: KomoPaletteOptions = {
  divider: gray[200],
  text: {
    primary: gray[800],
    secondary: gray[500],
    disabled: gray[400],
  },
  primary: {
    main: indigo[500],
    light: indigo[400],
    // Used for button hovers
    dark: indigo[600],
    contrastText: "#ffffff",
  },
  secondary: {
    main: lightBlue[300],
    // Used for button hovers
    dark: lightBlue[500],
    contrastText: gray[850],
  },
  error: {
    main: pink[600],
    light: pink[25],
    dark: pink[700],
  },
  warning: {
    light: orange[50],
    main: orange[400],
    dark: orange[700],
  },
  caution: {
    light: yellow[50],
    main: yellow[500],
    dark: yellow[800],
  },
  success: {
    main: teal[500],
    light: teal[50],
    dark: teal[900],
  },
  info: {
    main: gray[300],
    light: gray[50],
    dark: gray[700],
  },
  running: {
    main: blue[300],
    light: blue[50],
    dark: blue[600],
  },
  background: {
    default: gray[50],
    paper: "#ffffff",
  },
  backdrop: "rgba(0, 0, 0, 0.5)",
  // content border fill
  action: {
    // Mostly affects icons (e.g. icon buttons, pagination arrows)
    active: gray[500],

    // Controls the calculation for `hover` colors such as
    // `primary.states.hover`, `secondary.states.hover`, etc.
    // Applies to things such as buttons' background
    hoverOpacity: 0.04,
    // Controls the background of hovered elements such as table rows
    hover: "rgba(133, 142, 173, 0.07)",

    // Controls the calculation for `selected` colors such as
    // `primary.states.selected`
    selectedOpacity: 0.08,
    selected: gray[100],

    // Applies to disabled icon buttons and disabled text (e.g. on buttons)
    disabled: gray[400],
    // Applies to the background of disabled controls (e.g. the background color
    // of disabled buttons)
    disabledBackground: gray[100],
    disabledOpacity: 0.38,

    // Controls the calculation for `focus` colors such as
    // `primary.states.focus`,
    focusOpacity: 0.12,
    focus: "rgba(133, 142, 173, 0.50)",

    activatedOpacity: 0.12,
  },
};
