import React, { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { ifProp, ifNotProp, switchProp } from "styled-tools";

import { theme } from "../../../theme";
import { IconProps } from "../../../icons/types";

/** @deprecated */
export type IconButtonVariant = "default" | "danger";
/** @deprecated */
export type IconButtonSize = "small" | "medium" | "large";
/** @deprecated */
export interface IconButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: React.FC<IconProps>;
  variant?: IconButtonVariant;
  size?: IconButtonSize;
  noBorder?: boolean;
}

const defaultStyle = css`
  color: ${theme.iconButton.icon};
  outline: 1px solid ${theme.iconButton.border};
  :hover:enabled {
    background-color: ${theme.iconButton.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.iconButton.pressedBg};
  }
`;

const dangerStyle = css`
  color: ${theme.iconButton.danger.icon};
  outline: 1px solid ${theme.iconButton.danger.border};
  :hover:enabled {
    background-color: ${theme.iconButton.danger.hoverBg};
  }
  :active:enabled {
    background-color: ${theme.iconButton.danger.pressedBg};
  }
`;

const Container = styled.button<Omit<IconButtonProps, "icon">>`
  cursor: pointer;
  border: unset;
  border-radius: 4px;
  height: max-content;
  width: fit-content;
  background-color: ${theme.iconButton.bg};
  color: ${theme.iconButton.icon};
  ${switchProp("variant", {
    default: defaultStyle,
    danger: dangerStyle,
  })}
  ${switchProp("size", {
    small: "padding: 4px 4px 1px 4px;",
    medium: "padding: 6px 6px 3px 6px;",
    large: "padding: 8px 8px 5px 8px;",
  })}
  ${ifProp(
    "noBorder",
    `
    outline: 1px solid transparent;
    background-color: transparent;
    `
  )}
  :disabled {
    color: ${theme.iconButton.iconDisabled};
    ${ifNotProp("noBorder", `outline: 1px solid ${theme.iconButton.border};`)}
    cursor: not-allowed;
  }
`;

/**
 * @deprecated Use MUI IconButton instead.
 */
export const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Icon />
    </Container>
  );
};

IconButton.defaultProps = {
  variant: "default",
  size: "small",
  disabled: false,
  noBorder: false,
};
