import React from "react";

import { IconProps } from "../../types";
import { theme } from "../../..";

export const PSP: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 18.035 17.5"
      {...svgProps}
    >
      <path
        d="M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z"
        style={{
          fill: svgProps.fill,
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        d="M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z"
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "Sans",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          direction: "ltr",
          baselineShift: "baseline",
          textAnchor: "start",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          fill: "#fff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          marker: "none",
        }}
        transform="matrix(1.01489 0 0 1.01489 15.91 -3.873)"
      />
      <path
        transform="translate(460.398 75.865) scale(.35667)"
        d="m-1268.627-202.229-10.623 3.08 10.623 3.083 3.156-.916c1.443-1.338 3.364-2.166 5.467-2.166.51 0 1.009.054 1.494.146l.506-.146zM-1279.25-197.969v11.303l8.842 4.896v-3.47c0-1.051.428-1.916 1.08-2.615l.025-7.155zM-1266.83-195.344l-1.121.334.01 2.533a8.003 8.003 0 0 1 1.111-2.867zm6.826 2.992c-.739 0-1.273.535-1.273 1.274v1.271h2.545v-1.271c0-.739-.533-1.274-1.272-1.274z"
        style={{
          fill: "#fff",
          fillRule: "evenodd",
          stroke: "none",
          strokeWidth: 0.77621555,
          strokeLinecap: "square",
          strokeMiterlimit: 10,
        }}
      />
      <path
        transform="matrix(1.92107 0 0 1.92107 643.467 100.94)"
        d="M-329.23-49.713c-.599 0-1.084.486-1.084 1.084v.65a.435.435 0 0 0-.434.434v1.734c0 .239.195.432.434.432h2.168a.433.433 0 0 0 .433-.432v-1.734a.435.435 0 0 0-.433-.434v-.65c0-.598-.486-1.084-1.084-1.084zm0 .434c.36 0 .65.29.65.65v.65h-1.3v-.65c0-.36.29-.65.65-.65z"
        style={{ fill: "#fff", fillOpacity: 1, strokeWidth: 0.4190996 }}
      />
    </svg>
  );
};

PSP.defaultProps = {
  fill: theme.kubernetesIcon,
};
