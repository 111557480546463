import React from "react";
import { LinesLoader } from "@komodorio/design-system/komodor-ui";

import { useStateInSearchParams } from "@/shared/hooks/state";
import { ARGO_WORKFLOW_RUN_PARAM_KEY } from "@/shared/config/urlSearchParamsKeys";
import WorkflowRun from "@/components/ResourceView/resources/workflowRun";
import { Addon, Entity, WorkflowRunFromJSON } from "@/generated/addonsApi";
import { useGetAddonFullLiveState } from "@/shared/hooks/k8s-add-ons/useGetAddonFullLiveState";
import type WorkflowDag from "@/components/ResourceView/resources/workflowDag";

export const WorkflowDagContent: React.FC<{
  workflowDag: WorkflowDag;
  selectedTab: number;
}> = ({ workflowDag, selectedTab }) => {
  const [selectedRunId] = useStateInSearchParams(ARGO_WORKFLOW_RUN_PARAM_KEY);

  const { data, isFetching } = useGetAddonFullLiveState(
    {
      addon: Addon.Workflow,
      entity: Entity.WorkflowRun,
      uid: selectedRunId ?? "",
      name: workflowDag.id,
      getEntityRequest: {
        clusterName: workflowDag.cluster,
      },
    },
    (json) => ({
      type: json?.data?.type,
      // eslint-disable-next-line new-cap
      data: WorkflowRunFromJSON(json?.data?.data),
    })
  );
  const wfRun = data?.data ? new WorkflowRun(data.data) : undefined;

  return isFetching ? <LinesLoader /> : wfRun?.renderDrawerContent(selectedTab);
};
