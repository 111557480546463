import React, { useRef } from "react";

import { useIntersectionObserver } from "../../shared/hooks/useIntersectionObserver";

const IntersectionDetector: React.FC<{
  onShow: () => void;
  onHide?: () => void;
}> = ({ onShow, onHide }) => {
  const ref = useRef<HTMLDivElement>(null);
  useIntersectionObserver({
    ref,
    onShow,
    onHide,
  });

  return <div ref={ref} />;
};

export default IntersectionDetector;
