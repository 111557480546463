import Typography from "@mui/material/Typography";
import styled from "styled-components";

import { FlexColumns } from "./components/common/FlexContainers";

export const CenteredTypography = styled(Typography)`
  text-align: center;
`;

export const CenteredContainer = styled(FlexColumns)`
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
