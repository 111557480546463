import React from "react";

export const NewViolationsGraph: React.FC = () => {
  return (
    <svg viewBox="0 0 134 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 32V15L38.8641 1.5L131 10V32H1Z" fill="#DCFAF8" />
      <path
        d="M38.8641 2.5L38.9046 2.00165L38.8036 1.99342L38.7073 2.0252L38.8641 2.5ZM128.333 10C128.333 11.4728 129.527 12.6667 131 12.6667C132.473 12.6667 133.667 11.4728 133.667 10C133.667 8.52724 132.473 7.33333 131 7.33333C129.527 7.33333 128.333 8.52724 128.333 10ZM38.8235 2.99835L130.959 10.4984L131.041 9.50165L38.9046 2.00165L38.8235 2.99835ZM38.7073 2.0252L0.843256 14.5252L1.15674 15.4748L39.0208 2.9748L38.7073 2.0252Z"
        fill="#0A5C54"
      />
    </svg>
  );
};
