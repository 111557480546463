import React from "react";

import { IconProps } from "../types";

export const Download24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M4.97 11.03C4.89631 10.9613 4.83721 10.8785 4.79622 10.7865C4.75523 10.6945 4.73319 10.5952 4.73141 10.4945C4.72963 10.3938 4.74816 10.2938 4.78588 10.2004C4.8236 10.107 4.87974 10.0222 4.95096 9.95096C5.02218 9.87974 5.10701 9.8236 5.2004 9.78588C5.29379 9.74816 5.39382 9.72963 5.49452 9.73141C5.59523 9.73318 5.69454 9.75523 5.78654 9.79622C5.87854 9.83721 5.96134 9.89631 6.03 9.97L11 14.94V2.75C11 2.55109 11.079 2.36032 11.2197 2.21967C11.3603 2.07902 11.5511 2 11.75 2C11.9489 2 12.1397 2.07902 12.2803 2.21967C12.421 2.36032 12.5 2.55109 12.5 2.75V14.94L17.47 9.97C17.5387 9.89631 17.6215 9.83721 17.7135 9.79622C17.8055 9.75523 17.9048 9.73318 18.0055 9.73141C18.1062 9.72963 18.2062 9.74816 18.2996 9.78588C18.393 9.8236 18.4778 9.87974 18.549 9.95096C18.6203 10.0222 18.6764 10.107 18.7141 10.2004C18.7518 10.2938 18.7704 10.3938 18.7686 10.4945C18.7668 10.5952 18.7448 10.6945 18.7038 10.7865C18.6628 10.8785 18.6037 10.9613 18.53 11.03L12.28 17.28C12.1394 17.4205 11.9488 17.4993 11.75 17.4993C11.5512 17.4993 11.3606 17.4205 11.22 17.28L4.97 11.03ZM4.75 20.5C4.55109 20.5 4.36032 20.579 4.21967 20.7197C4.07902 20.8603 4 21.0511 4 21.25C4 21.4489 4.07902 21.6397 4.21967 21.7803C4.36032 21.921 4.55109 22 4.75 22H19.25C19.4489 22 19.6397 21.921 19.7803 21.7803C19.921 21.6397 20 21.4489 20 21.25C20 21.0511 19.921 20.8603 19.7803 20.7197C19.6397 20.579 19.4489 20.5 19.25 20.5H4.75Z" />
    </svg>
  );
};

Download24.defaultProps = {
  fill: "currentColor",
};
