import React from "react";

import { IconProps } from "../types";

export const Trash16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 1.75C6.5 1.6837 6.52634 1.62011 6.57322 1.57322C6.62011 1.52634 6.6837 1.5 6.75 1.5H9.25C9.3163 1.5 9.37989 1.52634 9.42678 1.57322C9.47366 1.62011 9.5 1.6837 9.5 1.75V3H6.5V1.75ZM11 1.75V3H13.25C13.4489 3 13.6397 3.07902 13.7803 3.21967C13.921 3.36032 14 3.55109 14 3.75C14 3.94891 13.921 4.13968 13.7803 4.28033C13.6397 4.42098 13.4489 4.5 13.25 4.5H2.75C2.55109 4.5 2.36032 4.42098 2.21967 4.28033C2.07902 4.13968 2 3.94891 2 3.75C2 3.55109 2.07902 3.36032 2.21967 3.21967C2.36032 3.07902 2.55109 3 2.75 3H5V1.75C5 0.784 5.784 0 6.75 0H9.25C10.216 0 11 0.784 11 1.75ZM4.496 6.675C4.48851 6.57519 4.46111 6.4779 4.41543 6.38884C4.36975 6.29979 4.30671 6.22078 4.23002 6.15647C4.15333 6.09216 4.06455 6.04385 3.9689 6.01438C3.87325 5.98492 3.77266 5.9749 3.67308 5.98491C3.5735 5.99492 3.47692 6.02476 3.38905 6.07268C3.30118 6.12059 3.22379 6.18561 3.16144 6.2639C3.09908 6.34219 3.05303 6.43217 3.02599 6.52853C2.99895 6.6249 2.99148 6.7257 3.004 6.825L3.664 13.425C3.70738 13.8566 3.90952 14.2567 4.23121 14.5478C4.5529 14.8388 4.97121 14.9999 5.405 15H10.595C11.495 15 12.247 14.319 12.336 13.424L12.996 6.824C13.01 6.6292 12.9475 6.43661 12.8217 6.2872C12.6959 6.13779 12.5168 6.04334 12.3225 6.02394C12.1281 6.00453 11.9339 6.06169 11.781 6.18328C11.6282 6.30486 11.5288 6.48127 11.504 6.675L10.844 13.275C10.8378 13.3367 10.8089 13.3939 10.7629 13.4355C10.7168 13.4771 10.657 13.5001 10.595 13.5H5.405C5.34298 13.5001 5.28315 13.4771 5.23714 13.4355C5.19112 13.3939 5.1622 13.3367 5.156 13.275L4.496 6.675Z"
      />
    </svg>
  );
};

Trash16.defaultProps = {
  fill: "currentColor",
};
