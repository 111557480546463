import React from "react";

import { IconProps } from "../types";

export const Team24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 10.5C16.3807 10.5 17.5 9.38071 17.5 8C17.5 6.61929 16.3807 5.5 15 5.5C13.6193 5.5 12.5 6.61929 12.5 8C12.5 9.38071 13.6193 10.5 15 10.5ZM15 12C17.2092 12 19 10.2091 19 8C19 5.79086 17.2092 4 15 4C12.7909 4 11 5.79086 11 8C11 10.2091 12.7909 12 15 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00002 12.5C7.82844 12.5 8.50002 11.8284 8.50002 11C8.50002 10.1716 7.82844 9.5 7.00002 9.5C6.17159 9.5 5.50002 10.1716 5.50002 11C5.50002 11.8284 6.17159 12.5 7.00002 12.5ZM7.00002 14C8.65687 14 10 12.6569 10 11C10 9.34315 8.65687 8 7.00002 8C5.34316 8 4.00002 9.34315 4.00002 11C4.00002 12.6569 5.34316 14 7.00002 14Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70171 20.3856C8.70341 20.3877 8.70544 20.39 8.70784 20.3926C8.74893 20.437 8.85177 20.5 9.00002 20.5H21C21.1483 20.5 21.2511 20.437 21.2922 20.3926C21.2946 20.39 21.2966 20.3876 21.2983 20.3856C20.5808 17.5759 18.0309 15.5 15 15.5C11.9691 15.5 9.41927 17.5759 8.70171 20.3856ZM7.24687 20.0202C8.1279 16.5595 11.2651 14 15 14C18.735 14 21.8721 16.5595 22.7532 20.0202C23.0257 21.0907 22.1046 22 21 22H9.00002C7.89545 22 6.97436 21.0907 7.24687 20.0202Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 22C5.66421 22 6 21.6642 6 21.25C6 20.8358 5.66421 20.5 5.25 20.5H2.99998C2.93142 20.5 2.8691 20.4856 2.81843 20.4636C2.80216 20.4565 2.78823 20.4492 2.77649 20.4422C3.35004 18.8876 4.75674 17.7391 6.45161 17.5331C6.72897 17.4994 6.98654 17.3558 7.13028 17.1162C7.43221 16.613 7.11321 15.9732 6.52851 16.0232C4.21125 16.2213 2.11038 17.7785 1.32873 20.0362C0.967352 21.08 1.89541 22 2.99998 22H5.25Z"
      />
    </svg>
  );
};

Team24.defaultProps = {
  fill: "currentColor",
};
