"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceIdentifierType = void 0;
var ServiceIdentifierType;
(function (ServiceIdentifierType) {
    ServiceIdentifierType["SERVICE_ID"] = "serviceId";
    ServiceIdentifierType["NAMESPACE"] = "namespace";
    ServiceIdentifierType["LABEL"] = "label";
    ServiceIdentifierType["ANNOTATION"] = "annotation";
    ServiceIdentifierType["CLUSTER_NAMESPACE"] = "cluster/namespace";
})(ServiceIdentifierType = exports.ServiceIdentifierType || (exports.ServiceIdentifierType = {}));
