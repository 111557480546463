import React, { useMemo } from "react";

import { WorkflowRunWithResults } from "../../../../../../../monitorsView/useWorkflowsRuns";
import EventGroup from "../../../../../../EventGroup";
import WorkflowIssueEventGroup from "../../../../../../EventGroup/workflowIssues/WorkflowIssueEventGroup";
import { Insights } from "../../common/Insight";
import { Section, SectionName } from "../../common/styles";
import { CorrelatedNodeEventsInsight } from "../../insights/correlatedNodeEventsInsight/CorrelatedNodeEventsInsight";
import { LatestDeploysInsight } from "../../insights/latestDeploysInsight/LatestDeploysInsight";
import { LatestManualActionsInsight } from "../../insights/manualActionsInsight/ManualActionsInsight";
import { SameIssueInClusterInsight } from "../../insights/sameIssueInClusterInsight/SameIssueInClusterInsight";
import {
  DemoInsights,
  useShowDemoInsights,
} from "../../common/Demo/DemoInsights";
import { RecentLimitDecreased } from "../../insights/recentLimitDecreasedinsight/RecentLimitDecreasedInsight";
import { IssueReasonInsight } from "../../insights/issueReasonInsight/IssueReasonInsight";
import { NoisyNeighborsInsight } from "../../insights/noisyNeighborsInsight/NoisyNeighborsInsight";
import { useIssueReasonInsight } from "../../insights/issueReasonInsight/useIssueReasonInsight";
import { useLatestDeploysInsight } from "../../insights/latestDeploysInsight/useLatestDeploysInsight";
import { useNoisyNeighborsInsight } from "../../insights/noisyNeighborsInsight/useNoisyNeighborsInsight";
import { useRecentLimitDecreaseInsight } from "../../insights/recentLimitDecreasedinsight/useRecentLimitDecreaseInsight";
import { useManualActionsInsight } from "../../insights/manualActionsInsight/useManualActionsInsight";
import { useCorrelatedNodeEventsInsight } from "../../insights/correlatedNodeEventsInsight/useCorrelatedNodeEventsInsight";
import { useSameIssueInClusterInsight } from "../../insights/sameIssueInClusterInsight/useSameIssueInClusterInsight";

export const InsightsSection: React.FC<{
  wfRun: WorkflowRunWithResults;
  eventGroup: WorkflowIssueEventGroup;
  setCorrelatedEvent: (event: EventGroup | null) => void;
}> = ({ wfRun, eventGroup, setCorrelatedEvent }) => {
  const issueReasonInsight = useIssueReasonInsight(
    wfRun.triageData.issueType,
    wfRun.triageData.subReason
  );
  const nosiyNeighborsInsight = useNoisyNeighborsInsight(
    wfRun.triageData.isNoisyNeighborsDetected
  );
  const latestDeploysInsight = useLatestDeploysInsight(
    wfRun.triageData.latestDeploysInsights
  );
  const recentLimitDecreaseInsight = useRecentLimitDecreaseInsight(
    wfRun.triageData.limitDecreaseInsight
  );
  const latestManualActionsInsight = useManualActionsInsight(
    wfRun.triageData.latestManualActionsInsights
  );
  const correlatedNodeEventsInsight = useCorrelatedNodeEventsInsight(
    wfRun.triageData.latestNodeIssuesInsights,
    wfRun.triageData.latestNodeTerminationsInsights
  );
  const sameIssueInClusterInsight = useSameIssueInClusterInsight(
    wfRun.triageData.sameIssuesInClusterInsights
  );

  const isThereAnyInsight = useMemo(
    () =>
      issueReasonInsight.shouldShow ||
      nosiyNeighborsInsight.shouldShow ||
      latestDeploysInsight.shouldShow ||
      recentLimitDecreaseInsight.shouldShow ||
      latestManualActionsInsight.shouldShow ||
      correlatedNodeEventsInsight.shouldShow ||
      sameIssueInClusterInsight.shouldShow,
    [
      correlatedNodeEventsInsight.shouldShow,
      issueReasonInsight.shouldShow,
      latestDeploysInsight.shouldShow,
      latestManualActionsInsight.shouldShow,
      nosiyNeighborsInsight.shouldShow,
      recentLimitDecreaseInsight.shouldShow,
      sameIssueInClusterInsight.shouldShow,
    ]
  );

  const showDemoInsights = useShowDemoInsights(wfRun.shortResourceName);

  if (!isThereAnyInsight && !showDemoInsights) {
    return null;
  }
  return (
    <Section>
      <SectionName>insights</SectionName>
      {showDemoInsights ? (
        <DemoInsights
          namespace={wfRun.namespace}
          wfRun={wfRun}
          eventGroup={eventGroup}
        />
      ) : (
        <Insights>
          {issueReasonInsight.shouldShow && <IssueReasonInsight />}
          {nosiyNeighborsInsight.shouldShow && (
            <NoisyNeighborsInsight runId={wfRun.id} />
          )}
          {latestDeploysInsight.shouldShow && (
            <LatestDeploysInsight
              latestDeployInsights={
                latestDeploysInsight.latestDeployInsightsUpdated
              }
              setCorrelatedEvent={setCorrelatedEvent}
              startTime={wfRun.eventTime}
            />
          )}
          {recentLimitDecreaseInsight.shouldShow && (
            <RecentLimitDecreased
              limitDecreaseInsight={
                recentLimitDecreaseInsight.limitDecreaseInsightUpdated
              }
              setCorrelatedEvent={setCorrelatedEvent}
              startTime={wfRun.eventTime}
            />
          )}
          {latestManualActionsInsight.shouldShow && (
            <LatestManualActionsInsight
              latestManualActionsInsights={
                wfRun.triageData.latestManualActionsInsights
              }
              setCorrelatedEvent={setCorrelatedEvent}
              startTime={wfRun.eventTime}
            />
          )}
          {correlatedNodeEventsInsight.shouldShow && (
            <CorrelatedNodeEventsInsight
              latestNodeIssuesInsights={
                correlatedNodeEventsInsight.latestNodeIssuesInsightsUpdated
              }
              latestNodeTerminationsInsights={
                wfRun.triageData.latestNodeTerminationsInsights
              }
              setCorrelatedEvent={setCorrelatedEvent}
              startTime={wfRun.eventTime}
              serviceId={eventGroup.serviceId}
            />
          )}
          {sameIssueInClusterInsight.shouldShow && (
            <SameIssueInClusterInsight
              sameIssuesInClusterInsights={
                sameIssueInClusterInsight.sameIssuesInClusterInsightsUpdated
              }
              setCorrelatedEvent={setCorrelatedEvent}
              startTime={wfRun.eventTime}
              serviceId={eventGroup.serviceId}
            />
          )}
        </Insights>
      )}
    </Section>
  );
};
