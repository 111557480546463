import styled from "styled-components";
import { muiColors } from "@komodorio/design-system";

export const RelativeContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  & > img {
    width: 100%;
  }
`;

export const CloseButtonClickArea = styled.div`
  position: absolute;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 34px;
  left: 25px;
`;

export const SelectableClickableArea = styled.div<{ selected: boolean }>`
  position: absolute;
  cursor: pointer;
  border: 1px solid;
  border-radius: 5px;
  border-color: ${({ selected }) =>
    selected ? `${muiColors.indigo[500]}` : "transparent"};
  transition: border-color 0.5s ease-in-out;
`;
