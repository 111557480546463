import React from "react";

import { RisksCards } from "./RiskCards/RiskCards";
import { RisksList } from "./RisksList/RisksList";
import { RiskDrawer } from "./RiskDrawer/RiskDrawer";
import { RisksListSummary } from "./RisksList/RisksListSummary";

export const RisksContent: React.FC = () => {
  return (
    <div>
      <RisksListSummary />
      <RisksCards />
      <RisksList />
      <RiskDrawer />
    </div>
  );
};
