import React from "react";

import { IconProps } from "../types";

export const FastForward16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M16.125 12L12.225 8.10005C12.0417 7.91672 11.95 7.68338 11.95 7.40005C11.95 7.11672 12.0417 6.88338 12.225 6.70005C12.4083 6.51672 12.6417 6.42505 12.925 6.42505C13.2083 6.42505 13.4417 6.51672 13.625 6.70005L18.225 11.3C18.325 11.4 18.3958 11.5084 18.4375 11.625C18.4792 11.7417 18.5 11.8667 18.5 12C18.5 12.1334 18.4792 12.2584 18.4375 12.375C18.3958 12.4917 18.325 12.6 18.225 12.7L13.625 17.3C13.4417 17.4834 13.2083 17.575 12.925 17.575C12.6417 17.575 12.4083 17.4834 12.225 17.3C12.0417 17.1167 11.95 16.8834 11.95 16.6C11.95 16.3167 12.0417 16.0834 12.225 15.9L16.125 12Z"
        fillRule="evenodd"
      />
      <path
        d="M9.175 12L5.275 8.10005C5.09167 7.91672 5 7.68338 5 7.40005C5 7.11672 5.09167 6.88338 5.275 6.70005C5.45833 6.51672 5.69167 6.42505 5.975 6.42505C6.25833 6.42505 6.49167 6.51672 6.675 6.70005L11.275 11.3C11.375 11.4 11.4458 11.5084 11.4875 11.625C11.5292 11.7417 11.55 11.8667 11.55 12C11.55 12.1334 11.5292 12.2584 11.4875 12.375C11.4458 12.4917 11.375 12.6 11.275 12.7L6.675 17.3C6.49167 17.4834 6.25833 17.575 5.975 17.575C5.69167 17.575 5.45833 17.4834 5.275 17.3C5.09167 17.1167 5 16.8834 5 16.6C5 16.3167 5.09167 16.0834 5.275 15.9L9.175 12Z"
        fillRule="evenodd"
      />
    </svg>
  );
};

FastForward16.defaultProps = {
  fill: "currentColor",
};
