import React from "react";

import { IconProps } from "../types";

export const Pod24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5939 2.35975C11.8524 2.24483 12.1476 2.24483 12.4061 2.35975L21.1561 6.24864C21.5173 6.40914 21.75 6.76726 21.75 7.16245V17.3455C21.75 17.7242 21.536 18.0705 21.1972 18.2399L12.4472 22.6149C12.1657 22.7557 11.8343 22.7557 11.5528 22.6149L2.80279 18.2399C2.464 18.0705 2.25 17.7242 2.25 17.3455V7.16245C2.25 6.76726 2.48273 6.40914 2.84386 6.24864L11.5939 2.35975ZM12 3.82072L4.84666 6.99998L12 10.1792L19.1533 6.99998L12 3.82072ZM20.25 8.15405L12.75 11.4874V20.7865L20.25 17.0365V8.15405ZM11.25 20.7865V11.4874L3.75 8.15405V17.0365L11.25 20.7865Z"
      />
    </svg>
  );
};

Pod24.defaultProps = {
  fill: "currentColor",
};
