import React from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { ReliabilityCheckData } from "../../../../../riskManagementTypes";

const Container = styled.div``;

type ManageChecksTableTextProps = {
  data: ReliabilityCheckData;
};

export const ManageChecksTableText: React.FC<ManageChecksTableTextProps> = ({
  data: { title, subtitle },
}) => {
  return (
    <Container>
      <Typography variant={"h5"} color={"text.primary"}>
        {title}
      </Typography>
      <Typography variant={"body3"} color={"text.secondary"}>
        {subtitle}
      </Typography>
    </Container>
  );
};
