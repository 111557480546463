import React from "react";

import { IconProps } from "../types";

export const CheckCircleOutlined24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M17.28 9.28C17.4125 9.13782 17.4846 8.94978 17.4812 8.75548C17.4777 8.56118 17.399 8.37579 17.2616 8.23838C17.1242 8.10097 16.9388 8.02225 16.7445 8.01882C16.5502 8.0154 16.3622 8.08752 16.22 8.22L10.25 14.19L7.78 11.72C7.63783 11.5875 7.44978 11.5154 7.25548 11.5188C7.06118 11.5223 6.87579 11.601 6.73838 11.7384C6.60097 11.8758 6.52225 12.0612 6.51883 12.2555C6.5154 12.4498 6.58752 12.6378 6.72 12.78L9.72 15.78C9.86063 15.9204 10.0512 15.9993 10.25 15.9993C10.4488 15.9993 10.6394 15.9204 10.78 15.78L17.28 9.28Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C7.06408 3.50089 9.48044 2.5 12 2.5C14.5196 2.5 16.9359 3.50089 18.7175 5.28249C20.4991 7.06408 21.5 9.48044 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C9.48044 21.5 7.06408 20.4991 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12Z"
      />
    </svg>
  );
};

CheckCircleOutlined24.defaultProps = {
  fill: "currentColor",
};
