import React from "react";

import { IconProps } from "../types";

export const Clock16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8C1.5 6.27609 2.18482 4.62279 3.40381 3.40381C4.62279 2.18482 6.27609 1.5 8 1.5C9.72391 1.5 11.3772 2.18482 12.5962 3.40381C13.8152 4.62279 14.5 6.27609 14.5 8C14.5 9.72391 13.8152 11.3772 12.5962 12.5962C11.3772 13.8152 9.72391 14.5 8 14.5C6.27609 14.5 4.62279 13.8152 3.40381 12.5962C2.18482 11.3772 1.5 9.72391 1.5 8ZM8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0Z"
      />
      <path d="M8.5 5.75C8.5 5.55109 8.42098 5.36032 8.28033 5.21967C8.13968 5.07902 7.94891 5 7.75 5C7.55109 5 7.36032 5.07902 7.21967 5.21967C7.07902 5.36032 7 5.55109 7 5.75V8H4.75C4.55109 8 4.36032 8.07902 4.21967 8.21967C4.07902 8.36032 4 8.55109 4 8.75C4 8.94891 4.07902 9.13968 4.21967 9.28033C4.36032 9.42098 4.55109 9.5 4.75 9.5H7.5C8.05228 9.5 8.5 9.05228 8.5 8.5V5.75Z" />
    </svg>
  );
};

Clock16.defaultProps = {
  fill: "currentColor",
};
