import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

import { RiskListObject } from "../types";
import { RiskUrgencyChip } from "../RiskUrgencyChip";
import { RiskDetailsExpandedContent } from "../RiskDetailsExpandedContent/RiskDetailsExpandedContent";
import { AnimatedKeyboardArrowDown } from "../../../../../../riskManagementStyles";
import { sectionGap } from "../../../../../../constants";

import { RiskCardViolationsBreakdown } from "./RiskCardViolationsBreakdown";
import { RiskDetailsCardInsights } from "./RiskDetailsCardInsights";
import { ExpandableContentContainer } from "./ExpandableContentContainer";

const StyledPaper = styled(Paper)`
  padding: 20px;
  margin-bottom: ${sectionGap};
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const FlexContainer = styled(Container)`
  justify-content: flex-start;
  flex-grow: 1;
`;

const StyledKeyboardArrowDown = styled(AnimatedKeyboardArrowDown)<{
  open: boolean;
}>`
  && {
    align-self: flex-start;
  }
`;

const PaddedDiv = styled.div`
  padding-right: 40px;
`;

const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
`;

type RiskDetailsCardProps = {
  riskData: RiskListObject;
};

export const RiskDetailsCard: React.FC<RiskDetailsCardProps> = ({
  riskData: { title, urgency, violations, description, insights },
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <StyledPaper>
      <FlexContainer>
        <StyledKeyboardArrowDown
          open={isExpanded}
          color={"action"}
          onClick={() => setIsExpanded(!isExpanded)}
        />
        <PaddedDiv>
          <Container>
            <FlexContainer>
              <NoWrapTypography variant={"h4"}>{title}</NoWrapTypography>
              <RiskUrgencyChip urgency={urgency} />
              <RiskCardViolationsBreakdown violations={violations} />
            </FlexContainer>
          </Container>
          <br />
          <Typography variant={"body2"} color={"text.secondary"}>
            {description}
          </Typography>
          <RiskDetailsCardInsights insights={insights} />
        </PaddedDiv>
      </FlexContainer>
      <ExpandableContentContainer isExpanded={isExpanded}>
        <RiskDetailsExpandedContent violations={violations} />
      </ExpandableContentContainer>
    </StyledPaper>
  );
};
