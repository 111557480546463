import React from "react";

import { IconProps } from "../types";

export const Folder16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 2.5C1.6837 2.5 1.62011 2.52634 1.57322 2.57322C1.52634 2.62011 1.5 2.6837 1.5 2.75V13.25C1.5 13.388 1.612 13.5 1.75 13.5H14.25C14.3163 13.5 14.3799 13.4737 14.4268 13.4268C14.4737 13.3799 14.5 13.3163 14.5 13.25V4.75C14.5 4.6837 14.4737 4.62011 14.4268 4.57322C14.3799 4.52634 14.3163 4.5 14.25 4.5H7.5C6.95 4.5 6.43 4.24 6.1 3.8L5.2 2.6C5.17671 2.56895 5.14652 2.54375 5.1118 2.52639C5.07709 2.50904 5.03881 2.5 5 2.5H1.75ZM0 2.75C0 1.784 0.784 1 1.75 1H5C5.55 1 6.07 1.26 6.4 1.7L7.3 2.9C7.32329 2.93105 7.35348 2.95625 7.3882 2.97361C7.42291 2.99096 7.46119 3 7.5 3H14.25C15.216 3 16 3.784 16 4.75V13.25C16 13.7141 15.8156 14.1592 15.4874 14.4874C15.1592 14.8156 14.7141 15 14.25 15H1.75C1.28587 15 0.840752 14.8156 0.512563 14.4874C0.184374 14.1592 0 13.7141 0 13.25L0 2.75Z"
      />
    </svg>
  );
};

Folder16.defaultProps = {
  fill: "currentColor",
};
