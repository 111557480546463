import React, { useMemo } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import {
  SingleSelect,
  MuiSelectionOption,
} from "@komodorio/design-system/komodor-ui";

import { sectionGap } from "../../../../../constants";
import { getTotalViolations } from "../risksListUtils";

import { riskDataList } from "./staticData";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: ${sectionGap} 0;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const RisksListSummary: React.FC = () => {
  const { totalRisks, accumulatedViolations } = useMemo(() => {
    const accumulatedViolations = getTotalViolations();
    return {
      totalRisks: riskDataList.length,
      accumulatedViolations,
    };
  }, []);

  const selectOptions: MuiSelectionOption<string>[] = [
    { label: "Risk", value: "Risk" },
  ];

  return (
    <Container>
      <Typography>
        Showing <b>{totalRisks}</b> risks with <b>{accumulatedViolations}</b>{" "}
        violations
      </Typography>
      <SelectContainer>
        <Typography>Group by:</Typography>
        <SingleSelect
          options={selectOptions}
          value={selectOptions[0]}
          width={"144px"}
        />
      </SelectContainer>
    </Container>
  );
};
