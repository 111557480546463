import React from "react";
import { TooltipWrapper } from "react-tooltip";

import { Insight, InsightContainer } from "../../common/Insight";
import { DashedText } from "../../common/styles";
import EventGroup from "../../../../../../EventGroup";
import { SameIssueInClusterInsightDataParsed } from "../../common/types";

import {
  SameIssueInClusterTooltip,
  SAME_ISSUE_IN_CLUSTER_TOOLTIP,
} from "./SameIssueInClusterTooltip";

export const SameIssueInClusterInsight: React.FC<{
  sameIssuesInClusterInsights: SameIssueInClusterInsightDataParsed[];
  setCorrelatedEvent: (event: EventGroup | null) => void;
  startTime: Date;
  serviceId: string;
}> = ({
  sameIssuesInClusterInsights,
  setCorrelatedEvent,
  startTime,
  serviceId,
}) => {
  const issuesLength = sameIssuesInClusterInsights.length;

  return (
    <InsightContainer>
      <TooltipWrapper tooltipId={SAME_ISSUE_IN_CLUSTER_TOOLTIP}>
        <Insight>
          The same issue appeared on {issuesLength} other{" "}
          <DashedText>
            service
            {issuesLength > 1 ? "s" : ""}
          </DashedText>{" "}
          in the cluster within 2 hours before the issue started
        </Insight>
      </TooltipWrapper>
      <SameIssueInClusterTooltip
        issues={sameIssuesInClusterInsights}
        issueStartTime={startTime}
        setCorrelatedEvent={setCorrelatedEvent}
        serviceId={serviceId}
      />
    </InsightContainer>
  );
};
