"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelType = void 0;
var ChannelType;
(function (ChannelType) {
    ChannelType["Slack"] = "slack";
    ChannelType["Teams"] = "teams";
    ChannelType["Opsgenie"] = "opsgenie";
    ChannelType["PagerDuty"] = "pagerduty";
    ChannelType["GenericWebhook"] = "generic-webhook";
})(ChannelType = exports.ChannelType || (exports.ChannelType = {}));
