import React from "react";
import Drawer from "@mui/material/Drawer";

import singlePointOfFailureDrawer from "../assets/singlePointOfFailureDrawer.png";
import { CloseButtonArea } from "../styles";

type SinglePointOfFailureDrawerProps = {
  open: boolean;
  onClose: () => void;
};
export const SinglePointOfFailureDrawer: React.FC<
  SinglePointOfFailureDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor={"right"}>
      <img
        src={singlePointOfFailureDrawer}
        alt={"single point of failure drawer"}
        width={864}
      />
      <CloseButtonArea onClick={onClose} />
    </Drawer>
  );
};
