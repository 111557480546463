import React from "react";

import { LogoIconProps } from "../types";

export const Opsgenie: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Opsgenie logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M12.0378 11.9251C14.9995 11.9251 17.4003 9.52418 17.4003 6.56254C17.4003 3.6009 14.9995 1.20001 12.0378 1.20001C9.07618 1.20001 6.67529 3.6009 6.67529 6.56254C6.67529 9.52418 9.07618 11.9251 12.0378 11.9251Z"
        fill="url(#paint0_linear_1_765)"
      />
      <path
        d="M11.5861 22.5906C8.09139 20.3612 5.16911 17.2883 3.09038 13.7032C3.03013 13.6129 3 13.5225 3 13.402C3 13.161 3.12051 12.9501 3.33139 12.8597L7.39848 10.8713C7.66962 10.7207 8.03114 10.8412 8.18177 11.1124C10.2304 14.5468 13.2732 17.3184 16.9185 19.0055C15.5628 20.3612 14.0866 21.5663 12.4899 22.5906C12.2187 22.7713 11.8572 22.7713 11.5861 22.5906Z"
        fill="url(#paint1_linear_1_765)"
      />
      <path
        d="M12.4899 22.59C16.0147 20.3607 18.9068 17.2878 20.9856 13.7027C21.0458 13.6123 21.0759 13.5219 21.0759 13.4014C21.0759 13.1604 20.9554 12.9495 20.7445 12.8591L16.6775 10.8708C16.4063 10.7202 16.0448 10.8407 15.8942 11.1118C13.8456 14.5462 10.8028 17.3179 7.15747 19.005C8.48304 20.3607 9.98937 21.5657 11.5861 22.59C11.8572 22.7708 12.2187 22.7708 12.4899 22.59Z"
        fill="#2684FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_765"
          x1="12.0421"
          y1="2.9735"
          x2="12.0421"
          y2="15.4646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2684FF" />
          <stop offset="0.82" stopColor="#0052CC" />
          <stop offset="1" stopColor="#0052CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_765"
          x1="8.72685"
          y1="13.6902"
          x2="12.911"
          y2="22.4473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2684FF" />
          <stop offset="0.62" stopColor="#0052CC" />
          <stop offset="1" stopColor="#0052CC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
