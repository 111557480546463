"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParamsKeys = exports.HeaderKeys = void 0;
exports.HeaderKeys = {
    ApiKey: "x-api-key",
    IsDisabled: "X-Komodor-Disabled",
    Jwt: "authorization",
};
exports.ParamsKeys = {
    InstallationId: "installationId",
};
