"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobsSortStrategy = exports.ServicesSortStrategy = exports.FilterType = exports.JobsFilterCategory = exports.ServicesFilterCategory = void 0;
var ServicesFilterCategory;
(function (ServicesFilterCategory) {
    ServicesFilterCategory["Cluster"] = "Cluster";
    ServicesFilterCategory["Namespace"] = "Namespace";
    ServicesFilterCategory["Kind"] = "Kind";
    ServicesFilterCategory["Health"] = "Health";
})(ServicesFilterCategory = exports.ServicesFilterCategory || (exports.ServicesFilterCategory = {}));
var JobsFilterCategory;
(function (JobsFilterCategory) {
    JobsFilterCategory["Cluster"] = "Cluster";
    JobsFilterCategory["Namespace"] = "Namespace";
    JobsFilterCategory["Kind"] = "Kind";
    JobsFilterCategory["Status"] = "Status";
})(JobsFilterCategory = exports.JobsFilterCategory || (exports.JobsFilterCategory = {}));
var FilterType;
(function (FilterType) {
    FilterType["Static"] = "static";
    FilterType["Dynamic"] = "dynamic";
})(FilterType = exports.FilterType || (exports.FilterType = {}));
var ServicesSortStrategy;
(function (ServicesSortStrategy) {
    ServicesSortStrategy["Health"] = "Health";
    ServicesSortStrategy["LastModified"] = "Last Modified";
    ServicesSortStrategy["Name"] = "Name";
    ServicesSortStrategy["Namespace"] = "Namespace";
})(ServicesSortStrategy = exports.ServicesSortStrategy || (exports.ServicesSortStrategy = {}));
var JobsSortStrategy;
(function (JobsSortStrategy) {
    JobsSortStrategy["Status"] = "Status";
    JobsSortStrategy["LastRun"] = "Last Run";
    JobsSortStrategy["Name"] = "Name";
    JobsSortStrategy["Namespace"] = "Namespace";
})(JobsSortStrategy = exports.JobsSortStrategy || (exports.JobsSortStrategy = {}));
