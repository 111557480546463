import { Dictionary } from "../../../../../../../shared/types/Dictionary";
import { findActiveAgentByClusterName } from "../../../../../../../shared/hooks/useAgents";
import {
  agentInfoToProperties,
  parseAgentInfo,
} from "../../../../../../../shared/hooks/useAgentInfo/useAgentInfo";
import { doesAgentVersionSupportResourceListFromAtmAndLiveData } from "../../../../../../../shared/utils/agent/agent";
import { ListKubernetesAgentsQuery } from "../../../../../../../generated/graphql";

export const stringListToDictionary = (arr: string[]): Dictionary<boolean> => {
  return arr.reduce((acc, curr) => ({ ...acc, [curr]: true }), {});
};

export const boolDictionaryToStringList = (
  dic: Dictionary<boolean>
): string[] =>
  Object.entries(dic)
    .filter(([, selected]) => selected)
    .map(([serviceId]) => serviceId);

export const doesAgentSupportAtmLiveData = (
  agents: ListKubernetesAgentsQuery | undefined,
  clusterName: string
): boolean => {
  const agent = findActiveAgentByClusterName(agents, clusterName);
  if (!agent) return false;
  const agentInfo = parseAgentInfo(agent);
  const agentProps = agentInfoToProperties(agentInfo);
  return doesAgentVersionSupportResourceListFromAtmAndLiveData(agentProps);
};
