import React from "react";
import styled from "styled-components";
import Edit from "@mui/icons-material/Edit";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const Container = styled.div`
  display: flex;
`;

export const ActionIcons: React.FC = () => {
  return (
    <Container>
      <IconButton>
        <Edit />
      </IconButton>

      <IconButton>
        <ContentCopy />
      </IconButton>

      <IconButton>
        <Delete />
      </IconButton>
    </Container>
  );
};
