import { get, orderBy, groupBy, chain } from "lodash";

import EventGroup from "../../EventGroup";
import { extractServiceName } from "../../../../shared/utils/serviceHelpers";
import { ServiceInfo } from "../../../../shared/types/ServiceInfo";
import { POD_EVENT_TYPE } from "../../EventGroup/nativePodEvent/utils";
import { agentDowntimeType } from "../../EventGroup/commonEventGroup/types";
import { WORKFLOW_CONFIG_TYPES } from "../../EventGroup/workflowIssues/constants";
import { WorkflowConfigType } from "../../../monitorsView/common/types";
import {
  NodeEventAction,
  NodeEventGroupTypes,
} from "../../EventGroup/nodeEvent/NodeChangeGroup";
import { POD_GROUP_TYPE } from "../../EventGroup/groupedPodEvent/PodPhaseGroup";

export interface LineGroup {
  prefix: string | null;
  label: string;
  events: EventGroup[];
}

enum GroupTypes {
  pod = "pod",
  cluster = "cluster",
  service = "service",
}

const createClusterLines = (clusterEventGroups: EventGroup[]): LineGroup[] => {
  const groupedEvents = groupBy(clusterEventGroups, (eventGroup) => {
    return get(eventGroup, "clusterName", "");
  });

  return Object.keys(groupedEvents).map((key) => {
    return { prefix: "Cluster: ", label: key, events: groupedEvents[key] };
  });
};

const getPodName = (eventGroup: EventGroup): string | null => {
  return (
    get(eventGroup, "podName", null) || get(eventGroup, "resourceName", null)
  );
};

const getPodNamespace = (eventGroup: EventGroup): string | null => {
  return get(eventGroup, "namespace", null);
};
const getPodClusterName = (eventGroup: EventGroup): string | null => {
  return get(eventGroup, "clusterName", null);
};

const createPodLines = (podEventGroups: EventGroup[]): LineGroup[] => {
  return chain(podEventGroups)
    .filter(
      (e) => !!getPodName(e) && !!getPodNamespace(e) && !!getPodClusterName(e)
    )
    .groupBy(
      (e) => `${getPodClusterName(e)};${getPodNamespace(e)};${getPodName(e)}`
    )
    .map((events, _) => {
      const podName = getPodName(events[0]) ?? "";
      return { prefix: "Pod: ", label: podName, events: events };
    })
    .value();
};

const buildLabelPrefix = (service: ServiceInfo): string => {
  const kind = service?.kind;
  const clusterName = service?.k8sCluster;
  return `${kind}: ${clusterName}/`;
};

export const createServiceLines = (
  eventGroups: EventGroup[],
  services: ServiceInfo[] | undefined
): LineGroup[] => {
  const eventsByServiceId = groupBy(eventGroups, (eventGroup) => {
    return eventGroup?.serviceId ?? "unknown";
  });

  return Object.entries(eventsByServiceId).map(([serviceId, serviceEvents]) => {
    const label = extractServiceName(serviceId);

    let prefix = null;
    if (!["unknown", "system-wide"].includes(label)) {
      const service = services?.find((service) => {
        return service?.id === serviceId;
      });
      if (service) {
        prefix = buildLabelPrefix(service);
      }
    }
    return {
      prefix: prefix,
      label: label,
      events: serviceEvents as EventGroup[],
    };
  });
};

export const groupEventsByLine = (
  eventGroups: EventGroup[],
  serviceIds: string[],
  services: ServiceInfo[] | undefined
): LineGroup[] => {
  const clusterLevelTypes = [
    agentDowntimeType,
    WORKFLOW_CONFIG_TYPES[WorkflowConfigType.NodeIssue],
    NodeEventGroupTypes[NodeEventAction.Create],
    NodeEventGroupTypes[NodeEventAction.Delete],
  ];
  const podLevelTypes = [
    POD_EVENT_TYPE,
    POD_GROUP_TYPE,
    WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Pod],
    WORKFLOW_CONFIG_TYPES[WorkflowConfigType.Workflow],
  ];

  const sortByLabel = (lineGroups: LineGroup[]) => {
    return orderBy(lineGroups, ["prefix", "label"], "desc");
  };

  const sortByEventTimeAsc = (lineGroups: LineGroup[]) => {
    return lineGroups.sort((a, b) => {
      a.events.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
      b.events.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
      return a.events[0].startTime.getTime() - b.events[0].startTime.getTime();
    });
  };
  const typeGroups = groupBy(eventGroups, (eventGroup) => {
    if (clusterLevelTypes.includes(eventGroup.type)) {
      return GroupTypes.cluster;
    }
    if (podLevelTypes.includes(eventGroup.type) && getPodName(eventGroup)) {
      return GroupTypes.pod;
    }
    return GroupTypes.service;
  });

  const clusterLines = createClusterLines(
    get(typeGroups, GroupTypes.cluster, [])
  );

  const podLines = createPodLines(get(typeGroups, GroupTypes.pod, []));

  const serviceLines = createServiceLines(
    get(typeGroups, GroupTypes.service, []),
    services
  );

  return [
    ...sortByLabel(clusterLines),
    ...sortByLabel(serviceLines),
    ...sortByEventTimeAsc(podLines),
  ];
};
