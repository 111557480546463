import React from "react";

import { IconProps } from "../types";

export const AlertCircleOutlined24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M11.2232 7.64458C11.2232 7.22707 11.571 6.88861 12 6.88861C12.429 6.88861 12.7768 7.22707 12.7768 7.64458V13.1804C12.7768 13.5979 12.429 13.9364 12 13.9364C11.571 13.9364 11.2232 13.5979 11.2232 13.1804V7.64458Z" />
      <path d="M11.2232 16.2441C11.2232 15.8265 11.571 15.4881 12 15.4881C12.429 15.4881 12.7768 15.8265 12.7768 16.2441C12.7768 16.6616 12.429 17 12 17C11.571 17 11.2232 16.6616 11.2232 16.2441Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      />
    </svg>
  );
};

AlertCircleOutlined24.defaultProps = {
  fill: "currentColor",
};
