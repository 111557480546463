import React from "react";

import { IconProps } from "../types";

export const QuestionCircle24: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M10.97 8.265C10.7605 8.40902 10.5926 8.60559 10.483 8.835C10.3901 9.00564 10.2345 9.13355 10.0491 9.19178C9.86373 9.25001 9.66298 9.23402 9.48915 9.14717C9.31533 9.06032 9.18199 8.9094 9.11724 8.72619C9.05248 8.54298 9.06136 8.34179 9.142 8.165C9.342 7.763 9.655 7.339 10.139 7.017C10.627 6.69 11.244 6.5 12 6.5C12.658 6.5 13.369 6.695 13.934 7.119C14.249 7.34926 14.5044 7.65142 14.6791 8.00034C14.8537 8.34926 14.9425 8.73484 14.938 9.125C14.938 10.158 14.425 10.845 13.911 11.34C13.721 11.523 13.512 11.698 13.332 11.848L13.185 11.971C13.0308 12.0972 12.8854 12.2338 12.75 12.38V13.75C12.75 13.9489 12.671 14.1397 12.5303 14.2803C12.3897 14.421 12.1989 14.5 12 14.5C11.8011 14.5 11.6103 14.421 11.4697 14.2803C11.329 14.1397 11.25 13.9489 11.25 13.75V12.277C11.25 12.04 11.317 11.773 11.497 11.541C11.717 11.261 11.983 11.024 12.215 10.827L12.398 10.674L12.399 10.673C12.571 10.53 12.723 10.403 12.869 10.261C13.237 9.906 13.438 9.585 13.438 9.125C13.4424 8.96774 13.4078 8.81184 13.3373 8.6712C13.2668 8.53056 13.1626 8.40956 13.034 8.319C12.766 8.118 12.384 8 12 8C11.506 8 11.186 8.121 10.97 8.265ZM13 17C13 17.2652 12.8946 17.5196 12.7071 17.7071C12.5196 17.8946 12.2652 18 12 18C11.7348 18 11.4804 17.8946 11.2929 17.7071C11.1054 17.5196 11 17.2652 11 17C11 16.7348 11.1054 16.4804 11.2929 16.2929C11.4804 16.1054 11.7348 16 12 16C12.2652 16 12.5196 16.1054 12.7071 16.2929C12.8946 16.4804 13 16.7348 13 17Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM2.5 12C2.5 9.48044 3.50089 7.06408 5.28249 5.28249C7.06408 3.50089 9.48044 2.5 12 2.5C14.5196 2.5 16.9359 3.50089 18.7175 5.28249C20.4991 7.06408 21.5 9.48044 21.5 12C21.5 14.5196 20.4991 16.9359 18.7175 18.7175C16.9359 20.4991 14.5196 21.5 12 21.5C9.48044 21.5 7.06408 20.4991 5.28249 18.7175C3.50089 16.9359 2.5 14.5196 2.5 12Z"
      />
    </svg>
  );
};

QuestionCircle24.defaultProps = {
  fill: "currentColor",
};
