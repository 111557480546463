import { RiskListObject, Severity, ViolationDetails } from "./types";

const violationDetails: Record<Severity, ViolationDetails[]> = {
  critical: [
    {
      id: "1",
      title: "Noisy neighbor",
      cluster: "prod",
      namespace: "default-prod",
      serviceName: "inventory-api",
    },
    {
      id: "2",
      title: "Requests not configured",
      cluster: "prod",
      namespace: "default-prod",
      serviceName: "inventory-api",
    },
    {
      id: "3",
      title: "Requests not configured",
      cluster: "prod",
      namespace: "default-prod",
      serviceName: "events-puller-service",
    },
    {
      id: "4",
      title: "Workload is under provisioned",
      cluster: "prod",
      namespace: "default-prod",
      serviceName: "db-brain",
    },
    {
      id: "5",
      title: "High requests/limits ratio",
      cluster: "prod",
      namespace: "default-prod",
      serviceName: "db-brain",
    },
  ],
  medium: [],
  low: [],
};

export const riskDataList: RiskListObject[] = [
  {
    id: "1",
    title: "Node pressure",
    description:
      "Violations that could generate unexpected load on Nodes, causing them to suffer from increased stress on the nodes themselves, subsequently impacting the performance of workloads hosted on those nodes.",
    urgency: "urgent",
    violations: {
      totals: {
        critical: 15,
        medium: 58,
        low: 61,
      },
      details: violationDetails,
    },
    insights: [
      {
        title: "Pod evictions",
        value: "314",
      },
      {
        title: "Unallocated usage (%)",
        value: "68%",
        isSevere: true,
      },
    ],
  },
  {
    id: "2",
    title: "Degraded service",
    description:
      "Degraded service is a situation where the service desired replicas are often not ready. This can lead to decreased application performance, slower response times, and an unreliable user experience.",
    urgency: "moderate",
    violations: {
      totals: {
        critical: 9,
        medium: 85,
        low: 38,
      },
      details: violationDetails,
    },
    insights: [
      {
        title: "Unschedulable time",
        value: "2h 37m",
      },
      {
        title: "Unschedulable pods",
        value: "65",
      },
      {
        title: "HPAs limit reaches",
        value: "5",
      },
      {
        title: "HPAs in max capacity",
        value: "6h 21m",
      },
      {
        title: "Pod evictions",
        value: "214",
      },
    ],
  },
  {
    id: "3",
    title: "Flaky services",
    description:
      "Degraded service is a situation where the service desired replicas are often not ready. This can lead to decreased application performance, slower response times, and an unreliable user experience.",
    urgency: "moderate",
    violations: {
      totals: {
        critical: 12,
        medium: 95,
        low: 17,
      },
      details: violationDetails,
    },
    insights: [
      {
        title: "Total throttling time",
        value: "2h 37m",
      },
      {
        title: "Throttled Pods",
        value: "115",
      },
      {
        title: "Container restarts",
        value: "8",
      },
    ],
  },
  {
    id: "4",
    title: "Issue identification",
    description:
      "Failure to configure Kubernetes probes can result in unresponsive containers, leading to downtime, cascading failures, and inefficient scaling, affecting application reliability and performance",
    urgency: "moderate",
    violations: {
      totals: {
        critical: 3,
        medium: 82,
        low: 24,
      },
      details: violationDetails,
    },
    insights: [
      {
        title: "Missing liveness probes",
        value: "8",
      },
      {
        title: "Missing readiness probes",
        value: "8",
      },
    ],
  },
];
