import { muiColors, theme } from "@komodorio/design-system";
import { Tag, Typography } from "@komodorio/design-system/deprecated";

const TagColor: { [key in TagText]: string } = {
  "new!": muiColors.green[500] ?? "",
  alpha: muiColors.deepPurple[200] ?? "",
  beta: muiColors.deepPurple[200] ?? "",
};

export type TagText = "new!" | "alpha" | "beta";

type FeatureTagProps = {
  tag: TagText;
};

export function FeatureTag({ tag }: FeatureTagProps) {
  return (
    <Tag color={TagColor[tag]}>
      <Typography variant="uppercase" color={theme.background.bgDark}>
        {tag}
      </Typography>
    </Tag>
  );
}
