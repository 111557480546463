import React from "react";

import { IconProps } from "../types";

export const NewWindow16: React.FC<IconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path d="M14.0996 9C13.6023 9 13.1992 9.40312 13.1992 9.90039V12.009C13.1992 12.4114 13.0972 12.7117 12.8931 12.9099C12.689 13.1081 12.3979 13.2072 12.0197 13.2072H3.9803C3.60214 13.2072 3.31101 13.1081 3.10692 12.9099C2.90884 12.7117 2.80979 12.4114 2.80979 12.009V4C2.80979 3.5976 2.90884 3.2973 3.10692 3.0991C3.31101 2.9009 3.60214 2.8018 3.9803 2.8018H5.0991C5.59665 2.8018 6 2.39845 6 1.9009C6 1.40335 5.59665 1 5.0991 1H3.84524C2.91484 1 2.20653 1.24024 1.72032 1.72072C1.24011 2.1952 1 2.8979 1 3.82883V12.1802C1 13.1111 1.24011 13.8138 1.72032 14.2883C2.20653 14.7628 2.91484 15 3.84524 15H12.1548C13.0912 15 13.7995 14.7598 14.2797 14.2793C14.7599 13.8048 15 13.1051 15 12.1802V9.9004C15 9.40312 14.5969 9 14.0996 9Z" />
      <path d="M14.0416 6.65766C14.2637 6.65766 14.4408 6.58258 14.5729 6.43243C14.7049 6.28228 14.771 6.08709 14.771 5.84685V1.90991C14.771 1.5976 14.6869 1.36937 14.5189 1.22523C14.3568 1.07508 14.1347 1 13.8526 1H9.89083C9.65072 1 9.45564 1.06607 9.30557 1.1982C9.16151 1.33033 9.08948 1.50751 9.08948 1.72973C9.08948 1.95195 9.16151 2.12913 9.30557 2.26126C9.81012 2.70551 10.5966 3.00266 11.1784 3.45045L8.25211 6.37838C8.08404 6.54054 8 6.72973 8 6.94595C8 7.18619 8.07503 7.38138 8.2251 7.53153C8.37516 7.67568 8.56725 7.74775 8.80135 7.74775C9.03545 7.74775 9.23654 7.66366 9.40461 7.49549L12.3219 4.58559C12.7662 5.15547 13.0664 5.95681 13.5104 6.44144C13.6425 6.58559 13.8195 6.65766 14.0416 6.65766Z" />
    </svg>
  );
};

NewWindow16.defaultProps = {
  fill: "currentColor",
};
