import React from "react";

import { LogoIconProps } from "../types";

export const GoogleCloud: React.FC<LogoIconProps> = (svgProps) => {
  return (
    <svg
      role={svgProps.onClick ? "button" : "img"}
      aria-label="Google Cloud logo"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor={svgProps.onClick ? "pointer" : undefined}
      {...svgProps}
    >
      <path
        d="M14.6909 8.04187H15.3009L17.0395 6.30336L17.1249 5.56525C16.1289 4.6862 14.9253 4.07556 13.6278 3.79101C12.3302 3.50645 10.9816 3.55738 9.70918 3.93897C8.43679 4.32057 7.28268 5.02024 6.35588 5.97189C5.42907 6.92353 4.76017 8.09574 4.41235 9.37778C4.60603 9.2984 4.82058 9.28552 5.02236 9.34118L8.49939 8.76778C8.49939 8.76778 8.67629 8.47497 8.76779 8.49327C9.51281 7.67505 10.5424 7.17255 11.6458 7.08863C12.7492 7.00472 13.843 7.34573 14.7031 8.04187H14.6909Z"
        fill="#EA4335"
      />
      <path
        d="M19.516 9.37797C19.1164 7.90641 18.2959 6.5835 17.1553 5.57153L14.7152 8.01155C15.2238 8.42707 15.6313 8.95263 15.9071 9.54861C16.1829 10.1446 16.3197 10.7954 16.3073 11.452V11.8851C16.5925 11.8851 16.8749 11.9413 17.1384 12.0504C17.4019 12.1595 17.6413 12.3195 17.8429 12.5211C18.0446 12.7228 18.2045 12.9622 18.3137 13.2257C18.4228 13.4891 18.479 13.7715 18.479 14.0567C18.479 14.3419 18.4228 14.6243 18.3137 14.8877C18.2045 15.1512 18.0446 15.3906 17.8429 15.5923C17.6413 15.7939 17.4019 15.9539 17.1384 16.063C16.8749 16.1722 16.5925 16.2283 16.3073 16.2283H11.9641L11.531 16.6675V19.2722L11.9641 19.7054H16.3073C17.5202 19.7148 18.7038 19.3336 19.6832 18.6182C20.6626 17.9028 21.3858 16.8911 21.7456 15.7328C22.1055 14.5746 22.083 13.3313 21.6814 12.1868C21.2799 11.0423 20.5206 10.0575 19.516 9.37797Z"
        fill="#4285F4"
      />
      <path
        d="M7.61476 19.6808H11.958V16.2037H7.61476C7.30533 16.2037 6.99952 16.1371 6.71806 16.0085L6.10805 16.1976L4.35734 17.9362L4.20483 18.5462C5.1866 19.2875 6.38455 19.6861 7.61476 19.6808Z"
        fill="#34A853"
      />
      <path
        d="M7.61491 8.40165C6.4381 8.40868 5.29287 8.78308 4.33917 9.47257C3.38548 10.1621 2.67095 11.1322 2.29538 12.2475C1.91981 13.3628 1.90195 14.5675 2.24431 15.6935C2.58666 16.8194 3.27213 17.8103 4.20498 18.5277L6.7243 16.0084C6.40415 15.8638 6.12386 15.6436 5.9075 15.3668C5.69114 15.09 5.54516 14.7649 5.48208 14.4193C5.419 14.0737 5.44072 13.718 5.54535 13.3826C5.64999 13.0472 5.83443 12.7422 6.08284 12.4938C6.33126 12.2454 6.63623 12.061 6.9716 11.9563C7.30697 11.8517 7.66271 11.83 8.00832 11.8931C8.35392 11.9561 8.67906 12.1021 8.95584 12.3185C9.23262 12.5349 9.45278 12.8151 9.59742 13.1353L12.1167 10.616C11.588 9.92468 10.9064 9.36509 10.1255 8.98095C9.34449 8.59681 8.48524 8.39854 7.61491 8.40165Z"
        fill="#FBBC05"
      />
    </svg>
  );
};
