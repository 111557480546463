import React, { useState } from "react";
import styled from "styled-components";

import { ClickableArea } from "../styles";
import reliabilityItemClose from "../assets/cluster1/reliabilityItemClose.png";
import reliabilityItemOpenV2 from "../assets/cluster1/reliabilityItemOpenV2.png";

import { NoisyNeighborDrawer } from "./NoisyNeighborDrawer";
import { EndOfLifeDrawer } from "./EndOfLifeDrawer";
import { Item } from "./Item";
import { NotHighResiliencyDrawer } from "./NotHighResiliencyDrawer";
import { FailsToRenewCertificatesDrawer } from "./FailsToRenewCertificatesDrawer";

const SelectableArea = styled(ClickableArea)<{ top: string }>`
  width: 72.3%;
  height: 12.8%;
  top: ${({ top }) => top};
  left: 26.3%;
`;

export const ReliabilityItemV2: React.FC = () => {
  const [openEOLDrawer, setOpenEOLDrawer] = useState(false);
  const [openNNDrawer, setOpenNNDrawer] = useState(false);
  const [openNHRDrawer, setOpenNHRDrawer] = useState(false);
  const [openFTRCDrawer, setOpenFTRCDrawer] = useState(false);

  return (
    <Item closedImg={reliabilityItemClose} openedImg={reliabilityItemOpenV2}>
      <SelectableArea top={"32.25%"} onClick={() => setOpenEOLDrawer(true)} />
      <SelectableArea top={"45.2%"} onClick={() => setOpenNNDrawer(true)} />
      <SelectableArea top={"58.25%"} onClick={() => setOpenNHRDrawer(true)} />
      <SelectableArea top={"71.2%"} onClick={() => setOpenFTRCDrawer(true)} />
      <EndOfLifeDrawer
        open={openEOLDrawer}
        onClose={() => setOpenEOLDrawer(false)}
      />
      <NoisyNeighborDrawer
        open={openNNDrawer}
        onClose={() => setOpenNNDrawer(false)}
      />
      <NotHighResiliencyDrawer
        open={openNHRDrawer}
        onClose={() => setOpenNHRDrawer(false)}
      />
      <FailsToRenewCertificatesDrawer
        open={openFTRCDrawer}
        onClose={() => setOpenFTRCDrawer(false)}
      />
    </Item>
  );
};
