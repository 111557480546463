import React from "react";
import styled, { keyframes } from "styled-components";

import { theme } from "../../../theme";

/** @deprecated */
export type SkeletonVariant = "circle" | "square";

/** @deprecated */
export interface SkeletonProps {
  variant?: SkeletonVariant;
  height: string;
  width: string;
  primaryColor?: string;
  pulseColor?: string;
  duration?: number;
}

const pulseAnimation = (
  primaryColor: string | undefined,
  pulseColor: string | undefined
) => keyframes`
  0% {
    background-color: ${primaryColor}
  }
  50% {
  background-color: ${pulseColor}
  }
  100% {
  background-color: ${primaryColor}
}
`;

const Container = styled.div<SkeletonProps>`
  ${({ variant }) =>
    variant === "square"
      ? `
  border-radius: 1rem;`
      : `clip-path: circle(50% at 50% 50%);`}
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  display: block;
  background-repeat: repeat-y;
  background-size: ${({ variant }) => (variant === "square" ? 30 : 45)}% 80%;
  background-position: 0 0;
  animation-name: ${({ primaryColor, pulseColor }) =>
    pulseAnimation(primaryColor, pulseColor)};
  animation-duration: ${({ duration }) => duration}s;
  animation-iteration-count: infinite;
`;

/**
 * @deprecated Use MUI Skeleton instead.
 */
export const Skeleton: React.FC<SkeletonProps> = (props) => {
  return <Container aria-label="the content is loading" {...props} />;
};

Skeleton.defaultProps = {
  variant: "square",
  primaryColor: theme.background.bgGrayDark,
  pulseColor: theme.background.bgGray,
  duration: 2,
};
